import { Application } from "../entities/Application";
import { useEffect, useState } from "react";
import { useStorage } from "../../../hooks/useStorage";
import { useFetchData } from "../../../hooks/useFetchData";
import { Visitor } from "../entities/Visitor";
import { Applicant } from "../entities/Applicant";
import axios from "axios";
import { useNaturalSpace } from "../../../hooks/useNaturalSpace";
import { Preferences } from "@capacitor/preferences";
import { useAlert } from "../../../hooks/useAlert";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import { useIonRouter } from "@ionic/react";
import { useHistory } from "react-router";
import { useLoading } from "../../../hooks/useLoading";

const STORAGE_KEY_VISITORS = "stored-visitors";
const STORAGE_KEY_APPLICATIONS = "stored-applications";
const STORAGE_KEY_NATURALSPACE = "naturalspace";

const API_URL = process.env.REACT_APP_BACKEND_URL_BASE;

export const useApplication = () => {
  const {
    saveStorage,
    getStoredData,
    data: storedData,
    naturalSpaceUUID,
  } = useStorage(STORAGE_KEY_NATURALSPACE);

  const {
    data: storedVisistors,
    saveStorage: saveVisitors,
    removeStorage: removeVisitorsStorage,
  } = useStorage(STORAGE_KEY_VISITORS);

  const { data: storedApplications, saveStorage: saveApplications } =
    useStorage(STORAGE_KEY_APPLICATIONS);

  const {
    visitingShifts,
    visitorTypes,
    loading,
    isLoadingAllVisitingShifts,
    isLoadingVisitingShifts,
    isLoadingVisitorTypes,
  } = useNaturalSpace("application", naturalSpaceUUID);

  const { presentLoading, dismissLoading } = useLoading();

  const { presentAlert } = useAlert();
  const navigation = useIonRouter();
  const history = useHistory();
  const { data } = useFetchData();
  const { fetchData } = useFetchData();
  const { translation } = useAppTranslations();
  const application = new Application();
  const [applications, setApplications] = useState<Application[]>();
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [timer, setTimer] = useState(0);
  const [showEndAlert, setShowEndAlert] = useState(false);
  const [applicationData, setApplicationData] = useState(application);
  const [showComponent, setShowComponent] = useState({
    visitorCard: false,
  });
  const [sameCountry, sameCountryForAll] = useState<any>("");
  const [maxVisitors, setMaxVisitors] = useState<number>();

  useEffect(() => {
    const updatedVisitors = applicationData.visitors.map((visitor, index) => ({
      ...visitor,
      country: index === 0 ? visitor.country : sameCountry,
    }));

    applicationData.visitors = updatedVisitors;
    setApplicationData({ ...applicationData });
  }, [sameCountry]);

  // const getStoredAuthorizations = async () => {
  //   return await getStoredData()
  // }

  useEffect(() => {
    if (applicationData.visitingShift) {
      let timer = 0;

      const interval = setInterval(() => {
        timer += 1;

        if (timer === 10 || timer === 13) setTimeLeft(timer);

        if (timer === 15) {
          setShowEndAlert(true);
          clearInterval(interval);
        }
      }, 1000 * 60); // each 60 seconds

      return () => {
        clearInterval(interval);
      };
    }
  }, [applicationData.visitingShift]);

  useEffect(() => {
    // console.log("APPLICATION DATA", applicationData);
  }, [applicationData]);

  useEffect(() => {
    if (applicationData.visitors.length > 0) {
      // console.log("VISITORS", applicationData.visitors);
      saveVisitors(applicationData.visitors);
    }
  }, [applicationData.visitors]);

  const setTimeLeft = (timer: any) => {
    setTimer(15 - timer);
    setIsOpenToast(true);
  };

  //TODO: pasar a tipo Date visitingDate
  const handleDate = (visitingDate: any) => {
    setApplicationData({
      ...applicationData,
      visitingDate: visitingDate,
      visitingShift: "",
      visitors: [],
    });
    setShowComponent({
      ...showComponent,

      visitorCard: false,
    });
  };

  const handleShift = async (visitingShift: string) => {
    await lockNewApplication(visitingShift, applicationData.applicationUUID);
  };

  const addVisitor = async (visitorType: string, applicant: boolean) => {
    let newCountry = "";
    if (sameCountry !== "") {
      newCountry = sameCountry;
    }

    const newVisitor = applicant
      ? new Applicant(visitorType)
      : new Visitor(visitorType, newCountry);

    setApplicationData((applicationData) => ({
      ...applicationData,
      visitors: [...applicationData.visitors, newVisitor],
    }));

    setShowComponent({ ...showComponent, visitorCard: true });
  };

  const lockApplication = async (
    visitingDate: string,
    visitingShiftUUID: string,
    applicationUUID?: string
  ) => {
    try {
      presentLoading("", "undefined");
      if (!applicationUUID) {
        const url =
          API_URL + `newapplication/${visitingDate}/${visitingShiftUUID}`;
        const response = await axios.get(url);
        return response;
      } else {
        const url =
          API_URL +
          `newapplication/${visitingDate}/${visitingShiftUUID}/${applicationUUID}`;
        const response = await axios.get(url);
        return response;
      }
    } catch (error: any) {
      throw new Error(error);
    } finally {
      dismissLoading();
    }
  };

  const saveApplication = async (newApplication: any) => {
    try {
      presentLoading("", "undefined");
      const url = API_URL + "submitapplication";
      const response = await axios.post(url, newApplication);
      return response;
    } catch (error: any) {
      console.log("ERROR", error);
      let alertData: any = {};

      if (error.response.status === 409) {
        alertData = {
          header: translation("errors.application.registered_id_number.header"),
          subHeader: translation(
            "errors.application.registered_id_number.content"
          ),
          buttons: [
            {
              text: translation("errors.button.confirm"),
              role: "confirm",
            },
          ],
        };
      } else {
        alertData = {
          header: translation("errors.unknow.header"),
          subHeader: translation("errors.unknow.content"),
          buttons: [
            {
              text: translation("errors.button.confirm"),
              role: "confirm",
            },
          ],
        };
      }

      presentAlert(alertData);
    } finally {
      dismissLoading();
    }
  };

  const setVisitorsCountry = (country: string) => {
    applicationData.visitors.map((visitor: Visitor, index) => {
      visitorData[index] = {
        ...visitorData[index],
        country: country,
      };
    });
    const visitorData = [...applicationData.visitors];
    setApplicationData((applicationData) => ({
      ...applicationData,
      visitors: visitorData,
    }));
  };
  const updateVisitorData = async (index: number, newData: any) => {
    const { name, age, country, legalIdType, legalIdNum, email } = newData;
    const visitorData = [...applicationData.visitors];

    if (email) {
      visitorData[index] = {
        ...visitorData[index],
        email: email,
        name: name,
        age: age,
        country: country,
        legalID: {
          legalIdType: legalIdType,
          legalIdNum: legalIdNum,
        },
      };
    } else {
      visitorData[index] = {
        ...visitorData[index],
        name: name,
        age: age,
        country: country,
        legalID: {
          legalIdType: legalIdType,
          legalIdNum: legalIdNum,
        },
      };
    }

    if (email) {
      setApplicationData((applicationData) => ({
        ...applicationData,
        email: email,
        visitors: visitorData,
      }));
    } else {
      setApplicationData((applicationData) => ({
        ...applicationData,
        visitors: visitorData,
      }));
    }
  };

  const removeVisitor = async (visitor: any, id?: number) => {
    const lastVisitorType = applicationData.visitors
      .map((visitor) => visitor.visitorType)
      .lastIndexOf(visitor);
    // Filtrar el array original utilizando el índice obtenido
    const updatetedArray = applicationData.visitors.filter(
      (visitor, index) => index !== lastVisitorType
    );

    setApplicationData((applicationData) => ({
      ...applicationData,
      visitors: updatetedArray,
    }));
  };

  const lockNewApplication = async (
    visitingShift: string,
    applicationUUID?: string
  ) => {
    const response = await lockApplication(
      applicationData.visitingDate,
      visitingShift,
      applicationUUID
    );

    const { uuid, visitingShiftUUID, locator } = response.data.application;
    const { maxVisitorsPerApplication } = response.data;
    // find the visitor type uuid with alias "Adult"
    setMaxVisitors(maxVisitorsPerApplication);

    const adultVisitorType = visitorTypes.find(
      (visitorType: any) => visitorType.alias === "Adult"
    );

    setApplicationData((prevState: any) => ({
      ...prevState,
      applicationUUID: uuid,
      locator: locator,
      visitingShift: visitingShiftUUID,
      visitors: [new Applicant(adultVisitorType.uuid)],
    }));
  };

  const sendData = async (applicationData: any, language: any) => {
    try {
      const { email, visitingDate, visitingShift, visitors } = applicationData;

      const newVisitors = visitors.map((visitor: Applicant) => {
        const { name, age, country, isPrincipal, visitorType, legalID } =
          visitor;
        const { legalIdType, legalIdNum } = legalID;

        return {
          isPrincipal: isPrincipal,
          name: name,
          age: age,
          country: country,
          visitorType: visitorType,
          legalID: {
            legalIdType: legalIdType,
            legalIdNum: legalIdNum,
          },
        };
      });

      const newApplication = {
        applicationUUID: applicationData.applicationUUID,
        email: email,
        visitingDate: visitingDate,
        visitingShiftUUID: visitingShift,
        visitors: newVisitors,
        language: language,
      };

      // console.log("SEND DATA", newApplication);
      const response = await saveApplication(newApplication);
      // console.log("RESPONSE", response);

      if (response?.status === 200) {
        const applicationToStore = response.data;

        const updateStoredApplications = [
          ...storedApplications,
          applicationToStore,
        ];
        await Preferences.set({
          key: "stored-applications",
          value: JSON.stringify(updateStoredApplications),
        });
        await saveApplications(updateStoredApplications);
        showApplicationAlert();
      }
      // console.log("RESPUESTA", response);
      return response;
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const showApplicationAlert = () => {
    const alertData = {
      header: translation("confirm_application_alert.header"),
      subHeader: translation("confirm_application_alert.subheader"),
      buttons: [
        {
          text: translation("confirm_application_alert.buttons.cancel"),
          role: "cancel",
          handler: () => {
            window.location.reload();
          },
        },
        {
          text: translation("confirm_application_alert.buttons.confirm"),
          role: "confirm",
          handler: () => {
            history.push("/authorisations");
          },
        },
      ],
    };

    presentAlert(alertData);
  };

  return {
    sendData,
    handleDate,
    handleShift,
    applicationData,
    storedApplications,
    applications,
    showComponent,
    lockNewApplication,
    isOpenToast,
    timer,
    showEndAlert,
    data,
    setIsOpenToast,
    //getStoredAuthorizations,
    setVisitorsCountry,
    addVisitor,
    removeVisitor,
    removeVisitorsStorage,
    updateVisitorData,
    sameCountryForAll,
    sameCountry,
    visitingShifts,
    visitorTypes,
    maxVisitors,
    loading,
    isLoadingAllVisitingShifts,
    isLoadingVisitingShifts,
    setApplicationData,
    isLoadingVisitorTypes,
  };
};

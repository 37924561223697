import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import Header from "../../../components/Header";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import DOMPurify from "dompurify";
import MNXOFooter from "../../../components/MNXOFooter";
import { useLoading } from "../../../hooks/useLoading";
import MNXOContent from "../../../components/MNXOContent";

import { useTranslation } from "react-i18next";
const Information: React.FC = () => {
  const { infoTranslation: information } = useAppTranslations("general_info");

  // Loading
  const { presentLoading, dismissLoading } = useLoading();
  const isLoading = !information;

  useEffect(() => {
    isLoading ? presentLoading() : dismissLoading();
  }, [isLoading]);

  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });
  const [t, i18n] = useTranslation("global");
  // information && // console.log(information);
  return (
    <>
      {information && (
        <IonPage>
          <Header isWhiteBackground={false} />

          <MNXOContent containerStyle="information-container">
            <div className="information-content ion-padding">
              {/* <h2>
                {information &&
                information.title &&
                information.language === "ES"
                  ? information.title
                  : t("info-page.header.title")}
              </h2> */}
              <div
                dangerouslySetInnerHTML={
                  information && {
                    __html: DOMPurify.sanitize(information.content),
                  }
                }
              ></div>
            </div>
          </MNXOContent>
          <MNXOFooter className="MNXOModalFooter" />
        </IonPage>
      )}
    </>
  );
};

export default Information;

import React, { useEffect, useState } from "react";
import { Storage } from "@ionic/storage";
import ErrorPage from "../../../pages/ErrorPage";

import LoginForm from "../../information/components/LoginForm";
const INTRO_KEY = "intro-seen";

const Login: React.FC = () => {
  const [error] = useState(null);
  const [introDone, setIntroDone] = useState(false);
  const [store, setStore] = useState<Storage>();

  useEffect(() => {
    const setupStorage = async () => {
      const newStore = new Storage();
      const store = await newStore.create();
      setStore(store);

      const introStorage = (await store!.get(INTRO_KEY)) || false;
      setIntroDone(introStorage);
    };

    setupStorage();
  }, []);

  return <>{error ? <ErrorPage /> : <LoginForm />}</>;
};

export default Login;

import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonRow,
  IonText,
} from "@ionic/react";
import { Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { getSliderImages } from "../mockups/images";
import { closeOutline } from "ionicons/icons";
import MNXOZoomModal from "./MNXOZoomImageModal";

const MNXOGallerySlider: React.FC<{ selectedCategory?: any }> = ({
  selectedCategory,
}) => {
  const { t } = useTranslation("global");
  const imageCategories = getSliderImages(t);
  const [showImages, setShowImages] = useState<any>([]);
  const [showOnlyHighlighted, setShowOnlyHighlighted] = useState(true);
  const [showAllClicked, setShowAllClicked] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  useEffect(() => {
    if (selectedCategory) {
      setShowImages(imageCategories[selectedCategory]);
      setShowOnlyHighlighted(true);
      setShowAllClicked(false);
    }
  }, [selectedCategory]);

  const handleImageClick = (image: any) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <IonGrid fixed id="gallery">
      <IonRow>
        <IonCol size="12">
          {/* <div className="link-gallery-slider">
            {showImages.length > 0 && !showAllClicked ? (
              <IonText onClick={() => setShowAllClicked(true)}>
                <a>
                  {showOnlyHighlighted
                    ? t("button.show_all")
                    : t("button.show_highlighted")}
                </a>
              </IonText>
            ) : null}
          </div> */}
          <Swiper
            spaceBetween={10}
            slidesPerView={3}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            className="gallery-slides"
          >
            {showImages
              .sort(() => Math.random() - 0.5)
              .map((image: any, index: number) => (
                <SwiperSlide key={index}>
                  <IonImg
                    className="swiper-slide"
                    src={image.src}
                    alt={image.alt}
                    onClick={() => handleImageClick(image)}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
          {selectedImage && (
            <MNXOZoomModal
              selectedImage={selectedImage}
              handleClose={handleClose}
            />
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MNXOGallerySlider;

import { useEffect, useState } from "react";
import { useFetchData } from "./useFetchData";
import { useStorage } from "./useStorage";

const API_URL = process.env.REACT_APP_BACKEND_URL_BASE;

const APP_PAGES = {
  HOME: "home",
  APPLICATION: "application",
};

export type VisitingShift = [
  {
    date: string;
    visitingShifts: [{}];
  }
];

export const useNaturalSpace = (
  currentPage: string = APP_PAGES.APPLICATION,
  naturalSpaceUUIDd?: string
) => {
  // const { naturalSpaceUUID } = useStorage(STORAGE_KEY);
  const STORAGE_KEY_NATURALSPACE = "naturalspace";

  const {
    data: visitingShifts,
    loading: isLoadingVisitingShifts,
    fetchData: fetchVisitingShifts,
  } = useFetchData();

  const {
    data: allVisitingShifts,
    loading: isLoadingAllVisitingShifts,
    fetchData: fetchAllVisitingShifts,
  } = useFetchData();

  const {
    data: visitorTypes,
    loading: isLoadingVisitorTypes,
    error: visitorTypesError,
    fetchData: fetchVisitorTypes,
  } = useFetchData();

  const {
    data: pointsOfInterest,
    loading: isLoadingPointsOfInterest,
    error: pointsOfInterestError,
    fetchData: fetchPointsOfInterest,
  } = useFetchData();

  const [loading, setLoading] = useState(true);
  let promises: any;

  useEffect(() => {
    const getNaturalSpaceData = async () => {
      // console.log("NATURAL SPACE UUID", naturalSpaceUUID);
      await getData();
    };
    getNaturalSpaceData();
  }, []);

  const getData = async () => {
    if (currentPage !== APP_PAGES.HOME) {
      !allVisitingShifts && (await getAllVisitingShifts());
      !visitingShifts && (await getVisitingShifts());
      !visitorTypes && (await getVisitorTypes());
    }

    if (currentPage === APP_PAGES.HOME) {
      !pointsOfInterest && (await getPointsOfInterest());
    }
  };

  const getVisitingShifts = async () => {
    const url = API_URL + `visitingshift`;
    // console.log("llamada al backend - TURNOS", naturalSpaceUUID);
    await fetchVisitingShifts(url);
  };

  const getPointsOfInterest = async (alias: string = "") => {
    try {
      const url = API_URL + `pointsofinterest/${alias}`;
      // console.log("llamada al backend - PUNTOS DE INTERES", naturalSpaceUUID);
      await fetchPointsOfInterest(url);
    } catch (error) {
      // console.log(error);
    }
  };

  const getAllVisitingShifts = async () => {
    const url = API_URL + `allshifts`;
    // console.log("llamada al backend - TODOS LOS TURNOS", naturalSpaceUUID);
    await fetchAllVisitingShifts(url);
  };

  const getVisitorTypes = async () => {
    const url = API_URL + `visitortype/`;
    // console.log(
    //   "llamada al backend - TODOS LOS TIPSO DE VISITANTES",
    //   naturalSpaceUUID
    // );

    await fetchVisitorTypes(url);
  };

  const saveNaturalSpaceInStorage = async (naturalSpace: any) => {
    // await saveStorage(naturalSpace);
  };

  return {
    loading,

    visitingShifts,
    visitorTypes,
    allVisitingShifts,
    isLoadingVisitingShifts,
    isLoadingAllVisitingShifts,
    isLoadingVisitorTypes,
    saveNaturalSpaceInStorage,
    pointsOfInterest,
    isLoadingPointsOfInterest,
  };
};

import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";

interface Props {
  text: string;
  subtext?: string;
  informationedit?: boolean;
}

const MNXODescriptiveText: React.FC<Props> = ({
  text,
  subtext,
  informationedit,
}) => {
  return (
    <IonGrid style={{ paddingBottom: "16px" }}>
      <div className={`${!informationedit ? "background-white" : ""}`}>
        <div className="background-body-color" style={{ paddingTop: "32px" }}>
          <IonRow>
            <IonCol className="flex-center">
              <h3 className="description-text">{text}</h3>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="flex-center">
              <h3 className="description-text">{subtext}</h3>
            </IonCol>
          </IonRow>
        </div>
      </div>
    </IonGrid>
  );
};

export default MNXODescriptiveText;

import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */

import "./theme/fonts.scss";
import "./theme/general.scss";
import "./theme/reset.scss";
import "./theme/variables.css";

import Menu from "./components/Menu";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import { AuthProvider, PrivateRoute } from "./providers/AuthProviders";
import Application from "./useCases/application/pages/Application";
import Faqs from "./useCases/faq/pages/MNXOFAQPage";
import Information from "./useCases/information/pages/Information";
import Informationnaturalpark from "./useCases/information/pages/MNXOInformationLobos";
import Login from "./useCases/login/pages/Login";
// import ValidateManualApplications from "./pages/ValidateManualApplications";
import SplashPage from "../src/pages/Splash";
import MasonryGallery from "./components/MNXOMasonryGallery";
import InfoInterest from "./pages/InfoInterest";
import MNXOComponentPage from "./pages/MNXOComponentPage";
import ModalTest from "./pages/MNXOModalInterest";
import ModalControllerTest from "./pages/ModalControllerTest";
import PhotoGallery from "./pages/PhotoGallery";
import Welcome from "./pages/Welcome";
import MNXOApplicationFooter from "./useCases/application/components/MNXOApplicationFooter";
import MNXOMenu from "./useCases/application/pages/MNXOMenu";
import RecoverAuthorisation from "./useCases/application/pages/RecoverAuthorisation";
import Rules from "./useCases/information/pages/Rules";
import MNXOTabsFooter from "./components/MNXOTabsFooter";
import MNXOMaintenance from "./pages/MNXOMaintenance";
import { useEffect, useState } from "react";
import { useFetchData } from "./hooks/useFetchData";
import MNXOGroupApplication from "./useCases/dashboard/pages/MNXOGroupApplication";

setupIonicReact();

const App: React.FC = () => {
  const [isActiveMaintenanceMode, setIsActiveMaintenanceMode] =
    useState<boolean>();
  const { data, loading, error, fetchData } = useFetchData();

  useEffect(() => {
    const API_URL_BASE = process.env.REACT_APP_BACKEND_URL_BASE;
    const endpoint = `${API_URL_BASE}maintenance-mode`;

    const getMaintenanceMode = async () => {
      await fetchData(endpoint);
    };

    getMaintenanceMode();
  }, []);

  useEffect(() => {
    // console.log("data", data);
    if (data) {
      // console.log("data", data);
      if (data?.maintenanceMode.isActive) {
        setIsActiveMaintenanceMode(true);
      }
    }
  }, [data]);

  return (
    <AuthProvider>
      <IonApp>
        {data !== null && (
          <>
            {isActiveMaintenanceMode === true ? (
              <IonReactRouter>
                <IonRouterOutlet id="main" placeholder={undefined}>
                  <Route exact path="/" component={MNXOMaintenance} />
                  <Route exact path="/login" component={Login} />
                  <PrivateRoute>
                    <Route
                      exact
                      path="/dashboard/main"
                      component={MNXOTabsFooter}
                    />
                  </PrivateRoute>
                </IonRouterOutlet>
              </IonReactRouter>
            ) : (
              <IonReactRouter>
                <IonSplitPane
                  when="lg"
                  contentId="main"
                  disabled={isActiveMaintenanceMode}
                >
                  <Menu />
                  {/* Mostrar el menú solo si no se muestra el splash */}
                  <IonRouterOutlet id="main" placeholder={undefined}>
                    <Route exact path="/" component={SplashPage} />
                    <Route exact path="/home" component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/home" component={Home} />
                    <Route exact path="/welcome" component={Welcome} />
                    <Route exact path="/error" component={ErrorPage} />
                    <Route exact path="/application" component={Application} />
                    <Route exact path="/test/" component={MNXOMenu} />
                    <Route exact path="/gallery" component={PhotoGallery} />
                    <Route exact path="/masonry" component={MasonryGallery} />
                    <Route exact path="/modaltest" component={ModalTest} />
                    <Route
                      exact
                      path="/info-interest"
                      component={InfoInterest}
                    />
                    <Route
                      exact
                      path="/modal-test"
                      component={ModalControllerTest}
                    />

                    <Route
                      exact
                      path="/authorisations/cancel"
                      component={RecoverAuthorisation}
                    />
                    <Route
                      exact
                      path="/authorisations"
                      component={RecoverAuthorisation}
                    />
                    {/* rutas info */}
                    <Route exact path="/info" component={Information} />
                    <Route exact path="/info/faqs" component={Faqs} />
                    <Route
                      exact
                      path="/info/naturalspace"
                      component={Informationnaturalpark}
                    />
                    {/* <Route exact path="/info/contact" component={Contact} /> */}
                    <Route exact path="/info/rules" component={Rules} />
                    <Route
                      exact
                      path="/maintenance"
                      component={MNXOMaintenance}
                    />

                    {/* PRIVATE ROUTES */}

                    <PrivateRoute>
                      <Route
                        exact
                        path="/dashboard/main"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/group-application"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/history"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/configuration"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/incident"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/incident/show"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/incident/create"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/incident/confirmation"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/configuration/information"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/configuration/visitingconfiguration"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/configuration/visitingconfiguration/edit"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/configuration/visitingshift/edit"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/configuration/openinghours/edit"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/configuration/visitortypes/edit"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/profile"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/control"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/statistics"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/visitors"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/validation"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/validation/scan"
                        component={MNXOTabsFooter}
                      />
                      <Route
                        exact
                        path="/dashboard/validation/manual"
                        component={MNXOTabsFooter}
                      />

                      <Route
                        exact
                        path="/componentpage"
                        component={MNXOComponentPage}
                      />
                      <Route
                        exact
                        path="/applicationfooter"
                        component={MNXOApplicationFooter}
                      />

                      <Route exact path="/error" component={ErrorPage} />
                    </PrivateRoute>
                  </IonRouterOutlet>
                </IonSplitPane>
              </IonReactRouter>
            )}
          </>
        )}
      </IonApp>
    </AuthProvider>
  );
};

export default App;

export class History {
    description: string;
    comment: string;
    modifiedBy: string;

    constructor() {
        this.description = "";
        this.comment = "";
        this.modifiedBy = "";
    }
}
import { atOutline, home, homeOutline, mailOutline } from "ionicons/icons";

const path = "app_data.routes.navigation.";

export const getNavigationRoutes = (t: any) => {
  const navigationRoutes = [
    {
      title: t(path + "home.title"),
      url: t(path + "home.url"),
      icon: homeOutline,
    },
    // {
    //   title: t(path + "contact.title"),
    //   url: t(path + "contact.url"),
    //   icon: atOutline,
    // },
    // {
    //   title: t(path + "applications.title"),
    //   url: t(path + "applications.url"),
    //   icon: ticket,
    // },
    // {
    //   title: t(path + "authorisations.title"),
    //   url: t(path + "authorisations.url"),
    //   icon: key,
    // },
  ];
  return navigationRoutes;
};

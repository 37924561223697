import { useEffect, useState } from "react";
import { Incident } from "../entities/Incident";
import { useStorage } from "../../../hooks/useStorage";
import { useFetchData } from "../../../hooks/useFetchData";
import axios from "axios";
import FormData from "form-data";
import { Preferences } from "@capacitor/preferences";
import { Geolocation } from "@capacitor/geolocation";
import { useHistory } from "react-router-dom";
import { useAlert } from "../../../hooks/useAlert";
import { useAppTranslations } from "../../../hooks/useAppTranslations";

const API_URL = process.env.REACT_APP_BACKEND_URL_BASE;

export const lobosCoords = {
  latitude: 28.748910934879248,
  longitude: -13.821847076757813,
};

export const useIncident = (email?: string) => {
  const incident = new Incident();
  const { translation } = useAppTranslations();
  const history = useHistory();
  const [incidentData, setIncidentData] = useState(incident);
  const [agentsList, setAgentsList] = useState<any | null>(null);
  const [loggedUser, setLoggedUser] = useState<any | null>(null);
  const { presentAlert } = useAlert();
  const {
    data: incidentsAgentList,
    loading: isIncidentsAgentListLoading,
    error: incidentsAgentListError,
    fetchData: fetchIncidentsAgentList,
  } = useFetchData();

  const {
    data: incidentTypes,
    loading: isIncidentTypesLoading,
    error: incidentTypesError,
    fetchData: fetchIncidentTypes,
  } = useFetchData();

  const {
    data: agentIncidents,
    loading: isAgentIncidentsLoading,
    error: agentIncidentsError,
    fetchData: fetchAgentIncidents,
  } = useFetchData();

  const {
    data: createdIncident,
    loading,
    error,
    fetchData: fetchCreateIncident,
  } = useFetchData();

  useEffect(() => {
    const getData = async () => {
      !incidentsAgentList && (await getIncidentsAgentsList());
      !incidentTypes && (await getIncidentTypes());
      !createdIncident && (await getAgentIncidents());
    };
    getData();
  }, []);

  useEffect(() => {
    const setAgentList = async () => {
      if (incidentsAgentList) {
        // console.log(incidentsAgentList);
        const currentAgent = await getCurrentAgent();
        setLoggedUser(currentAgent);
        const otherAgents = await incidentsAgentList.filter(
          (agent: any) => agent !== currentAgent
        );
        // console.log("logged", loggedUser);
        // console.log("others", otherAgents);

        setAgentsList(otherAgents);
      }
    };
    setAgentList();
  }, [incidentsAgentList]);

  const sendPhoto = async (event: any) => {
    try {
      let data = new FormData();

      data.append("INCIDENT", event.target.files[0]);
      const fileName = event.target.files[0].name;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_URL}uploadphotos`,
        data: data,
      };

      const response = await axios.request(config);

      if (response) {
        // console.log("RESPONSE", response.data);

        const updatedPhotosArray = [...incidentData.photos!, fileName];

        await updateIncidentData({
          ...incidentData,
          photos: updatedPhotosArray,
        });
      }
    } catch (error) {
      // console.log("ERROR", error);
    }
  };

  const getCurrentAgent = async () => {
    const { value } = await Preferences.get({ key: "logged-user" });
    const currentUser = await JSON.parse(value!);

    // console.log(currentUser.username);
    const currentAgent = await incidentsAgentList.find(
      (agent: any) => agent.email === currentUser.username
    );
    // console.log(incidentsAgentList);
    return currentAgent;
  };

  const getIncidentsAgentsList = async () => {
    const url = API_URL + `agents`;
    fetchIncidentsAgentList(url);
  };

  const getIncidentTypes = async () => {
    const url = API_URL + `incidenttypes`;
    fetchIncidentTypes(url);
  };

  const getAgentIncidents = async () => {
    const { value } = await Preferences.get({ key: "logged-user" });
    const loggedUser = JSON.parse(value!);
    // const { username } = loggedUser;

    const url = API_URL + `incidents/${loggedUser.username}`;
    return fetchAgentIncidents(url);
  };

  const createIncident = async () => {
    try {
      const url = API_URL + "incident";

      let agentuuid: any[] = [];

      agentuuid.push(loggedUser.uuid);

      incidentData.agents.map((agent: any) => {
        agentuuid.push(agent.uuid);
      });

      const data = {
        incidentData: {
          agents: agentuuid,
          incidentStatus: incidentData.incidentStatus,
          incidentType: incidentData.incidentType,
          date: incidentData.date,
          description: incidentData.description,
          observations: incidentData.observations,
          photos: incidentData.photos,
          location: { address: incidentData.location.address },
          // modifiedBy: loggedUser.username
        },
      };

      // console.log("DATA", data);

      await fetchCreateIncident(url, "post", data);
      return createdIncident;
    } catch (error) {
      // console.log("ERROR", error);
    }
  };

  const updateIncidentData = async (formData: any) => {
    // const formatedDate = await formData.date.toISOString().slice(0, 16);
    const {
      agents,
      incidentStatus,
      incidentType,
      date,
      description,
      observations,
      location,
      photos,
    } = formData;

    setIncidentData((incidentData) => ({
      ...incidentData,
      agents: agents,
      incidentStatus: incidentStatus,
      incidentType: incidentType,
      date: date,
      description: description,
      observations: observations,
      location: location,
      photos: photos,
    }));
  };

  //TODO: usar incidentFormData en vez de "data" para pasar el objeto a createIncident

  return {
    incidentData,
    updateIncidentData,
    incidentsAgentList: agentsList,
    incidentTypes,
    agentIncidents,
    createIncident,
    sendPhoto,
    loggedUser,
  };
};

import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchData } from "../hooks/useFetchData";

interface RemainingTime {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const MNXOMaintenance: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  const [maintenanceModeValidThrough, setMaintenanceModeValidThrough] =
    useState<Date | null>(null);

  const { data, loading, error, fetchData } = useFetchData();

  useIonViewWillEnter(() => {
    const API_URL_BASE = process.env.REACT_APP_BACKEND_URL_BASE;
    const endpoint = `${API_URL_BASE}maintenance-mode`;
    // axios call to get maintenance mode

    const getMaintenanceMode = async () => {
      await fetchData(endpoint);
    };

    getMaintenanceMode();
  });

  const calculateRemainingTime = () => {
    const now: any = new Date();
    const dateUntil: any = maintenanceModeValidThrough;
    const timeDifference: any = dateUntil - now;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    if (
      Math.sign(days) === -1 ||
      Math.sign(hours) === -1 ||
      Math.sign(minutes) === -1 ||
      Math.sign(seconds) === -1
    ) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return { days, hours, minutes, seconds };
  };

  const [remainingTime, setRemainingTime] = useState<RemainingTime | null>(
    null
  );

  useEffect(() => {
    if (data) {
      if (data.maintenanceMode.validThrough) {
        setMaintenanceModeValidThrough(
          new Date(data.maintenanceMode.validThrough)
        );
      }
    }
  }, [data]);

  useEffect(() => {
    if (maintenanceModeValidThrough) {
      // console.log("maintenanceMode", maintenanceModeValidThrough);
      const timer = setInterval(() => {
        setRemainingTime(calculateRemainingTime());
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [maintenanceModeValidThrough]);

  return (
    <IonPage>
      {maintenanceModeValidThrough && (
        <IonContent scrollY={false} className="maintenance">
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol size="10">
                <IonText>
                  <h2>{t("maintenance.title")}</h2>
                  <h3>{t("maintenance.subtitle")}</h3>
                  <p>{t("maintenance.content.first")}</p>
                  <p>{t("maintenance.content.second")}</p>
                  <p>{t("maintenance.content.third")}</p>
                  {remainingTime &&
                    remainingTime.days +
                      remainingTime.hours +
                      remainingTime.minutes +
                      remainingTime.seconds >
                      0 && (
                      <p id="timer">
                        {`${remainingTime.days} ${t("maintenance.timer.days")}, 
                  ${remainingTime.hours} ${t("maintenance.timer.hours")}, 
                  ${remainingTime.minutes} ${t("maintenance.timer.minutes")}, 
                  ${remainingTime.seconds} ${t("maintenance.timer.seconds")}`}
                      </p>
                    )}
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      )}
    </IonPage>
  );
};

export default MNXOMaintenance;

import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

interface LottieIconProps {
  src: string;
  className?: string;
  loop?: boolean;
  autoplay?: boolean;
}

const MNXOLottieIcon: React.FC<LottieIconProps> = ({
  src,
  className = '',
  loop = true,
  autoplay = true,
}) => {
  return (
    <Player
      src={src}
      className={className}
      loop={loop}
      autoplay={autoplay}
    />
  );
};

export default MNXOLottieIcon;

import { IonCard, IonCardHeader, IonModal, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import MNXOContent from "../../../components/MNXOContent";
import Header from "../../../components/Header";
import { useLoading } from "../../../hooks/useLoading";
import { useOpeningHours } from "../hooks/useOpeningHours";
import MNXOOpeningHoursForm from "../components/MNXOOpeningHoursForm";
import { useTranslation } from "react-i18next";

const MNXOOpeningHoursEdit: React.FC = () => {
  const {
    openingHours,
    loading,
    setOpeningHoursFormData,
    openingHoursFormData,
    updateOpeningHours,
    sendOpeningHours,
  } = useOpeningHours();

  // openingHours && // console.log("openingHours", openingHours);

  // TODO: implementar dentro de MNXOLContent
  const { presentLoading, dismissLoading } = useLoading();
  const isLoading = !openingHours && !openingHoursFormData;
  const [t, i18n] = useTranslation("global");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    isLoading ? presentLoading() : dismissLoading();
  }, [isLoading]);

  const handleCardClick = (openingHour: any) => {
    setOpeningHoursFormData(openingHour);
    handleOpen();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <IonPage>
      <Header
        backButtonLink={"/dashboard/configuration/visitingconfiguration"}
      />
      <MNXOContent containerStyle="ion-padding">
        <h2>{t("pages.opening_hours_edition.title")}</h2>

        {openingHoursFormData && (
          <MNXOOpeningHoursForm
            openingHoursData={openingHoursFormData}
            updateOpeningHours={updateOpeningHours}
            sendOpeningHours={sendOpeningHours}
            isOpen={isOpen}
            handleClose={handleClose}
          />
        )}

        {openingHours?.map((openingHour: any, index: number) => {
          return (
            <IonCard
              key={index}
              button
              style={{ marginBottom: "16px" }}
              className="ion-padding"
              onClick={() => handleCardClick(openingHour)}
            >
              <IonCardHeader>
                {/* Turno {openingHour.translations[0].name} */}
              </IonCardHeader>
            </IonCard>
          );
        })}
      </MNXOContent>
    </IonPage>
  );
};

export default MNXOOpeningHoursEdit;

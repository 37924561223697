import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonButton,
  IonIcon,
  IonItem,
  IonCol,
  IonRow,
  IonGrid,
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { getAuthRoutes } from "../routes/menu/authorisations.routes";
import { getInfoRoutes } from "../routes/menu/info.routes";
import { getNavigationRoutes } from "../routes/menu/navegation.routes";
import { useEffect, useRef, useState } from "react";
import { useCamera } from "../useCases/validation/hooks/useCamera";
// import "../theme/text.scss";

interface ContainerProps {
  name?: string;
  type?: string;
  hide?: boolean;
  img?: boolean;
  isWhiteBackground?: boolean;
  className?: string;
  backButtonLink?: string;
}
const Header: React.FC<ContainerProps> = ({
  name,
  type,
  img,
  hide,
  isWhiteBackground,
  className,
  backButtonLink,
}) => {
  const [t, i18n] = useTranslation("global");
  const navigationRoutes = getNavigationRoutes(t);
  const checkbox = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const infoRoutes = getInfoRoutes(t);
  const authRoutes = getAuthRoutes(t);
  const location = useLocation(); // Obtener la ubicación actual de la página
  const { stopCamera, startCamera } = useCamera();
  const isActivePage = (path: string): boolean => {
    // Verificar si la ubicación actual coincide con la ruta proporcionada
    return location.pathname === path;
  };
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname !== "/dashboard/validation/scan" && isActive) {
      stopCamera();
      setIsActive(false);
    } else {
      if (location.pathname === "/dashboard/validation/scan" && !isActive) {
        startCamera();
        setIsActive(true);
      }
    }
  }, [location]);
  const handleClick = (e: any) => {
    // console.log(e);
    if (checkbox.current.checked) {
      setIsOpen(true);
    }
    if (isOpen && e.target.id !== "menu") {
      // console.log(e.target.id);
      setIsOpen(false);
      checkbox.current.checked = false;
    }
  };
  // console.log(location);
  return (
    <IonHeader className="ion-no-border">
      <IonGrid>
        <IonToolbar
          className={`header ${type === "transparent" && "transparent"}`}
          style={
            isWhiteBackground
              ? { "--background": "white", "--color": "black" }
              : undefined
          }
        >
          <IonRow>
            <IonCol
              size="auto"
              className={`header-left-buttons ${
                type === "transparent" && "transparent"
              }`}
            >
              {!hide && (
                <IonItem
                  lines="none"
                  routerLink={`${backButtonLink ? backButtonLink : "/home"}`}
                >
                  <IonIcon
                    className="text-blue"
                    icon={chevronBackOutline}
                  ></IonIcon>
                </IonItem>
              )}
            </IonCol>

            <IonCol
              className={`header-mid-base ${
                (type === "transparent" && "transparent") ||
                (!name && "transparent")
              }`}
            >
              <IonRow
                className={`ion-align-items-center ion-justify-content-center ${
                  !img && "header-mid-base-logo"
                }`}
              >
                {name ? (
                  <>
                    <IonTitle
                      className={`ion-text-center header-mid-radius ${
                        type === "transparent" && "transparent"
                      }`}
                    >
                      {name}
                    </IonTitle>
                  </>
                ) : (
                  <>
                    {!img && (
                      <IonCol size="12" className="header-mid-base-logo radius">
                        <IonRow className="ion-justify-content-center ion-align-items-center">
                          <img
                            src="assets/Logo_Lobos.png"
                            height={"30px"}
                            alt="Islote de Lobos logo"
                          />
                        </IonRow>
                      </IonCol>
                    )}
                  </>
                )}
              </IonRow>
            </IonCol>
            <IonCol
              size="auto"
              className={`header-right-buttons ${
                type === "transparent" && "transparent"
              }`}
            >
              <IonRow className="ion-align-items-center">
                <IonCol
                  size="auto"
                  className={`header-right-buttons-base ${
                    type === "transparent" && "transparent"
                  }`}
                >
                  <IonButtons className="header-buttons">
                    <IonButton
                      className={
                        i18n.language === "es-ES" ? "selected-language" : ""
                      }
                      onClick={() => i18n.changeLanguage("es-ES")}
                    >
                      ES
                    </IonButton>
                    <IonButton
                      className={
                        i18n.language === "en-EN" ? "selected-language" : ""
                      }
                      onClick={() => i18n.changeLanguage("en-EN")}
                    >
                      EN
                    </IonButton>
                    <IonMenuButton />
                    {/* <label className="nav__btn" htmlFor="menu-cb"></label> */}
                  </IonButtons>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonGrid>
    </IonHeader>
  );
};

export default Header;

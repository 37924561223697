import React from 'react';
import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';

interface ZoomModalProps {
  selectedImage: any;
  handleClose: () => void;
}

const MNXOZoomModal: React.FC<ZoomModalProps> = ({ selectedImage, handleClose }) => {
  return (
    <div className="modal-container">
      <div className="modal-content">
        <img className="modal-image" src={selectedImage.src} alt={selectedImage.alt} />
        <IonIcon className="close-button" onClick={handleClose} icon={closeOutline} />
      </div>
    </div>
  );
};

export default MNXOZoomModal;

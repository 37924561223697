import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol, IonPage,
  IonRow
} from "@ionic/react";
import React, { useState } from "react";
import Card from "../components/Card";
import Modal from "../components/Modal";
import { useAppTranslations } from "../hooks/useAppTranslations";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import MNXOFAQModal from "../useCases/faq/components/MNXOFAQModal";
import MNXOContent from "../components/MNXOContent";
import MNXODescriptiveText from "../components/MNXODescriptiveText";

const DashboardConfiguration: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  const { infoTranslation: informations } = useAppTranslations("all");

  const [isOpen, setIsOpen] = useState(false);
  const [faqModalOpen, setFaqModalOpen] = useState(false);

  const [object, setObject] = useState();
  return (
    <IonPage>
      <Header
        backButtonLink={"/dashboard/configuration"}
      />

      <MNXOContent   containerStyle="background-body-color"
        page={""}
        type={""}
        title={t("information_edit.header.title")}
        text={t("information_edit.header.text")}
        chipText={t("information_edit.header.chip")}>
           <MNXODescriptiveText
          text=""
          subtext=""
        ></MNXODescriptiveText>
          <div className="ion-padding">
        {informations?.map((info: any, index: any) => (
          <Card
            key={index}
            index={index}
            object={info}
            isEditable={true}
            setIsOpen={setIsOpen}
            setObject={setObject}
          />
        ))}

        {object && (
          <Modal
            typeofModal={"information"}
            informationObject={object}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )}
        <IonCard className="visitor-application-card background-white">
          <IonRow>
            <IonCol className={`ion-padding`}>
              <IonCardHeader>
                <IonCardTitle style={{ marginBottom: "16px" }}>
                  {t("information_edit.faq_card.title")}
                </IonCardTitle>

                <IonCardSubtitle style={{ marginBottom: "16px" }}>
                {t("information_edit.faq_card.subtitle")}
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonButton onClick={() => setFaqModalOpen(true)}>
                  {t("button.edit")}
                </IonButton>
              </IonCardContent>
            </IonCol>
          </IonRow>
        </IonCard>
        </div>
        <MNXOFAQModal isOpen={faqModalOpen} setIsOpen={setFaqModalOpen} />
      </MNXOContent>
    </IonPage>
  );
};

export default DashboardConfiguration;

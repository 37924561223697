import { Translation } from "./Translation";

export const defaultLanguage = {
    ES: "ES",
    EN: "EN"
}

export class VisitingShift {
    maxCapacity: number;
    maxVisitorsPerApplication: number;
    isActive: boolean;
    openingHours: OpeningHours[];
    // closingHours?: OpeningHours[];
    translations: VisitingShiftTranslation[];

    constructor() {
        this.maxCapacity = 1;
        this.maxVisitorsPerApplication = 1;
        this.isActive = false;
        this.openingHours = [new OpeningHours()];
        this.translations = [
            new VisitingShiftTranslation(defaultLanguage.EN),
            new VisitingShiftTranslation(defaultLanguage.ES),
        ];
    }
}

export class OpeningHours {
    opens: string;
    closes: string;
    dayOfWeek: string;
    validFrom: Date;
    validThrough: Date | undefined;

    constructor() {
        this.opens = "";
        this.closes = "";
        this.dayOfWeek = "";
        this.validFrom = new Date();
        this.validThrough = undefined;
    }
}
export class VisitingShiftTranslation extends Translation {
    name: string;
    description: string;

    constructor(language: string) {
        super(language);
        this.name = "";
        this.description = "";
    }

}
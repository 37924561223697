import {
  IonCheckbox,
  IonCol,
  IonInput,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import QRCode from "react-qr-code";
import MNXOHeader from "../../../components/MNXOHeader";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import MNXOModal from "./MNXOModal";
import { useTranslation } from "react-i18next";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import { stringToLocaleDate } from "../../../utils/formatDate";

var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));

interface ContainerProps {
  applicationData: any;
  applicationStatus?: any;
  visitingShifts?: any;
  showView: any;
  setShowView: any;
  sendApplication: any;
  setIsValidEmail: any;
  setIsRulesAccepted: any;
  setIsPolicyAccepted: any;
  isOpen: any;
  setIsOpen: any;
  visitorTypes: any;
}

const MNXOApplicationThirdView: React.FC<ContainerProps> = ({
  applicationData,
  sendApplication,
  visitingShifts,
  showView,
  setShowView,
  setIsValidEmail,
  setIsRulesAccepted,
  setIsPolicyAccepted,
  isOpen,
  setIsOpen,
  visitorTypes,
}) => {
  const { visitingDate, applicationStatus, visitingShift, email, visitors } =
    applicationData;

  const [t, i18n] = useTranslation("global");

  const [verificationEmail, setVerificationEmail] = useState<any>();
  const [isValid, setIsValid] = useState<boolean>(false);

  const { legalIdTypeOptions } = useAppTranslations();
  const { currentLanguage, infoTranslation: rulesOfUse } =
    useAppTranslations("terms_of_use");
  const { infoTranslation: privacyPolicy } =
    useAppTranslations("Privacy Policy");

  const showCurrentLegalIdType = (legalIDType: any) => {
    const currentLegalIdType = legalIdTypeOptions.find(
      (legalIdType: any) => legalIdType.value === legalIDType
    );

    return currentLegalIdType?.text;
  };

  const getCurrentVisitingShiftTime = () => {
    if (visitingShifts && visitingShifts.length > 0) {
      // Encuentra el turno de visita actual correspondiente a visitingShift
      for (const dateInfo of visitingShifts) {
        const currentShift = dateInfo.visitingShifts.find(
          (shiftInfo: any) => shiftInfo.uuid === visitingShift
        );
        if (currentShift) {
          return currentShift; // Devuelve el turno de visita encontrado
        }
      }
    }
    return null; // Maneja el caso en el que no se encuentre el turno de visita
  };

  const modal = useRef<HTMLIonModalElement>(null);

  const [presentingElement, setPresentingElement] =
    useState<HTMLElement | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>(null);

  const openModal = (modalData: any) => {
    setModalData(modalData);
    setIsModalOpen(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setModalData(null);
    setIsModalOpen(false);
  };

  const currentVisitingShiftTime = getCurrentVisitingShiftTime();

  const languagueType = currentVisitingShiftTime.translations.filter(
    (translation: any) => translation.language == currentLanguage.toUpperCase()
  );
  const languague = languagueType[0];

  const opens = currentVisitingShiftTime.openingHours[0].opens.substring(0, 5);
  const closes = currentVisitingShiftTime.openingHours[0].closes.substring(
    0,
    5
  );

  function dismiss() {
    modal.current?.dismiss();
  }

  const emailVerify = (verificationEmail: any) => {
    if (verificationEmail === email.trim().toLowerCase()) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  return (
    <>
      <MNXOHeader
        text={t("header.text.confirm")}
        textChip={t("header.chip.confirm")}
        title={t("header.title.confirm")}
        hide={true}
      />

      <MNXODescriptiveText
        text={t("descriptive_text.app_data.text")}
        subtext={t("descriptive_text.app_data.subtext")}
      />

      <div className="application-third-view-content-base">
        <div className="application-third-view-content">
          <IonItemGroup style={{ marginBottom: "16px" }}>
            <IonItem lines="none" slot="header">
              <h3 className="white">{t("text.visit_details")}</h3>
            </IonItem>

            <IonRow>
              <IonCol sizeXs="10" offsetXs="1">
                <div className="visitor-details">
                  <IonItem lines="none">
                    <IonLabel>{t("label.date")}</IonLabel>
                    <IonText>
                      {stringToLocaleDate(applicationData.visitingDate)}
                    </IonText>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>{t("label.shift")}</IonLabel>
                    <IonText>{languague && languague.name}</IonText>
                  </IonItem>
                  {visitingShift && (
                    <IonItem lines="none">
                      <IonLabel>{t("label.schedule")}</IonLabel>
                      <IonText>
                        {t("app_data.from")} {opens} {t("app_data.to")} {closes}
                      </IonText>
                    </IonItem>
                  )}
                  {applicationStatus && (
                    <IonItem>
                      <IonLabel>{t("label.status")} </IonLabel>
                      <IonText>{applicationStatus}</IonText>
                    </IonItem>
                  )}
                </div>
              </IonCol>
            </IonRow>

            {/* <IonItem>
            <IonLabel>Email</IonLabel>
            <IonText>{email}</IonText>
          </IonItem> */}
          </IonItemGroup>
          <IonItemGroup style={{ marginBottom: "16px" }}>
            <IonItem lines="none" slot="header">
              <h3 className="white">{t("text.visitor_details")}</h3>
            </IonItem>
            <div slot="content">
              <IonRow>
                <IonCol sizeXs="10" offsetXs="1">
                  <IonList className="transparent">
                    {applicationData.visitors.map(
                      (visitor: any, index: number) => {
                        const {
                          name,
                          age,
                          authorization,
                          country,
                          legalID,
                          visitorType,
                          isPrincipal,
                        } = visitor;
                        // console.log(visitor);

                        const visitorWithTranslations = visitorTypes.filter(
                          (visitor2: any) =>
                            visitor2.uuid == visitor.visitorType
                        );

                        const visitorWithLanguage =
                          visitorWithTranslations[0].translations.filter(
                            (translation: any) =>
                              translation.language ==
                              currentLanguage.toUpperCase()
                          );
                        const visitorTypeWithLanguage =
                          visitorWithLanguage[0].name;

                        const { legalIdType, legalIdNum } = legalID;
                        const countryAlias = countries.getName(country, "es", {
                          select: "official",
                        });
                        // visitorType && // console.log("ALIAS", visitorType.alias);
                        return (
                          <div key={index} className="visitor-details">
                            <IonItem lines="none">
                              <IonLabel>
                                {/* aplicar cuando el backend me devuelva el tipo visitante
                                {isPrincipal
                                  ? visitorType.alias + " - main visitor"
                                  : visitorType.alias} */}
                                {isPrincipal ? (
                                  <>{t("label.main")}</>
                                ) : (
                                  <> {visitorTypeWithLanguage}</>
                                )}
                              </IonLabel>
                              <IonText>{name}</IonText>
                            </IonItem>
                            <IonItem lines="none">
                              <IonLabel>{t("label.age")}:</IonLabel>
                              <IonText>{+age}</IonText>
                            </IonItem>
                            <IonItem lines="none">
                              <IonLabel>{t("label.country")}:</IonLabel>
                              <IonText>{countryAlias}</IonText>
                            </IonItem>
                            <IonItem lines="none">
                              <IonLabel>{t("label.type_id")}:</IonLabel>
                              <IonText>
                                {showCurrentLegalIdType(legalIdType)}
                              </IonText>
                            </IonItem>
                            <IonItem lines="none">
                              <IonLabel>{t("label.num_id")}</IonLabel>
                              <IonText>{legalIdNum}</IonText>
                            </IonItem>

                            {authorization && (
                              <>
                                <IonItem>
                                  <IonLabel>{t("label.status")}</IonLabel>
                                  <IonText>
                                    {authorization.authorizationStatus}
                                  </IonText>
                                </IonItem>

                                <div
                                  style={{
                                    height: "auto",
                                    margin: "0 auto",
                                    maxWidth: 128,
                                    width: "100%",
                                  }}
                                >
                                  <QRCode
                                    size={256}
                                    style={{
                                      height: "auto",
                                      maxWidth: "100%",
                                      width: "100%",
                                    }}
                                    value={authorization.uuid}
                                    viewBox={`0 0 256 256`}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        );
                      }
                    )}
                  </IonList>
                </IonCol>
              </IonRow>
            </div>
          </IonItemGroup>
        </div>
      </div>

      {/* CONFIRMATION */}

      <div className="application-third-view-confirmation-email">
        <div className="application-third-view-confirmation-email-radius">
          <IonItemGroup>
            <IonItem lines="none" slot="header">
              <h3>{t("app_data.confirm")}</h3>
            </IonItem>
          </IonItemGroup>

          <IonRow>
            <IonCol>
              <IonItem lines="none">
                <IonLabel>{t("app_data.email_message")}</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeXs="10" offsetXs="1">
              <form
                style={{ padding: "0px" }}
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <IonInput
                  type="email"
                  name="email"
                  label={t("label.email")}
                  className="custom"
                  labelPlacement="stacked"
                  fill="outline"
                  value={verificationEmail}
                  onIonInput={(e) => {
                    setVerificationEmail(e.target.value);
                    emailVerify(e.target.value);
                  }}
                  required
                />
              </form>
            </IonCol>
          </IonRow>
          <IonRow className="align-items-center">
            <IonCol sizeXs="10" offsetXs="1">
              <div>
                <IonCheckbox
                  labelPlacement="start"
                  onIonChange={(e) =>
                    e.detail.checked
                      ? setIsPolicyAccepted(true)
                      : setIsPolicyAccepted(false)
                  }
                ></IonCheckbox>
                <span style={{ marginLeft: "8px" }}>
                  {t("app_data.check_policies.text")}
                  <a
                    id="open-modal"
                    onClick={() => openModal(privacyPolicy)}
                    style={{ marginLeft: "4px" }}
                  >
                    {t("app_data.check_policies.subtext")}
                  </a>
                </span>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              sizeXs="10"
              offsetXs="1"
              style={{ marginTop: "16px", marginBottom: "16px" }}
            >
              <div>
                <IonCheckbox
                  labelPlacement="start"
                  onIonChange={(e) =>
                    e.detail.checked
                      ? setIsRulesAccepted(true)
                      : setIsRulesAccepted(false)
                  }
                />
                <span style={{ marginLeft: "8px" }}>
                  {t("app_data.check_rules.text")}
                  <a
                    id="open-modal"
                    onClick={() => openModal(rulesOfUse)}
                    style={{ marginLeft: "4px" }}
                  >
                    {t("app_data.check_rules.subtext")}
                  </a>
                </span>
              </div>
            </IonCol>
          </IonRow>
        </div>
      </div>

      <MNXOModal
        isOpen={isModalOpen}
        onDidDismiss={closeModal}
        modal={modal}
        presentingElement={presentingElement}
        data={modalData}
        dismiss={dismiss}
        isThirdView={true}
      ></MNXOModal>

      {/* <IonGrid>
        <IonRow style={{ "margin-bottom": "16px" }}>
          <IonCol className="flex-center ">
            {" "}
            <IonButton
              className="border-radius text-gray secondary-button"
              fill="clear"
              onClick={() =>
                setShowView({ ...showView, secondView: true, thirdView: false })
              }
            >
              Back
            </IonButton>
          </IonCol>
          <IonCol className="flex-center">
            <IonButton
              onClick={confirm}
              type="submit"
              disabled={isValid ? false : true}
            >
              Confirm
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid> */}
    </>
  );
};

export default MNXOApplicationThirdView;

import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonItem,
  IonLabel,
  IonText,
} from "@ionic/react";
import React from "react";
import HighlightedText from "../../../components/HighLightedText";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  application: any;
  index?: any;
  searchKeyword?: any;
  handleSubmit: any;
  notHighlighted?: boolean;
}

const ApplicationCard: React.FC<ContainerProps> = ({
  application,
  index,
  searchKeyword,
  handleSubmit,
  notHighlighted,
}) => {
  const validStatus = ["CHECKED", "PARTIALLY_CHECKED", "ACTIVE"];
  const [t, i18n] = useTranslation("global");

  return (
    <IonCard className="validation-manual-card" key={index}>
      <IonCardHeader className="title-background manual-validation-card-header">
        <h1>
          {t("pages.manual_validation.card.application")}{" "}
          {notHighlighted ? (
            <>{application.locator}</>
          ) : (
            <HighlightedText
              text={application.locator}
              highlight={searchKeyword}
            />
          )}
        </h1>
      </IonCardHeader>
      <IonText>
        <div className=" ion-padding">
          <p className="manual-validation-card-p">
            {t("pages.manual_validation.card.application_status")}{" "}
            {notHighlighted ? (
              <>{application.applicationStatus}</>
            ) : (
              <HighlightedText
                text={application.applicationStatus}
                highlight={searchKeyword}
              />
            )}
          </p>
          <p className="manual-validation-card-p">
            {t("pages.manual_validation.card.email")}{" "}
            {notHighlighted ? (
              <>{application.email}</>
            ) : (
              <HighlightedText
                text={application.email}
                highlight={searchKeyword}
              />
            )}
          </p>
          <p className="manual-validation-card-p">
            {t("pages.manual_validation.card.visiting_date")}{" "}
            {notHighlighted ? (
              <>{application.visitingDate}</>
            ) : (
              <HighlightedText
                text={application.visitingDate}
                highlight={searchKeyword}
              />
            )}
          </p>
        </div>
      </IonText>

      <IonAccordionGroup>
        <IonAccordion value="first">
          <IonItem
            slot="header"
            color="light"
            className="manual-validation-card-accordion"
          >
            <IonLabel>{t("pages.manual_validation.card.visitors")} </IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            {application.visitors.map((visitor: any, index: number) => (
              <div key={index}>
                {visitor.isPrincipal == true && (
                  <p className="manual-validation-card-p text-visitor-form-header">
                    {t("pages.manual_validation.card.applicant")}{" "}
                  </p>
                )}
                {visitor.legalID && (
                  <>
                    <p className="manual-validation-card-p">
                      {t("pages.manual_validation.card.id_number")}{" "}
                      {notHighlighted ? (
                        <>{visitor.legalID.legalIdNum}</>
                      ) : (
                        <HighlightedText
                          text={visitor.legalID.legalIdNum}
                          highlight={searchKeyword}
                        />
                      )}
                    </p>
                  </>
                )}
                <div>
                  <p className="manual-validation-card-p">
                    {t("pages.manual_validation.card.authorisation_status")}{" "}
                    {visitor.authorization ? (
                      notHighlighted ? (
                        <>{visitor.authorization.authorizationStatus}</>
                      ) : (
                        <HighlightedText
                          text={visitor.authorization.authorizationStatus}
                          highlight={searchKeyword}
                        />
                      )
                    ) : (
                      "No hay autorización"
                    )}
                  </p>
                  <IonButtons slot="end">
                    <IonButton
                      disabled={
                        !validStatus.includes(application.applicationStatus)
                      }
                      onClick={() =>
                        handleSubmit(visitor.authorization.uuid, "CHECKED")
                      }
                    >
                      {t("button.validate")}
                    </IonButton>
                    <IonButton
                      disabled={
                        !validStatus.includes(application.applicationStatus)
                      }
                      onClick={() =>
                        handleSubmit(visitor.authorization.uuid, "CANCELLED")
                      }
                    >
                      {t("button.revoke")}
                    </IonButton>
                  </IonButtons>
                </div>
              </div>
            ))}
          </div>
        </IonAccordion>
        <IonAccordion value="second">
          <IonItem
            slot="header"
            color="light"
            className="manual-validation-card-accordion"
          >
            <IonLabel>
              {t("pages.manual_validation.card.history.title")}{" "}
            </IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            {application.history &&
              application.history.map((history: any, index: number) => (
                <div key={index}>
                  <h2>
                    {t("pages.manual_validation.card.history.title")} {index}
                  </h2>
                  <p>
                    {t("pages.manual_validation.card.history.modifiedBy")}:{" "}
                    {history.modifiedBy}
                  </p>
                  <p>
                    {t("pages.manual_validation.card.history.description")}:{" "}
                    {history.description}
                  </p>
                  <p>
                    {t("pages.manual_validation.card.history.statusBefore")}:{" "}
                    {history.status.before}
                  </p>
                  <p>
                    {t("pages.manual_validation.card.history.statusAfter")}:{" "}
                    {history.status.after}
                  </p>
                </div>
              ))}
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </IonCard>
  );
};

export default ApplicationCard;

import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useVisitingConfiguration } from "../hooks/useVisitingConfiguration";
import { VisitingConfiguration } from "../../../models/VisitingConfiguration.model";

interface Props {
  visitingConfigurationData: VisitingConfiguration;
  createHistory: (method: string) => void;
  updateVisitingConfiguration: (inputData: any) => void;
  translations: any;
}

const MNXOVisitingConfigurationForm: React.FC<Props> = ({
  visitingConfigurationData,
  updateVisitingConfiguration,
  createHistory,
  translations,
}) => {
  const {
    visitorsCapacity,
    visitorsPerApplication,
    applicantMinAge,
    maxTimeInAdvance,
    minTimeBeforeVisit,
    verificationPeriod,
    lockPeriod,
    autoConfirm,
    timezone,
  } = visitingConfigurationData;

  const { formInputs, buttons } = translations;

  // TODO: usar traducciones estaticas

  return (
    <>
      <form>
        <IonInput
          type="number"
          name="visitorsCapacity"
          label={formInputs.visitorsCapacity}
          labelPlacement="stacked"
          fill="outline"
          value={visitorsCapacity}
          onIonInput={(e) => updateVisitingConfiguration(e.target)}
          required
          className="custom"
        />
        <IonInput
          type="number"
          name="visitorsPerApplication"
          label={formInputs.visitorsPerApplication}
          labelPlacement="stacked"
          fill="outline"
          value={visitorsPerApplication}
          onIonInput={(e) => updateVisitingConfiguration(e.target)}
          required
          className="custom"
        />
        <IonInput
          type="number"
          label={formInputs.applicantMinAge}
          name="applicantMinAge"
          labelPlacement="stacked"
          fill="outline"
          value={applicantMinAge}
          onIonInput={(e) => updateVisitingConfiguration(e.target)}
          required
          className="custom"
        />
        <IonInput
          type="number"
          name="maxTimeInAdvance"
          label={formInputs.maxTimeInAdvance}
          labelPlacement="stacked"
          fill="outline"
          value={maxTimeInAdvance}
          onIonInput={(e) => updateVisitingConfiguration(e.target)}
          required
          className="custom"
        />
        <IonInput
          type="number"
          name="minTimeBeforeVisit"
          label={formInputs.minTimeBeforeVisit}
          labelPlacement="stacked"
          fill="outline"
          value={minTimeBeforeVisit}
          onIonInput={(e) => updateVisitingConfiguration(e.target)}
          required
          className="custom"
        />
        <IonInput
          type="number"
          name="verificationPeriod"
          label={formInputs.verificationPeriod}
          labelPlacement="stacked"
          fill="outline"
          value={verificationPeriod}
          onIonInput={(e) => updateVisitingConfiguration(e.target)}
          required
          className="custom"
        />
        <IonInput
          type="number"
          name="lockPeriod"
          label={formInputs.lockPeriod}
          labelPlacement="stacked"
          fill="outline"
          value={lockPeriod}
          onIonInput={(e) => updateVisitingConfiguration(e.target)}
          required
          className="custom"
        />
        <IonInput
          type="text"
          name="autoConfirm"
          label={formInputs.autoConfirm}
          labelPlacement="stacked"
          fill="outline"
          value={autoConfirm.toString()}
          onIonInput={(e) => updateVisitingConfiguration(e.target)}
          required
          className="custom"
        />
        <IonInput
          type="text"
          name="timezone"
          label={formInputs.timezone}
          labelPlacement="stacked"
          fill="outline"
          value={timezone}
          onIonInput={(e) => updateVisitingConfiguration(e.target)}
          required
          className="custom"
        />
        <IonButton onClick={() => createHistory("put")}>
          {buttons.send}
        </IonButton>
      </form>
    </>
  );
};

export default MNXOVisitingConfigurationForm;

import { IonCol } from "@ionic/react";
import React from "react";

interface CalendarProps {
  visitingShift: any;
}

const AvailableCapacityIndicator: React.FC<CalendarProps> = ({
  visitingShift,
}) => {
  const visitingShiftAvailableCapacityIndicator = (visitingShift: any) => {
    const { maxCapacity, availableCapacity } = visitingShift;
    if (availableCapacity >= maxCapacity * 0.5)
      return "high-available-capacity";
    if (availableCapacity < maxCapacity * 0.5 && availableCapacity > 20)
      return "medium-available-capacity";
    if (availableCapacity <= 20 && availableCapacity !== 0)
      return "low-available-capacity";
    if (availableCapacity === 0) return "full-capacity";
  };

  return (
    <IonCol
      style={{ gap: "8px" }}
      className={`visiting-shift-capacity 
      ${visitingShiftAvailableCapacityIndicator(visitingShift)}`}
    />
  );
};

export default AvailableCapacityIndicator;

import { IonContent, IonItem, IonLabel, IonModal } from "@ionic/react";
import React, { useState } from "react";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import MNXOHeader from "../../../components/MNXOHeader";
import MNXOModalFooter from "../../../components/MNXOModalFooter";
import VisitorForm from "./VisitorForm";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import MNXOLottieIcon from "../../../components/MNXOLottieIcon";

interface ContainerProps {
  isOpen: boolean;
  visitors: any[];
  visitorTypes: any;
  sameCountry: any;
  setFormData: any;
  setSameCountryForAll: any;
  cardNumber: number;
  closeModal: () => void;
  setCardNumber: (number: number) => void;
}

const MNXOVisitorsModal: React.FC<ContainerProps> = ({
  isOpen,
  visitors,
  visitorTypes,
  sameCountry,
  setFormData,
  setSameCountryForAll,
  closeModal,
  cardNumber,
  setCardNumber,
}) => {
  const { currentLanguage } = useAppTranslations();
  const [t, i18n] = useTranslation("global");

  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  const conditions = {
    isValidForm: isValidForm,
  };

  const getVisitorType = (visitorTypeUUID: string) => {
    const currentVisitorType = visitorTypes.filter(
      (visitor: any) => visitor.uuid === visitorTypeUUID
    );
    // console.log("CURRENT VISITOR TYPE", currentVisitorType);
    return currentVisitorType;
  };

  const getVisitorTypeName = (visitorTypeUUID: string) => {
    const visitorWithTranslations = visitorTypes.filter(
      (visitor: any) => visitor.uuid === visitorTypeUUID
    );

    // console.log("visitorWithTranslations", visitorWithTranslations);

    const languagueType = visitorWithTranslations[0].translations.filter(
      (translation: any) =>
        translation.language === currentLanguage.toUpperCase()
    );

    const language = languagueType[0];

    return language.name;
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={closeModal}>
      <MNXOHeader
        text={t("header.text.visitor_form")}
        textChip={t("header.chip.visitor_form")}
        type="modal"
        title={""}
      />
      <div className="background-body-color">
        <MNXODescriptiveText text={t("descriptive_text.visitor_form.text")} />
      </div>

      <IonContent className="visitor-form-content">
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          slidesOffsetBefore={0}
          onRealIndexChange={({ activeIndex }) => {
            // console.log("slide #", activeIndex);
            setCardNumber(activeIndex);
          }}
          initialSlide={cardNumber}
          pagination={{ clickable: true }}
          className="visitors-swiper-container"
        >
          {visitors.map((visitor: any, index: number) => (
            <SwiperSlide key={index}>
              <IonItem lines="none">
                <IonLabel
                  className="text-modal-visitor "
                  style={{ marginBottom: "45px" }}
                >
                  {visitor && visitor.isPrincipal
                    ? t("label.main")
                    : getVisitorTypeName(visitor.visitorType)}
                </IonLabel>
                <IonLabel
                  className="text-modal-visitor ion-text-end"
                  style={{ marginBottom: "45px" }}
                >
                  {`Pag ${index + 1}/${visitors.length}`}
                </IonLabel>
                <IonItem lines="none" className="animated-icon-swiper">
                  <MNXOLottieIcon
                    src="https://lottie.host/112dad8e-9a3e-4501-851c-1bc776b31a3a/oY9T6xpoZb.json"
                    className="player"
                    loop
                    autoplay
                  />
                </IonItem>
              </IonItem>
              <VisitorForm
                setSameCountryForAll={setSameCountryForAll}
                order={index}
                visitorType={getVisitorType(visitor.visitorType)}
                modalDismiss={closeModal}
                visitorData={visitor}
                visitors={visitors}
                setFormData={setFormData}
                setIsValidForm={setIsValidForm}
                visitorTypeName={getVisitorTypeName(visitor.visitorType)}
                sameCountry={sameCountry}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </IonContent>
      <MNXOModalFooter
        modalDismiss={closeModal}
        conditions={conditions}
        cardNumber={cardNumber}
      />
    </IonModal>
  );
};

export default MNXOVisitorsModal;

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
} from "@ionic/react";
import React from "react";
import Header from "../components/Header";
import {
  personCircleOutline,
  mailOutline,
  phonePortraitOutline,
} from "ionicons/icons";

const Profile: React.FC = () => {
  const adminInfo = {
    name: "John Doe",
    email: "johndoe@example.com",
    phone: "+1 123 456 7890",
  };

  return (
    <IonPage>
      <Header name="Profile" isWhiteBackground={false}/>

      <IonContent className="ion-padding">
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>Admin Information</IonCardSubtitle>
            <IonCardTitle>{adminInfo.name}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonIcon slot="start" icon={personCircleOutline} />
                <IonLabel>{adminInfo.name}</IonLabel>
              </IonItem>
              <IonItem>
                <IonIcon slot="start" icon={mailOutline} />
                <IonLabel>{adminInfo.email}</IonLabel>
              </IonItem>
              <IonItem>
                <IonIcon slot="start" icon={phonePortraitOutline} />
                <IonLabel>{adminInfo.phone}</IonLabel>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Profile;

import { IonContent, IonModal } from "@ionic/react";
import React, { useRef, useState } from "react";
import MNXOHeader from "../../../components/MNXOHeader";
import MNXOAuthCard from "./MNXOAuthCard";
import MNXOApplicationFooter from "./MNXOApplicationFooter";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  applicationData: any;
  isOpen: boolean;
  handleClose: any;
  visitingShifts: any;
  translations?: {
    visitingShifts: any;
    visitorTypes: any;
  };
  currentElement?: any;
  setCurrentElement?: any;
}

const MNXOApplicationModal: React.FC<ContainerProps> = ({
  applicationData,
  isOpen,
  handleClose,
  visitingShifts,
  translations,
  currentElement,
  setCurrentElement,
}) => {
  const [t, i18n] = useTranslation("global");

  let totalElements;
  const [currentPage, setCurrentPage] = useState(0);
  const swiperRef = useRef<any>(null);

  if (applicationData) totalElements = applicationData.visitors.length;

  const onNextView = () => {
    setCurrentElement((prevElement: number) => {
      if (swiperRef.current) {
        swiperRef.current.swiper.slideToLoop(prevElement + 1, 300, true);
      }
      return prevElement + 1;
    });
  };
  const onPreviusView = () => {
    setCurrentElement((prevElement: number) => {
      if (swiperRef.current) {
        swiperRef.current.swiper.slideToLoop(prevElement - 1, 300, true);
      }
      return prevElement - 1;
    });
  };

  return (
    <>
      {applicationData && (
        <IonModal
          isOpen={isOpen}
          onDidDismiss={handleClose}
          className="modal-container"
        >
          <MNXOHeader
            text={t("header.text.auths")}
            title={""}
            textChip={t("header.chip.visitor_auth_data")}
            type="modal"
            handleClose={handleClose}
          />
          <IonContent className="application-modal-content">
            <MNXOAuthCard
              swiperRef={swiperRef}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              applicationData={applicationData}
              setCurrentElement={setCurrentElement}
              currentElement={currentElement}
              visitingShifts={visitingShifts}
              translations={translations}
            />
          </IonContent>
          <MNXOApplicationFooter
            currentView={currentElement}
            currentElement={currentElement}
            totalOfViews={totalElements}
            onNextView={onNextView}
            onPreviusView={onPreviusView}
            modalDismiss={handleClose}
            type="modal"
          />
        </IonModal>
      )}
    </>
  );
};

export default MNXOApplicationModal;

import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface Props {
  dataToEdit: any;
  setValue?: any;
  name?: string;
  translation?: any;
}

const QuillEditor: React.FC<Props> = ({
  dataToEdit,
  setValue,
  name,
  translation,
}) => {
  const handleChange = (content: any) => {
    if (setValue && name) {
      if (translation) {
        setValue(name, content, translation);
      } else setValue(name, content);
    }
  };
  return <ReactQuill theme="snow" value={dataToEdit} onChange={handleChange} />;
};

export default QuillEditor;

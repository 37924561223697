import { IonCard, IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import AvailableCapacityIndicator from "./AvailabeCapacityIndicator";
import { useTranslation } from "react-i18next";

interface CalendarProps {
  visitingDate: any;
  selectedDate: any;
  setDate: any;
}

const CustomDate: React.FC<CalendarProps> = ({
  visitingDate,
  selectedDate,
  setDate,
}) => {
  const { date, visitingShifts } = visitingDate;
  const [t, i18n] = useTranslation("global");

  return (
    <IonCard
      id="date"
      //lines="none"
      className={`${
        date === selectedDate ? "selected-calendar-item" : "calendar-item"
      }`}
      disabled={visitingShifts.length === 0 && true}
      button
      onClick={() => {
        setDate(date);
      }}
    >
      <IonGrid>
        <IonRow className="visiting-shifts-indicators ion-text-center ion-justify-content-center">
          <IonCol>
            <p
              className={`${date === selectedDate ? "selected-date" : "date"}`}
            >
              {t("intlDateTime", {
                val: new Date(date),
                formatParams: {
                  val: { weekday: "short" },
                },
              })}
              {/* {new Date(date).toUTCString().substring(0, 3)} */}
            </p>

            <p
              className={`${
                date === selectedDate ? "selected-date number" : "date number"
              }`}
            >
              {new Date(date).getDate()}
            </p>

            {/* texto que indica que en esa fecha no hay turnos disponibles
            {visitingShifts.length === 0 && (
              <IonLabel>
                {" "}
                {t("application.visiting_shifts.available_capacity.empty")}
              </IonLabel>
            )} */}

            <IonRow className="ion-justify-content-center visiting-shifts-indicators">
              {visitingShifts.map((visitingShift: any, index: any) => {
                return (
                  <AvailableCapacityIndicator
                    visitingShift={visitingShift}
                    key={index}
                  />
                );
              })}
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default CustomDate;

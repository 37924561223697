import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonModal,
  IonPage,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import MNXOContent from "../../../components/MNXOContent";
import Header from "../../../components/Header";
import { useVisitingShift } from "../hooks/useVisitingShift";
import { useLoading } from "../../../hooks/useLoading";
import MNXOVisitingShiftForm from "../components/MNXOVisitingShiftForm";
import { VisitingShift } from "../../../models/VisitingShift";
import { methodOption } from "../../../models/AxiosConfig";
import { useTranslation } from "react-i18next";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";



// ATHOR: JOEL
const MNXOVisitingShiftEdit: React.FC = () => {
  const {
    visitingShifts,
    loading,
    setVisitingShiftFormData,
    updateTranslations,
    addNewTranslation,
    visitingShiftFormData,
    toggleIsActive,
    delNewOpeningHour,
    delTranslations,
    addNewOpeningHour,
    updateVisitingShift,
    updateOpeningHours,
    sendVisitingShift,
    translations,
    showHistoryAlert,
    handleClose,
    handleOpen,
    isOpen,
  } = useVisitingShift();

  const [t, i18n] = useTranslation("global");
  const { title } = translations;

  // TODO: implementar dentro de MNXOLContent
  const { presentLoading, dismissLoading } = useLoading();
  const isLoading = !visitingShifts && !visitingShiftFormData;

  const [action, setAction] = useState<string>("");

  useEffect(() => {
    isLoading ? presentLoading() : dismissLoading();
  }, [isLoading]);

  const handleCardClick = (visitingShift: any, action: string) => {
    setAction(action);
    setVisitingShiftFormData(visitingShift);
    handleOpen();
  };

  return (
    <IonPage>
      <Header
        backButtonLink={"/dashboard/configuration/visitingconfiguration"}
      />
       <MNXOContent   
      containerStyle="background-body-color"
      page={""}
      type={""}
      title={t("configuration_edit.header.title")}
      text={t("configuration_edit.header.text")}
      chipText={t("configuration_edit.header.chip")}>
         <MNXODescriptiveText
        text=""
        subtext=""
      ></MNXODescriptiveText>
        <div className="ion-padding">

        {visitingShiftFormData && (
          <MNXOVisitingShiftForm
            visitingShiftData={visitingShiftFormData}
            updateVisitingShift={updateVisitingShift}
            updateOpeningHours={updateOpeningHours}
            updateTranslations={updateTranslations}
            addNewOpeningHour={addNewOpeningHour}
            showHistoryAlert={showHistoryAlert}
            addNewTranslation={addNewTranslation}
            delNewOpeningHour={delNewOpeningHour}
            delTranslations={delTranslations}
            staticTranslations={translations}
            toggleIsActive={toggleIsActive}
            action={action}
            isOpen={isOpen}
            handleClose={handleClose}
          />
        )}
        {visitingShifts?.map((visitingShift: any, index: number) => {
          return (
            <IonCard
              key={index}
              button
              style={{ marginBottom: "16px" }}
              className="ion-padding"
              onClick={() =>
                handleCardClick(visitingShift, methodOption.UPDATE)
              }
            >
              <IonCardHeader>
                {t("visiting_configuration_page.card.title")} {visitingShift.translations[0]?.name || "sin traduccion"}
              </IonCardHeader>
            </IonCard>
          );
        })}

        <IonButton
          onClick={() => {
            const newVisitingShift = new VisitingShift();
            handleCardClick(newVisitingShift, methodOption.CREATE);
          }}
        >
          {t("button.new")}
        </IonButton>

      </div>
      </MNXOContent>
    </IonPage>
  );
};

export default MNXOVisitingShiftEdit;

import { IonCol, IonList, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import MNXOHeader from "../../../components/MNXOHeader";
import VisitorCard from "./VisitorCard";
import { Application } from "../entities/Application";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import { useTranslation } from "react-i18next";

import MNXOVisitorsModal from "./MNXOVisitorsModal";

interface ContainerProps {
  applicationData: Application;
  visitorTypes: any;
  showView: any;
  setShowView: any;
  setShowAlert: any;
  isOpen: any;
  sendData: any;
  setApplicationStatus: any;
  updateVisitorData: any;
  isValidVisitorForms: boolean;
  setIsOpen: any;
  setSameCountry: any;
  sameCountryForAll?: any;
  sameCountry?: any;
}

const MNXOApplicationSecondView: React.FC<ContainerProps> = ({
  applicationData,
  visitorTypes,
  // showView,
  // setShowView,
  // setShowAlert,
  // isOpen,
  // sendData,
  // isValidVisitorForms,
  // setApplicationStatus,
  updateVisitorData,
  // setIsOpen,
  setSameCountry,
  // sameCountryForAll,
  sameCountry,
}) => {
  const [t, i18n] = useTranslation("global");

  const [isOpen, setIsOpen] = useState(false);
  const [cardNumber, setCardNumber] = useState<number>(0);

  useEffect(() => {
    // console.log("CARD NUMBER #", cardNumber);
  }, [cardNumber]);

  const { visitors } = applicationData;

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <IonCol>
      <MNXOHeader
        title={t("application.second_view.header.title")}
        text={t("application.second_view.header.text")}
        textChip={t("application.second_view.header.chip").toUpperCase()}
        hide={true}
      />

      <MNXOVisitorsModal
        isOpen={isOpen}
        visitors={visitors}
        visitorTypes={visitorTypes}
        closeModal={closeModal}
        sameCountry={sameCountry}
        setSameCountryForAll={setSameCountry}
        setFormData={updateVisitorData}
        cardNumber={cardNumber}
        setCardNumber={setCardNumber}
      />

      <MNXODescriptiveText
        text={t("application.second_view.instructions.text")}
        subtext={t("application.second_view.instructions.subtext")}
      />

      {/* <ApplicationModal
          isOpen={isOpen}
          setShowAlert={setShowAlert}
          handleClose={() => setIsOpen(false)}
          applicationData={applicationData}
          sendApplication={sendData}
          handleApplicationStatus={setApplicationStatus}
          toConfirm={true}
        /> */}

      <IonList>
        <div className="visitors-list" style={{ paddingTop: "32px" }}>
          <IonRow className="row-column">
            {visitors?.map((visitor: any, index: number) => {
              return (
                <IonCol sizeXs="10" offsetXs="1" key={index}>
                  <VisitorCard
                    sameCountry={sameCountry}
                    visitors={applicationData.visitors}
                    setSameCountryForAll={setSameCountry}
                    key={index}
                    order={index}
                    setCardNumber={setCardNumber}
                    openModal={openModal}
                    setFormData={updateVisitorData}
                    visitorData={visitor}
                    visitorTypes={visitorTypes}
                  />
                </IonCol>
              );
            })}
          </IonRow>
        </div>
      </IonList>
    </IonCol>
  );
};

export default MNXOApplicationSecondView;

import { IonButton, IonCol, IonGrid, IonInput, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  action: string;
  dataToSearch: string;
  handleFilters: (formData: any) => void;
  applicationEmailLocator?: string | null;
}

interface FormData {
  locator: string;
  IDNumber: string;
}

const LocatorForm: React.FC<ContainerProps> = ({
  dataToSearch,
  action,
  handleFilters,
  applicationEmailLocator,
}) => {
  const [t, i18n] = useTranslation("global");
  const [formData, setFormData] = useState<FormData>({
    locator: "",
    IDNumber: "",
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    handleFilters(formData);
  };

  const handleFormData = async (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (applicationEmailLocator) {
      setFormData((prevData) => ({
        ...prevData,
        locator: applicationEmailLocator.substring(1, 9),
      }));
    }
  }, [applicationEmailLocator]);

  const buttonText =
    action === "search" ? t("button.search") : t("button.cancel");

  return (
    <form className="autohorization-form" onSubmit={(e) => handleSubmit(e)}>
      {/* <IonTitle color={"light"}>{t("label.locator_form_label")} </IonTitle> */}
      <IonInput
        type="text"
        name="locator"
        label={t("label.locator")}
        className="custom"
        maxlength={8}
        counter
        labelPlacement="stacked"
        fill="outline"
        onIonInput={(e) => handleFormData(e)}
        value={formData.locator}
        required
      />
      <IonInput
        type="text"
        name="IDNumber"
        label={t("label.num_id")}
        className="custom"
        labelPlacement="stacked"
        fill="outline"
        onIonInput={(e) => handleFormData(e)}
        required
      />

      <IonGrid>
        <IonRow className="flex-center">
          <IonCol>
            <IonButton type="submit">{buttonText}</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  );
};

export default LocatorForm;

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonLabel,
  IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import QuillEditor from "../../../components/QuillEditor";
import { useTranslation } from "react-i18next";
import MNXOModalFooter from "../../../components/MNXOModalFooter";
import { useInformation } from "../../information/hooks/useInformation";
import { useFAQApi } from "../hooks/useFAQAPI";

interface Props {
  infoObject?: any;
  setIsOpen?: any;
  faqEdit?: boolean;
}
const InformationTypeModal: React.FC<Props> = ({
  infoObject,
  setIsOpen,
  faqEdit,
}) => {
  const { updateInformation } = useInformation();
  const { updateFAQ } = useFAQApi();
  const [t, i18n] = useTranslation("global");

  const { translations, alias } = infoObject;
  const defaultLanguage = translations.filter(
    (translation: any) => translation.language === "EN"
  );
  const [translation, setTranslation] = useState(defaultLanguage[0]);
  const [aliasToEdit, setAliasToEdit] = useState(alias);

  // console.log(translation);

  const handleChange = (key: any, value: any) => {
    // console.log(key, value);
    setTranslation((translation: any) => ({
      ...translation,
      [key]: value,
    }));
  };
  const handleAliasChange = (key: any, value: any) => {
    // console.log(key, value);
    setAliasToEdit(value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!faqEdit) {
      const updatedTranslation = {
        alias: infoObject.alias,
        aliasToEdit: aliasToEdit,
        translation: translation,
      };
      await updateInformation(updatedTranslation);
    } else {
      const updatedTranslation = {
        alias: infoObject.alias,
        aliasToEdit: aliasToEdit,
        translation: translation,
      };
      await updateFAQ(updatedTranslation);
    }
    setIsOpen(false);
    window.location.reload();
  };
  //TODO: comprobar funcion update

  const handleLanguage = async (language: string) => {
    const currentLanguage = infoObject.translations.filter(
      (translation: any) => translation.language === language
    );
    setTranslation({ ...translation, ...currentLanguage[0] });
  };
  const modalDismiss = async () => {
    setIsOpen(false);
  };
  return (
    <>
      <IonContent className="ion-padding  background-body-color">
        <IonGrid>
          <IonRow>
            <IonCol offset="2">
              <IonButton onClick={() => handleLanguage("EN")}>EN</IonButton>
            </IonCol>
            <IonCol offset="2">
              <IonButton onClick={() => handleLanguage("ES")}>ES</IonButton>
            </IonCol>
          </IonRow>
          <IonRow style={{ marginTop: "16px", marginBottom: "16px" }}>
            {/* <IonCol>
              <IonLabel>Contenido</IonLabel>
            </IonCol> */}
          </IonRow>
          <IonRow>
            <IonCol>
              <form onSubmit={handleSubmit}>
                <div className="quill-editor-content">
                  {faqEdit && (
                    <>
                      <IonInput
                        label={t("label.alias")}
                        name="alias"
                        placeholder="alias"
                        disabled
                        onIonInput={(e) =>
                          handleAliasChange(e.target.name, e.target.value)
                        }
                        value={aliasToEdit}
                        className="custom"
                        labelPlacement="stacked"
                        fill="outline"
                      ></IonInput>
                      <IonInput
                        label={t("label.slug")}
                        name="slug"
                        placeholder="Slug"
                        onIonInput={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        value={translation.slug}
                        className="custom"
                        labelPlacement="stacked"
                        fill="outline"
                      ></IonInput>
                      <IonInput
                        label={t("label.question")}
                        name="question"
                        placeholder="Question"
                        onIonInput={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        value={translation.question}
                        className="custom"
                        labelPlacement="stacked"
                        fill="outline"
                      ></IonInput>
                      {/* <QuillEditor
                        name="question"
                        setValue={handleChange}
                        dataToEdit={translation.question}
                      /> */}
                      <br />
                      <IonLabel className="text-label-incident-form">
                        {t("label.answer")}
                      </IonLabel>

                      <QuillEditor
                        name="answer"
                        setValue={handleChange}
                        dataToEdit={translation.answer}
                      />
                    </>
                  )}
                  {!faqEdit && (
                    <>
                      <IonGrid>
                        <IonRow>
                          <IonCol>
                            <IonInput
                              label={t("label.alias")}
                              name="alias"
                              placeholder="Alias"
                              onIonInput={(e) =>
                                handleAliasChange(e.target.name, e.target.value)
                              }
                              value={aliasToEdit}
                              className="custom"
                              labelPlacement="stacked"
                              fill="outline"
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonInput
                              label={t("label.slug")}
                              name="slug"
                              placeholder="Slug"
                              onIonInput={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              value={translation.slug}
                              className="custom"
                              labelPlacement="stacked"
                              fill="outline"
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonInput
                              label={t("label.title")}
                              name="title"
                              placeholder="Título"
                              onIonInput={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              value={translation.title}
                              className="custom"
                              labelPlacement="stacked"
                              fill="outline"
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonInput
                              label={t("label.subtitle")}
                              name="subtitle"
                              placeholder="Subtítulo"
                              onIonInput={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              value={translation.subtitle}
                              className="custom"
                              labelPlacement="stacked"
                              fill="outline"
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol style={{ marginTop: "32px" }}>
                            <IonLabel className="text-label-incident-form">
                              {t("label.content")}
                            </IonLabel>
                            <QuillEditor
                              name="content"
                              setValue={handleChange}
                              dataToEdit={translation.content}
                            />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </>
                  )}
                </div>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <MNXOModalFooter
        modalDismiss={modalDismiss}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default InformationTypeModal;

import React from "react";
import { IonAlert } from "@ionic/react";

interface MNXOAlertProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  header: string;
  message: string;
  buttons: any[];
}

const MNXOAlert: React.FC<MNXOAlertProps> = ({
  isOpen,
  onDidDismiss,
  header,
  message,
  buttons,
}) => {
  return (
    <IonAlert
      className="custom-class"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      header={header}
      message={message}
      buttons={buttons}
    />
  );
};

export default MNXOAlert;

import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import MNXOHeader from "../../../components/MNXOHeader";
import { useHistory } from "react-router";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  removeVisitorsStorage: any;
}

const MNXOApplicationFinalView: React.FC<ContainerProps> = ({
  removeVisitorsStorage,
}) => {
  const [t, i18n] = useTranslation("global");
  const history = useHistory();

  const newApplication = () => {
    window.location.reload();
  };

  const goToAuthorizations = async () => {
    await removeVisitorsStorage();
    history.push("/authorisations");
  };

  return (
    <>
      <MNXOHeader 
      text={t("header.text.final_view")}
      textChip={t("header.chip.final_view")}
      title={t("header.title.final_view")}
      />

      <MNXODescriptiveText
        text={t("descriptive_text.final_view.text")}
        subtext={t("descriptive_text.final_view.subtext")}
      />

      <IonGrid className="buttons-final-view">
        <IonRow className="flex-center">
          <IonCol size="10">
            <IonButton onClick={() => goToAuthorizations()}>
              {t("button.view_auth")}
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow className="flex-center">
          <IonCol size="10">
            <IonButton
              fill="clear"
              className="ion-padding-top border-radius white"
              onClick={() => newApplication()}
            >
              {t("button.new_app")}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default MNXOApplicationFinalView;

import React, { useEffect, useState } from "react";
import { IonContent, IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
import { useHistory } from "react-router-dom";
import LobosSilhouette from "../assets/lobos_silhouette_bg-512x512.png";
import logoLobos from "../assets/logo-lobos_bg-512x512.png";
import logoCab from "../assets/logo cab_512x150.png";
import { useStorage } from "../hooks/useStorage";

interface Props {
  // setHasSeenSplash: any;
}
const SplashPage: React.FC<Props> = (
  {
    // setHasSeenSplash
  }
) => {
  const history = useHistory();
  // const { saveStorage } = useStorage("hasSeenSplash");
  // const [state, setState] = useState<boolean>(false);

  useEffect(
    () => {
      // setHasSeenSplash(false);
      // const x = async () => {
      //   if (state) {
      //     await skipSplash();
      //   }
      // };
      const timer = setTimeout(async () => {
        // setState(true);
        await skipSplash();
      }, 5 * 1000);

      // x();
      // Limpiar el temporizador al salir de la página
      return () => clearTimeout(timer);
    },
    [
      // state
    ]
  );

  const skipSplash = async () => {
    // Navegar a la página de bienvenida
    // setHasSeenSplash(true);
    // await saveStorage(true);
    history.push("/welcome");
  };

  return (
    <IonContent id="splash-content">
      <IonGrid onClick={skipSplash} className="grid-splash" fixed>
        <IonRow className="row-splash">
          <IonCol size="12" size-md="4" offset-md="4">
            <IonImg src={logoLobos}></IonImg>
            <IonImg src={LobosSilhouette}></IonImg>
          </IonCol>
        </IonRow>
        <IonRow className="row-splash">
          <IonCol size="12">
            <svg
              className="waves"
              xmlns="http://www.w3.org/2000/svg"
              xlinkHref="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28 "
              preserveAspectRatio="none"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160 44c30 0 
       58-18 88-18s
       58 18 88 18 
       58-18 88-18 
       58 18 88 18
       v44h-352z"
                />
              </defs>
              <g className="parallax1">
                <use xlinkHref="#gentle-wave" x="50" y="3" fill="#f461c1" />
              </g>
              <g className="parallax2">
                <use xlinkHref="#gentle-wave" x="50" y="0" fill="#4579e2" />
              </g>
              <g className="parallax3">
                <use xlinkHref="#gentle-wave" x="50" y="9" fill="#3461c1" />
              </g>
              <g className="parallax4">
                <use xlinkHref="#gentle-wave" x="50" y="6" fill="#fff" />
              </g>
            </svg>
          </IonCol>
        </IonRow>
        <IonRow className="row-splash">
          <IonCol size="12" size-md="4" offset-md="4">
            <IonImg src={logoCab}></IonImg>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default SplashPage;

import { IonButton, IonCol, IonGrid, IonInput, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  handleFilters: (formData: any) => void;
}

interface FormData {
  param: "";
}

const MNXOSearchApplicationForm: React.FC<ContainerProps> = ({
  handleFilters,
}) => {
  const [t, i18n] = useTranslation("global");
  const [formData, setFormData] = useState<FormData>({
    param: "",
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    handleFilters(formData);
  };

  const handleFormData = async (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trim(),
    }));
  };

  const buttonText = t("button.search");

  return (
    <form className="autohorization-form" onSubmit={(e) => handleSubmit(e)}>
      {/* <IonTitle color={"light"}>{t("label.locator_form_label")} </IonTitle> */}
      <IonInput
        type="text"
        name="param"
        label={t("label.locator_or_email")}
        className="custom"
        labelPlacement="stacked"
        fill="outline"
        onIonInput={(e) => handleFormData(e)}
        value={formData.param}
      />

      <IonGrid>
        <IonRow className="flex-center">
          <IonCol>
            <IonButton type="submit">{buttonText}</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  );
};

export default MNXOSearchApplicationForm;

import { IonButton, IonButtons, IonIcon, useIonRouter } from "@ionic/react";

import { logOutOutline } from "ionicons/icons";
import { useAuth } from "../providers/AuthProviders";

const LogoutButton: React.FC = () => {
  const navigation = useIonRouter();

  const { onLogout } = useAuth();

  const doLogout = async () => {
    await onLogout!();
  };
  const isDesktop = window.innerWidth > 768; // Definir el punto de corte según tus necesidades

  return (
    <IonButton
      onClick={() => doLogout()}
      size={`${isDesktop ? "large" : "default"}`}
      className={`logout-button`}
    >
      <IonIcon
        icon={logOutOutline}
        slot="icon-only"
        className={`text-white`}
      ></IonIcon>
    </IonButton>
  );
};

export default LogoutButton;

import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from "@ionic/react";
import React from "react";
import { reloadPage } from "../utils/pages";
import MNXOTitle from "./MNXOTitle";
import MNXOSticker from "./MNXOSticker";

interface ContainerProps {
  children: any;
  containerStyle?: string;
  hasRefresher?: boolean;
  refresherTimeout?: number;
  page?: string;
  type?: string;
  title?: string;
  text?: string;
  hide?: boolean;
  searchbar?: boolean;
  chipText?: string;
  handleClose?: any;
  handleMenu?: any;
  searchKeyword?: any;
  setSearchKeyword?:any;
}

const MNXOContent: React.FC<ContainerProps> = ({
  children,
  containerStyle,
  hasRefresher = true,
  refresherTimeout = 2000,
  page,
  type,
  title,
  text,
  searchbar,
  handleClose,
  chipText,
  hide,
  handleMenu,
  searchKeyword,
  setSearchKeyword
}) => {
  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setTimeout(() => {
      event.detail.complete();
      reloadPage();
    }, refresherTimeout);
  };

  return (
    <IonContent
      className={containerStyle}
      id="mnxo_content"
      // onClick={(e) => {
      //   handleMenu(e.target);
      // }}
    >
      {hasRefresher && (
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
      )}
      {title &&
        text && ( // Asegura que haya datos para mostrar en MNXOTitle
          <MNXOTitle
            title={title || ""}
            text={text || ""}
            page={page || ""}
            chipText={chipText || ""}
            type={type || ""}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
          ></MNXOTitle>
        )}
      {children}
    </IonContent>
  );
};

export default MNXOContent;

import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonRow,
  IonCol,
  IonItem,
  IonText,
  IonLabel,
  IonGrid,
  IonCardContent,
  IonButton,
} from "@ionic/react";
import QRCode from "react-qr-code";
import MNXOChip from "../../../components/MNXOChip";
import MNXOHighlightDate from "./MNXOHighlightDate";
import { useTranslation } from "react-i18next";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import { IonIcon, IonToast } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import { informationCircleOutline } from "ionicons/icons";
import MNXOAlert from "../../../components/MNXOAlert";
import axios from "axios";
import { useAlert } from "../../../hooks/useAlert";
import { useToast } from "../../../hooks/useToast";
import { useHistory } from "react-router";
import { useLoading } from "../../../hooks/useLoading";
interface CardProps {
  visitor?: any;
  application?: any;
  onCardClick: (application: any) => void;
  empty?: boolean;
  handleOpen?: any;
  agentValidation?: boolean;
  translations?: any;
  canDelete?: boolean;
}

const MNXOApplicationCard: React.FC<CardProps> = ({
  visitor,
  application,
  onCardClick,
  handleOpen,
  empty,
  agentValidation,
  translations,
  canDelete = true,
}) => {
  const [t, i18n] = useTranslation("global");
  const { presentAlert } = useAlert();
  const { presentToast } = useToast();
  const { presentLoading, dismissLoading } = useLoading();
  const history = useHistory();
  const { currentLanguage } = useAppTranslations();
  const [visitingShiftTranslation, setVisitingShiftTranslation] = useState<
    null | any
  >(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  // application && // console.log("application", application);
  // translations && // console.log("translations", translations);
  const BACKEND_URL_BASE = process.env.REACT_APP_BACKEND_URL_BASE;

  useEffect(() => {
    // console.log("application", application);
    getCurrentVisitingShiftTranslations();
  }, [application]);

  const getCurrentVisitingShiftTranslations = () => {
    if (translations && application && !visitingShiftTranslation) {
      // console.log("translations", translations);
      const currentVisitingShift = translations.visitingShifts.find(
        (visitingShift: any) =>
          visitingShift.uuid === application.visitingShift.uuid
      );

      const currentVisitingShiftTranslations =
        currentVisitingShift?.translations.find(
          (translation: any) =>
            translation.language === currentLanguage.toUpperCase()
        );

      setVisitingShiftTranslation(
        (prev: any) => currentVisitingShiftTranslations
      );
    }
  };

  const showCancelApplicationAlert = () => {
    const alertData = {
      header: t("cancel_application_alert.header"),
      subHeader: t("cancel_application_alert.subheader"),

      inputs: [
        {
          name: "legalIdNumber",
          placeholder: t("cancel_application_alert.placeholder"),
          type: "text",
          value: "",
        },
      ],
      buttons: [
        {
          text: t("cancel_application_alert.buttons.cancel"),
          role: "cancel",
          handler: () => {
            // console.log("Alert canceled");
          },
        },
        {
          text: t("cancel_application_alert.buttons.confirm"),
          role: "confirm",
          handler: async (alertData: any) => {
            const { legalIdNumber } = alertData;

            const data = {
              uuid: application.uuid,
              locator: application.locator,
              legalIdNum: legalIdNumber,
            };

            await cancelApplication(data);

            // console.log("Alert confirmed");
          },
        },
      ],
    };

    presentAlert(alertData);
  };

  const showCancelledApplicationAlert = (statusCode: number) => {
    // console.log("statusCode", statusCode);
    const alertData = {
      header: "",
      message: "",
      buttons: [
        {
          text: "OK",
          role: "confirm",
        },
      ],
    };
    switch (statusCode) {
      case 200:
        alertData.header = t("application.recover_messages.success");
        alertData.message = t("application.recover_messages.cancelled");
        break;

      case 500:
        alertData.header = t("application.recover_messages.error");
        alertData.message = t("application.recover_messages.cancel_error_500");
        break;

      case 400:
        alertData.header = t("application.recover_messages.error");
        alertData.message = t("application.recover_messages.cancel_error");
        break;

      case 404:
        alertData.header = t("alert.locator_not_found.header");
        alertData.message = t("alert.locator_not_found.content");
        break;

      case 422:
        alertData.header = t("application.recover_messages.error");
        alertData.message = t("application.recover_messages.cancel_error_422");
        break;

      default:
        alertData.header = t("application.recover_messages.error");
        alertData.message = t("application.recover_messages.cancel_error");
        break;
    }
    // console.log("alertData", alertData);
    presentAlert(alertData);
  };

  const cancelApplication = async (application: any) => {
    try {
      // console.log("application", application);
      const { locator, uuid, legalIdNum } = application;

      let data = {
        uuid: uuid,
        legalIdNum: legalIdNum,
        locator: locator,
      };

      const config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${BACKEND_URL_BASE}cancelapplication`,
        data: data,
      };
      presentLoading(undefined, "undefined");
      const response = await axios.request(config);
      history.push("/authorisations");
      const toastMessage = t("toast.application.cancel_success");
      presentToast(toastMessage, "middle");

      // if (response) // console.log(response.data);
    } catch (error: any) {
      // console.log(error);
      showCancelledApplicationAlert(error.response.status);
    } finally {
      dismissLoading();
    }
  };

  const showInformationToast = () => {
    let toastMessage = "";

    switch (application?.applicationStatus) {
      case "VERIFICATION_PENDING":
        toastMessage = t("app-status-verification-pending");
        break;
      case "CONFIRMED":
        toastMessage = t("app-status-confirmed");
        break;
      case "CANCELLED":
        toastMessage = t("app-status-cancelled");
        break;
      case "ACTIVE":
        toastMessage = t("app-status-active");
        break;
      case "EXPIRED":
        toastMessage = t("app-status-expired");
        break;
      case "PARTIALLY_CHECKED":
        toastMessage = t("app-status-partially-checked");
        break;
      case "CHECKED":
        toastMessage = t("app-status-checked");
        break;
      case "REJECTED":
        toastMessage = t("app-status-rejected");
        break;

      default:
        toastMessage = "Default explanation...";
        break;
    }

    setShowToast(true);
    setToastMessage(toastMessage);
  };
  return (
    <IonGrid>
      <MNXOAlert
        header={t("alert.status_information")}
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        // position="middle"
        buttons={[
          {
            text: t("button.confirm"),
            role: "confirm",
          },
          // t("button.close")
        ]}
      />
      <IonRow>
        <IonCol
          size="12"
          // sizeXs="12"
          // sizeSm="12"
          // sizeMd="12"
          // sizeLg="12"
          // sizeXl="12"
        >
          <IonCard
            className="application-card ion-align-items-center ion-no-margin ion-no-padding"
            onClick={() => {
              application && onCardClick(application);
              handleOpen();
            }}
          >
            <IonRow className="ion-justify-content-center background">
              <IonCol
                // size={!agentValidation ? "8" : ""}
                sizeXs="8"
                sizeSm="8"
                sizeMd="8"
                sizeLg="8"
                sizeXl="8"
                // offsetXs={!agentValidation ? "" : ""}
                className={` ion-padding  section-application-card-visitor background-white `}
              >
                <IonCardHeader>
                  <IonCardTitle>
                    <IonItem lines="none">
                      {!empty && (
                        <>
                          {visitor && visitor.isPrincipal && (
                            <IonLabel className="text-main-visitor">
                              {t("label.main")}
                            </IonLabel>
                          )}
                        </>
                      )}
                    </IonItem>
                    <h4 style={{ width: "150px" }} className="text-primary">
                      {visitor ? visitor.name : ""}
                    </h4>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="ion-no-padding">
                  {agentValidation && (
                    <IonText>
                      <p>
                        <span className="text-black">{t("label.status")}</span>
                        <MNXOChip
                          text={
                            visitor
                              ? visitor.authorization.authorizationStatus
                              : "TEST"
                          }
                        />
                      </p>
                    </IonText>
                  )}
                  {!empty && !agentValidation && (
                    <>
                      <IonItem lines="none">
                        <IonText>
                          <span className="text-black">
                            {t("label.locator")}
                          </span>
                          {application ? application.locator : "TEST"}
                        </IonText>
                      </IonItem>
                      <IonItem lines="none">
                        <MNXOChip
                          text={application?.applicationStatus}
                          translations={translations?.chipStatuses}
                        />
                        <IonIcon
                          className="icon-information"
                          icon={informationCircleOutline}
                          onClick={(e) => {
                            //  e.stopPropagation() en el manejador onClick, se evita que el evento click llegue
                            //a los elementos padre, incluyendo el modal. De esta forma, el toast se mostrará
                            //sin activar el modal.
                            e.stopPropagation();
                            showInformationToast();
                          }}
                        />
                        {application?.applicationStatus === "CONFIRMED" &&
                          canDelete && (
                            <IonIcon
                              className="custom"
                              icon={trashOutline}
                              slot="end"
                              onClick={(e) => {
                                e.stopPropagation();

                                showCancelApplicationAlert();
                              }}
                            />
                          )}
                      </IonItem>
                    </>
                  )}
                </IonCardContent>
                {agentValidation && (
                  <>
                    <IonText>
                      <p>
                        <span className="text-black">{t("label.locator")}</span>{" "}
                        {visitor ? visitor.legalID.legalIdType : "TEST"}{" "}
                      </p>
                    </IonText>

                    <IonText>
                      <p>
                        {" "}
                        <span className="text-black">
                          {t("label.num_id")}:
                        </span>{" "}
                        {visitor ? visitor.legalID.legalIdNum : ""}{" "}
                      </p>
                    </IonText>
                  </>
                )}
              </IonCol>

              <IonCol
                sizeXs="4"
                sizeSm="4"
                sizeMd="3"
                sizeLg="3"
                sizeXl="3"
                className={`section-application-card-date ion-text-center background-white ${
                  agentValidation && "flex-align-center "
                }`}
              >
                {agentValidation && (
                  <QRCode
                    size={256}
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    value={visitor.authorization.uuid}
                    viewBox={`0 0 256 256`}
                  />
                )}
                {!agentValidation && (
                  <>
                    {" "}
                    {!empty && (
                      <IonGrid>
                        <IonRow className="visitor-date-row">
                          <IonCol className="visitor-date-col">
                            <MNXOHighlightDate date={application} />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    )}
                    <IonGrid className="name-shift-authorizatin-card">
                      <IonRow>
                        <IonCol>
                          <div className="name-shift-auth-card-text">
                            {!empty && (
                              <IonText>
                                {visitingShiftTranslation &&
                                  visitingShiftTranslation.name}
                              </IonText>
                            )}
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </>
                )}
              </IonCol>
            </IonRow>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MNXOApplicationCard;

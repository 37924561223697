import { Preferences } from "@capacitor/preferences";
import { Location } from "./Location";

export class Incident {
  agents: any[];
  incidentStatus: string;
  incidentType: string;
  date: Date | string;
  description: string;
  observations?: string;
  photos?: string[];
  location: Location;
  naturalSpace: string;

  constructor() {
    this.agents = [];
    this.incidentStatus = "open";
    this.incidentType = "";
    this.date = new Date().toISOString().slice(0, 16);
    this.description = "";
    this.observations = "";
    this.photos = [];
    this.location = {
      address: "",
    };
    this.naturalSpace = "Lobos";
  }
}
import cabildoLagunitas from "../assets/slides/cabildo-las_lagunitas.jpg";
import centro from "../assets/slides/centro_de_interpretación_de_lobos.jpg";
import faro from "../assets/slides/faro_de_lobos.jpg";
import pardela from "../assets/slides/fotos_pardela-autor_manolo_carrillo.jpg";
import fauna1 from "../assets/slides/Fauna1.jpg";
import fauna2 from "../assets/slides/Fauna2.jpg";
import playa1 from "../assets/slides/Playas_La_Concha1.jpg";
import playa2 from "../assets/slides/Playas_La_Concha2.jpg";
import playa3 from "../assets/slides/Playas_La_Concha3.jpg";
import playa4 from "../assets/slides/Playas_La_Concha4.jpg";
import playa5 from "../assets/slides/Playas_La_Concha5.jpg";
import playa6 from "../assets/slides/Playas_La_Concha6.jpg";
import playa7 from "../assets/slides/Playas_La_Concha7.jpg";
import playa8 from "../assets/slides/Playas_Puertito1.jpg";
import playa9 from "../assets/slides/Playas_Puertito2.jpg";
import playa10 from "../assets/slides/Playas_Puertito3.jpg";
import playa11 from "../assets/slides/Playas_Puertito4.jpg";
import point_of_interest1 from "../assets/slides/Punto_de_interes_Puertito_de_Lobos1.jpg";
import point_of_interest2 from "../assets/slides/Punto_de_interes_Puertito_de_Lobos2.jpg";
import point_of_interest3 from "../assets/slides/Punto_de_interes_Puertito_de_Lobos3.jpg";
import point_of_interest4 from "../assets/slides/Punto_de_interes_Puertito_de_Lobos4.jpg";
import point_of_interest5 from "../assets/slides/Punto_de_interes_centro_de_interpretacion1.jpg";
import point_of_interest6 from "../assets/slides/Punto_de_interes_centro_de_interpretacion2.jpg";
import point_of_interest7 from "../assets/slides/Punto_de_interes_centro_de_interpretacion3.jpg";
import point_of_interest8 from "../assets/slides/Punto_de_interes_centro_de_interpretacion14.jpg";
import point_of_interest9 from "../assets/slides/Punto_de_interes_faro1.jpg";

import trails1 from "../assets/slides/Senderos1.jpg";
import trails2 from "../assets/slides/Senderos2.jpg";
import trails3 from "../assets/slides/Senderos3.jpg";
import trails4 from "../assets/slides/Senderos4.jpg";
import trails5 from "../assets/slides/Senderos5.jpg";
import trails6 from "../assets/slides/Senderos6.jpg";
import trails7 from "../assets/slides/Senderos7.jpg";

import lagunitas from "../assets/slides/las_lagunitas.jpg";
import montaña from "../assets/slides/montaña_de_la_caldera.jpg";
import siempreviva from "../assets/slides/siempreviva_de_lobos.jpg";
import tabaibas from "../assets/slides/tabaibas_en_lobos.jpg";
import uvilla from "../assets/slides/uvilla_de_mar-tetraena_fontanesii.jpg";
import vistaPuertito from "../assets/slides/vista_aérea_de_el_puertito.jpg";
import vistaConcha from "../assets/slides/vista_aérea_de_la_playa_de_la_concha.jpg";
import vistaLobos from "../assets/slides/vista_aérea_de_lobos.jpg";

//------------------ GALLERY SLIDER ------------------//

export const getSliderImages = (t: any) => {
  const imageCategories = {
    [t("home_page.categories.flora")]: [
      {
        src: siempreviva,
        alt: "Siempreviva de Lobos",
        highlighted: true,
        categorie: "flora",
      },
      {
        src: tabaibas,
        alt: "Tabaibas en Lobos",
        highlighted: false,
        categorie: "flora",
      },
      {
        src: uvilla,
        alt: "Uvilla de mar",
        highlighted: true,
        categorie: "flora",
      },
    ],
    [t("home_page.categories.fauna")]: [
      {
        src: pardela,
        alt: "Foto pardela",
        highlighted: false,
        categorie: "fauna",
      },
      {
        src: fauna1,
        alt: "Fauna1",
        highlighted: false,
        categorie: "fauna",
      },
      {
        src: fauna2,
        alt: "Fauna2",
        highlighted: false,
        categorie: "fauna",
      },
    ],
    [t("home_page.categories.trails")]: [
      {
        src: cabildoLagunitas,
        alt: "Las Lagunitas",
        highlighted: true,
        categorie: "trails",
      },
      {
        src: lagunitas,
        alt: "Las Lagunitas",
        highlighted: false,
        categorie: "trails",
      },
      {
        src: montaña,
        alt: "Montaña de la Caldera",
        highlighted: true,
        categorie: "trails",
      },
      {
        src: trails1,
        alt: "Trails 1",
        highlighted: true,
        categorie: "trails",
      },
      {
        src: trails2,
        alt: "Trails 2",
        highlighted: true,
        categorie: "trails",
      },
      {
        src: trails3,
        alt: "Trails 3",
        highlighted: true,
        categorie: "trails",
      },
      {
        src: trails4,
        alt: "Trails 4",
        highlighted: true,
        categorie: "trails",
      },
      {
        src: trails5,
        alt: "Trails 5",
        highlighted: true,
        categorie: "trails",
      },
      {
        src: trails6,
        alt: "Trails 6",
        highlighted: true,
        categorie: "trails",
      },
      {
        src: trails7,
        alt: "Trails 7",
        highlighted: true,
        categorie: "trails",
      },
    ],
    [t("home_page.categories.points_of_interest")]: [
      {
        src: centro,
        alt: "Centro de interpretación de Lobos",
        highlighted: false,
        categorie: "points_of_interest",
      },
      {
        src: faro,
        alt: "Faro de Lobos",
        highlighted: true,
        categorie: "points_of_interest",
      },
      {
        src: point_of_interest1,
        alt: "Point of interest 1",
        highlighted: true,
        categorie: "points_of_interest",
      },
      {
        src: point_of_interest2,
        alt: "Point of interest 2",
        highlighted: true,
        categorie: "points_of_interest",
      },
      {
        src: point_of_interest3,
        alt: "Point of interest 3",
        highlighted: true,
        categorie: "points_of_interest",
      },
      {
        src: point_of_interest4,
        alt: "Point of interest 4",
        highlighted: true,
        categorie: "points_of_interest",
      },
      {
        src: point_of_interest5,
        alt: "Point of interest 5",
        highlighted: true,
        categorie: "points_of_interest",
      },
      {
        src: point_of_interest6,
        alt: "Point of interest 6",
        highlighted: true,
        categorie: "points_of_interest",
      },
      {
        src: point_of_interest7,
        alt: "Point of interest 7",
        highlighted: true,
        categorie: "points_of_interest",
      },
      {
        src: point_of_interest8,
        alt: "Point of interest 8",
        highlighted: true,
        categorie: "points_of_interest",
      },
      {
        src: point_of_interest9,
        alt: "Point of interest 9",
        highlighted: true,
        categorie: "points_of_interest",
      },
    ],
    [t("home_page.categories.beaches")]: [
      {
        src: vistaConcha,
        alt: "Vista aérea playa de la Concha",
        highlighted: false,
        categorie: "beaches",
      },
      {
        src: vistaLobos,
        alt: "Vista aérea de Lobos",
        highlighted: true,
        categorie: "beaches",
      },
      {
        src: vistaPuertito,
        alt: "Vista aérea del puertito",
        highlighted: false,
        categorie: "beaches",
      },
      {
        src: playa1,
        alt: "Playa1",
        highlighted: false,
        categorie: "beaches",
      },
      {
        src: playa2,
        alt: "Playa2",
        highlighted: false,
        categorie: "beaches",
      },
      {
        src: playa3,
        alt: "Playa3",
        highlighted: false,
        categorie: "beaches",
      },
      {
        src: playa4,
        alt: "Playa4",
        highlighted: false,
        categorie: "beaches",
      },
      {
        src: playa5,
        alt: "Playa5",
        highlighted: false,
        categorie: "beaches",
      },
      {
        src: playa6,
        alt: "Playa6",
        highlighted: false,
        categorie: "beaches",
      },
      {
        src: playa7,
        alt: "Playa7",
        highlighted: false,
        categorie: "beaches",
      },
      {
        src: playa8,
        alt: "Playa8",
        highlighted: false,
        categorie: "beaches",
      },
      {
        src: playa9,
        alt: "Playa9",
        highlighted: false,
        categorie: "beaches",
      },
      {
        src: playa10,
        alt: "Playa10",
        highlighted: false,
        categorie: "beaches",
      },
      {
        src: playa11,
        alt: "Playa11",
        highlighted: false,
        categorie: "beaches",
      },
    ],
  };
  return imageCategories;
};

//------------------ MASONRY GALLERY ------------------//

export const getMasonryImages = (t: any) => {
  const images = [
    { src: cabildoLagunitas, alt: "Las Lagunitas", id: 1 },
    { src: centro, alt: "Centro de interpretación de Lobos", id: 2 },
    { src: faro, alt: "Faro de Lobos", id: 3 },
    { src: pardela, alt: "Foto pardela", id: 4 },
    { src: lagunitas, alt: "Las Lagunitas", id: 5 },
    { src: montaña, alt: "Montaña de la Caldera", id: 6 },
    { src: siempreviva, alt: "Siempreviva de Lobos", id: 7 },
    { src: tabaibas, alt: "Tabaibas en Lobos", id: 8 },
    { src: uvilla, alt: "Uvilla de mar", id: 9 },
    { src: vistaPuertito, alt: "Vista aérea del puertito", id: 10 },
    { src: vistaConcha, alt: "Vista aérea playa de la Concha", id: 11 },
    { src: vistaLobos, alt: "Vista aérea de Lobos", id: 12 },
  ];
  return images;
};

import { peopleOutline, statsChartOutline } from "ionicons/icons";

const path = "app_data.routes.dashboard.";
const pathAgent = "app_data.routes.agent.";
const pathAdmin = "app_data.routes.admin.";

export const getDashboardRoutes = (t: any) => {
  const dashboardRoutes = [
    {
      title: t(pathAgent + "validation.title"),
      url: t(pathAgent + "validation.url"),
    },
    {
      title: t(pathAgent + "incident.title"),
      url: t(pathAgent + "incident.url"),

    },
    {
      title: t(pathAdmin + "edition.title"),
      url: t(pathAdmin + "edition.url"),

    },
    // {
    //   title: t(path + "statistics.title"),
    //   url: t(path + "statistics.url"),
    //   icon: statsChartOutline,
    // },
    // {
    //   title: t(path + "visitors.title"),
    //   url: t(path + "visitors.url"),
    //   icon: peopleOutline,
    // },
  ];
  return dashboardRoutes;
};

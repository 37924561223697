const path = "pages.edit_visiting_configuration.";

export const getVisitingConfigurationPageTranslations = (translate: any) => {
    const visitingConfigurationPageTranslations = {
        title: translate(path + "title"),
        formInputs: {
            visitorsCapacity: translate(path + "form_inputs.visitors_capacity"),
            visitorsPerApplication: translate(path + "form_inputs.visitors_per_application"),
            applicantMinAge: translate(path + "form_inputs.applicant_min_age"),
            maxTimeInAdvance: translate(path + "form_inputs.max_time_in_advance"),
            minTimeBeforeVisit: translate(path + "form_inputs.min_time_before_visit"),
            verificationPeriod: translate(path + "form_inputs.verification_period"),
            lockPeriod: translate(path + "form_inputs.lock_period"),
            autoConfirm: translate(path + "form_inputs.auto_confirm"),
            timezone: translate(path + "form_inputs.timezone"),
        },
        buttons: {
            send: translate("button.send")
        }
    }

    return visitingConfigurationPageTranslations;
}
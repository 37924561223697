import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { t } from "i18next";
import { listOutline, documentAttachOutline } from "ionicons/icons";
import React from "react";
import Header from "../../../components/Header";
import MNXOContent from "../../../components/MNXOContent";
import MNXOTitle from "../../../components/MNXOTitle";
import MNXODashboardCard from "../components/MNXODashboardCard";
import { useTranslation } from "react-i18next";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";

const MNXOIncidentPage: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <IonPage>
      <Header backButtonLink={"/dashboard/main"}  hide={true} />

      <MNXOContent
        containerStyle="background-body-color"
        page={""}
        type={""}
        title={t("incident_page.header.title")}
        text={t("incident_page.header.text")}
        chipText={t("incident_page.header.chip")}
      >
        <MNXODescriptiveText
          text=""
          subtext=""
        ></MNXODescriptiveText>
        <IonGrid>
          <IonRow className="dashboard-first-row ion-padding subsection">
            <IonCol>
              <MNXODashboardCard
                title={t("incident_page.create_incident_card.title")}
                // icon={documentAttachOutline}
                link={"/dashboard/incident/create"}
              />
            </IonCol>
            <IonCol className="dashboard-col-card">
              <MNXODashboardCard
                title={t("incident_page.incident_list_card.title")}
                // icon={listOutline}
                link={"/dashboard/incident/show"}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </MNXOContent>
    </IonPage>
  );
};

export default MNXOIncidentPage;

import React, { useEffect, useState } from "react";
import {
  IonCol,
  IonContent,
  IonLabel,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonTitle,
  RefresherEventDetail,
} from "@ionic/react";

import { useHistory, useLocation } from "react-router-dom";
import { useApplicationsList } from "../hooks/useApplicationsList";
import MNXOApplicationCard from "../components/MNXOApplicationCard";
import LocatorForm from "../components/LocatorForm";
import MNXOApplicationModal from "../components/MNXOApplicationModal";
import { useTranslation } from "react-i18next";
import { useNaturalSpace } from "../../../hooks/useNaturalSpace";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import MNXOFooter from "../../../components/MNXOFooter";
import { useLoading } from "../../../hooks/useLoading";
import Header from "../../../components/Header";
import MNXOTitle from "../../../components/MNXOTitle";
import MNXOContent from "../../../components/MNXOContent";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import axios from "axios";
import { useAlert } from "../../../hooks/useAlert";
import { useToast } from "../../../hooks/useToast";
import { useFetchData } from "../../../hooks/useFetchData";
import MNXOContactButton from "../../../components/buttons/ContactButton";

const RecoverAuthorisation: React.FC = () => {
  // const { naturalSpaceUUID } = useStorage("naturalspace");
  const { visitingShifts, visitorTypes, allVisitingShifts } = useNaturalSpace(
    "recovery"
    // naturalSpaceUUID
  );

  const {
    applications,
    setOnRefresh,
    searchByLocator,
    // showCancelledApplicationAlert,
    // showCancelApplicationAlert,
    isVerified,
    showVerifiedApplicationAlert,
    applicationEmailLocator,
    isOpenAlert,
  } = useApplicationsList();

  const [t, i18n] = useTranslation("global");
  const history = useHistory();
  const [selectedApplication, setSelectedApplication] = useState<any | null>(
    null
  );
  const { presentAlert } = useAlert();
  const { presentLoading, dismissLoading } = useLoading();
  const { presentToast } = useToast();
  const isLoading = !visitorTypes || !allVisitingShifts || !visitingShifts;
  const [loadingActive, setLoadingActive] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentElement, setCurrentElement] = useState(0);
  const [applicationSearchedStatus, setApplicationSearchedStatus] = useState<
    string | undefined
  >(undefined);

  const { chipStatuses } = useAppTranslations();

  const BACKEND_URL_BASE = process.env.REACT_APP_BACKEND_URL_BASE;

  // Obtener el locator
  const { pathname, hash } = useLocation();

  const showAlreadyCancelApplicationAlert = () => {
    const application = {
      locator: hash.split("/")[0].slice(1),
      uuid: hash.split("/")[1],
    };
    const subHeaderMessage: string = t(
      "cancelled_application_alert.subheader"
    ).replace("X", application.locator.toUpperCase());
    const alertData = {
      header: `${t("cancelled_application_alert.header")}`,
      subHeader: subHeaderMessage,

      buttons: [
        {
          text: t("cancelled_application_alert.buttons.confirm"),
          role: "confirm",
          handler: () => {
            // console.log("Alert canceled");
          },
        },
      ],
    };

    presentAlert(alertData);
  };

  const showCancelApplicationAlert = () => {
    const application = {
      locator: hash.split("/")[0].slice(1).toUpperCase(),
      uuid: hash.split("/")[1],
    };

    const alertData = {
      header: `${t("cancel_application_alert.header")} ${application.locator}`,
      subHeader: t("cancel_application_alert.subheader"),

      inputs: [
        {
          name: "legalIdNumber",
          placeholder: t("cancel_application_alert.placeholder"),
          type: "text",
          value: "",
        },
      ],
      buttons: [
        {
          text: t("cancel_application_alert.buttons.cancel"),
          role: "cancel",
          handler: () => {
            // console.log("Alert canceled");
          },
        },
        {
          text: t("cancel_application_alert.buttons.confirm"),
          role: "confirm",
          handler: async (alertData: any) => {
            const { legalIdNumber } = alertData;

            const data = {
              uuid: application.uuid,
              locator: application.locator.toUpperCase(),
              legalIdNum: legalIdNumber.toUpperCase(),
            };

            cancelApplication(data);
            // console.log("Alert confirmed");
          },
        },
      ],
    };

    presentAlert(alertData);
  };

  const cancelApplication = async (application: any) => {
    try {
      // console.log("application", application);
      const { locator, uuid, legalIdNum } = application;

      let data = {
        uuid: uuid,
        legalIdNum: legalIdNum,
        locator: locator,
      };

      const config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${BACKEND_URL_BASE}cancelapplication`,
        data: data,
      };

      const response = await axios.request(config);
      history.push("/authorisations");
      const toastMessage = t("toast.application.cancel_success");
      presentToast(toastMessage);

      // if (response) // console.log(response.data);
    } catch (error: any) {
      // console.log("ERROR", error.response);
      showCancelledApplicationAlert(error.response.status);

      // console.log(error);
    }
  };

  const showCancelledApplicationAlert = (statusCode: number) => {
    // console.log("statusCode", statusCode);
    const alertData = {
      header: "",
      message: "",
      buttons: [
        {
          text: "OK",
          role: "confirm",
          handler: async () => {},
        },
      ],
    };
    switch (statusCode) {
      case 200:
        alertData.header = t("application.recover_messages.success");
        alertData.message = t("application.recover_messages.cancelled");
        break;

      case 500:
        alertData.header = t("application.recover_messages.error");
        alertData.message = t("application.recover_messages.cancel_error_500");
        break;

      case 400:
        alertData.header = t("application.recover_messages.error");
        alertData.message = t("application.recover_messages.cancel_error");
        break;

      case 404:
        alertData.header = t("alert.locator_not_found.header");
        alertData.message = t("alert.locator_not_found.content");
        break;

      case 422:
        alertData.header = t("application.recover_messages.error");
        alertData.message = t("application.recover_messages.cancel_error_422");
        break;

      default:
        alertData.header = t("application.recover_messages.error");
        alertData.message = t("application.recover_messages.cancel_error");
        break;
    }

    presentAlert(alertData);
  };

  const getApplicationStatus = async (applicationUUID: string) => {
    try {
      if (applicationSearchedStatus) return;
      const endpoint = `${BACKEND_URL_BASE}applicationbyuuid/${applicationUUID}`;
      const response = await axios.get(endpoint);

      const applicationStatus = response.data.applicationStatus;
      // console.log("status", applicationStatus);

      if (pathname === "/authorisations") {
        switch (applicationStatus) {
          case "VERIFICATION_PENDING":
            showVerifiedApplicationAlert(hash.slice(1));
            break;

          case "CONFIRMED":
            showVerifiedApplicationAlert(hash.slice(1));
            break;

          case "CANCELLED":
            showAlreadyCancelApplicationAlert();
            break;
        }
      } else if (pathname === "/authorisations/cancel") {
        switch (applicationStatus) {
          case "CONFIRMED":
            showCancelApplicationAlert();
            break;

          case "CANCELLED":
            showAlreadyCancelApplicationAlert();
            break;
        }
      }
      setApplicationSearchedStatus(applicationStatus);
    } catch (error) {}
  };

  let translations: any;
  let applicationCardTranslations: any;
  const [locatorFormAction, setLocatorFormAction] = useState<string>("search");

  if (visitorTypes && allVisitingShifts && visitingShifts) {
    const applicationUUID = hash.split("/")[1];

    if (!isOpenAlert && hash) {
      getApplicationStatus(applicationUUID);
    }

    translations = {
      visitingShifts: allVisitingShifts,
      visitorTypes: visitorTypes,
    };

    applicationCardTranslations = {
      visitingShifts: allVisitingShifts,
      chipStatuses: chipStatuses,
    };
  }

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setCurrentElement(0);
  };

  const handleFilters = async (filters: any) => {
    setLoadingActive(true);
    const { IDNumber, locator } = filters;
    const data = {
      IDNumber: IDNumber.toUpperCase(),
      locator: locator.toUpperCase(),
    };
    searchByLocator(data);
    setLoadingActive(false);
  };

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setLoadingActive(true);
    setTimeout(() => {
      setOnRefresh(true);
      // console.log("se ha refrescado el contenido");
      event.detail.complete();
      setLoadingActive(false);
    }, 2000);
  }

  const handleCardClick = (application: any) => {
    setSelectedApplication(application);
  };

  const handleClick = () => {
    history.push("/application");
    window.location.reload();
  };

  return (
    <IonPage>
      <Header />
      <MNXOContent
        containerStyle="authorizaion-page"
        text={t("recovery_page.header.text")}
        title={t("recovery_page.header.title")}
        chipText={t("recovery_page.header.chip")}
      >
        <MNXODescriptiveText
          text={t("recovery_page.instructions.text")}
          subtext={t("recovery_page.instructions.subtext")}
        />
        <IonRow className="ion-justify-content-center">
          <IonCol size="auto">
            <MNXOContactButton color="lobos-secondary" />
          </IonCol>
        </IonRow>
        <div className="container">
          <>{isLoading && presentLoading()}</>
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>

          {translations && visitingShifts && (
            <MNXOApplicationModal
              currentElement={currentElement}
              setCurrentElement={setCurrentElement}
              applicationData={selectedApplication}
              isOpen={isOpen}
              handleClose={handleClose}
              visitingShifts={visitingShifts}
              translations={translations}
            />
          )}

          {/* {!selectedApplication && ( */}
          {/* // Mostrar el formulario y la lista de aplicaciones */}
          <>
            <LocatorForm
              action={locatorFormAction}
              dataToSearch="application"
              handleFilters={handleFilters}
              applicationEmailLocator={applicationEmailLocator}
            />

            {applications ? (
              <>
                {applications.length > 0 ? (
                  <>
                    <IonTitle
                      className="application-card-title"
                      color={"lobos-text-grey"}
                    >
                      {t("text.recovery_auth")}
                    </IonTitle>

                    {/* <Virtuoso
                    style={{ height: "400px", width: "100%" }} // Ajusta el alto y el ancho según tus necesidades
                    totalCount={applications.length}
                    itemContent={(index) => {
                      const application = applications[index];
                      const principalVisitor = application.visitors.find(
                        (visitor) => visitor.isPrincipal
                      ); */}

                    {applications?.map((application: any, index: any) => {
                      const principalVisitor = application.visitors.find(
                        (visitor: any) => visitor.isPrincipal
                      );
                      return (
                        <div key={index}>
                          {applicationCardTranslations && (
                            <MNXOApplicationCard
                              key={index}
                              visitor={principalVisitor}
                              application={application}
                              onCardClick={handleCardClick}
                              handleOpen={handleOpen}
                              translations={applicationCardTranslations}
                            />
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <h2>{t("text.not_app")}</h2>
                )}
              </>
            ) : (
              <IonLabel color={"light"}>{t("text.not_app")}</IonLabel>
            )}
          </>
        </div>
      </MNXOContent>
      <MNXOFooter className="MNXOModalFooter" />
    </IonPage>
  );
};

export default RecoverAuthorisation;

import { IonLabel, IonNote } from "@ionic/react";
import React from "react";

import { useAppTranslations } from "../../../hooks/useAppTranslations";

const CurrentDate: React.FC = () => {
  const { currentLanguage, currentDateMessage } = useAppTranslations();
  let currentDate: string | null = null;

  // get the current date
  if (currentLanguage) {
    currentDate = new Date().toLocaleString(currentLanguage, {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }

  return (
    <>
      {currentDate && (
        <div className="current-date ion-text-end">
          <IonLabel className="current-date">
            <h3>{currentDateMessage}</h3>
          </IonLabel>
          <IonNote>{currentDate}</IonNote>
        </div>
      )}
    </>
  );
};

export default CurrentDate;

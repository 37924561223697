import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import { handLeftOutline, qrCodeOutline } from "ionicons/icons";
import React from "react";
import Header from "../../../components/Header";
import MNXOContent from "../../../components/MNXOContent";
import MNXOTitle from "../../../components/MNXOTitle";
import MNXODashboardCard from "../components/MNXODashboardCard";
import { useTranslation } from "react-i18next";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";

const MNXOValidationPage: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <IonPage>
      <Header backButtonLink={"/dashboard/main"} hide={true} />
      <MNXOContent
        containerStyle="background-body-color"
        page={""}
        type={""}
        title={t("validation_page.header.title")}
        text={t("validation_page.header.text")}
        chipText={t("incident_page.header.chip")}
      >
        <IonGrid>
          <MNXODescriptiveText
            text={t("validation_page.instructions.text")}
            subtext={t("validation_page.instructions.subtext")}
          ></MNXODescriptiveText>
          <IonRow className="ion-padding dashboard-row  dashboard-first-row">
            <IonCol size="12">
              <MNXODashboardCard
                title={t("validation_page.scan.title")}
                icon={qrCodeOutline}
                link={"/dashboard/validation/scan"}
              />
            </IonCol>
            <IonCol size="12" className="dashboard-col-card">
              <MNXODashboardCard
                title={t("validation_page.manual.title")}
                icon={handLeftOutline}
                link={"/dashboard/validation/manual"}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </MNXOContent>
    </IonPage>
  );
};

export default MNXOValidationPage;

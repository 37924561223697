import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import { useFetchData } from "../../../hooks/useFetchData";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import { useStorage } from "../../../hooks/useStorage";

export const useFAQ = (faqTranslationAlias?: string) => {
  const [t, i18n] = useTranslation("global");
  const { currentLanguage } = useAppTranslations();

  // Static translations

  // Dynamic translations
  const { data: faqTranslations, fetchData: fetchFaqTranslations } =
    useFetchData();
  const [faqTranslation, setFaqTranslation] = useState<any | null>(null);
  const [allFaqs, setAllFaqs] = useState<any | null>(null);

  useEffect(() => {
    if (faqTranslationAlias) {
      fetchInfo(faqTranslationAlias);
    }
  }, [faqTranslations, currentLanguage]);

  const fetchInfo = async (faqTranslationALias: string) => {
    if (faqTranslations) {
      setAllFaqs(faqTranslations);

      const translationArray: any = [];
      faqTranslations.map((faq: any) => {
        faq.translations.map((translation: any) => {
          if (translation.language === currentLanguage.toUpperCase()) {
            let translationAdapted = {
              alias: faq.alias,
              translation: translation,
            };
            translationArray.push(translationAdapted);
          }
        });
      });
      setFaqTranslation(translationArray);
    } else if (!faqTranslations) {
      await getFaqTranslations(faqTranslationALias);
    }
  };

  const getFaqTranslations = async (alias: string) => {
    const API_URL = `${process.env.REACT_APP_BACKEND_URL_BASE}faq/${alias}`;
    await fetchFaqTranslations(API_URL);
  };

  return { currentLanguage, faqTranslation, allFaqs };
};

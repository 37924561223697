import { GoogleMap } from "@capacitor/google-maps";
import { useEffect, useRef, useState } from "react";
import { Geolocation } from "@capacitor/geolocation";
import { useAppTranslations } from "../../../hooks/useAppTranslations";

interface ContainerProps {
  currentCoords: any;
  setCurrentCoords: (coords: any) => void;
}

const MNXOGeolocationMap: React.FC<ContainerProps> = ({
  currentCoords,
  setCurrentCoords,
}) => {
  const mapRef = useRef<HTMLElement>();
  let newMap: GoogleMap;
  const { currentLanguage } = useAppTranslations();

  const [marker, setMaker] = useState<any>({
    coordinate: {
      lat: currentCoords.latitude,
      lng: currentCoords.longitude,
    },
    draggable: true,
  });

  useEffect(() => {
    createMap(currentLanguage);
  }, [currentLanguage]);

  async function createMap(language: string) {
    if (!mapRef.current) return;

    const { coords } = await Geolocation.getCurrentPosition();
    const { latitude, longitude } = coords;

    const zoom = 14;

    newMap = await GoogleMap.create({
      id: "my-cool-map",
      element: mapRef.current,
      apiKey: process.env.REACT_APP_YOUR_API_KEY_HERE!,
      language: language,
      forceCreate: true,
      config: {
        scrollwheel: false,
        center: {
          lat: currentCoords.latitude,
          lng: currentCoords.longitude,
        },
        zoom: zoom,
      },
    });

    await newMap.addMarker(marker);

    await newMap.setOnMarkerDragEndListener(({ latitude, longitude }) => {
      setCurrentCoords({ latitude, longitude });
    });
  }

  return (
    <div className="component-wrapper">
      <capacitor-google-map
        ref={mapRef}
        style={{
          display: "inline-block",
          width: "100%",
          height: 400,
        }}
      />
    </div>
  );
};

export default MNXOGeolocationMap;

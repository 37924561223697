import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonModal,
  IonRow,
} from "@ionic/react";
import React, { useRef, useState } from "react";

import { useFAQ } from "../hooks/useFAQ";
import { useTranslation } from "react-i18next";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import MNXOHeader from "../../../components/MNXOHeader";
import MNXOFAQModalEditor from "./MNXOFAQModalEditor";
import { useFAQApi } from "../hooks/useFAQAPI";
import MNXOFAQCreateForm from "./MNXOFAQCreateForm";

interface Props {
  isOpen: boolean;
  setIsOpen?: any;
}

const MNXOFAQModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const [t, i18n] = useTranslation("global");
  const modal = useRef<HTMLIonModalElement>(null);
  const { deleteFAQ } = useFAQApi();
  const { faqTranslation: faqs, allFaqs } = useFAQ("all");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openCreateFAQ, setOpenCreateFAQ] = useState(false);

  const [faqToEdit, setFaqToEdit] = useState<any>(null);

  const handleClick = async (faq: any) => {
    setFaqToEdit(faq);
    setOpenEditModal(true);
  };
  const handleOpenDeleteAlert = async (faq: any) => {
    setFaqToEdit(faq);
    setOpenDeleteAlert(true);
  };
  const handleCreateFAQModal = async (faq: any) => {
    setOpenCreateFAQ(true);
  };

  const handleCloseDeleteAlert = async (faq: any) => {
    setFaqToEdit(null);
    setOpenDeleteAlert(false);
  };

  const handleDelete = async (e: any) => {
    setOpenDeleteAlert(false);
    deleteFAQ(faqToEdit, e);
    window.location.reload();
  };

  const handleClose = async () => {
    setIsOpen(false);
  };
  return (
    <>
      <IonModal
        ref={modal}
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
      >
        <MNXOHeader
          text={t("modal-faqs-edit.header.text")}
          type="modal"
          handleClose={handleClose}
          title={t("modal-faqs-edit.header.title")}
        />        
        <IonContent className="ion-padding background-body-color">
          {allFaqs &&
            allFaqs.map((faq: any, index: number) => {
              return (
                <div key={index}>
                  <IonCard className="visitor-application-card ">
                    <IonRow>
                      <IonCol className={`ion-padding`}>
                        <IonCardHeader>
                          <IonCardTitle style={{ marginBottom: "16px" }}>
                            {faq.alias}
                          </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                          <IonRow>
                            <IonCol>
                              <IonButton onClick={() => handleClick(faq)}>
                                {t("button.edit")}
                              </IonButton>
                            </IonCol>
                            <IonCol>
                              <IonButton
                                color={"danger"}
                                onClick={() => handleOpenDeleteAlert(faq)}
                              >
                                {t("button.delete")}
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonCardContent>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </div>
              );
            })}
          {openEditModal && (
            <MNXOFAQModalEditor
              isOpen={openEditModal}
              setOpenEditModal={setOpenEditModal}
              faq={faqToEdit}
            />
          )}

          <IonButton onClick={(e) => handleCreateFAQModal(e)}>
            {t("button.create")}
          </IonButton>

          {openCreateFAQ && (
            <MNXOFAQCreateForm
              isOpen={openCreateFAQ}
              setIsOpen={setOpenCreateFAQ}
            />
          )}
          <IonAlert
            isOpen={openDeleteAlert}
            cssClass="custom-class"
            header="¿Estás seguro de que quieres borrar este FAQ?"
            subHeader={`Alias: ${faqToEdit && faqToEdit.alias}`}
            message="Escribe el alias del FAQ para confirmar "
            inputs={[
              {
                placeholder: "Comentario",
                name: "comment",
              },
            ]}
            buttons={[
              {
                text: "Cancelar",
                role: "cancel",
                handler: (e) => handleCloseDeleteAlert(e),
              },
              {
                text: "Eliminar",
                role: "confirm",
                handler: (e) => {
                  handleDelete(e);
                },
              },
            ]}
            onDidDismiss={(e) => handleCloseDeleteAlert(e)}
          ></IonAlert>
        </IonContent>
      </IonModal>
    </>
  );
};

export default MNXOFAQModal;

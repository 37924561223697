import { useEffect, useState } from "react";
import {
  VisitingConfiguration,
  VisitingConfigurationHistory,
} from "../../../models/VisitingConfiguration.model";
import { useFetchData } from "../../../hooks/useFetchData";
import { useStorage } from "../../../hooks/useStorage";
import axios from "axios";
import { useToast } from "../../../hooks/useToast";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import { useHistory } from "./useHistory";
const API_URL_BASE = process.env.REACT_APP_BACKEND_URL_BASE;

export const useVisitingConfiguration = () => {
  const { naturalSpaceUUID } = useStorage("naturalspace");
  const { presentToast } = useToast();
  const { showHistoryAlert } = useHistory();
  const { translation, visitingConfigurationPageTranslations } =
    useAppTranslations();
  const {
    data: visitingConfiguration,
    loading,
    error,
    fetchData: fetchVisitingConfiguration,
  } = useFetchData();
  const [visitingConfigurationFormData, setVisitingConfigurationFormData] =
    useState<VisitingConfiguration>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        !visitingConfiguration
          ? await getVisitingConfiguration()
          : setVisitingConfigurationFormData(visitingConfiguration);
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, [visitingConfiguration]);

  const getVisitingConfiguration = async () => {
    const apiURL = API_URL_BASE + "configuration/all";
    const data = await fetchVisitingConfiguration(apiURL);
  };

  const updateVisitingConfiguration = async (inputData: any) => {
    const { name, value } = inputData;
    setVisitingConfigurationFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const presentResponseToast = (statusCode: number) => {
    let message: string;
    const position = "middle";
    const duration = 3000;

    // TODO: improve and use static translations
    switch (statusCode) {
      case 200:
        message = "Todo ha ido bien";
        break;

      default:
        message = "Algo ha fallado";
        break;
    }
    presentToast(message, position, duration);
  };

  const createHistory = (formAction: string) => {
    showHistoryAlert(formAction, sendVisitingConfiguration);
  };

  const sendVisitingConfiguration = async (
    method: string,
    newHistory: VisitingConfigurationHistory
  ) => {
    try {
      const data = {
        visitingConfiguration: visitingConfigurationFormData,
        visitingConfigurationHistory: newHistory,
      };

      const config = {
        method: method,
        maxBodyLength: Infinity,
        url: API_URL_BASE + "configuration/update/visitingconfiguration",
        data: data,
      };

      const response = await axios.request(config);

      if (response) {
        presentResponseToast(response.status);
      }
    } catch (error: any) {
      presentResponseToast(error.response.status);
    }
  };

  return {
    visitingConfiguration,
    loading,
    visitingConfigurationFormData,
    updateVisitingConfiguration,
    sendVisitingConfiguration,
    visitingConfigurationPageTranslations,
    createHistory,
  };
};

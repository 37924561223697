import axios from "axios";
import { History } from "../../../models/History";
import { Preferences } from "@capacitor/preferences";

const API_URL = process.env.REACT_APP_BACKEND_URL_BASE;

export const useFAQApi = () => {
  const updateFAQ = async (newInformation: any) => {
    // TODO: implementar hook useHistory
    const { value } = await Preferences.get({ key: "logged-user" });
    const loggedUser: any = JSON.parse(value!);

    const history = new History();
    history.comment = "FAQ actualizada";
    history.description = "Descripción por defecto";
    history.modifiedBy = loggedUser.username;

    const { alias, translation, aliasToEdit } = newInformation;
    const { question, answer, language, slug } = translation;
    // console.log(newInformation);

    const updatedTranslation = {
      faqHistory: history,
      faq: {
        alias: aliasToEdit,
        translation: translation,
      },
    };

    const url = API_URL + `faq/${alias}/${language}`;
    const response = await axios.patch(url, updatedTranslation);
    const { data, status } = response;
    return { data, status };
  };

  const createFAQ = async (faq: any) => {
    const url = API_URL + `faq/`;

    // TODO: implementar hook useHistory
    const { value } = await Preferences.get({ key: "logged-user" });
    const loggedUser: any = JSON.parse(value!);

    const history = new History();
    history.comment = "FAQ creada";
    history.description = "Descripción por defecto";
    history.modifiedBy = loggedUser.username;

    const createFaq = {
      faqHistory: history,
      faq,
    };
    const response = await axios.post(url, createFaq);
    const { data, status } = response;
    return { data, status };
  };

  const deleteFAQ = async (faq: any, comment: any) => {
    const object = {
      alias: faq.alias,
      faqHistory: {
        comment: comment.comment,
      },
    };
    // console.log(object);
    const url = API_URL + `faq/delete`;
    const response = await axios.post(url, object);
    const { data, status } = response;
    return { data, status };
  };

  return { updateFAQ, deleteFAQ, createFAQ };
};

import {
  informationCircleOutline,
  helpOutline,
  listOutline,
  mailOutline,
  pawOutline,
  ticket,
} from "ionicons/icons";

const path = "app_data.routes.auth.";

export const getAuthRoutes = (t: any) => {
  const authRoutes = [
    {
      title: t(path + "application.title"),
      url: t(path + "application.url"),
      icon: ticket,
    },
    {
      title: t(path + "recovery.title"),
      url: t(path + "recovery.url"),
      icon: listOutline,
    },
    // {
    //   title: t(path + "cancel.title"),
    //   url: t(path + "cancel.url"),
    //   icon: pawOutline,
    // },
  ];

  return authRoutes;
};

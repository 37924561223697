import { useEffect, useRef, useState } from "react";
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import MNXOLoading from "../components/MNXOLoading";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../components/Header";
import { useAppTranslations } from "../hooks/useAppTranslations";
import MNXOModalInterest from "./MNXOModalInterest";

type InfoProps = {
  images: any;
  interestInfo: any;
};

const InfoInterest: React.FC<InfoProps> = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const cardInfo = location.state as {
    interestInfo?: {
      alternateName: string;
      image: string;
      imageGallery: any;
      location: any;
      translations: any;
    };
  };
  const { currentLanguage } = useAppTranslations();
  const currentTranslations = cardInfo?.interestInfo?.translations.find(
    (translation: any) => translation.language === currentLanguage.toUpperCase()
  );

  const backgroundImageStyle = {
    backgroundImage: cardInfo
      ? `url(` + require(`../assets/slides/${cardInfo?.interestInfo?.image}`)
      : "",
  };
  const arrayImg: any = [];
  cardInfo?.interestInfo?.imageGallery.map(async (imageName: string) => {
    const result = require(`../assets/slides/${imageName}`);
    arrayImg.push(result);
  });

  const modal = useRef<HTMLIonModalElement>(null);
  const divModal: any = document.getElementById("poi-modal");
  // console.log("divModal", divModal);
  const content = document.getElementById("poi-content");
  // console.log("CONTENT", content);
  content && content?.appendChild(divModal);
  useEffect(() => {
    // console.log("MODAL", modal);

    if (location.pathname === "/info-interest") {
      // console.log(showModal);
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [showModal]);

  const handleDismiss = async () => {
    // console.log("dismiss");
    setShowModal(false);
  };
  return (
    <IonPage>
      <IonContent
        style={backgroundImageStyle}
        className="lobos-background "
        id="poi-content"
      >
        <Header
          className="info-interest"
          img
          type="transparent"
          isWhiteBackground={false}
        />
        <IonGrid>
          <IonRow>
            <IonCol className="ion-padding interest-title">
              <h2 id="welcome-title">{currentTranslations?.name}</h2>
              <h3 className="img-text">{currentTranslations?.subtitle}</h3>
            </IonCol>
          </IonRow>
        </IonGrid>
        <MNXOModalInterest
          initializeBreakpoint={0.25}
          modalRef={modal}
          handleDismiss={handleDismiss}
          showModal={showModal}
          setShowModal={setShowModal}
          info={cardInfo?.interestInfo}
          gallery={arrayImg}
        />
      </IonContent>
    </IonPage>
  );
};
export default InfoInterest;

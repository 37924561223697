import {
  IonButton,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonToggle,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "../../../theme/dashboard_page.scss";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header";
import MNXOContent from "../../../components/MNXOContent";
import MNXODashboardCard from "../components/MNXODashboardCard";
import {
  constructOutline,
  qrCodeOutline,
  warningOutline,
} from "ionicons/icons";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";

import { useFetchData } from "../../../hooks/useFetchData";
import { useMaintenanceMode } from "../hooks/useMaintenanceMode";
import { useToast } from "../../../hooks/useToast";

interface MaintenanceMode {
  isActive: boolean;
  validThrough?: Date;
}

const MNXOAdminDashboard: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  const [maintenanceMode, setMaintenanceMode] =
    useState<MaintenanceMode | null>(null);
  const { presentToast } = useToast();
  const { data, loading, error, fetchData } = useFetchData();
  const { fetchData: sendMaintenanceMode } = useFetchData();
  // const { maintenanceModeData, updateMaintenanceMode } = useMaintenanceMode();

  const API_URL_BASE = process.env.REACT_APP_BACKEND_URL_BASE;
  const endpoint = `${API_URL_BASE}maintenance-mode`;

  const handleMaintenanceModeStatus = () => {
    setMaintenanceMode((prev: any) => ({
      ...prev,
      isActive: !prev.isActive,
    }));
  };

  const handleMaintenanceModeDateUntil = (newDate: string) => {
    setMaintenanceMode((prev: any) => ({
      ...prev,
      validThrough: new Date(newDate),
    }));
  };

  const setMaintenanceModeConfig = async () => {
    const updatedMaintenanceMode = {
      maintenanceMode: {
        isActive: maintenanceMode?.isActive,
        validThrough: maintenanceMode?.validThrough,
      },
    };
    const response = await sendMaintenanceMode(
      endpoint,
      "POST",
      updatedMaintenanceMode
    );

    if (response?.status === 200) {
      presentToast(t("maintenance.form.success"), "middle");
    } else {
      presentToast(t("maintenance.form.error"), "middle");
    }
  };

  useIonViewWillEnter(() => {
    // console.log(endpoint);

    const getMaintenanceMode = async () => {
      await fetchData(endpoint);
    };

    getMaintenanceMode();
  });

  useEffect(() => {
    if (data) {
      const { isActive, validThrough } = data.maintenanceMode;
      const date = validThrough ? new Date(validThrough) : new Date();

      setMaintenanceMode({
        isActive: isActive,
        validThrough: date,
      });
    }
  }, [data]);

  useEffect(() => {
    if (maintenanceMode) {
      // console.log("DATA", maintenanceMode);
    }
  }, [maintenanceMode]);

  return (
    <IonPage>
      <Header hide={true} />
      <MNXOContent
        containerStyle="background-body-color"
        page={""}
        type={""}
        title={t("dashboard_page.header.title")}
        text={t("dashboard_page.header.text")}
        chipText={t("dashboard_page.header.chip")}
      >
        <MNXODescriptiveText
          text={t("dashboard_page.instructions.text")}
          subtext={t("dashboard_page.instructions.subtext")}
        ></MNXODescriptiveText>
        <IonGrid className="admin-dashboard">
          <IonButton routerLink="/dashboard/group-application">
            {t("group_application.button")}
          </IonButton>
          <IonRow className=" ion-padding dashboard-first-row dashboard-row">
            <IonCol size="12">
              <MNXODashboardCard
                title={t("dashboard_page.scan_card.title")}
                icon={qrCodeOutline}
                link={"/dashboard/validation"}
              />
            </IonCol>
            <IonCol className="dashboard-col-card">
              <MNXODashboardCard
                title={t("dashboard_page.incident_card.title")}
                icon={warningOutline}
                link={"/dashboard/incident"}
              />
            </IonCol>{" "}
            <IonCol className="dashboard-col-card">
              <MNXODashboardCard
                title={t("dashboard_page.configuration_card.title")}
                icon={constructOutline}
                link={"/dashboard/configuration"}
              />
            </IonCol>
          </IonRow>

          {maintenanceMode && (
            <>
              <IonCol className="maintenance-configuration" size="6">
                <IonRow className="">
                  <IonToggle
                    checked={maintenanceMode.isActive}
                    onClick={() => {
                      handleMaintenanceModeStatus();
                    }}
                  >
                    {t("maintenance.form.title")}
                  </IonToggle>
                </IonRow>
                <IonRow className="i ion-align-items-center">
                  <IonLabel>{t("maintenance.form.validThrough")}</IonLabel>
                  <IonDatetimeButton datetime="datetime" />
                  <IonModal keepContentsMounted={true}>
                    <IonDatetime
                      id="datetime"
                      value={maintenanceMode.validThrough?.toISOString()}
                      onIonChange={(e) => {
                        handleMaintenanceModeDateUntil(
                          e.detail.value! as string
                        );
                      }}
                    />
                  </IonModal>
                </IonRow>
                <IonButton onClick={() => setMaintenanceModeConfig()}>
                  {t("maintenance.form.button")}
                </IonButton>
              </IonCol>
            </>
          )}
        </IonGrid>
      </MNXOContent>
    </IonPage>
  );
};

export default MNXOAdminDashboard;

import { IonCol, IonIcon, IonRow } from "@ionic/react";
import React from "react";
import MNXOSticker from "./MNXOSticker";
import { close } from "ionicons/icons";

interface ContainerProps {
  page: string;
  type: string;
  title: string;
  text: string;
  hide?: boolean;
  searchbar?: boolean;
  chipText: string;
  handleClose?: any;
  searchKeyword?: any;
  setSearchKeyword?: any;
}

// AUTHOR: Joel
// Mejorar a futuro
const MNXOTitle: React.FC<ContainerProps> = ({
  page,
  type,
  title,
  text,
  searchbar,
  handleClose,
  chipText,
  hide,
  searchKeyword,
  setSearchKeyword,
}) => {
  const typeOptions = {
    SEARCHBAR: "search_bar",
    MODAL: "modal",
  };

  const pageOptions = {
    HOME: "home",
  };

  switch (type) {
    case typeOptions.SEARCHBAR:
      return (
        <IonRow className="validation-container">
          <IonCol
            className={`${
              page.toLowerCase() === pageOptions.HOME
                ? "mnxoheader-content-container home"
                : "mnxoheader-content-container"
            }`}
          >
            <h2>{title}</h2>
            <p>{text}</p>
            {chipText && (
              <div className="sticker">
                <MNXOSticker
                  type={type}
                  searchKeyword={searchKeyword ? searchKeyword : null}
                  setSearchKeyword={setSearchKeyword ? setSearchKeyword : null}
                ></MNXOSticker>
              </div>
            )}
          </IonCol>
        </IonRow>
      );

    case typeOptions.MODAL:
      return (
        <IonRow className="visitor-modal">
          <IonCol className="mnxoheader-content-container modal">
            <IonRow>
              <IonCol>
                <h2>{text}</h2>
              </IonCol>
              {handleClose && (
                <IonIcon
                  onClick={handleClose}
                  className="close-modal-icon"
                  color="lobos-primary"
                  icon={close}
                ></IonIcon>
              )}
            </IonRow>

            {chipText && (
              <div className="sticker">
                <MNXOSticker text={chipText}></MNXOSticker>
              </div>
            )}
          </IonCol>
        </IonRow>
      );

    default:
      return (
        <IonRow className={`validation-container`}>
          <IonCol
            className={`${
              page.toLowerCase() === pageOptions.HOME
                ? "mnxoheader-content-container home"
                : "mnxoheader-content-container"
            }`}
          >
            <h2>{title}</h2>
            <p>{text}</p>
            {chipText && (
              <div className="sticker">
                <MNXOSticker text={chipText}></MNXOSticker>
              </div>
            )}
          </IonCol>
        </IonRow>
      );
  }
};

export default MNXOTitle;

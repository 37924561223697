import {
  IonButton,
  IonButtons,
  IonContent, IonIcon,
  IonInput,
  IonModal, IonTitle,
  IonToggle,
  IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import {
  VisitorType,
  VisitorTypeTranslation,
} from "../../../models/VisitorType";
import { defaultLanguage } from "../../../models/VisitingShift";
import { useTranslation } from "react-i18next";
import { closeOutline } from "ionicons/icons";
import MNXOHeader from "../../../components/MNXOHeader";
interface Props {
  isOpen: boolean;
  action: string;
  visitorTypesData: VisitorType;
  createHistory: (method: string) => void;
  updateVisitorTypes: (inputData: any) => void;
  toggleIsActive: () => void;
  toggleAltersCapacity: () => void;
  addNewTranslation: (newLanguage?: string) => void;
  delTranslations: (translationToDelete: VisitorTypeTranslation) => void;
  updateTranslations: (inputData: any, index: number) => void;
  handleClose: any;
  translations?: any;
}

// ATHOR: JOEL
const MNXOVisitorTypesForm: React.FC<Props> = ({
  isOpen,
  visitorTypesData,
  createHistory,
  updateVisitorTypes,
  toggleIsActive,
  toggleAltersCapacity,
  handleClose,
  action,
  addNewTranslation,
  delTranslations,
  updateTranslations,
}) => {
  const { alias, minAge, maxAge, isActive, altersCapacity, translations } =
    visitorTypesData;

  const [newTranslationName, setNewTranslationName] = useState<string>("");
  const [t, i18n] = useTranslation("global");
  return (
    <IonModal isOpen={isOpen} onDidDismiss={handleClose}>
       <MNXOHeader
        text={t("pages.edit_visitor_type.modal.title")}
        type="modal"
        title={""}
        handleClose={handleClose}
      />
            
         
            <IonContent className="ion-padding background-body-color">
        <form>
          <IonInput
            type="text"
            name="alias"
            label={t("pages.edit_visitor_type.alias")}
            labelPlacement="stacked"
            fill="outline"
            value={alias}
            onIonInput={(e) => updateVisitorTypes(e.target)}
            required
            className="custom"
          />
          <IonInput
            type="number"
            name="minAge"
            label={t("pages.edit_visitor_type.minAge")}
            labelPlacement="stacked"
            fill="outline"
            value={+minAge}
            onIonInput={(e) => updateVisitorTypes(e.target)}
            required
            className="custom"
          />
          <IonInput
            type="number"
            label={t("pages.edit_visitor_type.maxAge")}
            name="maxAge"
            labelPlacement="stacked"
            fill="outline"
            value={+maxAge}
            onIonInput={(e) => updateVisitorTypes(e.target)}
            required
            className="custom"
          />

          <IonToggle className={isActive ? 'active-toggle' : ''} checked={isActive} onClick={toggleIsActive}>
          <p>
            {t("pages.edit_visitor_type.toggle_active")}</p>
          </IonToggle>

          <IonToggle className={altersCapacity ? 'active-toggle' : ''} checked={altersCapacity} onClick={toggleAltersCapacity}>
          <p>{t("pages.edit_visitor_type.toggle_alters")} </p>
          </IonToggle>

          {translations?.map(
            (translation: VisitorTypeTranslation, index: number) => {
              const { language, name, description, subtitle } = translation;
              return (
                <div key={index}>
                  <h3>{language || "NEW"} {t("pages.edit_visiting_shift.translation_form.title")}</h3>
                  <IonInput
                    type="text"
                    name="language"
                    readonly={defaultLanguage.hasOwnProperty(language)}
                    label={t("pages.edit_visiting_shift.form_inputs.language")}
                    labelPlacement="stacked"
                    className="custom"
                    fill="outline"
                    value={language}
                    onInput={(e) => updateTranslations(e.target, index)}
                    required
                  />
                  <IonInput
                    type="text"
                    name="name"
                    label={t("pages.edit_visiting_shift.form_inputs.name")}
                    labelPlacement="stacked"
                    className="custom"
                    fill="outline"
                    value={name}
                    onInput={(e) => updateTranslations(e.target, index)}
                    required
                  />
                  <IonInput
                    type="text"
                    label={t("pages.edit_visiting_shift.form_inputs.description")}
                    name="description"
                    labelPlacement="stacked"
                    className="custom"
                    fill="outline"
                    value={description}
                    onInput={(e) => updateTranslations(e.target, index)}
                    required
                  />
                  <IonInput
                    type="text"
                    label={t("pages.edit_visitor_type.subtitle")}
                    name="subtitle"
                    labelPlacement="stacked"
                    className="custom"
                    fill="outline"
                    value={subtitle}
                    onInput={(e) => updateTranslations(e.target, index)}
                    required
                  />

                  {!defaultLanguage.hasOwnProperty(language) && (
                    <IonButton onClick={() => delTranslations(translation)}>
                      {t("buttons.remove")}
                    </IonButton>
                  )}
                </div>
              );
            }
          )}

          <IonButton
            onClick={() => {
              addNewTranslation(newTranslationName);
              setNewTranslationName("");
            }}
          >
            {t("button.add_translation")}
          </IonButton>

          <IonButton
            onClick={() => {
              createHistory(action);
              handleClose();
            }}
          >
           {t("button.save")}
          </IonButton>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default MNXOVisitorTypesForm;

import { createContext, useContext, useEffect, useState } from "react";
import { Storage } from "@ionic/storage";
import axios from "axios";
import { Redirect } from "react-router";
import LogoutButton from "../components/LogoutButton";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import { documentOutline, personOutline, logOutOutline } from "ionicons/icons";
import DashboardMenu from "../components/DashboardMenu";
import ProfileElement from "../components/ProfileElement";
import { decodedToken } from "../utils/userToken";
import { useStorage } from "../hooks/useStorage";
import { useFetchData } from "../hooks/useFetchData";

interface AuthProps {
  getToken?: any;
  token?: any | null;
  maintenanceMode?: any;
  onRegister?: (data: any) => Promise<any>;
  onLogin?: (data: any) => Promise<any>;
  onLogout?: () => Promise<any>;
  isLoading?: boolean;
}

const userRol = {
  SUPERADMIN: "superadmin",
  ADMIN: "admin",
  AGENT: "agent",
};

const AuthContext = createContext<AuthProps>({});
const login = "https://65.109.155.230/naturalspace/login";
// const login = "http://localhost:3002/naturalspace/login";
const isPrivateRoutes = process.env.REACT_APP_KEYCLOAK_PRIVATES_ROUTES;

const API_URL = "https://test.api.fivolution.com/natural-space/login";
const API_URL_LOGOUT = "https://test.api.fivolution.com/natural-space/logout";

const JWT_KEY = "user-token";

export const AuthProvider = ({ children }: any) => {
  const [token, setToken] = useState(null);
  const [store, setStore] = useState<Storage>();
  const [isLoading, setLoading] = useState(true);
  const navigation = useIonRouter();
  const { removeStorage } = useStorage("logged-user");
  const { data: maintenanceMode, fetchData } = useFetchData();

  useEffect(() => {
    const loadToken = async () => {
      const newStore = new Storage();
      const store = await newStore.create();
      setStore(store);

      const storedToken = (await store!.get(JWT_KEY)) || null;
      setToken(storedToken);

      if (storedToken) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${storedToken.access_token}`;
      } else {
        axios.defaults.headers.common["Authorization"] = "";
      }
      setLoading(false);
    };
    loadToken();
  }, []);

  useEffect(() => {
    const API_URL_BASE = process.env.REACT_APP_BACKEND_URL_BASE;
    const endpoint = `${API_URL_BASE}maintenance-mode`;

    const getMaintenanceMode = async () => {
      await fetchData(endpoint);
    };

    getMaintenanceMode();
  }, []);

  const handleLogin = async (data: any) => {
    // try {
    //   const result = await axios.post(`${login}`, data);
    //   setToken(result.data.message);
    //   axios.defaults.headers.common[
    //     "Authorization"
    //   ] = `Bearer ${result.data.message.access_token}`;
    //   await store?.set(JWT_KEY, result.data.message);
    //   // console.log(result);
    //   return result;
    // } catch (e) {
    //   // console.log(e);
    //   return { error: true, msg: (e as any).response.data.msg };
    // }
  };

  const handleRegister = async (data: any) => {
    try {
      const result = await axios.post(`${API_URL}/users`, data);
      return result;
    } catch (e) {
      // console.log(e);
      return { error: true, msg: (e as any).response.data.msg };
    }
  };

  const handleLogout = async () => {
    try {
      // await axios.post(`${API_URL_LOGOUT}`, token);
      // await store?.clear();
      await removeStorage();
      window.location.reload();

      navigation.push("/login", "none", "replace");
    } catch (e) {
      // console.log(e);

      return e;
    }
  };
  // try {
  //   const result = await axios
  //     .post(`${API_URL_LOGOUT}`, storedToken)
  //     .then(() => {
  //       setToken(null);
  //       store?.remove(JWT_KEY);
  //       axios.defaults.headers.common["Authorization"] = "";
  //     });

  //   return result;
  // } catch (e) {
  //   // console.log(e);
  //   return { error: true, msg: (e as any).response.data.msg };
  // }

  const value = {
    token,
    maintenanceMode: maintenanceMode,
    onRegister: handleRegister,
    onLogin: handleLogin,
    onLogout: handleLogout,
    isLoading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const LoggedButton = () => {
  const { token, onLogout } = useAuth();

  if (!token) {
    return (
      <div>
        <IonGrid>
          <IonRow className="ion-justify-content-end">
            <IonCol size="10" className="log-menu-div">
              <IonRow className="ion-justify-content-between "></IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  } else {
    return (
      <div className="log-menu-div">
        <IonGrid>
          <IonRow>
            <IonCol className="flex-center ion-align-items-center">
              <IonLabel className="text-white" style={{ fontSize: "16px" }}>
                Logout
              </IonLabel>
            </IonCol>
            <IonCol className="flex-end">
              <IonItem
                lines="none"
                className="border-radius text-blue secondary-button"
                onClick={() => onLogout!()}
              >
                <IonIcon className="text-white" icon={logOutOutline} />
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  }
};

//esta funcion se usa para redireccionar si no estas logueado

export const PrivateRoute = ({ children }: any) => {
  const STORAGE_KEY = "logged-user";
  const { data: validUser } = useStorage(STORAGE_KEY);
  const { maintenanceMode } = useAuth();

  const urlToRedirect = maintenanceMode ? "/" : "/login";

  // const { token, isLoading } = useAuth();

  // if (isPrivateRoutes === "false") {
  //   return children;
  // }

  // if (isLoading || token === undefined) {
  //   return;
  // }

  if (validUser) {
    return isPrivateRoutes === "false" || !Array.isArray(validUser) ? (
      children
    ) : (
      <Redirect to={urlToRedirect} />
    );
  }
};

//esta funcion se usa para no mostrar componente si no estas logueado

export const PrivateComponentLogout = () => {
  const { token } = useAuth();
  const STORAGE_KEY = "logged-user";
  const { data: validUser } = useStorage(STORAGE_KEY);
  if (validUser && !Array.isArray(validUser)) {
    return (
      <>
        {/* <IonButton routerLink="/solicitud" routerDirection="back" slot="end">
          <IonIcon icon={documentOutline} slot="icon-only" />
        </IonButton> */}
        <LogoutButton />
      </>
    );
  }
  // else {
  //   return (
  //     <IonButton routerLink="/login" routerDirection="back" slot="end">
  //       <IonIcon icon={personOutline} slot="icon-only" />
  //     </IonButton>
  //   );}

  return null;
};

export const PrivateDashboardMenu = () => {
  const STORAGE_KEY = "logged-user";
  const { data: validUser } = useStorage(STORAGE_KEY);
  if (validUser && !Array.isArray(validUser)) return <DashboardMenu />;

  // const { token } = useAuth();

  // if (token) {
  //   return <DashboardMenu />;
  // }

  return null;
};

export const PrivateProfileElement = () => {
  const { token } = useAuth();
  //       nav__items custom-list
  if (token) {
    return (
      <IonItem
        id="profile"
        className="admin-item-menu menu-background2 
  
        "
        lines="none"
      >
        <ProfileElement
          imageSrc="../assets/user1.png"
          email="admin@gmail.com"
          role="Admin"
        />
      </IonItem>
    );
  }

  return null;
};

//funcion para que solo los usuarios con rol de agente puedan acceder a sus páginas

export const PrivateAgentComponent = ({ children }: any) => {
  const STORAGE_KEY = "logged-user";
  const { data: validUser } = useStorage(STORAGE_KEY);

  if (validUser?.rol === userRol.AGENT) return children;

  // const { token } = useAuth();
  // if (!token) {
  //   return null;
  // }

  // let tokenDecoded: any = decodedToken(token.access_token);

  // // console.log(tokenDecoded);
  // let rol = tokenDecoded.resource_access.LobosPass.roles.find(
  //   (rol: any) => rol === "agent"
  // );

  // if (rol === undefined) {
  //   //  return <Redirect to="/home" />;
  // } else if (rol === "agent") {
  //   return children;
  // }

  // return null;
};

//TODO: Redirigir al dashobard automáticamente
export const PrivateAdminComponent = ({ children }: any) => {
  const { token } = useAuth();

  if (!token) {
    return null;
  }

  let tokenDecoded: any = decodedToken(token.access_token);

  // console.log(tokenDecoded);
  let rol = tokenDecoded.resource_access.LobosPass.roles.find(
    (rol: any) => rol === "admin"
  );

  if (!rol) {
    // return <Redirect to="/home" />;
  } else if (rol === "admin") {
    return children;
  }

  return null;
};

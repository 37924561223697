import React from "react";
import { IonCol, IonRow } from "@ionic/react";

interface PaginationProps {
  currentElement: number;
  totalElement: number;
  onNextView?: () => void;
  onPreviusView?: () => void;
  conditions?: any;
  confirm?: any;
  setIsValidEmail?: any;
  setIsRulesAccepted?: any;
  setIsPolicyAccepted?: any;
}

const MNXOPaginationBullets: React.FC<PaginationProps> = ({
  currentElement: currentElement,
  totalElement: totalPagesElements,
  onNextView: onNextView,
  onPreviusView: onPreviusView,
  conditions: conditions,
  confirm: confirm,
  setIsValidEmail: setIsValidEmail,
  setIsRulesAccepted: setIsRulesAccepted,
  setIsPolicyAccepted: setIsPolicyAccepted,
}) => {
  // console.log("currentView", currentElement);

  const showBulletPoints = () => {
    switch (currentElement) {
      case 0:
        return (
          <IonRow>
            {Array.from({ length: totalPagesElements }).map((_, index) => (
              <IonCol
                key={index}
                className={`element-bullet ${
                  currentElement === index ? "active" : ""
                } ${
                  !conditions.isSelectedVisitingShift && index != currentElement
                    ? "element-bullet-disabled"
                    : ""
                } 
                ${
                  index > currentElement + 1 ? "element-bullet-disabled " : ""
                } ${index < currentElement ? "element-bullet-disabled " : ""}`}
                onClick={() => {
                  if (index > currentElement) {
                    onNextView!();
                  } else if (index < currentElement) {
                    onPreviusView!();
                  }
                }}
              ></IonCol>
            ))}
          </IonRow>
        );
      case 1:
        return (
          <IonRow>
            {Array.from({ length: totalPagesElements }).map((_, index) => (
              <IonCol
                key={index}
                className={`element-bullet ${
                  currentElement === index ? "active" : ""
                } ${
                  !conditions.isValidVisitorForms && index > currentElement
                    ? "element-bullet-disabled disabled"
                    : ""
                } ${
                  index > currentElement + 1
                    ? "element-bullet-disabled disabled"
                    : ""
                } `}
                onClick={() => {
                  if (index > currentElement) {
                    onNextView!();
                  } else if (index < currentElement) {
                    onPreviusView!();
                  }
                }}
              ></IonCol>
            ))}
          </IonRow>
        );

      case 2:
        return (
          <IonRow>
            {Array.from({ length: totalPagesElements }).map((_, index) => (
              <IonCol
                key={index}
                className={`element-bullet ${
                  currentElement === index ? "active" : ""
                }${
                  currentElement >= index
                    ? ""
                    : !conditions.isValidEmail ||
                      !conditions.isRulesAccepted ||
                      (!conditions.isPolicyAccepted &&
                        index === totalPagesElements - 1)
                    ? "element-bullet-disabled disabled"
                    : ""
                }`}
                onClick={async () => {
                  if (index > currentElement) {
                    await confirm!();
                  } else if (index < currentElement) {
                    setIsValidEmail(false);
                    setIsRulesAccepted(false);
                    setIsPolicyAccepted(false);
                    onPreviusView!();
                  }
                }}
              ></IonCol>
            ))}
          </IonRow>
        );
    }
  };
  // return showBulletPoints();
  return (
    <>
      {!conditions ? (
        <IonRow>
          {Array.from({ length: totalPagesElements }).map((_, index) => (
            <IonCol
              key={index}
              className={`element-bullet ${
                currentElement === index ? "active" : ""
              }`}
              onClick={() => {
                if (index > currentElement) {
                  onNextView!();
                } else if (index < currentElement) {
                  onPreviusView!();
                }
              }}
            ></IonCol>
          ))}
        </IonRow>
      ) : (
        showBulletPoints()
      )}
    </>
  );
};

export default MNXOPaginationBullets;

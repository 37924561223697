import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import Header from "../components/Header";

const Visitors: React.FC = () => {
  return (
    <IonPage>
      <Header name="Visitors" isWhiteBackground={false}/>

      <IonContent className="ion-padding">UI goes here...</IonContent>
    </IonPage>
  );
};

export default Visitors;

import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import Header from "../../../components/Header";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import DOMPurify from "dompurify";
import MNXOFooter from "../../../components/MNXOFooter";
import { useLoading } from "../../../hooks/useLoading";
import MNXOContent from "../../../components/MNXOContent";
import { useTranslation } from "react-i18next";

const Informationnaturalpark: React.FC = () => {
  const { infoTranslation: about } = useAppTranslations("description");

  // Loading
  const { presentLoading, dismissLoading } = useLoading();
  const isLoading = !about;

  useEffect(() => {
    isLoading ? presentLoading() : dismissLoading();
  }, [isLoading]);

  // about && // console.log(about);
  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });
  const [t, i18n] = useTranslation("global");
  return (
    <IonPage>
      <Header />

      <MNXOContent hasRefresher containerStyle="information-container">
        {/* <IonCardTitle> {about ? about.subtitle : "About"} </IonCardTitle> */}
        <div className="information-content ion-padding">
          <h2>
            {about && about.title ? about.title : t("about-page.header.title")}
          </h2>
          <div
            dangerouslySetInnerHTML={
              about && { __html: DOMPurify.sanitize(about.content) }
            }
          ></div>
        </div>
      </MNXOContent>
      <MNXOFooter className="MNXOModalFooter" />
    </IonPage>
  );
};

export default Informationnaturalpark;

import { AxiosHeaderValue, AxiosHeaders, AxiosRequestConfig } from "axios";

const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
const clientSecret = process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET;

export const methodOption = {
  READ: "GET",
  CREATE: "POST",
  UPDATE: "PUT",
  DELETE: "DELETE",
};

export class AxiosConfig implements AxiosRequestConfig {
  method: string;
  maxBodyLength?: number | undefined;
  url: string;
  headers: any;
  data?: any;

  constructor(
    url: string,
    method: string,
    naturalSpaceUUIID: string,
    data?: any
  ) {
    this.method = method;
    this.maxBodyLength = Infinity;
    this.url = url;
    this.headers = {
      client_id: clientId,
      client_secret: clientSecret,
      naturalspaceuuid: naturalSpaceUUIID,
    };
    this.data = data;
  }
}

import { useState } from "react";
export const useCamera = () => {
  const [isFrontCamera, setIsFrontCamera] = useState<boolean>(false);
  const stopCamera = async () => {
    const video: any = document.querySelector("video");

    // A video's MediaStream object is available through its srcObject attribute
    const mediaStream = video.srcObject;

    // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
    const tracks = await mediaStream.getTracks();

    // Tracks are returned as an array, so if you know you only have one, you can stop it with:
    tracks[0].stop();
    // Or stop all like so:
    // tracks.forEach((track) => track.stop());
  };

  const startCamera = async () => {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: true,
    });
    const video: any = document.querySelector("video");

    video.srcObject = mediaStream;
    setIsFrontCamera(true);
  };

  return {
    isFrontCamera,
    setIsFrontCamera,
    startCamera,
    stopCamera,
  };
};

import {
  IonInput,
  IonSelect,
  IonSelectOption,
  IonNote,
  IonCol,
  IonGrid,
  IonRow,
  IonToggle,
  IonContent,
  IonPopover,
  IonSearchbar,
  IonLabel,
  IonItem,
  IonModal,
  IonText,
} from "@ionic/react";
import { Applicant } from "../entities/Applicant";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import MNXOCountrySelector from "../../../components/MNXOCountrySelector";

// import { useStorage } from "../hooks/useStorage";
interface ContainerProps {
  modalDismiss: any;
  setFormData: any;
  visitorData: Applicant;
  order: number;
  visitorType: any;
  visitorTypeName: any;
  setIsValidForm: any;
  setSameCountryForAll?: any;
  visitors?: any;
  sameCountry?: any;
}

//TODO limitar edades por tipo visitante

const VisitorForm: React.FC<ContainerProps> = ({
  setFormData,
  order,
  modalDismiss,
  visitorData,
  visitorType,
  visitorTypeName,
  setIsValidForm,
  setSameCountryForAll,
  visitors,
  sameCountry,
}) => {
  const { currentLanguage } = useAppTranslations();
  let countries = require("i18n-iso-countries");
  countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
  countries.registerLocale(require("i18n-iso-countries/langs/es.json"));
  const [searchTerm, setSearchTerm] = useState<string>("");
  // console.log(searchTerm);
  const countriesToSelect = Object.entries(
    countries.getNames(currentLanguage.toLowerCase(), { select: "official" })
  );
  const { minAge, maxAge } = visitorType[0];
  const { name, age, country, email, isPrincipal, legalID } = visitorData;
  const { legalIdType, legalIdNum } = legalID;
  const { legalIdTypeOptions } = useAppTranslations();

  const [isTouched, setIsTouched] = useState<any>({
    age: false,
    email: false,
    country: false,
    legalIdType: false,
  });

  const [error, setError] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<any>({
    age: undefined,
    email: undefined,
    country: undefined,
    legalIdType: undefined,
  });

  const [t, i18n] = useTranslation("global");
  const [countryPoverOpen, setCountryPoverOpen] = useState(false);
  const [dniPopoverOpen, setDniPopoverOpen] = useState(false);

  const countryPopover = useRef<HTMLIonPopoverElement>(null);
  const dniPopover = useRef<HTMLIonPopoverElement>(null);

  const modal = useRef<HTMLIonModalElement>(null);

  const handleSubmit = async (formData: any) => {
    formData.preventDefault();
    // console.log("FORMDATA", formValues);
    // console.log("FORMDATA", visitors);
    // const { name, age, country, legalIdType, legalIdNum, email } = formValues;

    setIsTouched((prevState: any) => ({ ...prevState, legalIdType: true }));
    setIsTouched((prevState: any) => ({ ...prevState, country: true }));

    if (country === "" || legalIdType === "") {
    } else {
      setIsValidForm(true);

      // await setFormData(order, newData);
      modalDismiss();
    }
  };

  const showCurrentCountryAlias = () => {
    // console.log("countriesToSelect", countriesToSelect);
    const currentAlias: any =
      countriesToSelect.find((i18country: any) => i18country[0] === country) ||
      "";
    return currentAlias[1];
  };

  const validateEmail = (email: string) => {
    return email.match(
      /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    );
  };

  const validate = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;
    // console.log(value);
    setIsValid((prevState: any) => ({ ...prevState, email: undefined }));

    if (value === "") return;

    validateEmail(value) !== null
      ? setIsValid((prevState: any) => ({ ...prevState, email: true }))
      : setIsValid((prevState: any) => ({ ...prevState, email: false }));
  };

  const checkVisitorTypeAge = (value: number) => {
    const age = value;
    setIsValid((prevState: any) => ({ ...prevState, age: true }));
    age >= minAge && age <= maxAge
      ? setIsValid((prevState: any) => ({ ...prevState, age: true }))
      : setIsValid((prevState: any) => ({ ...prevState, age: false }));
  };

  const checkCountry = (value: any) => {
    const country = value;
    // console.log(country);
    setIsValid((prevState: any) => ({ ...prevState, country: true }));
    country !== ""
      ? setIsValid((prevState: any) => ({ ...prevState, country: true }))
      : setIsValid((prevState: any) => ({ ...prevState, country: false }));
  };

  const checkLegalIdType = (value: any) => {
    const legalIdType = value;
    // console.log(legalIdType);
    setIsValid((prevState: any) => ({ ...prevState, legalIdType: true }));
    legalIdType !== ""
      ? setIsValid((prevState: any) => ({ ...prevState, legalIdType: true }))
      : setIsValid((prevState: any) => ({ ...prevState, legalIdType: false }));
  };

  const markTouched = (field: string) => {
    setIsTouched((prevState: any) => ({ ...prevState, [field]: true }));
  };

  const handleFormData = async (e: any, index: number) => {
    const { name, value } = e.target;
    // console.log(name, value);

    const visitorData = [...visitors];

    if (name === "legalIdType" || name === "legalIdNum") {
      visitorData[index] = {
        ...visitorData[index],
        legalID: {
          ...legalID,
          [name]: value,
        },
      };
    } else {
      visitorData[index] = {
        ...visitorData[index],
        [name]: value,
      };
    }

    setFormData((prevData: any) => ({
      ...prevData,
      email: visitorData[0].email,
      visitors: visitorData,
    }));

    // console.log("VISITORS", visitors);
  };

  useEffect(() => {
    if (legalIdType !== "") {
      setIsValid((prevState: any) => ({ ...prevState, legalIdType: true }));
    }
    if (country !== "") {
      setIsValid((prevState: any) => ({ ...prevState, country: true }));
    }
  }, [setIsTouched]);

  const openPopover = (e: any) => {
    if (e.target.itemID === "countryPopover") {
      countryPopover.current!.event = e;
      setCountryPoverOpen(true);
    } else if (e.target.itemID === "dniPopover") {
      dniPopover.current!.event = e;
      setDniPopoverOpen(true);
    }
  };

  const closeModal = () => {
    modal.current?.dismiss();
  };

  const handleCountry = async (country: any) => {
    const visitorData = [...visitors];

    visitorData[order] = {
      ...visitorData[order],
      country: country,
    };

    setFormData((prevData: any) => ({
      ...prevData,
      visitors: visitorData,
    }));
    closeModal();
  };

  return (
    <>
      <IonModal
        id={`${order}`}
        trigger={"country-selector" + order}
        ref={modal}
      >
        <MNXOCountrySelector
          title={t("label.country")}
          countries={countriesToSelect}
          selectedCountry={country}
          onSelectionCancel={() => modal.current?.dismiss()}
          handleCountry={handleCountry}
          closeModal={closeModal}
        />
      </IonModal>
      <form
        id={`visitor-form-${order}`}
        className="ion-padding visitor-form"
        onSubmit={(e) => handleSubmit(e)}
      >
        <IonInput
          type="text"
          name="name"
          className="custom"
          label={t("label.name")}
          labelPlacement="stacked"
          required
          fill="outline"
          value={name}
          onIonInput={(e) => handleFormData(e, order)}
        />

        <IonInput
          label={t("label.age")}
          labelPlacement="stacked"
          name="age"
          min={minAge}
          max={maxAge}
          className={`custom ${
            isValid.age === false ? "ion-invalid" : "ion-valid"
          } ${isTouched.age && "ion-touched"}`}
          fill="outline"
          type="number"
          // helperText="required"
          required
          errorText={`Enter a valid age ${minAge} - ${maxAge}`}
          onIonInput={(e) => {
            checkVisitorTypeAge(+e.detail.value!);
            handleFormData(e, order);
          }}
          onIonBlur={() => markTouched("age")}
          value={age === 0 ? "" : age}
        />
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol size="12">
              <IonItem
                lines="none"
                className="country-selector"
                disabled={!visitorData.isPrincipal && sameCountry != ""}
                button={true}
                detail={false}
                id={"country-selector" + order}
              >
                <IonLabel>{t("label.country")}</IonLabel>
                <div slot="end">{showCurrentCountryAlias()}</div>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-between ion-align-items-end">
            <IonCol size="12">
              {setSameCountryForAll !== null &&
                visitorData &&
                country !== "" &&
                visitorData.isPrincipal && (
                  <>
                    <IonRow className="ion-align-items-end">
                      <IonToggle
                        itemID="countryPopover"
                        labelPlacement="end"
                        justify="start"
                        className={
                          sameCountry !== ""
                            ? "toggle-active"
                            : "toggle-inactive"
                        }
                        style={{ paddingTop: "16px" }}
                        // onClick={openPopover}
                        onIonChange={(e) => {
                          e.detail.checked
                            ? setSameCountryForAll(country)
                            : setSameCountryForAll("");
                        }}
                        checked={sameCountry != "" ? true : false}
                      >
                        <p className="toggle-text">{t("text.same_country")}</p>
                        {/* Todos los visitantes son del mismo país */}
                      </IonToggle>
                    </IonRow>
                    {/* </IonItem> */}
                  </>
                )}
              <IonPopover
                ref={countryPopover}
                isOpen={countryPoverOpen}
                onDidDismiss={() => setCountryPoverOpen(false)}
                showBackdrop={false}
                side="bottom"
                alignment="center"
              >
                {countryPopover.current?.event &&
                countryPopover?.current!.event.target.checked ? (
                  <IonContent class="ion-padding">
                    {t("text.same_country")}
                  </IonContent>
                ) : (
                  <IonContent class="ion-padding">
                    {t("text.diff_country")}
                  </IonContent>
                )}
              </IonPopover>
            </IonCol>
          </IonRow>
          {!isValid.country && isTouched.country && (
            <IonRow>
              <IonCol>
                <IonNote className="error text">{t("text.required")}</IonNote>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol
              size={
                visitors &&
                visitors[0].legalID.legalIdType === "DNI" &&
                visitors[0].legalID.legalIdNum !== "" &&
                legalIdType === "DNI" &&
                (visitorTypeName === "Bebé" || visitorTypeName === "Niño")
                  ? "10"
                  : "12"
              }
            >
              <IonSelect
                label={t("label.type_id")}
                name="legalIdType"
                className={`custom ${
                  !isValid.legalIdType ? "ion-invalid" : "ion-valid"
                } ${isTouched.legalIdType && "ion-touched"}`}
                labelPlacement="stacked"
                interface="popover"
                fill="outline"
                placeholder={t("text.select_type")}
                value={legalIdType}
                onIonChange={(e) => {
                  checkLegalIdType(e.target.value);
                  handleFormData(e, order);
                }}
                onIonBlur={() => markTouched("legalIdType")}

                // onIonChange={(e) => handleChange(e, "legalIdTypeField")}
              >
                {legalIdTypeOptions.map((typeId: any, index: any) => (
                  <IonSelectOption key={index} value={typeId.value}>
                    {typeId.text}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-end">
            <IonCol
              className="ion-justify-content-center"
              size="1"
              style={{ paddingLeft: "12px" }}
            >
              {visitors &&
                visitors[0].legalID.legalIdType === "DNI" &&
                visitors[0].legalID.legalIdNum !== "" &&
                legalIdType == "DNI" &&
                (visitorTypeName === "Bebé" || visitorTypeName === "Niño") && (
                  <>
                    <IonToggle
                      itemID="dniPopover"
                      onClick={openPopover}
                      // onIonChange={(e) =>
                      //   e.detail.checked
                      //     ? setFormData((prevData: any) => ({
                      //         ...prevData,
                      //         legalIdNum: visitors[0].legalID.legalIdNum,
                      //       }))
                      //     : setFormData((prevData: any) => ({
                      //         ...prevData,
                      //         legalIdNum: "",
                      //       }))
                      // }
                      style={{ marginRight: "8px" }}
                      checked={
                        legalIdNum == visitors[0].legalID.legalIdNum
                          ? true
                          : false
                      }
                    >
                      {/* Todos los visitantes son del mismo país */}
                    </IonToggle>
                    <IonPopover
                      ref={dniPopover}
                      isOpen={dniPopoverOpen}
                      onDidDismiss={() => setDniPopoverOpen(false)}
                      showBackdrop={false}
                      side="bottom"
                      alignment="center"
                    >
                      {dniPopover.current?.event &&
                      dniPopover?.current!.event.target.checked ? (
                        //TODO: Checkear bien este operador ternario, ya que está al reves, si está checkeado, debería decir
                        //que el dni es el mismo
                        <IonContent class="ion-padding">
                          {t("text.same_document")}
                        </IonContent>
                      ) : (
                        <IonContent class="ion-padding">
                          {t("text.diff_document")}
                        </IonContent>
                      )}
                    </IonPopover>
                  </>
                )}
            </IonCol>
          </IonRow>
          {!isValid.legalIdType && isTouched.legalIdType && (
            <IonRow>
              <IonCol>
                <IonNote className="error text">{t("text.required")}</IonNote>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
        <IonInput
          type="text"
          className="custom"
          name="legalIdNum"
          label={t("label.num_id")}
          labelPlacement="stacked"
          fill="outline"
          required
          value={legalIdNum || ""}
          onIonInput={(e) => handleFormData(e, order)}
          //onIonChange={(e) => handleChange(e, "legalIdNumField")}
        />

        {isPrincipal && (
          <IonInput
            label={t("label.email")}
            labelPlacement="stacked"
            name="email"
            className={`custom ${
              isValid.email === false ? "ion-invalid" : "ion-valid"
            } ${isTouched.email && "ion-touched"}`}
            fill="outline"
            type="email"
            required
            errorText="Enter a valid email"
            onIonInput={(ev) => {
              validate(ev);
              handleFormData(ev, order);
            }}
            onIonBlur={() => markTouched("email")}
            value={email}
          />
        )}
      </form>
    </>
  );
};

export default VisitorForm;

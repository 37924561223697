import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonRow,
} from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppTranslations } from "../hooks/useAppTranslations";

interface Props {
  object: any;
  isEditable: boolean;
  index: number;
  setIsOpen?: any;
  setObject: any;
}

const Card: React.FC<Props> = ({
  object,
  isEditable,
  index,
  setIsOpen,
  setObject,
}) => {
  const [t, i18n] = useTranslation("global");
  const { currentLanguage } = useAppTranslations();
  const { alias, translations } = object;
  const translation = translations.find(
    (translation: any) => translation.language === currentLanguage
  );
  
  function handleClick() {
    setObject(object);
    setIsOpen(true);
  }
  return (
    <IonCard key={index} className="visitor-application-card background-white">
      <IonRow>
        <IonCol className={`ion-padding`}>
          <IonCardHeader>
            <IonCardTitle style={{ marginBottom: "16px" }}>
            {alias}
            </IonCardTitle>
            {translation && translation?.title !== "" && (
              <IonCardSubtitle style={{ marginBottom: "16px" }}>
                {translation?.title}
              </IonCardSubtitle>
            )}
          </IonCardHeader>
          <IonCardContent>
            {/* {translationEs?.content != "" && translationEs?.content} */}
            {isEditable && (
              <IonButton onClick={handleClick}>{t("button.edit")}</IonButton>
            )}
          </IonCardContent>
        </IonCol>
      </IonRow>
    </IonCard>
  );
};

export default Card;

import {
  IonButton,
  IonCol,
  IonFooter,
  IonGrid,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import MNXOPaginationBullets from "../../../components/MNXOPaginationBullets";
import { useTranslation } from "react-i18next";
import MNXOAlert from "../../../components/MNXOAlert";

interface ContainerProps {
  currentView: number;
  totalOfViews: number;
  onNextView: any;
  onPreviusView: any;
  setIsValidEmail?: any;
  setIsRulesAccepted?: any;
  setIsPolicyAccepted?: any;
  conditions?: any;
  modalDismiss?: any;
  type?: string;
  confirm?: any;
  currentElement?: any;
  setCurrentElement?: any;
  currentPage?: any;
  setCurrentPage?: any;
}

const MNXOApplicationFooter: React.FC<ContainerProps> = ({
  currentView,
  totalOfViews,
  onNextView,
  onPreviusView,
  setIsValidEmail,
  setIsRulesAccepted,
  setIsPolicyAccepted,
  conditions,
  confirm,
  modalDismiss,
  type,
  currentElement,
}) => {
  const [t, i18n] = useTranslation("global");
  // console.log(currentElement);
  const isSingleView = totalOfViews === 1;
  const [showAlert, setShowAlert] = useState(false);

  if (type === "modal") {
    const showButtons = () => {
      return (
        <>
          {!isSingleView && ( // Mostrar botones solo si no es una sola vista
            <>
              <IonButton
                className="white footer"
                fill="clear"
                disabled={currentView === 0 && true}
                onClick={async () => {
                  await onPreviusView();
                }}
              >
                {t("button.previous")}
              </IonButton>
              <IonButton
                className="footer"
                disabled={currentView === totalOfViews - 1 && true}
                onClick={async () => {
                  await onNextView();
                }}
              >
                {t("button.next")}
              </IonButton>
            </>
          )}
        </>
      );
    };

    return (
      <IonFooter className="MNXOModalFooter no-padding">
        <IonToolbar className="modal-toolbar">
          <IonGrid>
            <IonRow className="ion-align-items-center ion-justify-content-between modal-row">
              <IonCol className="modal-pagination" size="">
                <MNXOPaginationBullets
                  currentElement={currentView}
                  totalElement={totalOfViews}
                  onNextView={onNextView}
                  onPreviusView={onPreviusView}
                />
              </IonCol>
              <IonCol size="" className="modal-buttons-container">
                <IonRow className="ion-justify-content-end ion-align-items-center">
                  <IonButton
                    className="white footer"
                    fill="clear"
                    onClick={modalDismiss}
                  >
                    {t("button.back")}
                  </IonButton>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    );
  } else {
    const showButtons = () => {
      // console.log("conditions", conditions); // Verifica las condiciones aquí
      // console.log("showAlert", showAlert);
      switch (currentView) {
        case 0:
          return (
            <>
              {conditions.isSelectedVisitingShift && (
                <IonButton
                  className="custom-footer footer"
                  disabled={!conditions.isSelectedVisitingShift && true}
                  onClick={() => onNextView()}
                >
                  {t("button.next")}
                </IonButton>
              )}
            </>
          );

        case 1:
          return (
            <>
              <IonButton
                className="white footer"
                fill="clear"
                onClick={() => onPreviusView()}
              >
                {t("button.back")}
              </IonButton>
              <IonButton
                className="footer"
                disabled={!conditions.isValidVisitorForms && true}
                onClick={() => onNextView()}
              >
                {t("button.save")}
              </IonButton>
            </>
          );

        case 2:
          return (
            <>
              <IonButton
                className="white footer"
                fill="clear"
                onClick={() => {
                  setIsValidEmail(false);
                  setIsRulesAccepted(false);
                  setIsPolicyAccepted(false);
                  onPreviusView();
                }}
              >
                {t("button.back")}
              </IonButton>
              <IonButton
                // type="submit"
                className="footer"
                onClick={async () => {
                  if (
                    !conditions.isValidEmail ||
                    !conditions.isRulesAccepted ||
                    !conditions.isPolicyAccepted
                  ) {
                    setShowAlert(true);
                  } else {
                    setShowAlert(false);
                    await confirm();
                  }
                }}
              >
                {t("button.confirm")}
              </IonButton>
              <MNXOAlert
                header={t("alert.status_information")}
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                message={t("alert.incomplete_conditions")}
                buttons={[
                  {
                    text: t("button.confirm"),
                    role: "confirm",
                  },
                ]}
              />
            </>
          );
      }
    };

    return (
      <IonFooter className={"MNXOFooter"}>
        <IonToolbar>
          <IonGrid>
            <IonRow className="ion-align-items-center ion-justify-content-between">
              <IonCol size="">
                <MNXOPaginationBullets
                  setIsValidEmail={setIsValidEmail}
                  setIsRulesAccepted={setIsRulesAccepted}
                  setIsPolicyAccepted={setIsPolicyAccepted}
                  confirm={confirm}
                  currentElement={currentView}
                  totalElement={totalOfViews}
                  onNextView={onNextView}
                  onPreviusView={onPreviusView}
                  conditions={conditions}
                />
              </IonCol>
              <IonCol size="auto">{showButtons()}</IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    );
  }
};

export default MNXOApplicationFooter;

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import React, { useState } from "react";
import IncidentModalUUID from "../../validation/components/IncidentModalUUID";
import axios from "axios";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  agentIncidents: any[];
}

const IncidentsList: React.FC<ContainerProps> = ({ agentIncidents }) => {
  const API_URL = process.env.REACT_APP_BACKEND_URL_BASE;
  const [incidentData, setIncidentData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [t, i18n] = useTranslation("global");

  const getData = async (uuid: any) => {
    const response = await axios.get(`${API_URL}/incident/${uuid}`);
    //  const response = await axios.get(
    //   `http://localhost:3002/naturalspace/incident/${uuid}`
    // );

    return response.data;
  };
  const [isClicked, setIsClicked] = useState(false);

  const handleShareButtonClick = () => {
    setIsClicked(true);
  };

  const handleCloseClick = (e: any) => {
    e.stopPropagation();
    setIsClicked(false);
  };

  return (
    <>
      {incidentData && (
        <IncidentModalUUID
          isOpen={isOpen}
          handleClose={() => setIsOpen(false)}
          incidentData={incidentData}
          //le paso los datos del backend para recoger los nombres de los
          toConfirm={false}
          className="modal-container"
        />
      )}

      {agentIncidents?.length > 0 ? (
        agentIncidents?.map((incident: any, index: any) => {
          const { incidentCode, date, location, incidentType, incidentStatus } =
            incident;
          const { address } = location;

          return (
            <IonCard key={index} className="ion-padding incident-card">
              <IonCardHeader
                style={{ paddingLeft: "16px", marginBottom: "10px" }}
                className="title-background"
              >
                <IonCardTitle key={index}>
                  {" "}
                  {t("pages.incident_list.code")} #{incidentCode}
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <p>
                  {t("pages.create_incident.form_inputs.date")}: {date}
                </p>

                <p>
                  {t("pages.create_incident.form_inputs.address")}: {address}
                </p>

                <p>
                  {t("pages.create_incident.form_inputs.type")}:
                  {incidentType.name}
                </p>

                <p>
                  {t("pages.create_incident.state")}:{incidentStatus}
                </p>

                <IonButton
                  onClick={async () => {
                    const uuid = await getData(incident.uuid);
                    setIncidentData(uuid);
                    setIsOpen(true);
                  }}
                  slot="end"
                >
                  {t("pages.incident_list.details")}
                </IonButton>
              </IonCardContent>
            </IonCard>
          );
        })
      ) : (
        <p className="incidents-list-message">
          {t("pages.incident_list.no_incidents")}
        </p>
      )}
    </>
  );
};

export default IncidentsList;

import React from "react";
import { IonAlert } from "@ionic/react";

interface Props {
  message: string;
  subMesage: string;
  trig: string;
  email: string;
}

const AlertWithInput: React.FC<Props> = ({
  message,
  subMesage,
  trig,
  email,
}) => {
  return (
    <>
      <IonAlert
        trigger={trig}
        cssClass="custom-class"
        header="Informative message"
        subHeader={message}
        message={subMesage}
        buttons={[
          {
            text: "Send",
            role: "confirm",
          },
        ]}
        inputs={[
          {
            type: "text",
            value: email,
          },
        ]}
      ></IonAlert>
    </>
  );
};
export default AlertWithInput;

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { close } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import MNXOHeader from "../../../components/MNXOHeader";
interface ContainerProps {
  isOpen: boolean;
  handleClose: () => void;
  incidentData: any;
  loggedUser: any;
  sendIncidentData?: any;
  setShowAlert?: any;
  incidentTypes?: any;
  toConfirm: boolean;
}
const IncidentModal: React.FC<ContainerProps> = ({
  isOpen,
  handleClose,
  loggedUser,
  incidentData,
  setShowAlert,
  toConfirm,
  incidentTypes,
  sendIncidentData,
}) => {
  const [incidentType, setIncidentType] = useState();
  //TODO:usar los valores de incidentFormData, en vez de formData.
  const confirm = async () => {
    try {
      await sendIncidentData();
      handleClose();
      setShowAlert(true);
    } catch (e) {
      // console.log(e);
    }
  };
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const getIncidentType = async () => {
      const incidentType = await incidentTypes?.find(
        (incidentType: any) => incidentType.uuid === incidentData.incidentType
      ).name;
      setIncidentType(incidentType);
    };
    getIncidentType();
  }, [incidentData.incidentType]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={handleClose}>
      <MNXOHeader
        text={t("pages.confirm_incident.title")}
        type="modal"
        title={""}
        handleClose={handleClose}
      />

      <IonContent className="ion-padding background-body-color">
        <form>
          <IonLabel className="incident-title-section">
            <h2>{t("pages.create_incident.creator")}</h2>
          </IonLabel>

          <IonItem lines="none">
            <IonLabel>{loggedUser?.firstName}</IonLabel>
          </IonItem>
          {incidentData.agents.length > 0 && (
            <>
              <IonLabel className="incident-title-section">
                <h2>{t("pages.create_incident.agents_involved")}</h2>
              </IonLabel>

              {incidentData.agents.map((agent: any, index: number) => {
                if (index == 1) {
                  return (
                    <IonItem key={index} lines="none">
                      <IonLabel>{agent.firstName}</IonLabel>
                    </IonItem>
                  );
                } else {
                  return (
                    <IonItem key={index} lines="none">
                      <IonLabel>{agent.firstName}</IonLabel>
                    </IonItem>
                  );
                }
              })}
            </>
          )}
          <IonLabel className="incident-title-section">
            <h2>{t("pages.create_incident.data")}</h2>
          </IonLabel>
          <IonItem lines="none">
            <IonLabel>{t("pages.create_incident.form_inputs.type")}</IonLabel>
            <IonText>{incidentType}</IonText>
          </IonItem>
          <IonItem lines="none">
            <IonLabel> {t("pages.create_incident.form_inputs.date")}</IonLabel>
            <IonText>{incidentData?.date.toString()}</IonText>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>
              {t("pages.create_incident.form_inputs.description")}
            </IonLabel>
            <IonText>{incidentData?.description}</IonText>
          </IonItem>
          {incidentData?.observations && (
            <IonItem lines="none">
              <IonLabel>
                {t("pages.create_incident.form_inputs.observations")}
              </IonLabel>
              <IonText>{incidentData?.observations}</IonText>
            </IonItem>
          )}

          <IonItem lines="none">
            <IonLabel>
              {t("pages.create_incident.form_inputs.address")}
            </IonLabel>
            <IonText>{incidentData?.location.address}</IonText>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>
              {t("pages.create_incident.form_inputs.is_resolved")}
            </IonLabel>
            <IonText>{incidentData?.incidentStatus}</IonText>
          </IonItem>

          <IonItem lines="none">
            {toConfirm && (
              <>
                <IonButton size="default" slot="start" onClick={handleClose}>
                  {t("button.cancel")}
                </IonButton>
                <IonButton
                  size="default"
                  slot="end"
                  strong={true}
                  onClick={confirm}
                >
                  {t("button.confirm")}
                </IonButton>
              </>
            )}
          </IonItem>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default IncidentModal;

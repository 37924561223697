import React from "react";
import { IonAvatar, IonItem, IonLabel } from "@ionic/react";

interface ProfileElementProps {
  imageSrc: string;
  email: string;
  role: string;
}

const ProfileElement: React.FC<ProfileElementProps> = ({
  imageSrc,
  email,
  role,
}) => {
  return (
    <IonItem lines="none" className=" menu-background2 nav__item">
      <IonAvatar slot="start">
        <img src={imageSrc} alt="Profile Image" />
      </IonAvatar>
      <IonLabel className="ion-text-wrap" style={{ paddingLeft: "16px" }}>
        <div>
          <h3 className="text-white">{role}</h3>
        </div>
        <div>
          <p className="text-white">{email}</p>{" "}
        </div>
      </IonLabel>
    </IonItem>
  );
};

export default ProfileElement;

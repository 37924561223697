import { IonCol, IonIcon, IonRow } from "@ionic/react";
import React from "react";
import Header from "./Header";
import MNXOSticker from "./MNXOSticker";
import { close } from "ionicons/icons";

interface Props {
  navtext?: string;
  text: string;
  textChip?: string;
  title?: string;
  hide?: boolean;
  type?: string;
  isWhiteBackground?: boolean;
  handleClose?: any;
  page?: string;
  isThirdView?: boolean;
}
const MNXOHeaderWithSticker: React.FC<Props> = ({
  textChip,
  text,
  title,
  navtext,
  hide,
  type,
  handleClose,
  page,
  isThirdView,
}) => {
  if (type === "search_bar") {
    return (
      <IonRow className="validation-container">
        <Header
          name={navtext ? navtext : ""}
          hide={hide ? true : false}
          isWhiteBackground={false}
        />

        <IonCol
          className={`${
            page === "home"
              ? "mnxoheader-content-container home"
              : "mnxoheader-content-container"
          }`}
        >
          <h2>{title}</h2>
          <p>{text}</p>
          {textChip && (
            <div className="sticker">
              <MNXOSticker type={type} text={textChip}></MNXOSticker>
            </div>
          )}
        </IonCol>
      </IonRow>
    );
  } else if (type === "modal") {
    return (
      <IonRow className={`${!isThirdView ? "visitor-modal" : ""}`}>
        <IonCol className="mnxoheader-content-container modal">
          <IonRow>
            <IonCol>
              <h2>{text}</h2>
            </IonCol>
            {handleClose && (
              <IonIcon
                onClick={handleClose}
                className="close-modal-icon"
                color="lobos-primary"
                icon={close}
              ></IonIcon>
            )}
          </IonRow>

          {textChip && (
            <div className="sticker">
              <MNXOSticker text={textChip}></MNXOSticker>
            </div>
          )}
        </IonCol>
      </IonRow>
    );
  } else {
    return (
      <IonRow className={`validation-container`}>
        <Header
          name={navtext ? navtext : ""}
          hide={hide ? true : false}
          isWhiteBackground={false}
        />

        <IonCol
          className={`${
            page === "home"
              ? "mnxoheader-content-container home"
              : "mnxoheader-content-container"
          }`}
        >
          <h2>{title}</h2>
          <p>{text}</p>
          {textChip && (
            <div className="sticker">
              <MNXOSticker text={textChip}></MNXOSticker>
            </div>
          )}
        </IonCol>
      </IonRow>
    );
  }
};

export default MNXOHeaderWithSticker;

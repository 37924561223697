import {
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  playCircle,
  radio,
  library,
  search,
  constructOutline,
  readerOutline,
  qrCodeOutline,
  warningOutline,
} from "ionicons/icons";
import React from "react";
import { Redirect, Route } from "react-router";
import QrReader from "../useCases/validation/components/MNXOQrReader";

import DashboardConfiguration from "../pages/DashboardConfiguration";
import Profile from "../pages/Profile";
import Visitors from "../pages/Visitors";
import CancelAuthorization from "../useCases/application/pages/ControlAuthorisations";
import MNXOConfigurationPage from "../useCases/dashboard/pages/MNXOConfigurationPage";
import MNXOVisitingConfigurationPage from "../useCases/dashboard/pages/MNXOVisitingConfigurationPage";
import IncidentsList from "../useCases/incident/components/IncidentList";
import IncidentConfirmation from "../useCases/incident/pages/IncidentConfirmation";
import Incidents from "../useCases/incident/pages/Incidents";
import Statistics from "../useCases/information/pages/Statistics";
import ValidateManualApplications from "../useCases/validation/components/ValidateManualApplications";
import ManualValidation from "../useCases/validation/pages/ManualValidation";
import Validate from "../useCases/validation/pages/Validate";
import AdminDashboard from "../useCases/dashboard/pages/MNXOAdminDashboard";
import MNXOHistoryPage from "../useCases/dashboard/pages/MNXOHIstoryPage";
import { getTabsRoutes } from "../routes/menu/dashboard_tabs.routes";
import { useTranslation } from "react-i18next";
import MNXOIncidentPage from "../useCases/dashboard/pages/MNXOIncidentsPage";
import Incident from "../useCases/incident/pages/Incident";
import MNXOValidationPage from "../useCases/dashboard/pages/MNXOValidationPage";
import MNXOOpeningHoursEdit from "../useCases/dashboard/pages/MNXOOpeningHoursEdit";
import MNXOVisitingConfigurationEdit from "../useCases/dashboard/pages/MNXOVisitingConfigurationEdit";
import MNXOVisitingShiftEdit from "../useCases/dashboard/pages/MNXOVisitingShiftEdit";
import MNXOVisitorTypesEdit from "../useCases/dashboard/pages/MNXOVisitorTypesEdit";
import MNXOGroupApplication from "../useCases/dashboard/pages/MNXOGroupApplication";

interface Props {
  link: string;
}

const MNXOTabsFooter: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  const path = "dashboard_page.";

  // const tabsRoutes = getTabsRoutes(t);

  return (
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet placeholder={undefined}>
          <Redirect exact path="/dashboard" to="/dashboard/main" />

          <Route exact path="/dashboard/main" component={AdminDashboard} />
          <Route exact path="/dashboard/history" component={MNXOHistoryPage} />
          <Route
            exact
            path="/dashboard/configuration"
            component={MNXOConfigurationPage}
          />
          <Route
            exact
            path="/dashboard/incident"
            component={MNXOIncidentPage}
          />
          <Route
            exact
            path="/dashboard/group-application"
            component={MNXOGroupApplication}
          />
          <Route exact path="/dashboard/incident/show" component={Incidents} />
          <Route exact path="/dashboard/incident/create" component={Incident} />
          <Route
            exact
            path="/dashboard/incident/confirmation"
            component={IncidentConfirmation}
          />
          <Route
            exact
            path="/dashboard/configuration/information"
            component={DashboardConfiguration}
          />
          <Route
            exact
            path="/dashboard/configuration/visitingconfiguration"
            component={MNXOVisitingConfigurationPage}
          />
          <Route
            exact
            path="/dashboard/configuration/visitingconfiguration/edit"
            component={MNXOVisitingConfigurationEdit}
          />
          <Route
            exact
            path="/dashboard/configuration/visitingshift/edit"
            component={MNXOVisitingShiftEdit}
          />
          <Route
            exact
            path="/dashboard/configuration/openinghours/edit"
            component={MNXOOpeningHoursEdit}
          />
          <Route
            exact
            path="/dashboard/configuration/visitortypes/edit"
            component={MNXOVisitorTypesEdit}
          />
          <Route exact path="/dashboard/profile" component={Profile} />
          <Route
            exact
            path="/dashboard/control"
            component={CancelAuthorization}
          />
          <Route exact path="/dashboard/statistics" component={Statistics} />
          <Route exact path="/dashboard/visitors" component={Visitors} />
          <Route
            exact
            path="/dashboard/validation"
            component={MNXOValidationPage}
          />
          <Route exact path="/dashboard/validation/scan" component={Validate} />
          <Route
            exact
            path="/dashboard/validation/manual"
            component={ValidateManualApplications}
          />
          {/* <Route
            exact
            path="/dashboard/validate/manual/validation"
            component={ValidateManualApplications}
          /> */}
        </IonRouterOutlet>

        <IonTabBar slot="bottom">
          <IonTabButton tab="scan" href="/dashboard/validation">
            <IonIcon icon={qrCodeOutline} />
            <IonLabel>{t(path + "scan_card.title")}</IonLabel>
          </IonTabButton>
          <IonTabButton tab="main" href="/dashboard/main">
            <IonIcon icon={library} />
            <IonLabel>{t(path + "main_card.title")}</IonLabel>
          </IonTabButton>
          <IonTabButton tab="incident" href="/dashboard/incident">
            <IonIcon icon={warningOutline} />
            <IonLabel>{t(path + "incident_card.title")}</IonLabel>
          </IonTabButton>
          <IonTabButton tab="configuration" href="/dashboard/configuration">
            <IonIcon icon={constructOutline} />
            <IonLabel>{t(path + "configuration_card.title")}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  );
};

export default MNXOTabsFooter;

import {
  IonButton,
  IonCol,
  IonFooter,
  IonGrid,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  conditions?: any;
  modalDismiss?: any;
  handleSubmit?: any;
  cardNumber?: number;
}

const MNXOModalFooter: React.FC<ContainerProps> = ({
  conditions,
  modalDismiss,
  handleSubmit,
  cardNumber,
}) => {
  const [t, i18n] = useTranslation("global");

  return (
    <IonFooter className="MNXOFooter">
      <IonToolbar>
        <IonGrid>
          <IonRow className="ion-justify-content-between ion-align-items-center ion-padding">
            <IonCol size="auto">
              <IonButton
                className="white footer"
                fill="clear"
                onClick={modalDismiss}
              >
                {t("button.back")}
              </IonButton>
            </IonCol>
            <IonCol size="auto">
              {handleSubmit ? (
                <IonButton className="footer" onClick={handleSubmit}>
                  {t("button.save")}
                </IonButton>
              ) : (
                <IonButton
                  className="footer"
                  form={`visitor-form-${cardNumber}`}
                  type="submit"
                >
                  {t("button.save")}
                </IonButton>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonFooter>
  );
};

export default MNXOModalFooter;

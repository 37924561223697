import { Application } from "../entities/Application";
import { useEffect, useState } from "react";
import { useStorage } from "../../../hooks/useStorage";
import { useFetchData } from "../../../hooks/useFetchData";
import { useToast } from "../../../hooks/useToast";
import { useAlert } from "../../../hooks/useAlert";
import axios from "axios";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { Preferences } from "@capacitor/preferences";

interface Filters {
  locator: string;
  IDNumber: string;
}

export const useApplicationsList = () => {
  const STORAGE_KEY_APPLICATIONS = "stored-applications";
  const API_URL = process.env.REACT_APP_BACKEND_URL_BASE;

  // Obtener el locator
  const { hash } = useLocation();

  const { presentToast } = useToast();
  const { presentAlert } = useAlert();
  const history = useHistory();
  const [isVerified, setIsVerified] = useState<boolean>(false);

  const [t, i18n] = useTranslation("global");

  const {
    data: storedApplications,
    getStoredData,
    loadingStorage,
    saveStorage: saveApplications,
  } = useStorage(STORAGE_KEY_APPLICATIONS);

  const {
    data: searchedApplications,
    loading,
    fetchData: searchAllApplications,
  } = useFetchData();

  const {
    data: searchedApplication,
    loading: load,
    error,
    fetchData: searchApplication,
  } = useFetchData();

  const { data: x, fetchData: searchApplicationByEmailOrLocator } = useFetchData();

  const [applications, setApplications] = useState<Application[] | null>(null);
  const [applicationsOfManualValidations, setApplicationsOfManualValidations] =
    useState<Application[] | null>(null);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [onRefresh, setOnRefresh] = useState(false);
  const [applicationEmailLocator, setApplicationEmailLocator] = useState<
    string | null
  >(null);
  const [isOpenAlert, setIsOpenAlert] = useState(false);

  const pathname = window.location.pathname;

  // Buscar solicitudes generico
  useEffect(() => {
    const fetchApplications = async () => {
      // console.log("USANDO HOOK");
      if (storedApplications) {
        if (!searchedApplications) {
          // console.log("ESTA BUSCANDO DE NUEVO LAS SOLICITUDES");
          await getApplicationsByLocators();
        }
        if (searchedApplications && !searchedApplication && !onRefresh) {
          // console.log("ESTA ACTUALIZANDO DE NUEVO LAS SOLICITUDES");
          await updateStoredApplications(searchedApplications);
        }
      }
    };
    fetchApplications();
  }, [storedApplications, searchedApplications]);

  // refresh
  useEffect(() => {
    const fetchApplications = async () => {
      if (onRefresh) {
        // console.log("funcion dentro del refresh");
        await refreshApplications();
        // console.log("APLPICATIONS", applications);
      }
    };
    fetchApplications();
  }, [onRefresh]);

  // buscar 1 solicitud
  useEffect(() => {
    const fetchApplications = async () => {
      setApplications(null);
      if (searchedApplication) {
        setApplications(searchedApplication);
        await saveLastSearchs(searchedApplication);
      }
    };
    fetchApplications();
  }, [searchedApplication]);

  const refreshApplications = async () => {
    try {
      const url = API_URL + "applications";

      let filteredSearch: {
        filteredSearch: any[];
      };

      // console.log("STORED APPLICATION ON REFRESH", storedApplications);
      if (storedApplications) {
        // console.log("STORED APPLICATIONS REFRESH", storedApplications);

        if (storedApplications?.length > 0) {
          let allStoredApplications: Filters[] = [];

          await Promise.all(
            storedApplications?.map((application: any) => {
              allStoredApplications.push({
                locator: application.locator.toUpperCase(),
                IDNumber:
                  application.visitors[0].legalID.legalIdNum.toUpperCase(),
              });
            })
          );

          filteredSearch = {
            filteredSearch: allStoredApplications,
          };

          const response = await axios.post(url, filteredSearch);

          if (response.data) {
            // console.log("UPDATED APPLICATIONS", response.data);
            await saveApplications(response.data);
            // console.log("GUARDADO EN STORAGE");
            setOnRefresh(false);
            // console.log("REFRESH OFF");
            setApplications(response.data);
            // console.log("ACTUALIZADAS");
          }
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const saveLastSearchs = async (filteredSearch: any) => {
    if (filteredSearch) {
      // console.log("DATA", filteredSearch);
      const updateStoredApplications = [
        ...storedApplications,
        filteredSearch[0],
      ];
      // console.log("TODAS LAS SOLICITUDES", updateStoredApplications);
      await saveApplications(updateStoredApplications);
    }
  };

  const seconds = (ms: number) => {
    return ms * 1000;
  };

  const showCancelledApplicationAlert = (statusCode: number) => {
    const alertData = {
      header: "",
      message: "",
      buttons: [
        {
          text: "OK",
          role: "confirm",
          handler: async () => {
            setIsOpenAlert(false);
          },
        },
      ],
    };
    switch (statusCode) {
      case 200:
        alertData.header = t("application.recover_messages.success");
        alertData.message = t("application.recover_messages.cancelled");
        break;

      case 500:
        alertData.header = t("application.recover_messages.error");
        alertData.message = t("application.recover_messages.cancel_error_500");
        break;

      case 400:
        alertData.header = t("application.recover_messages.error");
        alertData.message = t("application.recover_messages.cancel_error");
        break;

      case 422:
        alertData.header = t("application.recover_messages.error");
        alertData.message = t("application.recover_messages.cancel_error_422");
        break;

      default:
        alertData.header = t("application.recover_messages.error");
        alertData.message = t("application.recover_messages.cancel_error");
        break;
    }

    presentAlert(alertData);
  };

  const showVerifiedApplicationAlert = async (path: string) => {
    try {
      if (!isVerified) {
        const applicationData = path.split("/");
        const locator = applicationData[0];
        const uuid = applicationData[1];
        const response = await axios.get(
          API_URL + `application/verify/${locator}/${uuid}`
        );
        // console.log("RESPONSE", response);
      }

      const alertData = {
        header: t("application.recover_messages.verified_header"),
        message: t("application.recover_messages.verified_message"),
        // cssClass: "cancel-toast",
        buttons: [
          {
            text: "OK",
            role: "confirm",
            handler: async () => {
              const currentApplicationEmailLocator = window.location.hash;
              history.push("/authorisations");
              await refreshApplications();
              setApplicationEmailLocator(currentApplicationEmailLocator);
              setIsOpenAlert(false);
              setIsVerified(true);
            },
          },
        ],
      };

      presentAlert(alertData);
      setIsOpenAlert(true);
    } catch (error) {
      const errorAlertData = {
        header: t("verified_application_alert.header"),
        message: t("verified_application_alert.subheader"),
        buttons: [
          {
            text: t("verified_application_alert.buttons.confirm"),
            role: "confirm",
          },
        ],
      };

      presentAlert(errorAlertData);
      history.push("authorisations");
    }
  };

  const showCancelApplicationAlert = () => {
    const alertData = {
      header: t("application.recover_messages.cancel_subheader"),
      // subHeader: t("application.recover_messages.cancel_subheader"),
      message: t("application.recover_messages.cancel_message"),
      cssClass: "custom-class",
      buttons: [
        {
          text: t("application.recover_messages.cancel_button"),
          role: "cancel",
          handler: () => {
            history.push("/home");
            // console.log("Alert canceled");
          },
        },
        {
          text: t("button.confirm"),
          role: "confirm",
          handler: () => {
            const currentApplicationEmailLocator = window.location.hash;
            setApplicationEmailLocator(currentApplicationEmailLocator);
            setIsOpenAlert(false);
            // console.log("Alert confirmed");
          },
        },
      ],
    };

    presentAlert(alertData);
    setIsOpenAlert(true);
  };

  const updateStoredApplications = async (updatedStoredApplications: any) => {
    if (updatedStoredApplications) {
      setOnRefresh(false);
      setApplications(updatedStoredApplications);
      await saveApplications(updatedStoredApplications);
    }
  };

  const cancelApplication = async (path: string, filters: Filters) => {
    try {
      const url = API_URL + "cancelapplication";

      const applicationData = path.split("/");
      // console.log("UUID", path);
      const uuid = applicationData[1];

      const data = {
        uuid: uuid,
        locator: filters.locator.toUpperCase(),
        legalIdNum: filters.IDNumber.toUpperCase(),
      };

      // console.log("CANCEL DATA", data);

      // API call
      const response: any = await axios.put(url, data);

      if (response) {
        const statusCode = response.status;
        // console.log("STATUS CODE", statusCode);
        history.push("/authorisations/cancel");
        showCancelledApplicationAlert(statusCode);
        await refreshApplications();
      }
    } catch (error: any) {
      // console.log("ERROR", error);
      showCancelledApplicationAlert(error.response.status);
    }
  };

  // TODO: optimizar para que nunca haga la primera llamada cuando se busca por locator
  const getApplicationsByLocators = async (filters?: Filters) => {
    const url = API_URL + "applications";

    let filteredSearch: {
      filteredSearch: any[];
    };

    const storedApplications = await getStoredData();

    if (storedApplications) {
      // console.log("STORED APPLICATIONS", storedApplications);
      if (storedApplications?.length > 0) {
        let allStoredApplications: Filters[] = [];

        storedApplications?.map((application: any) => {
          allStoredApplications.push({
            locator: application.locator.toUpperCase(),
            IDNumber: application.visitors[0].legalID.legalIdNum.toUpperCase(),
          });
        });

        filteredSearch = {
          filteredSearch: allStoredApplications,
        };
        // console.log("FILTERS", filteredSearch);
        if (
          (!searchedApplications && storedApplications.length > 0) ||
          onRefresh
        ) {
          await searchAllApplications(url, "post", filteredSearch!);
        } else {
          setApplications(searchedApplications);
        }
      }
    }
  };

  const searchByLocator = async (filters: Filters) => {
    const url = API_URL + "applications";
    const { locator, IDNumber } = filters;
    if (pathname === "/dashboard/validation/manual") {
      let filteredSearch = {
        filteredSearch: [
          {
            locator: locator.toUpperCase(),
            IDNumber: IDNumber.toUpperCase(),
          },
        ],
      };
      let result = await searchApplication(url, "post", filteredSearch!);
      if (result) {
        setApplicationsOfManualValidations(result.data);
      }
    } else {
      if (pathname === "/authorisations/cancel") {
        await cancelApplication(hash.slice(1), filters);
      } else {
        const checkIfExist = () => {
          // check if the current locator already exist in storedApplications with Array.some
          const exist = storedApplications.some(
            (application: any) => application.locator === locator
          );
          return exist;
        };

        if (!checkIfExist()) {
          // console.log(filters);
          let filteredSearch: {
            filteredSearch: any[];
          };

          filteredSearch = {
            filteredSearch: [
              {
                locator: locator.toUpperCase(),
                IDNumber: IDNumber.toUpperCase(),
              },
            ],
          };

          await searchApplication(url, "post", filteredSearch!);
        } else {
          // console.log("Ya existe una solicitud con este localizador");
        }
      }
    }
  };

  const searchByParam = async (param: string) => {
    let emailRegexp: RegExp = new RegExp(/^\S+@\S+\.\S+$/);
    const url = API_URL + `application/${param}`;
    if (pathname === "/dashboard/validation/manual") {
      let result = await searchApplicationByEmailOrLocator(url, "get");
      if (result) {
        if (emailRegexp.test(param)) {
          setApplicationsOfManualValidations(result.data);
        } else {
          setApplicationsOfManualValidations([result.data]);
        }
      }
    }
  };

  return {
    searchByParam,
    applicationsOfManualValidations,
    storedApplications,
    isOpenModal,
    setIsOpenModal,
    applications,
    setOnRefresh,
    searchByLocator,
    showCancelledApplicationAlert,
    showCancelApplicationAlert,
    showVerifiedApplicationAlert,
    applicationEmailLocator,
    isVerified,
    isOpenAlert,
    data: searchedApplications,
  };
};

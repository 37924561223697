import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import { AuthProvider } from "../providers/AuthProviders";
import { useAppTranslations } from "../hooks/useAppTranslations";
import { useNaturalSpace } from "../hooks/useNaturalSpace";
import { useState } from "react";
import MNXOFooter from "../components/MNXOFooter";
import MasonryGallery from "../components/MNXOMasonryGallery";
import MNXOGallerySlider from "../components/MNXOGallerySlider";
import { useTranslation } from "react-i18next";
import MNXOIconSwiper from "../components/MNXOIconSwiper";
import MNXOInterestCard from "../components/MNXOInterestCard";
import { useHistory } from "react-router";
import { getMasonryImages } from "../mockups/images";
import { useLoading } from "../hooks/useLoading";
import MNXOContent from "../components/MNXOContent";
import Header from "../components/Header";

const Home: React.FC = () => {
  //const { data, loading } = getNaturalSpace();

  const { pointsOfInterest, isLoadingPointsOfInterest } =
    useNaturalSpace("home");
  // console.log("pointsOfInterest", pointsOfInterest);

  const { presentLoading, dismissLoading } = useLoading();
  const isLoading = !pointsOfInterest;
  isLoading && presentLoading();

  const envtest = process.env.REACT_APP_TEST;
  const { currentLanguage } = useAppTranslations();
  const [loadingActive, setLoadingActive] = useState(false);
  const loadingDuration = 5000;
  const [t, i18n] = useTranslation("global");
  const [selectedCategory, setSelectedCategory] = useState("Flora");
  // const pointsOfInterest = getInterests(t);
  const history = useHistory();
  const images = getMasonryImages(t);

  const handleInterestCardClick = (interestInfo: any, images: any) => {
    history.push("/info-interest", { interestInfo, images });
  };

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
  };
  return (
    <AuthProvider>
      <IonPage>
        <Header hide />
        <MNXOContent
          page={"home"}
          title={t("home_page.header.title")}
          text={t("home_page.header.text")}
        >
          <IonGrid>
            <IonRow className="icon-swiper-container">
              <IonCol>
                <h1 className="ion-padding">
                  {t("home_page.categories.title")}
                </h1>
                <MNXOIconSwiper handleCategorySelect={handleCategorySelect} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <MNXOGallerySlider selectedCategory={selectedCategory} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="home-page-masonry-container">
                <h1 className="ion-padding">{t("home_page.gallery")}</h1>
                <MasonryGallery images={images} />
              </IonCol>
            </IonRow>
            <IonRow className="interest-container">
              <IonCol>
                <h1>{t("home_page.interest")}</h1>
              </IonCol>
            </IonRow>
            {pointsOfInterest &&
              pointsOfInterest.map((interest: any, index: number) => (
                <IonRow key={index}>
                  <IonCol>
                    <MNXOInterestCard
                      imageSrc={interest.image}
                      title={interest.title}
                      subtitle={interest.subtitle}
                      text={interest.text}
                      interestPoint={interest}
                      currentLanguage={currentLanguage}
                      onClick={() => handleInterestCardClick(interest, images)}
                    />
                  </IonCol>
                </IonRow>
              ))}
          </IonGrid>
        </MNXOContent>
        <MNXOFooter className="MNXOModalFooter" />
      </IonPage>
    </AuthProvider>
  );
};

export default Home;

import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import "../../../theme/text.scss";
import {
  PrivateDashboardMenu,
  PrivateProfileElement,
} from "../../../providers/AuthProviders";
import { getNavigationRoutes } from "../../../routes/menu/navegation.routes";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import {
  chevronBackOutline,
  readerOutline,
  receiptOutline,
} from "ionicons/icons";
import { getAuthRoutes } from "../../../routes/menu/authorisations.routes";
import { getInfoRoutes } from "../../../routes/menu/info.routes";
import MNXOContent from "../../../components/MNXOContent";

const MNXOMenu: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  const navigationRoutes = getNavigationRoutes(t);

  const infoRoutes = getInfoRoutes(t);
  const authRoutes = getAuthRoutes(t);
  const location = useLocation(); // Obtener la ubicación actual de la página
  let type = null;
  let isWhiteBackground = true;
  let hide = false;
  let name = false;
  let img = false;

  const checkbox = useRef<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isActivePage = (path: string): boolean => {
    // Verificar si la ubicación actual coincide con la ruta proporcionada
    return location.pathname === path;
  };
  const content = document.getElementById("mnxo_content");
  const handleModal = async (e: any) => {
    // console.log("target", e);
    // console.log(content);
    if (e.id === "menu-cb" && !isMenuOpen) {
      checkbox.current.checked = true;
      setIsMenuOpen(true);
    }
    if (e === content && isMenuOpen) {
      checkbox.current.checked = false;
      setIsMenuOpen(false);
    }
  };
  return (
    <IonPage>
      <MNXOContent handleMenu={handleModal}>
        <input
          type="checkbox"
          className="nav__cb"
          id="menu-cb"
          ref={checkbox}
        />

        <div
          id="menu"
          className="nav__content"
          style={{ height: "100vh", zIndex: "99" }}
        >
          <div className="first-item custom-list nav__item-text">
            <IonHeader className="header-menu">
              <IonToolbar className="menu-background">
                <IonRow className="background-base-white">
                  <IonCol className="background-base-blue" size="auto">
                    <div className="menu-logo logo">
                      <img
                        src="assets/Logo_Lobos.png"
                        width={"80px"}
                        // alt="Islote de Lobos logo"
                      />
                    </div>
                  </IonCol>

                  <IonCol className="menu-logo right-radius" />
                </IonRow>
              </IonToolbar>
            </IonHeader>
          </div>
          {PrivateProfileElement()} {PrivateDashboardMenu()}
          <IonList
            className="nav__items custom-list"
            style={{ padding: "0px" }}
          >
            {navigationRoutes.map((item, index) => (
              <IonItem
                lines="none"
                id="item-menu"
                routerLink={item.url}
                key={item.title}
                className={
                  isActivePage(item.url)
                    ? // "active menu-background"
                      " menu-background2 active nav__item"
                    : "menu-background2 nav__item"
                }
              >
                <IonIcon className="menu-icons" id="icons" icon={item.icon} />
                <IonLabel className="nav__item-text">{item.title}</IonLabel>
              </IonItem>
            ))}
          </IonList>
          <IonAccordionGroup className="custom-accordion-group">
            <IonAccordion value="first">
              <IonItem slot="header" className="menu-item-background nav__item">
                <IonIcon
                  className="ion-icon-inside-menu-item "
                  id="icons"
                  icon={receiptOutline}
                />
                <IonLabel className="nav__item-text ">
                  {t("app_data.routes.auth.title")}
                </IonLabel>
              </IonItem>
              <div slot="content">
                <IonList
                  style={{
                    paddingLeft: "24px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                  className=" nav__items custom-list "
                >
                  {authRoutes.map((item, index) => (
                    <IonMenuToggle key={index} autoHide={false}>
                      <IonItem
                        className={
                          isActivePage(item.url)
                            ? // "active menu-background"
                              "menu-background2 active nav__item"
                            : "menu-background2 nav__item"
                        }
                        lines="none"
                        id="item-menu"
                        routerLink={item.url}
                        key={item.title}
                      >
                        <IonLabel className="label-accordion-menu nav__item-text">
                          {item.title}
                        </IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  ))}
                </IonList>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
          <IonAccordionGroup className="custom-accordion-group">
            <IonAccordion value="first">
              <IonItem slot="header" className="menu-item-background">
                <IonIcon
                  className="ion-icon-inside-menu-item "
                  id="icons"
                  icon={readerOutline}
                />
                <IonLabel className="nav__item-text">Info</IonLabel>
              </IonItem>
              <div slot="content">
                <IonList
                  style={{
                    paddingLeft: "24px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                  className=" nav__items custom-list "
                >
                  {infoRoutes.map((item, index) => (
                    <IonMenuToggle key={index} autoHide={false}>
                      <IonItem
                        className={
                          isActivePage(item.url)
                            ? // "active menu-background"
                              "menu-background2 active  nav__item"
                            : "menu-background2 nav__item"
                        }
                        lines="none"
                        id="item-menu"
                        routerLink={item.url}
                        key={item.title}
                      >
                        {/* <IonIcon
                          className="menu-icons-secondary"
                          id="icons"
                          icon={item.icon}
                        /> */}

                        <IonLabel className="label-accordion-menu nav__item-text">
                          {item.title}
                        </IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  ))}
                </IonList>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
          <IonFooter
            className="ion-no-border nav__items custom-list "
            style={{ marginTop: "auto" }}
          >
            <IonToolbar className="cabildo-background-base">
              <IonRow>
                <IonCol size="12" className="cabildo-logo-container base">
                  <div className="cabildo-logo-container radius"></div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol offset="2" size="10" className="cabildo-logo-container">
                  <IonRow className="ion-justify-content-end">
                    <a href="/login" style={{ width: "90%" }}>
                      <img src="/assets/Logo_Cabildo.png" alt="" />
                    </a>
                  </IonRow>
                </IonCol>
              </IonRow>

              {/* <IonMenuToggle className="cabildo-background-base" autoHide={false}>
              {LoggedButton()}
            </IonMenuToggle> */}
            </IonToolbar>
          </IonFooter>
          {/* <div className="log-button">{LoggedButton()}</div> */}
        </div>

        <IonHeader className="ion-no-border">
          <IonGrid>
            <IonToolbar
              className={`header ${type === "transparent" && "transparent"}`}
              style={
                isWhiteBackground
                  ? { "--background": "white", "--color": "black" }
                  : undefined
              }
            >
              {" "}
              <IonRow>
                <IonCol
                  size="auto"
                  className={`header-left-buttons ${
                    type === "transparent" && "transparent"
                  }`}
                >
                  {!hide && (
                    <IonItem lines="none" routerLink="/home">
                      <IonIcon
                        className="text-blue"
                        icon={chevronBackOutline}
                      ></IonIcon>
                    </IonItem>
                  )}
                </IonCol>
                <IonCol
                  className={`header-mid-base ${
                    (type === "transparent" && "transparent") ||
                    (!name && "transparent")
                  }`}
                >
                  <IonRow
                    className={`ion-align-items-center ion-justify-content-center ${
                      !img && "header-mid-base-logo"
                    }`}
                  >
                    {name ? (
                      <>
                        <IonTitle
                          className={`ion-text-center header-mid-radius ${
                            type === "transparent" && "transparent"
                          }`}
                        >
                          {name}
                        </IonTitle>
                      </>
                    ) : (
                      <>
                        {!img && (
                          <IonCol
                            size="12"
                            className="header-mid-base-logo radius"
                          >
                            <IonRow className="ion-justify-content-center ion-align-items-center">
                              <img
                                src="assets/Logo_Lobos.png"
                                height={"30px"}
                                alt="Islote de Lobos logo"
                              />
                            </IonRow>
                          </IonCol>
                        )}
                      </>
                    )}
                  </IonRow>
                </IonCol>
                <IonCol
                  size="auto"
                  className={`header-right-buttons ${
                    type === "transparent" && "transparent"
                  }`}
                >
                  <IonRow className="ion-align-items-center buttons-menu">
                    <IonCol
                      size="auto"
                      className={`header-right-buttons-base ${
                        type === "transparent" && "transparent"
                      }`}
                    >
                      <IonButtons className="header-buttons">
                        <IonButton onClick={() => i18n.changeLanguage("es")}>
                          ES
                        </IonButton>
                        <IonButton onClick={() => i18n.changeLanguage("en")}>
                          EN
                        </IonButton>

                        <label
                          className="nav__btn"
                          htmlFor="menu-cb"
                          onClick={() => setIsMenuOpen(true)}
                        ></label>
                      </IonButtons>
                    </IonCol>
                  </IonRow>{" "}
                </IonCol>
              </IonRow>
            </IonToolbar>
          </IonGrid>
        </IonHeader>
      </MNXOContent>
    </IonPage>
  );
};

export default MNXOMenu;

import axios from "axios";
import { useFetchData } from "../../../hooks/useFetchData";
import { useEffect } from "react";
import { useStorage } from "../../../hooks/useStorage";
import { obtenerFechaActualComoYYYYMMDD } from "../../../utils/formatDate";
import { useLocation } from "react-router";
const API_URL = process.env.REACT_APP_BACKEND_URL_BASE;
// const API_URL = "http://localhost:3002/naturalspace/";

export const useValidate = () => {
  const { naturalSpaceUUID } = useStorage("naturalspace");
  const {
    data: visitingShiftsByDate,
    loading,
    error,
    fetchData,
  } = useFetchData();

  const {
    data: allApplicationsByDateAndVisitingShiftUUID,
    loading: loadingAllApplicationsByDateAndVisitingShiftUUID,
    // error,
    fetchData: fetchAllApplicationsByDateAndVisitingShiftUUID,
  } = useFetchData();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/dashboard/validation/manual") {
      const getData = async () => {
        await getVisitingShiftsByDate(obtenerFechaActualComoYYYYMMDD());
      };
      getData();
    }
  }, []);

  const getAllApplicationsByDateAndVisitingShiftUUID = async () => {
    const url = API_URL + `allapplications`;
    // const response = await axios.get(url);
    await fetchAllApplicationsByDateAndVisitingShiftUUID(url);
  };

  const getAuthorizationByUUID = (authorizationUUID: any) => {
    const url = API_URL + `getauthorization/${authorizationUUID}`;
    const response = axios.get(url);
    return response;
  };

  const updateAuthorizations = (authorizations: any) => {
    const url = API_URL + "validate/";
    const response = axios.put(url, authorizations);
    return response;
  };

  const getVisitingShiftsByDate = async (visitingDate: string) => {
    const url = API_URL + `visitingshift/${visitingDate}`;
    await fetchData(url);
  };

  return {
    allApplicationsByDateAndVisitingShiftUUID,
    loadingAllApplicationsByDateAndVisitingShiftUUID,
    getAllApplicationsByDateAndVisitingShiftUUID,
    updateAuthorizations,
    visitingShiftsByDate,
    getAuthorizationByUUID,
  };
};

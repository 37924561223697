import { useAlert } from "../../../hooks/useAlert";
import { useStorage } from "../../../hooks/useStorage";
import { History } from "../../../models/History";
import { useTranslation } from "react-i18next";

export const useHistory = () => {
  const { presentAlert } = useAlert();
  const { data: loggedUser } = useStorage("logged-user");
  const history = new History();
  const [t, i18n] = useTranslation("global");
  const showHistoryAlert = async (
    formAction: string,
    sendData: (formAction: string, newHistory: History) => void
  ) => {
    const { comment, description } = history;

    const alertData = {
      header: t("alert_config_edit.header"),
      subHeader: t("alert_config_edit.subheader"),
      inputs: [
        {
          name: "description",
          placeholder: t("alert_config_edit.description_placeholder"),
          type: "text",
          value: description,
        },
        {
          name: "comment",
          placeholder: t("alert_config_edit.comment_placeholder"),
          type: "text",
          value: comment,
        },
      ],
      buttons: [
        {
          text: t("button.cancel"),
          role: "cancel",
          handler: () => {
            // console.log("Alert canceled");
          },
        },
        {
          text: t("button.confirm"),
          role: "confirm",
          handler: async (alertData: any) => {
            const { description, comment } = alertData;

            const newHistory = {
              description,
              comment,
              modifiedBy: loggedUser.username,
            };

            sendData(formAction, newHistory);

            // console.log("Alert confirmed");
          },
        },
      ],
    };

    presentAlert(alertData);
  };

  return { showHistoryAlert };
};

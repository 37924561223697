import { Visitor } from "./Visitor";

export class Applicant extends Visitor {
  isPrincipal: boolean = true;
  email?: string = "";

  constructor(visitorType: string) {
    super(visitorType);
  }
}

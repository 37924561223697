import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonModal,
  IonPage,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import MNXOContent from "../../../components/MNXOContent";
import Header from "../../../components/Header";
import { useVisitingShift } from "../hooks/useVisitingShift";
import { useLoading } from "../../../hooks/useLoading";
import MNXOVisitingShiftForm from "../components/MNXOVisitingShiftForm";
import { useVisitorTypes } from "../hooks/useVisitorTypes";
import MNXOVisitorTypesForm from "../components/MNXOVisitorTypesForm";
import { VisitorType } from "../../../models/VisitorType";
import { useTranslation } from "react-i18next";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";

// ATHOR: JOEL
const MNXOVisitingShiftEdit: React.FC = () => {
  const {
    visitorTypes,
    loading,
    setVisitorTypesFormData,
    visitorTypesFormData,
    updateVisitorTypes,
    sendVisitorTypes,
    toggleIsActive,
    toggleAltersCapacity,
    addNewTranslation,
    delTranslations,
    updateTranslations,
    createHistory,
  } = useVisitorTypes();

  // TODO: implementar dentro de MNXOLContent
  const { presentLoading, dismissLoading } = useLoading();
  const isLoading = !visitorTypes && !visitorTypesFormData;

  const formAction = {
    CREATE: "post",
    UPDATE: "put",
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [action, setAction] = useState<string>("");

  // visitorTypesFormData &&
  //   // console.log("visitorTypesFormData", visitorTypesFormData);

  useEffect(() => {
    isLoading ? presentLoading() : dismissLoading();
  }, [isLoading]);

  const handleCardClick = (visitorType: any, action: string) => {
    setAction(action);
    setVisitorTypesFormData(visitorType);
    handleOpen();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const [t, i18n] = useTranslation("global");
  return (
    <IonPage>
      <Header
        backButtonLink={"/dashboard/configuration/visitingconfiguration"}
      />
      <MNXOContent
        containerStyle="background-body-color"
        page={""}
        type={""}
        title={t("pages.edit_visitor_type.header.title")}
        text={t("pages.edit_visitor_type.header.text")}
        chipText={t("pages.edit_visitor_type.header.chip")}
      >
        <MNXODescriptiveText text="" subtext=""></MNXODescriptiveText>
        <div className="ion-padding">
          {visitorTypesFormData && (
            <MNXOVisitorTypesForm
              visitorTypesData={visitorTypesFormData}
              updateVisitorTypes={updateVisitorTypes}
              createHistory={createHistory}
              toggleIsActive={toggleIsActive}
              toggleAltersCapacity={toggleAltersCapacity}
              addNewTranslation={addNewTranslation}
              delTranslations={delTranslations}
              updateTranslations={updateTranslations}
              action={action}
              isOpen={isOpen}
              handleClose={handleClose}
            />
          )}

          {visitorTypes?.map((visitorType: any, index: number) => {
            return (
              <IonCard
                key={index}
                button
                style={{ marginBottom: "16px" }}
                className="ion-padding"
                onClick={() => handleCardClick(visitorType, formAction.UPDATE)}
              >
                <IonCardHeader>{visitorType.alias}</IonCardHeader>
              </IonCard>
            );
          })}

          <IonButton
            onClick={() => {
              const newVisitingShift = new VisitorType();
              handleCardClick(newVisitingShift, formAction.CREATE);
            }}
          >
            {t("button.new")}
          </IonButton>
        </div>
      </MNXOContent>
    </IonPage>
  );
};

export default MNXOVisitingShiftEdit;

import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useValidate } from "../hooks/useValidate";
import {
  AuthorizationList,
  UpdateAuthorizationByAgent,
} from "../adapters/validation.adapters";
import ApplicationCard from "../../application/components/ApplicationCard";
import Header from "../../../components/Header";
import MNXOContent from "../../../components/MNXOContent";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../../hooks/useLoading";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import "../../../theme/validation_manual_page.scss";
import LocatorForm from "../../application/components/LocatorForm";
import MNXOApplicationCard from "../../application/components/MNXOApplicationCard";
import { useApplicationsList } from "../../application/hooks/useApplicationsList";
import { useNaturalSpace } from "../../../hooks/useNaturalSpace";
import MNXOApplicationModal from "../../application/components/MNXOApplicationModal";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import MNXOSearchApplicationForm from "./MNXOSearchApplicationForm";

const ValidateManualApplications: React.FC = () => {
  const {
    getAllApplicationsByDateAndVisitingShiftUUID,
    updateAuthorizations,
    allApplicationsByDateAndVisitingShiftUUID: applications,
  } = useValidate();

  const { applicationsOfManualValidations, searchByParam } =
    useApplicationsList();
  const { visitingShifts, visitorTypes, allVisitingShifts } = useNaturalSpace(
    "recovery"
    // naturalSpaceUUID
  );
  // const [applications, setApplications] = useState<any>();
  const [showAlert, setShowAlert] = useState(false); // Estado para mostrar el alert
  const setAlertFalse = async () => {
    setShowAlert(false);
    // setApplications(null);
  };
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const fetchApplications = async () => {
      await getAllApplicationsByDateAndVisitingShiftUUID();
      // setApplications(allApplicationsByDateAndVisitingShiftUUID);
    };
    if (!applications) {
      fetchApplications();
    }
  }, []);
  const { presentLoading, dismissLoading } = useLoading();
  const isLoading = !applications;
  // applications && // console.log(applications);

  useEffect(() => {
    isLoading ? presentLoading() : dismissLoading();
  }, [isLoading]);

  //useEffect para filtrar por la palabra que ponga el agente
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  useEffect(() => {
    if (applications) {
      console.log(applications);
      setCurrentPage(1);
      const filteredApplications = applications.filter(
        ({ application }: any) =>
          Object.values(application).some(
            (value: any) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchKeyword.toLowerCase())
          ) ||
          application.visitors.some(
            (visitor: any) =>
              Object.values(visitor).some(
                (visitorValue: any) =>
                  typeof visitorValue === "string" &&
                  visitorValue
                    .toLowerCase()
                    .includes(searchKeyword.toLowerCase())
              ) ||
              (visitor.authorization &&
                Object.values(visitor.authorization).some(
                  (authValue: any) =>
                    typeof authValue === "string" &&
                    authValue
                      .toLowerCase()
                      .includes(searchKeyword.toLowerCase())
                )) ||
              (visitor.legalID &&
                Object.values(visitor.legalID).some(
                  (legalIDValue: any) =>
                    typeof legalIDValue === "string" &&
                    legalIDValue
                      .toLowerCase()
                      .includes(searchKeyword.toLowerCase())
                ))
          )
      );
      setFilteredData(filteredApplications);
    }
  }, [applications, searchKeyword]);
  //si en el parametro no hay ni fecha ni turno, redireciona al home
  // useEffect(() => {
  //   if (!date || !visitingShiftUUID) {
  //     history.push("/home");
  //   }
  // }, [date, visitingShiftUUID, history]);
  const [alertMessage, setAlertMessage] = useState(""); // Mensaje para la alerta
  const [currentElement, setCurrentElement] = useState(0);
  const handleClose = () => {
    setIsOpen(false);
    setCurrentElement(0);
  };
  const handleSubmit = async (authorizationUUID: any, status: any) => {
    const uuid = authorizationUUID;
    const authorizationStatus = status;
    const authorizationsArray = new AuthorizationList([
      new UpdateAuthorizationByAgent(uuid, authorizationStatus),
    ]);
    try {
      await updateAuthorizations(authorizationsArray);

      if (status === "CHECKED") {
        setAlertMessage(t("validation_page.manual.alert.checked"));
      } else if (status === "CANCELLED") {
        setAlertMessage(t("validation_page.manual.alert.cancelled"));
      }

      setShowAlert(true);
    } catch (error) {
      console.error("Error updating authorizations:", error);
    }
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ITEMS_PER_PAGE = 10;
  const handleOpen = () => {
    setIsOpen(true);
  };

  // State variable to track the current page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the range of applications to display based on the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  // Handle the "Next Page" button click
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const [loadingActive, setLoadingActive] = useState(false);

  const handleFilters = async (param: any) => {
    setLoadingActive(true);
    searchByParam(param.param);
    setLoadingActive(false);
  };
  const [selectedApplication, setSelectedApplication] = useState<any | null>(
    null
  );
  const { chipStatuses } = useAppTranslations();

  let translations: any;
  let applicationCardTranslations: any;

  if (visitorTypes && allVisitingShifts && visitingShifts) {
    translations = {
      visitingShifts: allVisitingShifts,
      visitorTypes: visitorTypes,
    };

    applicationCardTranslations = {
      visitingShifts: allVisitingShifts,
      chipStatuses: chipStatuses,
    };
  }

  return (
    <>
      <IonPage>
        <Header backButtonLink="/dashboard/validation/" />
        <MNXOContent
          containerStyle="background-body-color"
          page={""}
          type={"search_bar"}
          title={t("validation_page.manual.header.title")}
          text={t("validation_page.manual.header.text")}
          chipText={t("validation_page.manual.header.chip")}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
        >
          <IonAlert
            isOpen={showAlert}
            cssClass="custom-class"
            onDidDismiss={() => {
              setAlertFalse();
              setAlertMessage(""); // Reiniciar el mensaje de la alerta
            }}
            header={alertMessage} // Usar el mensaje correspondiente
            message={alertMessage}
            // buttons={[t("button.accept")]}
          />
          <MNXODescriptiveText
            text={t("validation_page.manual.instructions.text")}
            subtext={t("validation_page.manual.instructions.subtext")}
          ></MNXODescriptiveText>
          <>
            <IonGrid className="ion-padding">
              {translations && visitingShifts && (
                <MNXOApplicationModal
                  currentElement={currentElement}
                  setCurrentElement={setCurrentElement}
                  applicationData={selectedApplication}
                  isOpen={isOpen}
                  handleClose={handleClose}
                  visitingShifts={visitingShifts}
                  translations={translations}
                />
              )}
              <>
                <MNXOSearchApplicationForm handleFilters={handleFilters} />

                {applicationsOfManualValidations ? (
                  <>
                    {applicationsOfManualValidations.length > 0 ? (
                      <>
                        <IonTitle
                          className="application-card-title"
                          color={"lobos-text-grey"}
                        >
                          {t("text.recovery_auth")}
                        </IonTitle>

                        {applicationsOfManualValidations?.map(
                          (application: any, index: any) => {
                            const principalVisitor = application.visitors.find(
                              (visitor: any) => visitor.isPrincipal
                            );
                            return (
                              <div
                                className="card-manual-validation"
                                key={index}
                              >
                                <ApplicationCard
                                  application={application}
                                  index={index}
                                  searchKeyword={searchKeyword}
                                  handleSubmit={handleSubmit}
                                />
                              </div>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <h2>{t("text.not_app")}</h2>
                    )}
                  </>
                ) : (
                  <IonLabel color={"light"}>{t("text.not_app")}</IonLabel>
                )}
              </>
           

              {filteredData.length > 0 ? (
                filteredData
                  .slice(startIndex, endIndex)
                  .map(({ application }: any, index: number) => (
                    <div className="card-manual-validation" key={index}>
                      <ApplicationCard
                        application={application}
                        index={index}
                        searchKeyword={searchKeyword}
                        handleSubmit={handleSubmit}
                      />
                    </div>
                  ))
              ) : (
                <p className="validation-manual-message">
                  {t("validation_page.manual.search.not_found")}
                </p>
              )}
              {applications && applications.length > 10 && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <IonButton
                    className="text-button-manual"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    {t("button.previous_page")}
                  </IonButton>
                  <IonButton
                    className="text-button-manual"
                    onClick={handleNextPage}
                    disabled={
                      currentPage === totalPages || filteredData.length <= 0
                    }
                  >
                    {t("button.next_page")}
                  </IonButton>
                </div>
              )}

              {/* </form> */}
            </IonGrid>
          </>
        </MNXOContent>
      </IonPage>
    </>
  );
};
export default ValidateManualApplications;

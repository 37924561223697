import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import React from "react";
import { useForm } from "react-hook-form";

import { useHistory } from "react-router";
import { useIncident } from "../hooks/useIncident";
import Header from "../../../components/Header";
import MNXOContent from "../../../components/MNXOContent";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import { useTranslation } from "react-i18next";

const IncidentConfirmation: React.FC = (data: any) => {
  const { createIncident } = useIncident();
  const formData = data.location.state;
  const { handleSubmit } = useForm();
  const history = useHistory();
  const [t, i18n] = useTranslation("global");
  // console.log(data);
  const sendForm = async (data: any) => {
    await createIncident();
    //constante para mandar a la página de incidencias, para que aparezca al usuario como que su incidencia se ha a creado
    const created: any = 1;
    history.push("/dashboard/incidents/", created);
  };
  return (
    <IonPage>
      <Header backButtonLink={"/dashboard/configuration/"} />
      <MNXOContent
        containerStyle="background-body-color"
        page={""}
        type={""}
        title={t("pages.edit_visitor_type.header.title")}
        text={t("pages.edit_visitor_type.header.text")}
        chipText={t("pages.edit_visitor_type.header.chip")}
      >
        <MNXODescriptiveText text="" subtext=""></MNXODescriptiveText>
        <div className="ion-padding">
          {/*Formulario */}
          <IonList>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel>
                    {formData &&
                      formData.agents.map((formDataAgent: any, index: any) => (
                        <p key={index}>{formDataAgent}</p>
                      ))}
                  </IonLabel>
                </IonCol>
                <IonCol>
                  {/*TODO: Fix the default currentDate on the incident form, because it is send as currentDate:"" */}

                  <IonLabel position="floating">Date</IonLabel>
                  <IonInput
                    disabled={true}
                    type="datetime-local"
                    value={formData.date}
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel position="floating">Description</IonLabel>
                  <IonTextarea
                    disabled={true}
                    autoGrow={true}
                    value={formData.description}
                  ></IonTextarea>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel position="floating">Observations</IonLabel>
                  <IonTextarea
                    disabled={true}
                    autoGrow={true}
                    value={formData.observations}
                  ></IonTextarea>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel position="floating">Location</IonLabel>
                  <IonInput
                    disabled={true}
                    type="text"
                    value={formData.location}
                  ></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel position="floating">Image</IonLabel>{" "}
                  <input type="file" />
                </IonCol>
                <IonCol>
                  <IonLabel position="floating">Image</IonLabel>{" "}
                  <input type="file" />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonList>

          <form onSubmit={handleSubmit(sendForm)}>
            <IonButton expand="full" type="submit" shape="round">
              <IonIcon slot="end" icon={addCircleOutline} />
              Confirm
            </IonButton>
          </form>
        </div>
      </MNXOContent>
    </IonPage>
  );
};

export default IncidentConfirmation;

import {
  informationCircleOutline,
  helpOutline,
  listOutline,
  mailOutline,
  pawOutline,
} from "ionicons/icons";

const path = "app_data.routes.info.";

export const getInfoRoutes = (t: any) => {
  const infoRoutes = [
    {
      title: t(path + "information.title"),
      url: t(path + "information.url"),
      icon: informationCircleOutline,
    },
    {
      title: t(path + "rules.title"),
      url: t(path + "rules.url"),
      icon: listOutline,
    },
    {
      title: t(path + "about_natural_space.title"),
      url: t(path + "about_natural_space.url"),
      icon: pawOutline,
    },
    {
      title: t(path + "faqs.title"),
      url: t(path + "faqs.url"),
      icon: helpOutline,
    },
  ];

  return infoRoutes;
};

export const getInfoAlias = (t: any) => {
  const infoAlias = t(path + "alias")

  return infoAlias;
};
import { IonAlert, IonCol, IonRow } from "@ionic/react";
import React from "react";
import CustomCalendar from "./CustomCalendar";
import ShiftSelector from "./ShiftSelector";
import VisitorCounter from "./VisitorCounter";
import { Application } from "../entities/Application";
import { useTranslation } from "react-i18next";
import MNXOHighlightDate from "./MNXOHighlightDate";
import CurrentDate from "./CurrentDate";

interface ContainerProps {
  visitingShifts: any;
  applicationData: Application;
  visitorTypes: any;
  showView: any;
  setShowView: any;
  isOpenToast: boolean;
  handleDate: any;
  handleShift: any;
  removeVisitor: any;
  addVisitor: any;
  setIsOpenToast: any;
  timer: any;
  showEndAlert: any;
  maxVisitors?: any;
}

const MNXOApplicationFirstView: React.FC<ContainerProps> = ({
  visitingShifts,
  applicationData,
  handleDate,
  handleShift,
  visitorTypes,
  removeVisitor,
  addVisitor,
  timer,
  showEndAlert,
  showView,
  setShowView,
  isOpenToast,
  setIsOpenToast,
  maxVisitors,
}) => {
  const [t, i18n] = useTranslation("global");

  return (
    <>
      <div className="calendar-container-base">
        <IonRow className="calendar-container">
          <IonCol className="calendar-content-container">
            <h2>{t("application.calendar.title")}</h2>
            <h3>{t("application.calendar.subtitle")}</h3>
            <CurrentDate />
            <CustomCalendar
              availableDays={visitingShifts}
              selectedDate={applicationData.visitingDate}
              setDate={handleDate}
            />
          </IonCol>
        </IonRow>
      </div>
      <div className="visiting-shifts-container-base">
        <IonRow className="visiting-shifts-container">
          <IonCol>
            <h3 className="shift-selector-tittle">
              {t("application.visiting_shifts.title")}
            </h3>
            <IonRow className="ion-align-items-center ion-text-center">
              <IonCol sizeXs="4">
                <MNXOHighlightDate
                  date={applicationData}
                  visitingShifts={visitingShifts}
                />
              </IonCol>
              <IonCol className="divisory-line" />
              <IonCol sizeXs="7" className="shift-selector">
                <ShiftSelector
                  visitingShifts={visitingShifts}
                  selectedDate={applicationData.visitingDate}
                  selectedShift={applicationData.visitingShift}
                  onShiftSelected={handleShift}
                />
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </div>
      <div>
        <IonRow className="visitor-counter-container">
          <IonCol>
            <VisitorCounter
              visitorTypes={visitorTypes}
              visitors={applicationData.visitors}
              selectedDate={applicationData.visitingDate}
              selectedShift={applicationData.visitingShift}
              removeVisitor={removeVisitor}
              addVisitor={addVisitor}
              maxVisitors={maxVisitors}
            />
          </IonCol>
        </IonRow>
      </div>

      <IonAlert
        isOpen={showEndAlert}
        cssClass="custom-class"
        header="Se ha acabado el tiempo"
        subHeader={"Se ha acabado el tiempo para crear la solicitud"}
        buttons={[
          {
            text: "Crear nueva",
            role: "confirm",
            handler: () => {
              window.location.reload();
            },
          },
        ]}
        onDidDismiss={() => window.location.reload()}
      />
    </>
  );
};

export default MNXOApplicationFirstView;

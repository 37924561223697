import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import Header from "./Header";

const MNXOHeaderImage: React.FC = () => {
  return (
    <div className="lobos-background">
      <Header name="Info" type="transparent" isWhiteBackground={false}/>

      <div className="container">
        <IonGrid>
          <IonRow>
            <IonCol>
              <h2>Bienvenidos a Lobos</h2>
              <h3 className="img-text">Parque Natural Islote de Lobos</h3>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default MNXOHeaderImage;

import { IonLabel } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  date: any;
  color?: string;
  visitingShifts?: any;
}

const MNXOHighlightDate: React.FC<ContainerProps> = ({
  date,
  color = "lobos-primary",
  visitingShifts,
}) => {
  const [t, i18n] = useTranslation("global");
  const currentDay =
    new Date(date.visitingDate).getDate() ||
    new Date(visitingShifts[0].date).getDate();
  const currentMonth =
    new Date(date.visitingDate).toDateString().substring(3, 7) ||
    new Date(visitingShifts[0].date).toDateString().substring(3, 7);

  return (
    <div className={`MNXOHighlightDate`}>
      {/* <IonLabel>
          <p>{t("application.visiting_shifts.date")}</p>
        </IonLabel> */}
      <IonLabel color={color} className="day-format">
        {currentDay}
      </IonLabel>
      <IonLabel color={color} className="month-format">
        {/* {currentMonth} */}
        {t("intlDateTime", {
          val: new Date(date.visitingDate),
          formatParams: {
            val: { month: "short" },
          },
        })}
      </IonLabel>
    </div>
  );
};

export default MNXOHighlightDate;

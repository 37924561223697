import { IonButton } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import MNXOContactButton from "./buttons/ContactButton";

interface Props {
  typeOfButton: string;
}
const Button: React.FC<Props> = ({ typeOfButton }) => {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      {typeOfButton === "next" && <IonButton>{t("button.next")}</IonButton>}
      {typeOfButton === "disable" && (
        <IonButton disabled>{t("button.disabled")}</IonButton>
      )}
      {typeOfButton === "send" && (
        <IonButton type="submit">{t("button.send")}</IonButton>
      )}
      {typeOfButton === "contact" && <MNXOContactButton />}
    </>
  );
};

export default Button;

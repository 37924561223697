import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useState } from "react";
import Header from "../components/Header";
import MNXOFooter from "../components/MNXOFooter";
import { useStorage } from "../hooks/useStorage";
import { useNaturalSpace } from "../hooks/useNaturalSpace";
import { useAppTranslations } from "../hooks/useAppTranslations";
import MNXOLoading from "../components/MNXOLoading";
import { useTranslation } from "react-i18next";
import curva from "../assets/curva.svg";
import { useLoading } from "../hooks/useLoading";
import { getInfoRoutes } from "../routes/menu/info.routes";

const Welcome: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  // LOADING
  // const { presentLoading, dismissLoading } = useLoading();
  // const isLoading = CONDITION;
  // isLoading && presentLoading("Loading...", 1000);

  const { naturalSpaceUUID } = useStorage("naturalspace");
  const { loading } = useNaturalSpace("home", naturalSpaceUUID);
  const [loadingActive, setLoadingActive] = useState(false);
  const loadingDuration = 5000;

  return (
    <IonPage id="welcome-page">
      <IonContent className="lobos-background" id="poi-content">
        <Header
          hide={true}
          className="info-interest"
          name=""
          img
          type="transparent"
          isWhiteBackground={false}
        />
        <MNXOLoading
          isOpen={loadingActive}
          message={"Loading..."}
          duration={loadingDuration}
        />
        <IonGrid>
          <IonRow>
            <IonCol className="ion-padding interest-title">
              <h2 id="welcome-title">{t("welcome.title")}</h2>
              <h3 className="img-text">{t("welcome.subtitle")}</h3>
            </IonCol>
          </IonRow>

          <div className="bottom-info-container">
            <IonRow className="separator-wave">
              <IonCol size="12">
                <svg
                  className="welcome-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#fff"
                    fillOpacity="1"
                    d="M0,256L120,261.3C240,267,480,277,720,250.7C960,224,1200,160,1320,128L1440,96L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                  ></path>
                </svg>
              </IonCol>
            </IonRow>
            <IonRow className="bottom-info ion-align-self-end">
              <IonCol size="12" class="ion-text-start">
                <h4 id="welcome-p-title">
                  {t("welcome.call-to-action.text")}
                  <strong>{t("welcome.call-to-action.subtext")}</strong>
                </h4>
                <p>
                  {t("welcome.content")}
                  <a href="/info">{t("welcome.link")}</a>.
                </p>
              </IonCol>
            </IonRow>
          </div>
        </IonGrid>
      </IonContent>
      <MNXOFooter background={"white"} className="MNXOIntroFooter" />
    </IonPage>
  );
};

export default Welcome;

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect } from "react";
import MNXOContent from "../../../components/MNXOContent";
import { useVisitingConfiguration } from "../hooks/useVisitingConfiguration";
import { useLoading } from "../../../hooks/useLoading";
import Header from "../../../components/Header";
import MNXOVisitingConfigurationForm from "../components/MNXOVisitingConfigurationForm";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import { useTranslation } from "react-i18next";

const MNXOVisitingConfigurationEdit: React.FC = () => {
  const {
    visitingConfiguration,
    loading,
    visitingConfigurationFormData,
    updateVisitingConfiguration,
    sendVisitingConfiguration,
    visitingConfigurationPageTranslations,
    createHistory,
  } = useVisitingConfiguration();

  const { title } = visitingConfigurationPageTranslations;
  const [t, i18n] = useTranslation("global");
  // visitingConfigurationFormData &&
  //   // console.log("FORM", visitingConfigurationFormData);

  // TODO: implementar dentro de MNXOLContent
  const { presentLoading, dismissLoading } = useLoading();
  const isLoading = !visitingConfiguration && !visitingConfigurationFormData;

  useEffect(() => {
    isLoading ? presentLoading() : dismissLoading();
  }, [isLoading]);

  return (
    <IonPage>
      <Header
        backButtonLink={"/dashboard/configuration/visitingconfiguration"}
      />

      <MNXOContent
        containerStyle="background-body-color"
        page={""}
        type={""}
        title={t("configuration_edit.header.title")}
        text={t("configuration_edit.header.text")}
        chipText={t("configuration_edit.header.chip")}
      >
        <MNXODescriptiveText text="" subtext=""></MNXODescriptiveText>
        <div className="ion-padding">
          {visitingConfigurationFormData && (
            <MNXOVisitingConfigurationForm
              visitingConfigurationData={visitingConfigurationFormData}
              updateVisitingConfiguration={updateVisitingConfiguration}
              createHistory={createHistory}
              translations={visitingConfigurationPageTranslations}
            />
          )}
        </div>
      </MNXOContent>
    </IonPage>
  );
};

export default MNXOVisitingConfigurationEdit;

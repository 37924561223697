import { useEffect, useState } from "react";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRow,
} from "@ionic/react";
import { add, remove } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useAppTranslations } from "../../../hooks/useAppTranslations";

interface ContainerProps {
  addVisitor: any;
  removeVisitor: any;
  selectedDate: any;
  selectedShift: any;
  visitors: any;
  visitorTypes: any;
  maxVisitors?: any;
}
const VisitorCounter: React.FC<ContainerProps> = ({
  addVisitor,
  removeVisitor,
  selectedShift,
  selectedDate,
  visitors,
  visitorTypes,
  maxVisitors,
}) => {
  const limitVisitorCount = maxVisitors;
  const [visitorCount, setVisitorCount] = useState(0);
  const { currentLanguage } = useAppTranslations();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    if (visitorTypes) {
      setVisitorCount(visitors.length);
    }
  }, [selectedShift]);

  const getVisitorTypeTranslation = (visitor: any) => {
    const currentTranslation = visitor.translations.find(
      (translation: any) =>
        translation.language === currentLanguage.toUpperCase()
    );
    return currentTranslation;
  };

  const visitorTypeCounter = (type: string) => {
    const visitorsByType = visitors.filter(
      (visitor: any) => visitor.visitorType === type
    );
    return visitorsByType.length;
  };

  return (
    <>
      {visitorTypes && (
        <>
          <h2 className="text-white">
            {t("application.visitor_counter.title")}
          </h2>

          <div className="visitor-counter-content">
            <IonList className="visitor-counter-card">
              {visitorTypes.map((visitor: any, index: any) => {
                // const languagueType = visitor.translations.filter(
                //   (translation: any) =>
                //     translation.language == currentLanguage.toUpperCase()
                // );
                // const languague = languagueType[0];
                // console.log("language NAME", languague);

                // languague.name && // console.log("language NAME", languague.name);
                return (
                  <IonItem
                    lines="none"
                    className="visitor-counter-card ion-justify-content-center"
                    key={index}
                  >
                    <IonGrid>
                      <IonRow className="ion-align-items-center ion-justify-content-between">
                        <IonCol size="auto">
                          <IonLabel position="fixed">
                            {getVisitorTypeTranslation(visitor).name}
                          </IonLabel>
                          <IonNote>
                            {getVisitorTypeTranslation(visitor).description}
                          </IonNote>
                        </IonCol>

                        <IonCol size="auto">
                          <IonRow className="ion-align-items-center ion-justify-content-center counter-buttons">
                            <IonCol>
                              <IonButton
                                disabled={visitorCount === 0 ? true : false}
                                className="counter-button counter"
                                onClick={() => {
                                  if (
                                    visitorCount > 1 &&
                                    visitorTypeCounter(visitor.uuid) > 0
                                  ) {
                                    if (
                                      visitorTypeCounter(
                                        visitorTypes[0].uuid
                                      ) === 1 &&
                                      visitor.alias === "Adult"
                                    ) {
                                      return;
                                    } else {
                                      removeVisitor(visitor.uuid);
                                      setVisitorCount(visitorCount - 1);
                                    }
                                  }
                                }}
                                expand="block"
                              >
                                <IonIcon
                                  className="counter-visitor-icon"
                                  slot="icon-only"
                                  icon={remove}
                                />
                              </IonButton>
                            </IonCol>
                            <IonCol>
                              <IonLabel className="ion-text-center">
                                {visitorTypeCounter(visitor.uuid)}
                              </IonLabel>
                            </IonCol>
                            <IonCol>
                              <IonButton
                                className="counter-button counter"
                                disabled={
                                  visitorCount === 0 ||
                                  visitorCount >= limitVisitorCount
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  if (visitorCount < limitVisitorCount) {
                                    addVisitor(visitor.uuid);
                                    setVisitorCount(visitorCount + 1);
                                  }
                                }}
                                expand="block"
                              >
                                <IonIcon
                                  className="counter-visitor-icon "
                                  slot="icon-only"
                                  icon={add}
                                />
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                );
              })}
            </IonList>
          </div>
        </>
      )}
    </>
  );
};

export default VisitorCounter;

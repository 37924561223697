const path = "app_data.chip_statuses."

export const getChipStatuses = (t: any) => {
    const chipStatuses =
    {
        created: t(path + "created"),
        confirmed: t(path + "confirmed"),
        verificationPending: t(path + "verification_pending"),
        active: t(path + "active"),
        partiallyChecked: t(path + "partially_checked"),
        checked: t(path + "checked"),
        expired: t(path + "expired"),
        cancelled: t(path + "cancelled"),
        revoked: t(path + "revoked")
    }

    return chipStatuses;
}
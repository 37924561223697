import React from "react";
import { IonButton } from "@ionic/react";
import { useAppTranslations } from "../../hooks/useAppTranslations";

interface ContainerProps {
  color?: string;
}

const MNXOContactButton: React.FC<ContainerProps> = ({ color = "primary" }) => {
  const { getJSONTranslation } = useAppTranslations();
  const buttonText = getJSONTranslation("contact_email");

  return (
    <IonButton
      type="button"
      href="mailto:support@lobospass.com?subject=[LobosPass]%20Informe%20sobre%20nueva%20incidencia"
      className="contact-button"
      // color={color}
    >
      {buttonText}
    </IonButton>
  );
};

export default MNXOContactButton;

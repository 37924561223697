import { IonCol, IonContent, IonGrid, IonInput, IonLabel, IonModal, IonRow } from "@ionic/react";
import React, { useState } from "react";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import MNXOHeader from "../../../components/MNXOHeader";
import { useTranslation } from "react-i18next";
import QuillEditor from "../../../components/QuillEditor";
import { useFAQApi } from "../hooks/useFAQAPI";
import MNXOModalFooter from "../../../components/MNXOModalFooter";

interface Props {
  isOpen: boolean;
  setIsOpen: any;
}
interface Translations {
  language: string;
  answer: string;
  question: string;
  slug: string;
}
interface FAQForm {
  alias: string;
  translations: Translations[];
}
const MNXOFAQCreateForm: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const [t, i18n] = useTranslation("global");
  const { createFAQ } = useFAQApi();
  const languages: any = i18n.store.data;
  const languageArray = [];

  for (var key in languages) {
    languageArray.push(key.toUpperCase());
  }
  let translationArrayObject: any = [];
  languageArray.map((language: string) => {
    let object = {
      language: language,
      answer: "",
      question: "",
      slug: "",
    };
    translationArrayObject.push(object);
  });
  const [formValues, setFormValues] = useState<FAQForm>({
    alias: "",
    translations: translationArrayObject,
  });

  const modalDismiss = async () => {
    setIsOpen(false);
  };

  const handleChange = async (name: string, value: any, language?: string) => {
    if (name === "alias") {
      setFormValues((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setFormValues((prevData: FAQForm) => {
      const updatedTranslations = prevData.translations.map((translation) => {
        if (translation.language === language) {
          return {
            ...translation,
            [name]: value,
          };
        }
        return translation;
      });

      return {
        ...prevData,
        translations: updatedTranslations,
      };
    });
  };

  const handleSubmit = async (data: any) => {
    data.preventDefault();
    const faq = formValues;
    await createFAQ(faq);
    window.location.reload();
  };
  const handleClose = async () => {
    setIsOpen(false);
  };
  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
      <MNXOHeader
        text={t("modal-new-faq.header.text")}
        type="modal"
        title={""}
        handleClose={handleClose}
      />
      <IonContent className="ion-padding background-body-color">
        <form onSubmit={handleSubmit}>
          <IonGrid>
            <IonRow>
              <IonCol >
          <IonInput
            label={t("label.alias")}
            type="text"
            name="alias"
            required
            value={formValues.alias}
            placeholder="Alias"
            onIonChange={(e) => {
              handleChange(e.target.name, e.target.value);
            }}
            className="custom"
            labelPlacement="stacked"
            fill="outline"
          ></IonInput>
              </IonCol>
            </IonRow>
           

          {formValues.translations &&
            formValues.translations.map((translation: any, index: number) => (
              <div key={index}>
                 <IonRow>
              <IonCol style={{marginTop:"32px"}}>
                <IonLabel className="text-label-incident-form">{translation.language}</IonLabel>
                <IonInput
                label={t("label.slug")}
                  type="text"
                  name="slug"
                  required
                  value={formValues.translations[index].slug}
                  placeholder="Slug"
                  onIonChange={(e) => {
                    handleChange(
                      e.target.name,
                      e.target.value,
                      translation.language
                    );
                  }}
                  className="custom"
            labelPlacement="stacked"
            fill="outline"
                ></IonInput>
                </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonInput
                label={t("label.question")}
                  type="text"
                  name="question"
                  required
                  value={formValues.translations[index].question}
                  placeholder={t("label.question")}
                  onIonChange={(e) => {
                    handleChange(
                      e.target.name,
                      e.target.value,
                      translation.language
                    );
                  }}
                  className="custom"
            labelPlacement="stacked"
            fill="outline"
                ></IonInput>
</IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{marginTop:"32px"}}>
                <IonLabel className="text-label-incident-form">{t("label.answer")}</IonLabel>

                <QuillEditor
                  name="answer"
                  translation={translation.language}
                  setValue={handleChange}
                  dataToEdit={formValues.translations[index].answer}
                />
              </IonCol>
              </IonRow>
              </div>
            ))}

         
          </IonGrid>
        </form>
        <MNXOModalFooter
          modalDismiss={modalDismiss}
          handleSubmit={handleSubmit}
        />
      </IonContent>
    </IonModal>
  );
};

export default MNXOFAQCreateForm;

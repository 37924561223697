import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonModal,
  IonRow,
  IonText,
  useIonActionSheet,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import VisitorForm from "./VisitorForm";
import MNXOHeader from "../../../components/MNXOHeader";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import MNXOModalFooter from "../../../components/MNXOModalFooter";
import { useTranslation } from "react-i18next";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import { Player } from "@lottiefiles/react-lottie-player";
import MNXOLottieIcon from "../../../components/MNXOLottieIcon";

interface ContainerProps {
  visitorData: any;
  setFormData: any;
  visitorTypes: any;
  order: any;
  setCardNumber: any;
  setSameCountryForAll: any;
  visitors?: any;
  sameCountryForAll?: any;
  sameCountry?: any;
  openModal?: any;
}

const VisitorCard: React.FC<ContainerProps> = ({
  sameCountryForAll,
  visitorData,
  setFormData,
  visitorTypes,
  setCardNumber,
  order,
  setSameCountryForAll,
  visitors,
  sameCountry,
  openModal,
}) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const page = useRef(null);
  const [t, i18n] = useTranslation("global");
  const { currentLanguage } = useAppTranslations();

  const visitorWithTranslations = visitorTypes.filter(
    (visitor: any) => visitor.uuid == visitorData.visitorType
  );

  const languagueType = visitorWithTranslations[0].translations.filter(
    (translation: any) => translation.language === currentLanguage.toUpperCase()
  );

  const languague = languagueType[0];

  const { name, age, country, legalID, visitorType: uuid } = visitorData;
  const { legalIdType, legalIdNum } = legalID;
  const [visitorType, setVisitorType] = useState<any>();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  const conditions = {
    isValidForm: isValidForm,
  };

  const [presentingElement, setPresentingElement] =
    useState<HTMLElement | null>(null);

  const [present] = useIonActionSheet();

  const getMinAndMaxAge = async () => {
    const currentVisitorType = await visitorTypes.filter(
      (visitorType: any) => visitorType.uuid === uuid
    );
    setVisitorType(currentVisitorType);
  };

  useEffect(() => {
    if (!visitorType) {
      getMinAndMaxAge();
    }

    setPresentingElement(page.current);
  }, [visitorData]);

  function dismiss() {
    modal.current?.dismiss();
  }

  function canDismiss() {
    return new Promise<boolean>((resolve, reject) => {
      present({
        header: "Are you sure?",
        buttons: [
          {
            text: "Yes",
            role: "confirm",
          },
          {
            text: "No",
            role: "cancel",
          },
        ],
        onWillDismiss: (ev) => {
          if (ev.detail.role === "confirm") {
            resolve(true);
          } else {
            reject();
          }
        },
      });
    });
  }

  const [message, setMessage] = useState(
    "This modal example uses the modalController to present and dismiss modals."
  );

  let cardHeader;
  if (visitorType) {
    cardHeader = visitorType[0].alias;
  }

  return (
    <>
      <IonCard
        button
        className="visitor-application-card background-white"
        onClick={() => {
          openModal();
          setCardNumber(order);
        }}
      >
        <IonRow>
          <IonCol className={`ion-padding`}>
            <IonCardHeader>
              <IonCardTitle>
                {visitorData && visitorData.isPrincipal && (
                  <p className="text-visitor-form-header">{t("label.main")}</p>
                )}
                <p style={{ width: "150px" }} className="visitor-card-name">
                  <span className="text-black">{t("label.name")}:</span>
                  {visitorData && " " + visitorData.name}
                </p>
              </IonCardTitle>
            </IonCardHeader>
            <IonText>
              <p>
                <span className="text-black">{t("label.type")}:</span>
                {languague ? " " + languague.name : ""}
              </p>
            </IonText>
            {visitorData.isPrincipal && (
              <IonText>
                <p>
                  <span className="text-black">{t("label.email")}:</span>
                  {" " + visitorData.email}
                </p>
              </IonText>
            )}
          </IonCol>
          <IonItem lines="none" className="icon-edit">
            <MNXOLottieIcon
              src="https://lottie.host/42603dda-f026-4b0c-a062-b8f1ba2aa220/r0ml9fuukV.json"
              className="player"
              loop
              autoplay
            />
          </IonItem>
        </IonRow>
      </IonCard>

      <IonModal
        // ref={modal}
        // trigger={"open-modal-" + order}
        // canDismiss={canDismiss}
        presentingElement={presentingElement!}
      >
        <MNXOHeader
          text={t("header.text.visitor_form")}
          textChip={t("header.chip.visitor_form")}
          type="modal"
          title={""}
        />
        <div className="background-body-color" style={{ paddingTop: "32px" }}>
          <MNXODescriptiveText text={t("descriptive_text.visitor_form.text")} />
        </div>
        {/* <IonToolbar>
          <IonTitle>{visitorData.type}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              className="ion-padding"
              onClick={() => dismiss()}
              color="lobos-primary"
            >
              ✕
            </IonButton>
          </IonButtons>
        </IonToolbar> */}
        <IonContent className="ion-padding">
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol>
              {visitorType && (
                <VisitorForm
                  visitors={visitors}
                  setSameCountryForAll={setSameCountryForAll}
                  order={order}
                  visitorType={visitorType}
                  modalDismiss={dismiss}
                  visitorData={visitorData}
                  setFormData={setFormData}
                  setIsValidForm={setIsValidForm}
                  visitorTypeName={languague.name}
                  sameCountry={sameCountry}
                />
              )}
            </IonCol>
          </IonRow>
        </IonContent>
        <MNXOModalFooter modalDismiss={dismiss} conditions={conditions} />
      </IonModal>
    </>
  );
};

export default VisitorCard;

import {
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonPage,
  IonTitle,
} from "@ionic/react";
import React from "react";
import Header from "../../../components/Header";
import MNXOContent from "../../../components/MNXOContent";

const Statistics: React.FC = () => {
  return (
    <IonPage>
      <Header
        name="Statistics"
        isWhiteBackground={false}
        backButtonLink={"/dashboard/main"}
      />

      <MNXOContent containerStyle="ion-padding">
        <IonCardHeader>
          <h2>Visitors</h2>
          <IonTitle>Monthly Visitors</IonTitle>
        </IonCardHeader>
        <IonCardContent>
          <div className="progress-bar">
            <div className="progress" style={{ width: "66%" }}></div>
            <p>Total Visitors: 10,000</p>
            <p>Unique Visitors: 7,500</p>
            <p>Average Time Spent: 2 hours</p>
          </div>
        </IonCardContent>

        <IonCardHeader>
          <h2>Activities</h2>
          <IonTitle>Top Activities</IonTitle>
        </IonCardHeader>
        <IonCardContent>
          <div className="progress-bar">
            <div className="progress" style={{ width: "60%" }}></div>
            <p>Hiking: 60%</p>
            <div className="progress" style={{ width: "25%" }}></div>
            <p>Boat Tours: 25%</p>
            <div className="progress" style={{ width: "10%" }}></div>
            <p>Snorkeling: 10%</p>
            <div className="progress" style={{ width: "5%" }}></div>
            <p>Bird Watching: 5%</p>
          </div>
        </IonCardContent>

        <IonCardHeader>
          <h2>Wildlife</h2>
          <IonTitle>Popular Species</IonTitle>
        </IonCardHeader>
        <IonCardContent>
          <ul>
            <li>Loggerhead Sea Turtles</li>
            <li>Hawksbill Sea Turtles</li>
            <li>Osprey</li>
            <li>Monk Seals</li>
          </ul>
        </IonCardContent>

        <IonCardHeader>
          <h2>Revenue</h2>
          <IonTitle>Monthly Revenue</IonTitle>
        </IonCardHeader>
        <IonCardContent>
          <div className="progress-bar">
            <div className="progress" style={{ width: "66%" }}></div>
            <p>Total Revenue: $50,000</p>
            <p>Revenue Growth: 10%</p>
          </div>
        </IonCardContent>
      </MNXOContent>
    </IonPage>
  );
};

export default Statistics;

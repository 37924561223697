import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { t } from "i18next";
import {
  calendarOutline,
  cubeOutline,
  personOutline,
  serverOutline,
  statsChartOutline,
} from "ionicons/icons";
import React from "react";
import Header from "../../../components/Header";
import MNXOContent from "../../../components/MNXOContent";
import MNXOTitle from "../../../components/MNXOTitle";
import MNXODashboardCard from "../components/MNXODashboardCard";
import { useTranslation } from "react-i18next";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";

const MNXOVisitingConfigurationPage: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <IonPage>
      <Header backButtonLink={"/dashboard/configuration"}  hide={true} />

      <MNXOContent 
      containerStyle="background-body-color"
      page={""}
      type={""}
      title={t("visiting_configuration_page.header.title")}
      text={t("visiting_configuration_page.header.text")}
      chipText={t("visiting_configuration_page.header.chip")}      
      >
         <MNXODescriptiveText
          text=""
          subtext=""
        ></MNXODescriptiveText>
        <IonGrid>
          <IonRow className="ion-padding dashboard-row dashboard-first-row subsection">
            <IonCol size="12">
              <MNXODashboardCard
                title={t(
                  "visiting_configuration_page.visiting_configuration_card.title"
                )}
                //icon={cubeOutline}
                link={"/dashboard/configuration/visitingconfiguration/edit"}
              />
            </IonCol>
            <IonCol size="12" className="dashboard-col-card">
              <MNXODashboardCard
                title={t(
                  "visiting_configuration_page.visiting_shift_card.title"
                )}
                //icon={calendarOutline}
                link={"/dashboard/configuration/visitingshift/edit"}
              />
            </IonCol>

            {/* TODO: Edit 1 openinghour */}
            {/* <IonCol size="12" className="dashboard-col-card">
            <IonCol size="12" className="dashboard-col-card">
              <MNXODashboardCard
                title={t(
                  "visiting_configuration_page.visitor_types_card.title"
                )}
                icon={personOutline}
                link={"/dashboard/configuration/visitortypes/edit"}
              />
            </IonCol>
            <IonCol size="12" className="dashboard-col-card">
              <MNXODashboardCard
                title={"Opening Hours"}
                icon={personOutline}
                link={"/dashboard/configuration/openinghours/edit"}
              />
            </IonCol> */}
            <IonCol size="12" className="dashboard-col-card">
              <MNXODashboardCard
                title={t(
                  "visiting_configuration_page.visitor_type_card.title"
                )}
                //icon={personOutline}
                link={"/dashboard/configuration/visitortypes/edit"}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </MNXOContent>
    </IonPage>
  );
};

export default MNXOVisitingConfigurationPage;

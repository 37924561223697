import { IonGrid, IonPage, IonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";

//hooks
import { useApplication } from "../hooks/useApplication";

// components

import { useTranslation } from "react-i18next";
import MNXOApplicationFirstView from "../components/MNXOApplicationFirstView";
import MNXOApplicationSecondView from "../components/MNXOApplicationSecondView";
import MNXOApplicationThirdView from "../components/MNXOApplicationThirdView";
import MNXOApplicationFinalView from "../components/MNXOApplicationFinalView";
import MNXOApplicationFooter from "../components/MNXOApplicationFooter";
import MNXOAlert from "../../../components/MNXOAlert";
import Header from "../../../components/Header";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import { useLoading } from "../../../hooks/useLoading";
import MNXOContent from "../../../components/MNXOContent";

const Application: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  const {
    sendData,
    handleDate,
    handleShift,
    applicationData,
    showComponent,
    removeVisitorsStorage,
    addVisitor,
    removeVisitor,
    setIsOpenToast,
    timer,
    showEndAlert,
    isOpenToast,
    updateVisitorData,
    sameCountryForAll,
    sameCountry,
    visitingShifts,
    visitorTypes,
    maxVisitors,
    setApplicationData,
    loading,
    isLoadingAllVisitingShifts,
    isLoadingVisitingShifts,
    isLoadingVisitorTypes,
  } = useApplication();

  const { presentLoading, dismissLoading } = useLoading();

  const { currentLanguage } = useAppTranslations();

  let isLoading =
    isLoadingAllVisitingShifts ||
    isLoadingVisitingShifts ||
    isLoadingVisitorTypes;

  useEffect(() => {
    isLoading ? presentLoading("", "undefined") : dismissLoading();
  }, [isLoading]);

  // console.log("LOADING", isLoading);
  const [loadingActive, setLoadingActive] = useState(false);
  const [currentView, setCurrentView] = useState(0);

  const [respuesta, setRespuesta] = useState({
    mensaje: "",
  });

  const [showAlert, setShowAlert] = useState(false);

  //hook para abrir modal de confirmación
  const [isOpen, setIsOpen] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState();
  // SHOW
  const [message, setMessage] = useState<any>("");

  const [isValidVisitorForms, setIsValidVisitorForms] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isRulesAccepted, setIsRulesAccepted] = useState(false);
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);

  useEffect(() => {
    applicationData.visitors.map((visitor: any) => {
      if (visitor.name === "") {
        setIsValidVisitorForms(false);
      } else {
        setIsValidVisitorForms(true);
      }
    });
  }, [applicationData.visitors]);

  const conditions = {
    isSelectedVisitingShift: applicationData.visitingShift,
    isValidVisitorForms: isValidVisitorForms,
    isValidEmail: isValidEmail,
    isRulesAccepted: isRulesAccepted,
    isPolicyAccepted: isPolicyAccepted,
  };

  const onNextView = () => {
    setCurrentView((prevState: any) => prevState + 1);
  };
  const onPreviusView = () => {
    setCurrentView((prevState: any) => prevState - 1);
  };
  const confirm = async () => {
    setLoadingActive(true);

    try {
      const startTime = performance.now();
      await sendData(applicationData, currentLanguage.toLowerCase());

      // Establece la duración de la carga en función del tiempo de respuesta
      const endTime = performance.now();
      const requestDuration = endTime - startTime; // Define la variable requestDuration
      // setLoadingDuration(Math.min(requestDuration, 5000)); // Límite de 5000 ms (5 segundos) para la duración

      // onNextView();

      setLoadingActive(false); // Desactiva la carga cuando la respuesta se recibe
    } catch (error: any) {
      // console.log("ERROR", error.response);
      setMessage(error.response);
      console.log("ERROR", error);
      setShowAlert(true);
      setLoadingActive(false); // Desactiva la carga cuando la respuesta se recibe

      // console.log(// console.log("ERROR", error));
    }
  };

  useEffect(() => {
    if (visitingShifts?.length > 0 && !applicationData.visitingDate) {
      const firstAvailableVisitingShift = visitingShifts.filter(
        (visitingShift: any) => visitingShift.visitingShifts.length
      );
      handleDate(firstAvailableVisitingShift[0].date);
    }
  }, [visitingShifts]);

  return (
    <IonPage>
      {currentView === 0 && <Header />}

      <MNXOContent containerStyle={`blue ${currentView === 0 && "background"}`}>
        <MNXOAlert
          isOpen={isOpenToast}
          header={t("alert.timer_left")}
          message={`Tiene ${timer} minutos para realizar su solicitud`}
          // duration={3000}
          onDidDismiss={() => setIsOpenToast(false)}
          buttons={[
            {
              text: t("button.confirm"),
              role: "confirm",
            },
          ]}
        />

        <MNXOAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={message.statusText}
          message={message.data?.message}
          buttons={[
            {
              text: t("button.confirm"),
              role: "confirm",
            },
          ]}
        />

        <IonGrid>
          <>
            {currentView === 0 && visitingShifts && visitorTypes && (
              <MNXOApplicationFirstView
                applicationData={applicationData}
                visitingShifts={visitingShifts}
                visitorTypes={visitorTypes}
                showView={currentView}
                setShowView={setCurrentView}
                isOpenToast={isOpenToast}
                handleDate={handleDate}
                handleShift={handleShift}
                removeVisitor={removeVisitor}
                addVisitor={addVisitor}
                setIsOpenToast={setIsOpenToast}
                timer={timer}
                showEndAlert={showEndAlert}
                maxVisitors={maxVisitors}
              />
            )}

            {currentView === 1 && (
              <MNXOApplicationSecondView
                setSameCountry={
                  Array.isArray(applicationData.visitors) &&
                  applicationData.visitors.length > 1
                    ? sameCountryForAll
                    : null
                }
                applicationData={applicationData}
                visitorTypes={visitorTypes}
                showView={currentView}
                setShowView={setCurrentView}
                setShowAlert={setShowAlert}
                isOpen={isOpen}
                sendData={sendData}
                isValidVisitorForms={isValidVisitorForms}
                setApplicationStatus={setApplicationStatus}
                // updateVisitorData={updateVisitorData}
                updateVisitorData={setApplicationData}
                setIsOpen={setIsOpen}
                sameCountry={sameCountry}
              />
            )}

            {currentView === 2 && (
              <MNXOApplicationThirdView
                visitorTypes={visitorTypes}
                applicationData={applicationData}
                showView={currentView}
                visitingShifts={visitingShifts}
                setShowView={setCurrentView}
                sendApplication={sendData}
                setIsValidEmail={setIsValidEmail}
                setIsRulesAccepted={setIsRulesAccepted}
                setIsPolicyAccepted={setIsPolicyAccepted}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
            )}

            {currentView === 3 && (
              <MNXOApplicationFinalView
                removeVisitorsStorage={removeVisitorsStorage}
              />
            )}
          </>
        </IonGrid>
      </MNXOContent>

      <MNXOApplicationFooter
        currentView={currentView}
        totalOfViews={4}
        onNextView={onNextView}
        onPreviusView={onPreviusView}
        setIsValidEmail={setIsValidEmail}
        setIsRulesAccepted={setIsRulesAccepted}
        setIsPolicyAccepted={setIsPolicyAccepted}
        confirm={confirm}
        conditions={conditions}
      />
    </IonPage>
  );
};

export default Application;

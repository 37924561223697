import {
  IonButton,
  IonGrid,
  IonIcon,
  IonInput,
  IonPage,
  IonSelect,
  IonSelectOption,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useState } from "react";
import Header from "../../../components/Header";
import MNXOContent from "../../../components/MNXOContent";
import { useTranslation } from "react-i18next";
import { documentOutline } from "ionicons/icons";
import { useFetchData } from "../../../hooks/useFetchData";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import { useToast } from "../../../hooks/useToast";
import { useLoading } from "../../../hooks/useLoading";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";

const MNXOGroupApplication: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  const { currentLanguage } = useAppTranslations();

  const API_URL_BASE = process.env.REACT_APP_BACKEND_URL_BASE;
  const { fetchData: getVisitingShifts } = useFetchData();
  const { fetchData: sendGroupApplication } = useFetchData();
  const [visitingShiftsOptions, setVisitingShiftsOptions] = useState<any[]>([]);
  const { presentLoading, dismissLoading } = useLoading();
  const { presentToast } = useToast();

  const openFileDialog = () => {
    (document as any).getElementById("file-upload").click();
  };

  const setImage = (_event: any) => {
    let f = _event.target.files![0];
  };

  const submitForm = async (event: any) => {
    try {
      event.preventDefault();

      const currentData = {
        visitingDate: event.target["date"].value,
        visitingShift: event.target["visiting-shift"].value,
        csvFile: event.target.csv.files[0],
        language: currentLanguage,
      };

      const errorTypes = {
        emptyVisitingShift: t(
          "group_application.errorTypes.emptyVisitingShift"
        ),
        emptyCsvFile: t("group_application.errorTypes.emptyCsvFile"),
      };

      const { visitingDate, visitingShift, csvFile, language } = currentData;
      const { emptyVisitingShift, emptyCsvFile } = errorTypes;

      if (!visitingShift) throw new Error(emptyVisitingShift);
      if (!csvFile) throw new Error(emptyCsvFile);

      const formData = new FormData();
      formData.append("visitingDate", visitingDate);
      formData.append("visitingShift", visitingShift);
      formData.append("csvFile", csvFile);
      formData.append("language", language);

      const endpoint = `${API_URL_BASE}applicationcsvtest`;

      presentLoading(undefined, "undefined");

      const response: any = await sendGroupApplication(
        endpoint,
        "POST",
        formData
      );

      if (response.status !== 200) throw new Error("Error");
      presentToast(t("group_application.success"), "middle");
    } catch (error: any) {
      presentToast(error.message, "middle");
    } finally {
      dismissLoading();
    }
  };

  useIonViewWillEnter(() => {
    getVisitingShiftsOptions();
  });

  const getVisitingShiftsOptions = async (currentDate?: Date) => {
    try {
      presentLoading(undefined, "undefined");
      const date = currentDate || new Date();
      const formattedDate = date.toISOString().substring(0, 10);

      const endpoint = `${API_URL_BASE}visitingshift/${formattedDate}`;
      const response: any = await getVisitingShifts(endpoint);

      if (response.status !== 200) throw new Error("Error");

      const visitingShiftsOptions = response?.data.visitingShifts;

      setVisitingShiftsOptions(visitingShiftsOptions);
    } catch (error) {
    } finally {
      dismissLoading();
    }
  };

  const handleDate = async (event: any) => {
    const currentDate = new Date(event.target.value);
    await getVisitingShiftsOptions(currentDate);
  };

  const showTranslatedName = (translations: any) => {
    const translatedName = translations.find(
      (translation: any) => translation.language === currentLanguage
    );
    return translatedName?.name;
  };

  return (
    <IonPage className="group-application">
      <Header />

      <MNXOContent
        containerStyle="background-body-color"
        title={t("group_application.title")}
        text={t("group_application.subtitle")}
        chipText={t("group_application.chip")}
      >
        <MNXODescriptiveText
          text={t("group_application.instructions.text")}
          subtext={t("group_application.instructions.subtext")}
        />
        <IonGrid className="group-application">
          <form action="post" id="group-application" onSubmit={submitForm}>
            <IonInput
              type="date"
              name="date"
              className="custom"
              label={t("group_application.form.date")}
              labelPlacement="stacked"
              required
              fill="outline"
              onIonChange={handleDate}
            />
            <IonSelect
              name="visiting-shift"
              className="custom"
              label={t("group_application.form.visiting_shift")}
              labelPlacement="stacked"
              fill="outline"
            >
              {visitingShiftsOptions?.map((visitingShift: any, index: any) => (
                <IonSelectOption key={index} value={visitingShift.uuid}>
                  {showTranslatedName(visitingShift.translations)}
                </IonSelectOption>
              ))}
            </IonSelect>

            <input
              type="file"
              id="file-upload"
              name="csv"
              style={{ display: "none" }}
              onChange={setImage}
            />

            <IonButton onClick={openFileDialog}>
              {t("group_application.form.file")}
              <IonIcon slot="start" icon={documentOutline} />
            </IonButton>
          </form>

          <IonButton form="group-application" type="submit" expand="block">
            {t("group_application.form.submit")}
          </IonButton>
        </IonGrid>
      </MNXOContent>
    </IonPage>
  );
};

export default MNXOGroupApplication;

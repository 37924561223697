import { useEffect, useState } from "react";
import { useFetchData } from "../../../hooks/useFetchData";
import { useStorage } from "../../../hooks/useStorage";
import axios from "axios";
import { useToast } from "../../../hooks/useToast";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import { VisitingShift, OpeningHours } from "../../../models/VisitingShift";
import {
  VisitorType,
  VisitorTypeTranslation,
} from "../../../models/VisitorType";
import { History } from "../../../models/History";
import { useHistory } from "../hooks/useHistory";

const API_URL_BASE = process.env.REACT_APP_BACKEND_URL_BASE;

// ATHOR: JOEL
export const useVisitorTypes = () => {
  const { presentToast } = useToast();
  const { showHistoryAlert } = useHistory();
  const { translation } = useAppTranslations();
  const {
    data: visitorTypes,
    loading,
    error,
    fetchData: fetchVisitorTypes,
  } = useFetchData();
  const [visitorTypesFormData, setVisitorTypesFormData] =
    useState<VisitorType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getVisitorTypes();
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, []);

  const getVisitorTypes = async () => {
    // console.log("llamada");
    const apiURL = API_URL_BASE + "visitortype";
    const data = await fetchVisitorTypes(apiURL);
  };

  const updateVisitorTypes = async (inputData: any) => {
    const { type, name, value } = inputData;
    // console.log(inputData.type, "campo", name, "value", typeof value);
    if (type === "number") {
      setVisitorTypesFormData((prev: any) => ({ ...prev, [name]: +value }));
    } else {
      setVisitorTypesFormData((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const toggleIsActive = () => {
    const currentIsActive = visitorTypesFormData?.isActive;
    setVisitorTypesFormData((prev: any) => ({
      ...prev,
      isActive: !currentIsActive,
    }));
  };

  const toggleAltersCapacity = () => {
    const currentAltersCapacity = visitorTypesFormData?.altersCapacity;
    setVisitorTypesFormData((prev: any) => ({
      ...prev,
      altersCapacity: !currentAltersCapacity,
    }));
  };

  const addNewTranslation = (newLanguage: string = "") => {
    setVisitorTypesFormData((prev: any) => ({
      ...prev,
      translations: [
        ...prev.translations,
        new VisitorTypeTranslation(newLanguage),
      ],
    }));
  };

  const delTranslations = (translationToDelete: VisitorTypeTranslation) => {
    const updatedArray = visitorTypesFormData?.translations.filter(
      (translation: VisitorTypeTranslation) =>
        translation !== translationToDelete
    );
    setVisitorTypesFormData((prev: any) => ({
      ...prev,
      translations: updatedArray,
    }));
  };

  const updateTranslations = async (inputData: any, index: number) => {
    const { name, value } = inputData;
    const updatedVisitingShiftTranslations = [
      ...visitorTypesFormData!.translations,
    ];

    updatedVisitingShiftTranslations[index] = {
      ...updatedVisitingShiftTranslations[index],
      [name]: value,
    };
    setVisitorTypesFormData((prev: any) => ({
      ...prev,
      translations: updatedVisitingShiftTranslations,
    }));
  };

  const presentResponseToast = (statusCode: number) => {
    let message: string;
    const position = "middle";
    const duration = 3000;

    // TODO: improve and use static translations
    switch (statusCode) {
      case 200:
        message = "Todo ha ido bien";
        break;

      default:
        message = "Algo ha fallado";
        break;
    }
    presentToast(message, position, duration);
  };

  const createHistory = (formAction: string) => {
    showHistoryAlert(formAction, sendVisitorTypes);
  };

  const sendVisitorTypes = async (method: string, newHistory: History) => {
    try {
      const data = {
        visitingConfigurationHistory: newHistory,
        visitorTypeData: [visitorTypesFormData],
      };

      //   // console.log("SEND DATA", data);

      const config = {
        method: method,
        maxBodyLength: Infinity,
        url: API_URL_BASE + "visitortype",
        data: data,
      };

      const response = await axios.request(config);
      //   // console.log("RESPONSE", response);

      if (response) {
        presentResponseToast(response.status);
        await getVisitorTypes();
      }
    } catch (error: any) {
      presentResponseToast(error.response.status);
    }
  };

  return {
    visitorTypes,
    loading,
    visitorTypesFormData,
    setVisitorTypesFormData,
    updateVisitorTypes,
    sendVisitorTypes,
    toggleIsActive,
    toggleAltersCapacity,
    addNewTranslation,
    delTranslations,
    updateTranslations,
    createHistory,
  };
};

import { IonAlert, IonContent, IonPage, IonTitle } from "@ionic/react";
import Header from "../../../components/Header";
import React, { useEffect, useState } from "react";
import { useIncident } from "../hooks/useIncident";
import IncidentModal from "../../validation/components/IncidentModal";
import { useHistory } from "react-router-dom";
import IncidentForm from "../components/IncidentForm";
import { usePhotoGallery } from "../../../hooks/usePhotoGallery";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../hooks/useAlert";
import MNXOContent from "../../../components/MNXOContent";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";

const Incident: React.FC = () => {
  const { photos, takePhoto } = usePhotoGallery();
  const [t, i18n] = useTranslation("global");
  const { presentAlert } = useAlert();
  const history = useHistory();
  // const { saveIncidentStorage } = useStorage();
  const {
    incidentData,
    createIncident,
    updateIncidentData,
    sendPhoto,
    incidentTypes,
    loggedUser,
  } = useIncident();

  const [showAlert, setShowAlert] = useState(false);

  //hook para abrir modal de confirmación
  const [isOpen, setIsOpen] = useState(false);

  //Datos del formulario en un hook para pasar al modal de confirmación
  //TODO: usar el local storage para almacenar los datos de la incidencia

  //getAgentsList

  useEffect(() => {
    // console.log("INCIDENT_DATA", incidentData);
  }, [incidentData]);

  const showVerifiedApplicationAlert = () => {
    try {
      const alertData = {
        header: t("application.recover_messages.verified_header"),
        message: t("application.recover_messages.verified_message"),
        buttons: [
          {
            text: "Ver todas",
            role: "confirm",
            handler: () => {
              history.push("/dashboard/incident/show");
            },
          },
          {
            text: "Crear nueva",
            role: "cancel",
            handler: () => {
              window.location.reload();
            },
          },
        ],
      };

      presentAlert(alertData);
      setShowAlert(false);
    } catch (error) {
      // console.log(error);
    }
  };

  // Formatear la fecha y hora como "YYYY-MM-DDThh:mm" para que coincida con el formato requerido por el campo de entrada de tipo datetime

  // console.log(currentDate);

  //TODO: sacar de la BD

  //TODO: Crear modal para confirmar datos, como componente aparte
  //TODO: Pasar inputs a componentes externos
  //TODO: Pasar textos de incidencia a español
  return (
    <IonPage>
      <Header isWhiteBackground={false} backButtonLink="/dashboard/incident" />
      <MNXOContent
        containerStyle="background-body-color"
        page={""}
        type={""}
        title={t("pages.create_incident.header.title")}
        text={t("pages.create_incident.header.text")}
        chipText={t("pages.create_incident.header.chip")}
      >
        <MNXODescriptiveText text="" subtext=""></MNXODescriptiveText>
        <div className="ion-padding">
          <IncidentModal
            isOpen={isOpen}
            setShowAlert={setShowAlert}
            handleClose={() => setIsOpen(false)}
            loggedUser={loggedUser}
            incidentData={incidentData}
            sendIncidentData={createIncident}
            incidentTypes={incidentTypes}
            //le paso los datos del backend para recoger los nombres de los
            toConfirm={true}
          />

          <IonAlert
            isOpen={showAlert}
            cssClass="custom-class"
            header={t("created_incident_alert.header")}
            buttons={[
              {
                text: t("created_incident_alert.buttons.new"),
                role: "cancel",
                handler: () => {
                  window.location.reload();
                },
              },
              {
                text: t("created_incident_alert.buttons.confirm"),
                role: "confirm",
                handler: () => {
                  history.push("/dashboard/incident/show");
                },
              },
            ]}
            onDidDismiss={() => setIsOpen(false)}
          />

          <IncidentForm
            incidentData={incidentData}
            sendPhoto={sendPhoto}
            updateIncidentData={updateIncidentData}
            openModal={setIsOpen}
            takePhoto={takePhoto}
            photos={photos}
            setIsOpen={setIsOpen}
          />
        </div>
      </MNXOContent>
    </IonPage>
  );
};

export default Incident;

import { IonModal } from "@ionic/react";
import React, { useRef } from "react";

import { useTranslation } from "react-i18next";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import MNXOHeader from "../../../components/MNXOHeader";
import InformationTypeModal from "./InformationTypeModal";

interface Props {
  isOpen?: boolean;
  setOpenEditModal?: any;
  faq?: any;
}

const MNXOFAQModalEditor: React.FC<Props> = ({
  isOpen,
  setOpenEditModal,
  faq,
}) => {
  const [t, i18n] = useTranslation("global");
  const modal = useRef<HTMLIonModalElement>(null);
  const handleClose = async () => {
    setOpenEditModal(false);
  };
  return (
    <IonModal
      ref={modal}
      isOpen={isOpen}
      onDidDismiss={() => setOpenEditModal(false)}
    >
      <MNXOHeader
        text={t("modal-faqs-edit.header.text")}
        type="modal"
        title={""}
        handleClose={handleClose}
      />
      <InformationTypeModal
        infoObject={faq}
        setIsOpen={setOpenEditModal}
        faqEdit={true}
      />
    </IonModal>
  );
};

export default MNXOFAQModalEditor;

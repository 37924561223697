import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useRef, useState } from "react";
import MNXOHeader from "../../../components/MNXOHeader";
import MNXOQrReader from "../components/MNXOQrReader";
import "../../../theme/general.scss";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import MNXOAuthCard from "../../application/components/MNXOAuthCard";
import { useNaturalSpace } from "../../../hooks/useNaturalSpace";
import { useStorage } from "../../../hooks/useStorage";
import Header from "../../../components/Header";
import MNXOContent from "../../../components/MNXOContent";
import MNXOTitle from "../../../components/MNXOTitle";
import { useTranslation } from "react-i18next";

const Validate: React.FC = () => {
  const [qrCode, setQRCode] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<any | null>(
    null
  );
  const swiperRef = useRef<any>(null);
  const [currentElement, setCurrentElement] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { naturalSpaceUUID } = useStorage("naturalspace");
  const { visitingShifts, visitorTypes, allVisitingShifts } = useNaturalSpace(
    "validation",
    naturalSpaceUUID
  );
  const translations = {
    visitingShifts: allVisitingShifts,
    visitorTypes: visitorTypes,
  };
  const onNextView = () => {
    setCurrentElement((prevElement) => {
      if (swiperRef.current) {
        swiperRef.current.swiper.slideToLoop(prevElement + 1, 300, true);
      }
      return prevElement + 1;
    });
  };
  const onPreviusView = () => {
    setCurrentElement((prevElement) => {
      if (swiperRef.current) {
        swiperRef.current.swiper.slideToLoop(prevElement - 1, 300, true);
      }
      return prevElement - 1;
    });
  };

  const handleCardClick = (application: any) => {
    setSelectedApplication(application);
  };
  const [t, i18n] = useTranslation("global");

  return (
    <IonPage>
      <Header
        name=""
        isWhiteBackground={false}
        backButtonLink={"/dashboard/validation"}
      />
      <MNXOContent
        containerStyle=" ion-justify-content-center background-body-color"
        page={""}
        type={""}
        title={t("validation_page.scan.header.title")}
        text={t("validation_page.scan.header.text")}
        chipText={t("validation_page.scan.header.chip")}
      >
        {/* <MNXOHeader text="Verification Authorizations" textChip="SCAN QR CODE" /> */}
        {!selectedApplication ? (
          <>
              <MNXODescriptiveText
                text=""
                subtext=""
              />

            <IonGrid>
              <IonRow>
                <IonCol className="scan-container" size="10" offset="1">
                  <MNXOQrReader handleCardClick={handleCardClick} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        ) : (
          selectedApplication && (
            <div className="auth-card-test-container">
              <MNXOAuthCard
                swiperRef={swiperRef}
                applicationData={selectedApplication}
                currentPage={currentPage}
                setCurrentElement={setCurrentElement}
                setCurrentPage={setCurrentPage}
                currentElement={currentElement}
                agentValidation={true}
                visitingShifts={visitingShifts}
                translations={translations}
              />
            </div>
          )
        )}
      </MNXOContent>
    </IonPage>
  );
};

export default Validate;

import { useIonAlert } from "@ionic/react";

export const useAlert = () => {
  const [present] = useIonAlert();

  const presentAlert = (alertData?: any) => {
    const {
      header,
      subHeader,
      message,
      inputs = [],
      buttons = [],
      mode = "md",
    } = alertData;

    present({
      cssClass: "custom-class",
      header: header,
      subHeader: subHeader,
      message: message,
      inputs: inputs,
      buttons: buttons,
      mode: mode,
    });
  };

  return { presentAlert };
};

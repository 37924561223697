import {
  IonButton,
  IonButtons, IonContent, IonIcon,
  IonInput, IonModal, IonTitle,
  IonToggle,
  IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import {
  OpeningHours,
  VisitingShift,
  VisitingShiftTranslation,
  defaultLanguage,
} from "../../../models/VisitingShift";
import { useTranslation } from "react-i18next";
import { closeOutline } from "ionicons/icons";
import MNXOHeader from "../../../components/MNXOHeader";
interface Props {
  isOpen: boolean;
  action: string;
  visitingShiftData: VisitingShift;
  updateVisitingShift: (inputData: any) => void;
  updateOpeningHours: (inputData: any, index: number) => void;
  updateTranslations: (inputData: any, index: number) => void;
  addNewOpeningHour: () => void;
  addNewTranslation: (newLanguage?: string) => void;
  delNewOpeningHour: (openingHourToDelete: OpeningHours) => void;
  delTranslations: (translationToDelete: VisitingShiftTranslation) => void;
  toggleIsActive: () => void;
  showHistoryAlert: any;
  handleClose: any;
  staticTranslations?: any;
}

// ATHOR: JOEL
const MNXOVisitingShiftForm: React.FC<Props> = ({
  isOpen,
  action,
  visitingShiftData,
  updateVisitingShift,
  updateOpeningHours,
  delNewOpeningHour,
  delTranslations,
  addNewOpeningHour,
  addNewTranslation,
  updateTranslations,
  toggleIsActive,
  staticTranslations,
  showHistoryAlert,
  handleClose,
}) => {
  const {
    maxCapacity,
    maxVisitorsPerApplication,
    isActive,
    openingHours,
    translations,
  } = visitingShiftData;

  const [newTranslationName, setNewTranslationName] = useState<string>("");
  const [t, i18n] = useTranslation("global");
  return (
    <IonModal isOpen={isOpen} onDidDismiss={handleClose}>
      <MNXOHeader
        text={t("pages.edit_visiting_shift.title")}
        type="modal"
        title={""}
        handleClose={handleClose}
      />
      <IonContent className="ion-padding background-body-color">
        <form>
          <IonInput
            type="number"
            name="maxCapacity"
            label={staticTranslations.formInputs.maxCapacity}
            labelPlacement="stacked"
            fill="outline"
            className="custom"
            value={maxCapacity}
            onIonInput={(e) => updateVisitingShift(e.target)}
            required
          />
          <IonInput
            type="number"
            name="maxVisitorsPerApplication"
            label={staticTranslations.formInputs.maxVisitorsPerApplication}
            labelPlacement="stacked"
            fill="outline"
            className="custom"
            value={maxVisitorsPerApplication}
            onIonInput={(e) => updateVisitingShift(e.target)}
            required
          />
          <IonToggle className={isActive ? 'active-toggle' : ''} checked={isActive} onClick={toggleIsActive}>
            <p>{staticTranslations.formInputs.isActive}</p>
          </IonToggle>

          {openingHours?.map((openingHour: any, index: number) => {
            const { dayOfWeek, opens, closes, validFrom, validThrough } =
              openingHour;
            return (
              <div key={index}>
                <h3>{t("pages.edit_visiting_shift.hours_form.title")} {index + 1}</h3>
                <IonInput
                  type="text"
                  name="dayOfWeek"
                  label={t("pages.edit_visiting_shift.form_inputs.dayOfWeek")}
                  labelPlacement="stacked"
                  className="custom"
                  fill="outline"
                  value={dayOfWeek}
                  onIonInput={(e) => updateOpeningHours(e.target, index)}
                  required
                />
                <IonInput
                  type="text"
                  name="opens"
                  label={t("pages.edit_visiting_shift.form_inputs.opens")}
                  labelPlacement="stacked"
                  className="custom"
                  fill="outline"
                  value={opens}
                  onIonInput={(e) => updateOpeningHours(e.target, index)}
                  required
                />
                <IonInput
                  type="text"
                  label={t("pages.edit_visiting_shift.form_inputs.closes")}
                  name="closes"
                  labelPlacement="stacked"
                  className="custom"
                  fill="outline"
                  value={closes}
                  onIonInput={(e) => updateOpeningHours(e.target, index)}
                  required
                />
                <IonInput
                  type="date"
                  name="validFrom"
                  label={t("pages.edit_visiting_shift.form_inputs.validFrom")}
                  labelPlacement="stacked"
                  className="custom"
                  fill="outline"
                  value={validFrom.toString()}
                  onIonChange={(e) => updateOpeningHours(e.target, index)}
                  required
                />
                <IonInput
                  type="date"
                  label={t("pages.edit_visiting_shift.form_inputs.validThrough")}
                  name="validThrough"
                  labelPlacement="stacked"
                  className="custom"
                  fill="outline"
                  value={validThrough?.toString()}
                  onIonChange={(e) => updateOpeningHours(e.target, index)}
                  required
                />
                {index !== 0 && (
                  <IonButton onClick={() => delNewOpeningHour(openingHour)}>
                    {staticTranslations.buttons.remove}
                  </IonButton>
                )}
              </div>
            );
          })}

          <IonButton style={{marginBottom: "48px"}} onClick={() => addNewOpeningHour()}>
            {staticTranslations.buttons.addOpeningHour}
          </IonButton>

          {translations?.map(
            (translation: VisitingShiftTranslation, index: number) => {
              const { language, name, description } = translation;
              return (
                <div key={index} >
                  <h3>{language || "NEW"} {t("pages.edit_visiting_shift.translation_form.title")}</h3>
                  <IonInput
                    type="text"
                    name="language"
                    readonly={defaultLanguage.hasOwnProperty(language)}
                    label={t("pages.edit_visiting_shift.form_inputs.language")}
                    labelPlacement="stacked"
                    className="custom"
                    fill="outline"
                    value={language}
                    onIonInput={(e) => updateTranslations(e.target, index)}
                    required
                  />
                  <IonInput
                    type="text"
                    name="name"
                    label={t("pages.edit_visiting_shift.form_inputs.name")}
                    labelPlacement="stacked"
                    className="custom"
                    fill="outline"
                    value={name}
                    onIonInput={(e) => updateTranslations(e.target, index)}
                    required
                  />
                  <IonInput
                    type="text"
                    label={t("pages.edit_visiting_shift.form_inputs.description")}
                    name="description"
                    labelPlacement="stacked"
                    className="custom"
                    fill="outline"
                    value={description}
                    onIonInput={(e) => updateTranslations(e.target, index)}
                    required
                  />

                  {!defaultLanguage.hasOwnProperty(language) && (
                    <IonButton onClick={() => delTranslations(translation)}>
                      {staticTranslations.buttons.remove}
                    </IonButton>
                  )}
                </div>
              );
            }
          )}

          <IonButton
            onClick={() => {
              addNewTranslation(newTranslationName);
              setNewTranslationName("");
            }}
          >
            {staticTranslations.buttons.addTranslation}
          </IonButton>

          <IonButton className="submit-button-shift-form"
            onClick={() => {
              showHistoryAlert(action);
            }}
          >
            {staticTranslations.buttons.save}
          </IonButton>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default MNXOVisitingShiftForm;

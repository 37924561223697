import { useEffect, useState } from "react";
import { useFetchData } from "../../../hooks/useFetchData";
import { useStorage } from "../../../hooks/useStorage";
import axios from "axios";
import { useToast } from "../../../hooks/useToast";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import {
  VisitingShift,
  OpeningHours,
  VisitingShiftTranslation,
} from "../../../models/VisitingShift";
import { useAlert } from "../../../hooks/useAlert";
import { History } from "../../../models/History";
import { useHistory } from "../hooks/useHistory";

const API_URL_BASE = process.env.REACT_APP_BACKEND_URL_BASE;

// ATHOR: JOEL
export const useVisitingShift = () => {
  const { data: loggedUser } = useStorage("logged-user");
  const { showHistoryAlert } = useHistory();
  const { presentToast } = useToast();
  const { presentAlert } = useAlert();
  const { translation, visitingShiftPageTranslations: translations } =
    useAppTranslations();
  const {
    data: visitingShifts,
    loading,
    error,
    fetchData: fetchVisitingShifts,
  } = useFetchData();
  const [visitingShiftFormData, setVisitingShiftFormData] =
    useState<VisitingShift>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getVisitingShift();
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, []);

  const getVisitingShift = async () => {
    // console.log("llamada");
    const apiURL = API_URL_BASE + "allshifts";
    const data = await fetchVisitingShifts(apiURL);
  };

  const updateVisitingShift = async (inputData: any) => {
    const { name, value } = inputData;
    setVisitingShiftFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const addNewTranslation = (newLanguage: string = "") => {
    setVisitingShiftFormData((prev: any) => ({
      ...prev,
      translations: [
        ...prev.translations,
        new VisitingShiftTranslation(newLanguage),
      ],
    }));
  };

  const addNewOpeningHour = () => {
    setVisitingShiftFormData((prev: any) => ({
      ...prev,
      openingHours: [...prev.openingHours, new OpeningHours()],
    }));
  };

  const delTranslations = (translationToDelete: VisitingShiftTranslation) => {
    const updatedArray = visitingShiftFormData?.translations.filter(
      (translation: VisitingShiftTranslation) =>
        translation !== translationToDelete
    );
    setVisitingShiftFormData((prev: any) => ({
      ...prev,
      translations: updatedArray,
    }));
  };

  const delNewOpeningHour = (openingHourToDelete: OpeningHours) => {
    const updatedArray = visitingShiftFormData?.openingHours.filter(
      (openingHour: OpeningHours) => openingHour !== openingHourToDelete
    );
    setVisitingShiftFormData((prev: any) => ({
      ...prev,
      openingHours: updatedArray,
    }));
  };

  const toggleIsActive = () => {
    const currentIsActive = visitingShiftFormData?.isActive;
    setVisitingShiftFormData((prev: any) => ({
      ...prev,
      isActive: !currentIsActive,
    }));
  };

  const updateOpeningHours = async (inputData: any, index: number) => {
    const { name, value } = inputData;
    const updatedOpeningHour = [...visitingShiftFormData!.openingHours];

    updatedOpeningHour[index] = {
      ...updatedOpeningHour[index],
      [name]: value,
    };
    setVisitingShiftFormData((prev: any) => ({
      ...prev,
      openingHours: updatedOpeningHour,
    }));
  };

  const updateTranslations = async (inputData: any, index: number) => {
    const { name, value } = inputData;
    const updatedVisitingShiftTranslations = [
      ...visitingShiftFormData!.translations,
    ];

    updatedVisitingShiftTranslations[index] = {
      ...updatedVisitingShiftTranslations[index],
      [name]: value,
    };
    setVisitingShiftFormData((prev: any) => ({
      ...prev,
      translations: updatedVisitingShiftTranslations,
    }));
  };

  const createHistory = (formAction: string) => {
    showHistoryAlert(formAction, sendVisitingShift);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const presentResponseToast = (statusCode: number) => {
    let message: string;
    const position = "middle";
    const duration = 3000;

    // TODO: improve and use static translations
    switch (statusCode) {
      case 200:
        message = translations.statusCodeMessages[200];
        break;

      case 500:
        message = translations.statusCodeMessages[500];
        break;

      case 404:
        message = translations.statusCodeMessages[404];
        break;

      default:
        message = translations.statusCodeMessages[500];
        break;
    }
    presentToast(message, position, duration);
  };

  const sendVisitingShift = async (method: string, newHistory: History) => {
    try {
      // console.log(visitingShiftFormData);

      const data = {
        visitingConfigurationHistory: newHistory,
        visitingShift: visitingShiftFormData,
      };

      // console.log("SEND DATA", data);

      const config = {
        method: method,
        maxBodyLength: Infinity,
        url: API_URL_BASE + "visitingshift",
        data: data,
      };

      // console.log("SEND CONFIG", config);

      const response = await axios.request(config);

      if (response) {
        handleClose();
        presentResponseToast(response.status);
        await getVisitingShift();
      }
    } catch (error: any) {
      presentResponseToast(error.response.status);
    }
  };

  return {
    visitingShifts,
    loading,
    visitingShiftFormData,
    setVisitingShiftFormData,
    updateTranslations,
    addNewTranslation,
    updateVisitingShift,
    updateOpeningHours,
    sendVisitingShift,
    addNewOpeningHour,
    toggleIsActive,
    delNewOpeningHour,
    delTranslations,
    showHistoryAlert: createHistory,
    translations,
    handleClose,
    handleOpen,
    isOpen,
  };
};

import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { t } from "i18next";
import { serverOutline, statsChartOutline } from "ionicons/icons";
import React from "react";
import Header from "../../../components/Header";
import MNXOContent from "../../../components/MNXOContent";
import MNXOTitle from "../../../components/MNXOTitle";
import MNXODashboardCard from "../components/MNXODashboardCard";
import { useTranslation } from "react-i18next";

const MNXOHistoryPage: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <IonPage>
      <Header backButtonLink={"/dashboard/main"} />

      <MNXOContent 
      containerStyle="background-body-color"
      page={""}
      type={""}
      title={""}
      text={""}
      chipText={""}
      >
        <IonGrid>
          <IonRow className="dashboard-first-row">
            {/* <IonCol size="12">
              <MNXODashboardCard
                title={t("configuration_page.information_card.title")}
                icon={serverOutline}
                link={"/dashboard/configuration/information"}
              />
            </IonCol>
            <IonCol size="12" className="dashboard-col-card">
              <MNXODashboardCard
                title={t(
                  "configuration_page.visiting_configuration_card.title"
                )}
                icon={statsChartOutline}
                link={"/dashboard/configuration/visitingconfiguration"}
              />
            </IonCol> */}
          </IonRow>
        </IonGrid>
      </MNXOContent>
    </IonPage>
  );
};

export default MNXOHistoryPage;

import {
  leafOutline,
  fishOutline,
  trailSignOutline,
  businessOutline,
  restaurantOutline,
  sunnyOutline,
  navigateOutline,
} from "ionicons/icons";

//----------------- HOME PAGE ICONS -----------------//
export const getHomePageIcons = (t: any) => {
  const iconList = [
    { icon: leafOutline, label: t("home_page.categories.flora") },
    { icon: fishOutline, label: t("home_page.categories.fauna") },
    { icon: trailSignOutline, label: t("home_page.categories.trails") },
    {
      icon: navigateOutline,
      label: t("home_page.categories.points_of_interest"),
    },
    { icon: sunnyOutline, label: t("home_page.categories.beaches") },
  ];
  return iconList;
};

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";

// Author: DAMIAN!!!!!
interface Props {
  title: string;
  icon?: any;
  link: string;
  disabled?: boolean;
}
const MNXODashboardCard: React.FC<Props> = ({
  title,
  icon,
  link,
  disabled,
}) => {
  return (
    <IonCard className="dashboard-card" routerLink={link} disabled={disabled}>
      <IonCardContent>
        <IonGrid className="card-dashboard-content">
          <IonRow className="flex-center">
            {icon && (
              <IonCol size="12">
                <IonIcon className="dashboard-card-icons" icon={icon}></IonIcon>
              </IonCol>
            )}

            <IonCol size="12">
              <IonTitle>{title}</IonTitle>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default MNXODashboardCard;

import { useEffect, useState } from "react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Preferences } from "@capacitor/preferences";
import { methodOption, AxiosConfig } from "../models/AxiosConfig";
import { useToast } from "./useToast";
import { useLoading } from "./useLoading";
import { useAlert } from "./useAlert";
import { useTranslation } from "react-i18next";

const STORAGE_KEY = "naturalspaceuuid";

// ATHOR: JOEL
export const useFetchData = () => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);
  const { presentAlert } = useAlert();
  // const { presentLoading, dismissLoading } = useLoading()
  const [t, i18n] = useTranslation("global");

  const sendRequest = async (config: AxiosRequestConfig) => {
    const response = await axios.request(config);
    const result = response.data;
    setData(result);
    return response;
  };

  const retryApiCall = async (fetchOptions: any, errorResponseData: any) => {
    const { url, method, newData } = fetchOptions;
    const { validNaturalSpaceUUID } = errorResponseData;
    axios.defaults.headers.common["naturalspaceuuid"] = validNaturalSpaceUUID;
    Preferences.set({
      key: STORAGE_KEY,
      value: validNaturalSpaceUUID,
    });

    const config = new AxiosConfig(url, method, validNaturalSpaceUUID, newData);

    await sendRequest(config);
  };

  const alertData = {
    header: "Alert",
    message: "alert",
    // cssClass: "cancel-toast",
    buttons: [
      {
        text: t("button.confirm"),
        role: "confirm",
      },
    ],
  };

  const manageErrorResponse = async (
    errorResponse: AxiosResponse,
    fetchOptions: any
  ) => {
    const { status, data } = errorResponse;
    // console.log("ERROR", errorResponse);
    let toastMessage: string | null = null;

    switch (status) {
      case 500:
        alertData.header = t("alert.server_error");
        alertData.message = t("alert.server_error");
        presentAlert(alertData);
        break;
      case 404:
        alertData.header = t("alert.locator_not_found.header");
        alertData.message = t("alert.locator_not_found.content");
        presentAlert(alertData);
        break;
      case 422:
        await retryApiCall(fetchOptions, data);
        break;

      default:
        alertData.header = t("alert.default_error");
        alertData.message = t("alert.default_error");
        presentAlert(alertData);
        break;
    }
  };

  const fetchData = async (
    url: string,
    method: string = methodOption.READ,
    newData?: any
  ) => {
    try {
      setLoading(true);
      setError(null);

      const { value: naturalSpaceUUID } = await Preferences.get({
        key: STORAGE_KEY,
      });
      axios.defaults.headers.common["naturalspaceuuid"] = naturalSpaceUUID;
      const config = new AxiosConfig(url, method, naturalSpaceUUID!, newData);
      const response = await sendRequest(config);
      // console.log("RESPONSE", response);
      return response;
    } catch (error: any) {
      // console.log(error.response);
      const fetchOptions = {
        url: url,
        method: method,
        newData: newData,
      };

      await manageErrorResponse(error.response, fetchOptions);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchData };
};

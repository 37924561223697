import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import Header from "../components/Header";
import GallerySlider from "../components/MNXOGallerySlider";

const PhotoGallery: React.FC = () => {
  return (
    <IonPage>
      <Header name="Photo Gallery" isWhiteBackground={false}/>

      <IonContent className="ion-padding">
        <h1>Photo Gallery</h1>
        <GallerySlider/>
      </IonContent>
    </IonPage>
  );
};

export default PhotoGallery;
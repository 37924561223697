import { useTranslation } from "react-i18next";
import { getChipStatuses } from "../theme/translations/chips/chipStatuses";
import { getLegalIdTypeOptions } from "../mockups/legalIdTypeOptions";
import { useEffect, useState } from "react";
import { useFetchData } from "./useFetchData";
import { getErrorMessages } from "../theme/translations/errorMessages";
import { getCurrentDate } from "../theme/translations/currentDate";
import { getVisitingConfigurationPageTranslations } from "../useCases/dashboard/translations/VisitingConfiguration.page";
import { getVisitingShiftPageTranslations } from "../useCases/dashboard/translations/VisitingShift.page";

export const useAppTranslations = (infoTranslationAlias?: string) => {
  const [t, i18n] = useTranslation("global");
  const API_URL = process.env.REACT_APP_BACKEND_URL_BASE;

  // Static translations
  const currentLanguage = i18n.language.substring(3, 5) || "EN";
  const currentDateMessage = getCurrentDate(t);
  const chipStatuses = getChipStatuses(t);
  const legalIdTypeOptions = getLegalIdTypeOptions(t);
  const errorMessages = getErrorMessages(t);
  const visitingConfigurationPageTranslations =
    getVisitingConfigurationPageTranslations(t);
  const visitingShiftPageTranslations = getVisitingShiftPageTranslations(t);

  // Dynamic translations
  const {
    data: infoTranslations,
    error,
    loading,
    fetchData: fetchInfoTranslations,
  } = useFetchData();

  const [infoTranslation, setInfoTranslation] = useState<any | null>(null);

  useEffect(() => {
    const setDefaultLanguage = async () => {
      // Idiomas permitidos en nuestra app.
      const allowedLanguages = ["es-ES", "en-EN"];
      // Cambiar al idioma por defecto en caso de error.
      if (!allowedLanguages.includes(i18n.language)) {
        await i18n.changeLanguage("en-EN");
      }
    };
    setDefaultLanguage();
    // Cambiar al detectar el idioma del navegador.
  }, [i18n.language]);

  useEffect(() => {
    const fetchData = async () => {
      if (infoTranslationAlias) {
        await fetchInfo(infoTranslationAlias);
      }
    };
    fetchData();

    // TODO: optimizar para reducir las llamadas
  }, [infoTranslations, currentLanguage, infoTranslation]);

  const fetchInfo = async (infoTranslationAlias: string) => {
    if (infoTranslations) {
      if (!Array.isArray(infoTranslations)) {
        // console.log("TRANSLATIONS", infoTranslations);
        // console.log("CURRENT LANGUAGE", currentLanguage);
        // console.log("CURRENT LANGUAGE I18N", i18n.language);
        // i18n.language &&
        //   // console.log("CURRENT LANGUAGE I18N LOADED", i18n.language);

        const currentTranslation = await infoTranslations.translations.find(
          (translation: any) =>
            translation.language === currentLanguage.toUpperCase()
        );

        if (currentTranslation) {
          // console.log("CURRENT TRANSLATION", currentTranslation);
          setInfoTranslation(currentTranslation);
        }
      } else {
        // console.log("TRANSLATIONS", infoTranslations);
        setInfoTranslation(infoTranslations);
      }
    } else if (!infoTranslations) {
      await getInformationTranslations(infoTranslationAlias);
    }
  };

  const getJSONTranslation = (value: string) => {
    try {
      const translation = t(value);
      return translation;
    } catch (error) {}
  };

  const getInformationTranslations = async (alias: string) => {
    const urlBase = `${API_URL}information/${alias}`;
    // console.log("TRANSLATION URL", urlBase);

    await fetchInfoTranslations(urlBase);
  };

  return {
    chipStatuses,
    translation: t,
    legalIdTypeOptions,
    currentLanguage,
    infoTranslation,
    errorMessages,
    currentDateMessage,
    visitingConfigurationPageTranslations,
    visitingShiftPageTranslations,
    getJSONTranslation,
  };
};

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonNote,
  IonRow,
  IonText,
  IonTitle,
} from "@ionic/react";
import React from "react";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import MNXOChip from "../../../components/MNXOChip";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";
import MNXOHighlightDate from "./MNXOHighlightDate";
import { Navigation, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import "swiper/css";
interface ContainerProps {
  handleApplicationStatus?: any;
  applicationData: any;
  agentValidation?: boolean;
  setCurrentElement?: any;
  currentElement?: any;
  currentPage?: any;
  setCurrentPage?: any;
  swiperRef?: any;
  visitingShifts?: any;
  translations?: any;
}

const MNXOAuthCard: React.FC<ContainerProps> = ({
  applicationData,
  agentValidation,
  setCurrentElement,
  currentElement,
  currentPage,
  setCurrentPage,
  swiperRef,
  visitingShifts,
  translations,
}) => {
  const [t, i18n] = useTranslation("global");
  const history = useHistory();
  const { visitingDate, visitingShift, visitors } = applicationData;

  const { currentLanguage, chipStatuses } = useAppTranslations();

  const cardsPerPage = 1;
  // translations && // console.log("TRANSLATIONS", translations);
  // visitingShift && // console.log("VISITINGSHIFTS", visitingShifts);
  // visitors && // console.log("VISITORS", visitors);
  // applicationData && // console.log("APPLICATIONDATA", applicationData);

  const opensHour = visitingShift.openingHour.opens;
  const closesHour = visitingShift.openingHour.closes;
  const formatedOpensHour = opensHour.substring(0, opensHour.length - 3);
  const formatedClosesHour = closesHour.substring(0, closesHour.length - 3);
  const totalElements = visitors.length;
  const visibleVisitor = visitors[currentElement];

  const currentVisitorTypeTranslation = (visitorTypeUUID: string) => {
    const currentVisitorType = visitorTypeUUID;

    // console.log("currentVisitorType", currentVisitorType);

    const visitorType = translations.visitorTypes.find(
      (visitorType: any) => visitorType.uuid === currentVisitorType
    );

    // console.log("visitorType", visitorType);

    const visitorTypeTranslation = visitorType.translations.find(
      (translations: any) =>
        translations.language === currentLanguage.toUpperCase()
    );

    // console.log("visitorTypeTranslation", visitorTypeTranslation);
    return visitorTypeTranslation.name;
  };

  const currentVisitingShift = () => {
    const selectedVisitingShift = visitingShift.uuid;

    const currentVisitingShift = translations.visitingShifts.find(
      (visitingShift: any) => visitingShift.uuid === selectedVisitingShift
    );

    const visitingShiftTranslation = currentVisitingShift.translations.find(
      (translation: any, index: number) =>
        translation.language === currentLanguage.toUpperCase()
    );

    return visitingShiftTranslation;
  };

  //TODO: no usar window.reload
  const backButton = () => {
    window.location.reload();
  };
  // visitingShifts && // console.log(visitingShifts);

  return (
    <div style={agentValidation ? { marginTop: "32px" } : {}}>
      <MNXODescriptiveText
        text={t("descriptive_text.auth_view.text")}
        subtext={t("descriptive_text.auth_view.subtext")}
      />
      <IonTitle
        className="application-card-title"
        color={"lobos-text-grey"}
      ></IonTitle>
      {visibleVisitor && applicationData && (
        <div className="authorization-card-container">
          <IonRow className="ion-justify-content-center">
            <IonCol sizeXs="10">
              <IonCard key={visibleVisitor} className="authorization-card">
                <IonCardHeader
                  className=" ion-padding authorization-card-header"
                  color={"lobos-secondary"}
                >
                  <IonRow className="ion-align-items-center">
                    <IonCol size="3" className="ion-text-center">
                      <MNXOHighlightDate
                        date={applicationData}
                        color="lobos-primary"
                      />
                    </IonCol>
                    <div className="divisory-line primary"></div>
                    <IonCol sizeXs="5" className="shift-authorization-card">
                      <IonText>
                        <p className="shift-authorization-card-header ">
                          {currentVisitingShift().name}
                        </p>
                      </IonText>
                      <IonNote className="openingHours-authorization-card-header">
                        {t("app_data.from")} {formatedOpensHour}{" "}
                        {t("app_data.to")} {formatedClosesHour}
                      </IonNote>
                    </IonCol>
                  </IonRow>
                </IonCardHeader>
                <IonCardContent className="authorization">
                  <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    slidesOffsetBefore={0}
                    onSlideChange={async (slide) => {
                      setCurrentElement(slide.realIndex);
                    }}
                    modules={[Navigation, Pagination]}
                    navigation
                    pagination={{ clickable: true }}
                    ref={swiperRef}
                  >
                    {applicationData.visitors?.map(
                      (visitor: any, index: number) => {
                        const createdStatus = {
                          CREATED: "CREATED",
                        };
                        // console.log(t("app_data.chip_statuses.created"));
                        return (
                          <SwiperSlide key={index}>
                            <IonGrid fixed>
                              <IonRow>
                                <IonCol
                                  sizeXs="12"
                                  sizeXl="12"
                                  className="ion-no-padding"
                                >
                                  {visitor.authorization ? (
                                    <>
                                      <IonItemGroup style={{ height: "400px" }}>
                                        <IonItem
                                          lines="none"
                                          className="authorization"
                                        >
                                          <IonTitle
                                            className="custom"
                                            color={"lobos-primary"}
                                          >
                                            {
                                              // transform the first letter into upper case
                                              visitor.name
                                                .charAt(0)
                                                .toUpperCase() +
                                                visitor.name.slice(1)
                                            }
                                          </IonTitle>
                                        </IonItem>
                                        <IonItem lines="none">
                                          <IonLabel color="lobos-text-black">
                                            {visitor.isPrincipal && (
                                              <>{t("label.main")}: </>
                                            )}

                                            <IonText
                                              color="lobos-text-grey"
                                              className="visitor-type-text"
                                            >
                                              {currentVisitorTypeTranslation(
                                                visitor.visitorType.uuid
                                              )}
                                            </IonText>
                                          </IonLabel>
                                          {visitor.authorization
                                            .authorizationStatus !==
                                            createdStatus.CREATED && (
                                            <MNXOChip
                                              text={
                                                visitor.authorization
                                                  .authorizationStatus
                                              }
                                              translations={translations}
                                            />
                                          )}
                                        </IonItem>

                                        <IonRow>
                                          <IonCol size="8" offset="2">
                                            <div
                                              className="codeQR"
                                              style={{
                                                height: "auto",
                                                margin: "auto",
                                                maxWidth: 280,
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <QRCode
                                                size={200}
                                                style={{
                                                  height: "auto",
                                                  maxWidth: "100%",
                                                }}
                                                value={
                                                  visitor.authorization.uuid
                                                }
                                                viewBox={`0 0 256 256`}
                                              />
                                            </div>
                                          </IonCol>
                                        </IonRow>
                                        <IonRow className="flex-center ">
                                          <IonCol sizeXs="12" sizeXl="8">
                                            <p className="text-black ">
                                              {visitor.authorization.uuid}
                                            </p>
                                          </IonCol>
                                        </IonRow>
                                        {agentValidation && (
                                          <IonRow
                                            className="flex-center"
                                            style={{ marginTop: "16px" }}
                                          >
                                            <IonCol sizeXs="12" sizeXl="8">
                                              <IonButton onClick={backButton}>
                                                {t("button.back")}
                                              </IonButton>
                                            </IonCol>
                                          </IonRow>
                                        )}
                                      </IonItemGroup>
                                    </>
                                  ) : (
                                    <>
                                      <IonRow>
                                        <IonCol>
                                          <IonItem
                                            lines="none"
                                            className="authorization"
                                          >
                                            <IonTitle color={"lobos-primary"}>
                                              {visitor.name}
                                            </IonTitle>
                                          </IonItem>
                                          <IonItem lines="none">
                                            <IonLabel color="lobos-text-black">
                                              <>
                                                {visitor.isPrincipal &&
                                                  t("label.main")}
                                              </>

                                              <IonText
                                                color="lobos-text-grey"
                                                className="visitor-type-text"
                                              >
                                                <>
                                                  {currentVisitorTypeTranslation(
                                                    visitor.visitorType.uuid
                                                  )}
                                                </>
                                              </IonText>
                                            </IonLabel>
                                          </IonItem>
                                          <IonText className="ion-text-center">
                                            <p>{t("text.not_auth")}</p>
                                          </IonText>
                                        </IonCol>
                                      </IonRow>
                                    </>
                                  )}
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </SwiperSlide>
                        );
                      }
                    )}
                  </Swiper>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </div>
      )}
    </div>
  );
};

export default MNXOAuthCard;

import { alertCircleOutline } from "ionicons/icons";

const path = "app_data.routes.admin.";

export const getAdminRoutes = (t: any) => {
  const adminRoutes = [
    {
      title: t(path + "information.title"),
      url: t(path + "information.url"),
      icon: alertCircleOutline,
    },
  ];
  return adminRoutes;
};

import { checkmarkOutline, alertCircleOutline } from "ionicons/icons";

const path = "app_data.routes.agent.";

export const getAgentRoutes = (t: any) => {
  const agentRoutes = [
    {
      title: t(path + "incident.title"),
      url: t(path + "incident.url"),
      icon: alertCircleOutline,
    },
    {
      title: t(path + "incident-list.title"),
      url: t(path + "incident-list.url"),
      icon: alertCircleOutline,
    },
    {
      title: t(path + "validate.title"),
      url: t(path + "validate.url"),
      icon: checkmarkOutline,
    },
    {
      title: t(path + "manual-validation.title"),
      url: t(path + "manual-validation.url"),
      icon: checkmarkOutline,
    },
  ];
  return agentRoutes;
};

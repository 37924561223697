import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { OpeningHours, VisitingShift } from "../../../models/VisitingShift";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  openingHoursData: OpeningHours;
  sendOpeningHours: (method?: string) => void;
  updateOpeningHours: (inputData: any) => void;
  handleClose: any;
  translations?: any;
}

// ATHOR: JOEL
const MNXOOpeningHoursForm: React.FC<Props> = ({
  isOpen,
  openingHoursData,
  sendOpeningHours,
  updateOpeningHours,
  handleClose,
}) => {
  const { dayOfWeek, opens, closes, validFrom, validThrough } =
    openingHoursData;
    const [t, i18n] = useTranslation("global");
  return (
    <IonModal isOpen={isOpen} onDidDismiss={handleClose}>
      
      <form>
        <IonInput
          type="text"
          name="dayOfWeek"
          label={t("pages.edit_visiting_shift.form_inputs.dayOfWeek")}
          labelPlacement="stacked"
          fill="outline"
          value={dayOfWeek}
          onInput={(e) => updateOpeningHours(e.target)}
          required
        />
        <IonInput
          type="text"
          name="opens"
          label={t("pages.edit_visiting_shift.form_inputs.opens")}
          labelPlacement="stacked"
          fill="outline"
          value={opens}
          onInput={(e) => updateOpeningHours(e.target)}
          required
        />
        <IonInput
          type="text"
          label={t("pages.edit_visiting_shift.form_inputs.closes")}
          name="closes"
          labelPlacement="stacked"
          fill="outline"
          value={closes}
          onInput={(e) => updateOpeningHours(e.target)}
          required
        />
        <IonInput
          type="date"
          name="validFrom"
          label={t("pages.edit_visiting_shift.form_inputs.validFrom")}
          labelPlacement="stacked"
          fill="outline"
          value={validFrom.toString()}
          onInput={(e) => updateOpeningHours(e.target)}
          required
        />
        <IonInput
          type="date"
          label={t("pages.edit_visiting_shift.form_inputs.validThrough")}
          name="validThrough"
          labelPlacement="stacked"
          fill="outline"
          value={validThrough?.toString()}
          onInput={(e) => updateOpeningHours(e.target)}
          required
        />

        <IonButton
          onClick={() => {
            sendOpeningHours("put");
            handleClose();
          }}
        >
         {t("buttons.save")}
        </IonButton>
      </form>
    </IonModal>
  );
};

export default MNXOOpeningHoursForm;

import { Visitor } from "./Visitor";
import { Applicant } from "./Applicant";

export class Application {
  locator?: string;
  visitingDate: string;
  applicationUUID?: string;
  email: string;
  applicationStatus?: string;
  visitingShift: string;
  visitors: Visitor[] | Applicant[];

  constructor() {
    this.locator = "";
    this.visitingDate = "";
    this.applicationUUID = "";
    this.email = "";
    this.applicationStatus = "";
    this.visitingShift = "";
    this.visitors = [];
  }
}

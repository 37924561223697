import React from "react";

interface Props {
  text: any;
  highlight: any;
}

const HighlightedText: React.FC<Props> = ({ text, highlight }) => {
  let parts: any;
  if (text) {
    parts = text.split(new RegExp(`(${highlight})`, "gi"));
  }

  return (
    <span>
      {text &&
        parts.map((part: any, index: any) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <mark key={index} style={{ backgroundColor: "green" }}>
              {part}
            </mark>
          ) : (
            part
          )
        )}
    </span>
  );
};

export default HighlightedText;

import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import "../theme/general.scss";

import { useNaturalSpace } from "../hooks/useNaturalSpace";
import { t } from "i18next";
import MNXOFooter from "../components/MNXOFooter";
import ApplicationModal from "../useCases/application/components/ApplicationModal";
import CustomCalendar from "../useCases/application/components/CustomCalendar";
import ShiftSelector from "../useCases/application/components/ShiftSelector";
import VisitorCounter from "../useCases/application/components/VisitorCounter";
import { useApplication } from "../useCases/application/hooks/useApplication";
import MNXOButton from "../components/MNXOButton";
import MNXOHeaderImage from "../components/MNXOHeaderImage";
import MNXOHeaderWithSticker from "../components/MNXOHeader";
import AuthCardTest from "../useCases/application/components/MNXOAuthCard";
import MNXOPaginationBullets from "../components/MNXOPaginationBullets";
import MNXODescriptiveText from "../components/MNXODescriptiveText";
import MNXOSticker from "../components/MNXOSticker";
import { useApplicationsList } from "../useCases/application/hooks/useApplicationsList";
import { useStorage } from "../hooks/useStorage";

const MNXOComponentPage: React.FC = () => {
  const { naturalSpaceUUID } = useStorage("naturalspace");
  const { loading, visitingShifts, visitorTypes } = useNaturalSpace(
    "components",
    naturalSpaceUUID
  );

  const {
    sendData,
    handleDate,
    handleShift,
    applicationData,
    lockNewApplication,
    showComponent,
    removeVisitorsStorage,
    addVisitor,
    removeVisitor,
    setIsOpenToast,
    timer,
    isOpenToast,
    updateVisitorData,
  } = useApplication();

  useEffect(() => {
    if (visitingShifts && !applicationData.visitingDate) {
      const firstAvailableVisitingShift = visitingShifts!.filter(
        (visitingShift: any) => visitingShift.visitingShifts.length
      );
      handleDate(firstAvailableVisitingShift[0].date);
    }
  }, [visitingShifts]);

  const { applications } = useApplicationsList();

  const [respuesta, setRespuesta] = useState({
    mensaje: "",
  });

  const [showAlert, setShowAlert] = useState(false);

  //hook para abrir modal de confirmación
  const [isOpen, setIsOpen] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState();

  // SHOW
  const [message, setMessage] = useState(false);

  const [showHeaders, setShowHeaders] = useState(true);
  const hideHeaders = async () => {
    if (showHeaders) {
      setShowHeaders(false);
    } else {
      setShowHeaders(true);
    }
  };

  //PAGINATION BULLETS
  const [currentElement, setCurrentElement] = useState(0);
  const ElementsPerPage = 1;
  const totalElements = 3;
  const handleNextElement = () => {
    if (currentElement < 3 - 1) {
      setCurrentElement(currentElement + 1);
    }
  };

  const handlePreviousElement = () => {
    if (currentElement > 0) {
      setCurrentElement(currentElement - 1);
    }
  };

  return (
    <IonPage>
      {showHeaders && (
        <>
          <MNXOHeaderImage />
          <div>
            {" "}
            <Header name={"Navigation"} isWhiteBackground={false}></Header>
          </div>

          <MNXOHeaderWithSticker
            text="Verification Authorizations"
            title="Verification Authorizations"
            textChip="ENTER CODE"
          />
        </>
      )}
      <IonButton onClick={hideHeaders}>Hide Headers</IonButton>

      <IonContent className="container-page">
        {" "}
        {/*className="content-padding"*/}
        <br />
        <IonGrid>
          <p>Grid</p>
          <br />
          <h1>Buttons</h1>
          <br />
          <IonRow className="flex-center margin-bottom">
            <IonCol size="6">
              <MNXOButton typeOfButton="send"></MNXOButton>
            </IonCol>
            <IonCol size="6">
              <MNXOButton typeOfButton="disable"></MNXOButton>
            </IonCol>
          </IonRow>
          <IonRow className="flex-center">
            <IonCol size="6">
              <MNXOButton typeOfButton="next"></MNXOButton>
            </IonCol>
            <IonCol size="6">
              <MNXOButton typeOfButton="next"></MNXOButton>
            </IonCol>
          </IonRow>
          <br />
          <br />

          <IonRow>
            <IonCol>
              <IonText>Busqueda por filtro</IonText>
              <br />
              <IonInput
                type="text"
                placeholder="Filtro de búsqueda..."
                value=""
              />
              <br></br>
            </IonCol>
          </IonRow>

          {/* <IonRow>
            <IonCol>
              {" "}
              <br />
              <h1>CARD</h1>
              <br />
              <MNXOApplicationCard
                onCardClick={function (application: any): void {
                  throw new Error("Function not implemented.");
                }}
              />
            </IonCol>
          </IonRow>
          <br />
          <IonRow>
            <IonCol>
              {" "}
              <br />
              <h1>EMPTY CARD</h1>
              <br />
              <MNXOApplicationCard
                empty={true}
                onCardClick={function (application: any): void {
                  throw new Error("Function not implemented.");
                }}
              />
            </IonCol>
          </IonRow> */}
          <br />
        </IonGrid>
        <br />
        <h1>Sticker</h1>
        <MNXOSticker text={"STICKER"} />
        <br />
        <br />
        <div className="component-page-titles">
          <h1>h1</h1>
          <h2>h2</h2>
          <h3>h3</h3>
          <h4>h4</h4>
          <h5>h5</h5>
          <h6>h6</h6>
        </div>
        <br />
        <br />
        {visitingShifts && visitorTypes && (
          <>
            <IonGrid>
              <IonRow className="calendar-container">
                <IonCol className="calendar-content-container">
                  <h1>Calendar</h1>
                  <CustomCalendar
                    availableDays={visitingShifts}
                    selectedDate={applicationData.visitingDate}
                    setDate={handleDate}
                  />
                </IonCol>
              </IonRow>
              <br />

              <IonRow className="visiting-shifts-container ">
                {/* <h1>Shift Selector</h1> */}

                <IonCol>
                  <h3 className="text-black">
                    {t("application.visiting_shifts.title")}
                  </h3>
                  <br />

                  <IonRow className="ion-align-items-center ion-text-center">
                    <IonCol sizeXs="4" className="selected-date">
                      <IonLabel>
                        <p>{t("application.visiting_shifts.date")}</p>
                      </IonLabel>
                      <IonLabel>
                        <p>
                          {new Date(applicationData.visitingDate).getDate() ||
                            new Date(visitingShifts[0].date).getDate()}
                        </p>
                      </IonLabel>
                      <IonLabel>
                        <p>
                          {applicationData.visitingDate
                            ? new Date(applicationData.visitingDate)
                                .toDateString()
                                .substring(3, 7)
                            : new Date(visitingShifts[0].date)
                                .toDateString()
                                .substring(3, 7)}
                        </p>
                      </IonLabel>
                    </IonCol>

                    <IonCol sizeXs="8" className="shift-selector">
                      <ShiftSelector
                        visitingShifts={visitingShifts}
                        selectedDate={applicationData.visitingDate}
                        selectedShift={applicationData.visitingShift}
                        onShiftSelected={handleShift}
                      />
                    </IonCol>
                  </IonRow>
                  <br />
                </IonCol>
              </IonRow>
              <h1>Visitor Counter</h1>

              <IonRow className="visitor-counter-container ">
                <IonCol>
                  <VisitorCounter
                    visitorTypes={visitorTypes}
                    visitors={applicationData.visitors}
                    selectedDate={applicationData.visitingDate}
                    selectedShift={applicationData.visitingShift}
                    removeVisitor={removeVisitor}
                    addVisitor={addVisitor}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            <br />

            <h1>Visitor modal</h1>

            <ApplicationModal
              isOpen={isOpen}
              setShowAlert={setShowAlert}
              handleClose={() => setIsOpen(false)}
              applicationData={applicationData}
              sendApplication={sendData}
              handleApplicationStatus={setApplicationStatus}
              toConfirm={true}
            />

            {/* {showComponent.visitorCard &&
              applicationData.visitors.map((visitor: any, index: number) => (
                <VisitorCard
                setSameCountryForAll={}
                  key={index}
                  order={index}
                  setFormData={updateVisitorData}
                  visitorData={visitor}
                  visitorTypes={visitorTypes}
                />
              ))} */}

            {message && (
              <>
                <p>{respuesta.mensaje}</p>
              </>
            )}

            <IonButton
              onClick={() => setIsOpen(true)}
              type="submit"
              className="ion-margin-top"
              shape="round"
            >
              Send
            </IonButton>

            <IonToast
              isOpen={isOpenToast}
              message={`Tiene ${timer} minutos para realizar su solicitud`}
              duration={1000}
              onDidDismiss={() => setIsOpenToast(false)}
            />

            <IonAlert
              isOpen={showAlert}
              cssClass="custom-class"
              header="solicitud creada"
              subHeader={"Status " + applicationStatus}
              buttons={[
                {
                  text: "Crear nueva solicitud",
                  role: "new",
                  handler: () => {
                    window.location.reload();
                  },
                },
                {
                  text: "Aceptar",
                  role: "confirm",
                  handler: async () => {
                    await removeVisitorsStorage();
                  },
                },
              ]}
              onDidDismiss={() => setIsOpen(false)}
            />
          </>
        )}
        <br></br>
        <h1>VALIDATION</h1>
        <MNXODescriptiveText
          text="Enter the 6 digits code"
          subtext="to valide an authorization"
        />
        <IonGrid>
          <IonRow className="calendar-content-container">
            <IonCol size="2">col 2</IonCol>
            <IonCol size="2">col 2</IonCol>
            <IonCol size="2">col 2</IonCol>
            <IonCol size="2">col 2</IonCol>
            <IonCol size="2">col 2</IonCol>
            <IonCol size="2">col 2</IonCol>
          </IonRow>
          <br></br>
          <h1>AUTHORIZATION CARD</h1>

          {applications && <AuthCardTest applicationData={applications[0]} />}
        </IonGrid>
        <br />
        <h1>Pagination Bullets</h1>
        <MNXOPaginationBullets
          currentElement={currentElement}
          totalElement={totalElements}
          onNextView={handleNextElement}
          onPreviusView={handlePreviousElement}
        />
        <br />
      </IonContent>

      <MNXOFooter />
    </IonPage>
  );
};

export default MNXOComponentPage;

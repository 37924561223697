import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
} from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import MNXOHeader from "../../../components/MNXOHeader";
const IMAGES_URL = process.env.REACT_APP_IMAGES_URL_BASE;

interface ContainerProps {
  isOpen: boolean;
  handleClose: () => void;
  incidentData: any;
  sendIncidentData?: any;
  setShowAlert?: any;
  toConfirm: boolean;
  className: string;
}

const IncidentModalUUID: React.FC<ContainerProps> = ({
  isOpen,
  handleClose,
  incidentData,
  setShowAlert,
  toConfirm,
  sendIncidentData,
  className,
}) => {
  // console.log("INCIDENT DATA MODAL", incidentData);
  const [t, i18n] = useTranslation("global");

  //TODO:usar los valores de incidentFormData, en vez de formData.
  async function confirm() {
    const response = await sendIncidentData();

    if (response.status === 200) {
      // console.log(response.data);
      handleClose();
      setShowAlert(true);
    } else {
      // console.log("ERROR");
    }
  }
  return (
    <IonModal isOpen={isOpen} onDidDismiss={handleClose}>
      <MNXOHeader
        text={t("pages.incident_data.title")}
        type="modal"
        title={""}
        handleClose={handleClose}
      />
      <IonContent className="ion-padding background-body-color">
        <form>
          <IonLabel className="incident-title-section">
            <h2> {t("pages.incident_list.incident_modal.agents")}</h2>
          </IonLabel>
          {incidentData?.agents.map((agent: any, index: number) => {
            return (
              <IonItem lines="none" key={index}>
                <IonLabel>{agent.firstName}</IonLabel>
              </IonItem>
            );
          })}
          <IonLabel className="incident-title-section">
            <h2>{t("pages.incident_list.incident_modal.data")}</h2>
          </IonLabel>
          <IonItem lines="none">
            <IonLabel>{t("pages.incident_list.code")}</IonLabel>
            <IonText>{incidentData?.incidentCode}</IonText>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{t("pages.create_incident.form_inputs.type")}</IonLabel>
            <IonText>{incidentData?.incidentType.name}</IonText>
          </IonItem>
          <IonItem lines="none">
            <IonGrid>
              <IonRow style={{ marginBottom: "16px" }}>
                <IonCol>
                  <IonLabel>
                    {t("pages.create_incident.form_inputs.description")}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>{incidentData?.incidentType.description} </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{t("pages.create_incident.form_inputs.date")}</IonLabel>
            <IonText>{incidentData?.date}</IonText>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>
              {t("pages.create_incident.form_inputs.description")}
            </IonLabel>
            <IonText>{incidentData?.description}</IonText>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>
              {t("pages.create_incident.form_inputs.observations")}
            </IonLabel>
            <IonText>{incidentData?.observations}</IonText>
          </IonItem>

          <IonItem lines="none">
            <IonLabel>{t("pages.create_incident.state")}</IonLabel>
            <IonText>{incidentData?.incidentStatus}</IonText>
          </IonItem>
          {incidentData.photos.map((photo: any, index: number) => {
            // console.log(photo.name);
            return (
              <img key={index} src={IMAGES_URL + photo.name} alt={photo.alt} />
            );
          })}
          <IonLabel className=" incident-title-section">
            <h2>{t("pages.incident_list.incident_modal.location")}</h2>
          </IonLabel>
          <IonItem lines="none">
            <IonLabel>
              {t("pages.create_incident.form_inputs.address")}
            </IonLabel>
            <IonText>{incidentData?.location.address}</IonText>
          </IonItem>
          {/* <IonItem>
            <IonLabel>Localidad</IonLabel>
            <IonText>{incidentData?.location.locality}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>Latitud</IonLabel>
            <IonText>{incidentData?.location.latitude}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>Longitud</IonLabel>
            <IonText>{incidentData?.location.longitude}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>Municipalidad</IonLabel>
            <IonText>{incidentData?.location.municipality}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>Código zip</IonLabel>
            <IonText>{incidentData?.location.zipCode}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>Zona</IonLabel>
            <IonText>{incidentData?.location.zone}</IonText>
          </IonItem> */}

          {incidentData?.photo && (
            <IonLabel>
              <h2>{t("pages.incident_list.incident_modal.photo")}</h2>
            </IonLabel>
          )}
        </form>
        <IonItem lines="none">
          {toConfirm && (
            <>
              <IonButton size="default" slot="end" onClick={handleClose}>
                {t("button.cancel")}
              </IonButton>
              <IonButton
                size="default"
                slot="end"
                strong={true}
                onClick={confirm}
              >
                {t("button.confirm")}
              </IonButton>
            </>
          )}
        </IonItem>
      </IonContent>
    </IonModal>
  );
};

export default IncidentModalUUID;

import {
  IonAccordion,
  IonAccordionGroup,
  IonCol,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import React, { useEffect } from "react";
import Header from "../../../components/Header";
import MNXOFooter from "../../../components/MNXOFooter";
import { useFAQ } from "../hooks/useFAQ";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../../hooks/useLoading";
import MNXOContactButton from "../../../components/buttons/ContactButton";

const MNXOFAQPage: React.FC = () => {
  const { faqTranslation: faqs, allFaqs } = useFAQ("all");

  // Loading

  const { presentLoading, dismissLoading } = useLoading();
  const isLoading = !faqs;

  useEffect(() => {
    isLoading ? presentLoading() : dismissLoading();
  }, [isLoading]);

  // console.log(faqs);
  const [t, i18n] = useTranslation("global");
  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });
  // const faqs = [
  //   {
  //     question: "How can I obtain an authorization to visit the natural park?",
  //     answer:
  //       "To obtain an authorization to visit the natural park, you need to complete the application form in our app. Once the application is received, our team will review it and contact you with further information.",
  //   },
  //   {
  //     question: "How long does it take to process an authorization request?",
  //     answer:
  //       "The processing time for authorization requests may vary. Our goal is to review and respond to requests within 3-5 business days. However, please note that during high-demand periods, the response time may be longer.",
  //   },
  //   {
  //     question: "Can I modify or cancel an existing authorization?",
  //     answer:
  //       "Yes, you can modify or cancel an existing authorization. Please contact our support team and provide the details of your authorization so that we can assist you with the modification or cancellation process.",
  //   },
  // ];

  return (
    <IonPage>
      <Header name={faqs ? faqs.title : t("faqs-page.header.title")} />
      <IonContent className="faqs-container">
        <IonAccordionGroup className="faqs-content ion-padding">
          <h1 className="text-blue faqs-title">{t("faqs-page.title_page")}</h1>
          {faqs &&
            faqs.map((faq: any, index: any) => (
              <IonAccordion key={index}>
                <IonItem
                  lines="none"
                  slot="header"
                  key={index}
                  className="text-blue icon-inside-item"
                >
                  {/* <IonIcon id="icons" icon={informationCircleOutline} /> */}
                  <IonLabel id="faq-label">{faq.translation.question}</IonLabel>
                </IonItem>
                <div
                  className="text-grey faq-answer"
                  slot="content"
                  id="text"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(faq.translation.answer),
                  }}
                ></div>
              </IonAccordion>
            ))}
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <MNXOContactButton color="lobos-secondary" />
            </IonCol>
          </IonRow>
        </IonAccordionGroup>
      </IonContent>
      <MNXOFooter className="MNXOModalFooter" />
    </IonPage>
  );
};

export default MNXOFAQPage;

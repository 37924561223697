import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonLabel,
  IonNote,
  IonRow,
} from "@ionic/react";
// import "./Shift.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppTranslations } from "../../../hooks/useAppTranslations";

interface ChooseShiftProps {
  visitingShifts: any;
  selectedDate?: any;
  selectedShift?: any;
  onShiftSelected?: (e: string) => void;
  agentValidate?: boolean;
}
const ShiftSelector: React.FC<ChooseShiftProps> = ({
  visitingShifts,
  onShiftSelected,
  selectedDate,
  agentValidate,
  selectedShift,
}) => {
  const { currentLanguage } = useAppTranslations();
  const [t, i18n] = useTranslation("global");
  const [availableVisitingShifts, setAvailableVisitingShifts] = useState({
    date: "",
    visitingShifts: [],
  });

  const getCurrentVisitingShiftsbyDate = (
    date: string = visitingShifts[0].date
  ) => {
    return visitingShifts.filter(
      (visitingShifts: any) => visitingShifts.date === date
    );
  };

  useEffect(() => {
    const fetch = async () => {
      let currentVisitingShifts;
      if (!agentValidate) {
        if (selectedDate) {
          currentVisitingShifts = await getCurrentVisitingShiftsbyDate(
            selectedDate
          );
        } else {
          currentVisitingShifts = await getCurrentVisitingShiftsbyDate();
        }

        setAvailableVisitingShifts({
          ...availableVisitingShifts,
          date: currentVisitingShifts[0].date,
          visitingShifts: currentVisitingShifts[0].visitingShifts,
        });
      } else {
        //si son los shift selecor de agente:
        setAvailableVisitingShifts({
          ...availableVisitingShifts,
          date: visitingShifts.date,
          visitingShifts: visitingShifts.visitingShifts,
        });
      }
    };
    fetch();
  }, [selectedDate]);

  const visitingShiftAvailableCapacityIndicator = (visitingShift: any) => {
    let currentTime = new Date();

    const { maxCapacity, availableCapacity, opens } = visitingShift;

    if (availableCapacity >= maxCapacity * 0.5)
      return (
        <p className={`visiting-shift-capacity-text `}>
          {t("application.visiting_shifts.available_capacity.high")}
        </p>
      );
    if (availableCapacity < maxCapacity * 0.5 && availableCapacity > 20)
      return (
        <p className={`visiting-shift-capacity-text `}>
          {t("application.visiting_shifts.available_capacity.medium")}
        </p>
      );
    if (availableCapacity <= 20 && availableCapacity > 0) {
      let customText: string = t(
        "application.visiting_shifts.available_capacity.low"
      );
      return (
        <p className={`visiting-shift-capacity-text `}>
          {customText.replace("X", availableCapacity)}
        </p>
      );
    }

    if (availableCapacity <= 0)
      return (
        <p className={`visiting-shift-capacity-text `}>
          {t("application.visiting_shifts.available_capacity.empty")}
        </p>
      );
  };

  const handleSubmit = async (visitingShiftUUID: string) => {
    if (visitingShiftUUID !== selectedShift) {
      if (onShiftSelected) {
        onShiftSelected!(visitingShiftUUID);
      }
    }
  };

  const history = useHistory();
  const manualValidation = (visitingShiftUUID: string) => {
    history.push(
      `/dashboard/validate/manual/validation?date=${availableVisitingShifts.date}&visitingShiftUUID=${visitingShiftUUID}`
      // [availableVisitingShifts.date, visitingShiftUUID]
    );
    // history.push('/component-two',{params:'Hello World'})
  };

  return (
    <>
      {availableVisitingShifts && (
        <>
          {availableVisitingShifts?.visitingShifts.map(
            (visitingShift: any, index: number) => {
              const { availableCapacity, openingHours } = visitingShift;
              const { opens, closes } = openingHours[0];

              const languageType = visitingShift.translations.filter(
                (translation: any) =>
                  translation.language == currentLanguage.toUpperCase()
              );

              const language = languageType[0];

              return (
                <div key={index}>
                  <IonRow>
                    <IonCol
                      className="availability ion-text-center"
                      offset="2"
                      size="10"
                    >
                      <IonRow className="ion-align-items-center">
                        <IonCol className="availability-content">
                          {visitingShiftAvailableCapacityIndicator(
                            visitingShift
                          )}
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                  {/* <div className="availability">
                    <div>hola</div>I
                  </div> */}
                  <IonCard
                    key={index}
                    button
                    disabled={availableCapacity <= 0 && true}
                    onClick={() => handleSubmit(visitingShift.uuid)}
                    className={`ion-padding ${
                      selectedShift === visitingShift.uuid
                        ? "selected-visiting-shift"
                        : "application"
                    }`}
                  >
                    <IonCardHeader>
                      <IonCardTitle key={index}>
                        <IonRow>
                          <IonCol
                            size="12"
                            className="ion-text-start available-capacity title"
                          >
                            <IonLabel className="visiting-shift-time">
                              {language?.name && language.name}
                            </IonLabel>
                          </IonCol>
                          <IonCol
                            size="auto"
                            className="ion-text-end available-capacity"
                          ></IonCol>
                        </IonRow>
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content">
                      <IonGrid>
                        <IonRow>
                          <IonCol className="ion-text-start">
                            <IonNote>
                              {t("application.visiting_shifts.from")}:{" "}
                              {opens.substring(0, 5)} -{" "}
                              {t("application.visiting_shifts.to")}:{" "}
                              {closes.substring(0, 5)}
                            </IonNote>
                          </IonCol>
                        </IonRow>

                        {agentValidate && (
                          <IonButton
                            onClick={() => manualValidation(visitingShift.uuid)}
                          >
                            {t("button.manual_validate")}
                          </IonButton>
                        )}
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
                </div>
              );
            }
          )}
        </>
      )}
    </>
  );
};

export default ShiftSelector;

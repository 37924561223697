import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonModal,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MasonryGallery from "../components/MNXOMasonryGallery";
import MNXOIconCardInterest from "../components/MNXOIconCardInterest";
import { star, trailSignOutline } from "ionicons/icons";
import { useAppTranslations } from "../hooks/useAppTranslations";
interface Props {
  info: any;
  gallery: [];
  handleDismiss: any;
  showModal: any;
  setShowModal: any;
  modalRef: any;
  locationPath?: any;
  initializeBreakpoint: number;
}
const MNXOModalInterest: React.FC<Props> = ({
  info,
  gallery,
  handleDismiss,
  showModal,
  setShowModal,
  modalRef,
  locationPath,
  initializeBreakpoint,
}) => {
  const { currentLanguage } = useAppTranslations();
  const {
    alternateName,
    image,
    imageGallery,
    isActive,
    location,
    translations,
  } = info || {};

  // console.log(modalRef);
  const currentTranslations = translations?.find(
    (translation: any) => translation.language === currentLanguage.toUpperCase()
  );
  const [t, i18n] = useTranslation("global");
  const loadImage = (imageArray: any) => {
    imageArray.map((image: string) => {
      require(`../assets/slides/${image}`);
    });
    // console.log(imageArray);

    return imageArray;
  };

  const [selectedTab, setSelectedTab] = useState("first");

  const [showFullText, setShowFullText] = useState(false);
  const textLimit = 200; // Límite de caracteres para mostrar inicialmente

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
    // console.log(tab);
  };

  // const handleScroll = (e: any) => {
  //   // console.log(e);
  //   if (e.deltaY > 0) {
  //     setShowModal(true);
  //   } else {
  //     setShowModal(false);
  //   }
  // };

  return (
    <IonModal
      id="poi-modal"
      ref={modalRef}
      isOpen={showModal}
      className="modal-poi"
      initialBreakpoint={initializeBreakpoint}
      onDidDismiss={() => {
        handleDismiss();
      }}
      backdropBreakpoint={0.5}
      backdropDismiss={false}
      breakpoints={[0.25, 0.5, 0.75]}
      // backdropDismiss={false}
    >
      <IonHeader className="modal-info-header">
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol size="8">
              <h1>{currentTranslations?.name}</h1>
              <h3>
                <i>{currentTranslations?.subtitle}</i>
              </h3>
            </IonCol>
            <IonCol size="4" className="poi-score">
              <IonItem lines="none">
                <IonText>
                  <IonIcon icon={star}></IonIcon>
                </IonText>
                <IonText>
                  <p>4,7</p>
                </IonText>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonToolbar>
          <IonSegment
            value={selectedTab}
            onIonChange={(e: any) => handleTabChange(e.detail.value!)}
          >
            <IonSegmentButton
              value="first"
              className={`modaltest ${
                selectedTab === "first"
                  ? "modaltest_checked"
                  : "modaltest_no_checked"
              }`}
            >
              {t("modal_info.first_tab")}
            </IonSegmentButton>
            <IonSegmentButton
              value="second"
              className={`modaltest ${
                selectedTab === "second"
                  ? "modaltest_checked"
                  : "modaltest_no_checked"
              }`}
            >
              {t("modal_info.second_tab")}
            </IonSegmentButton>
            <IonSegmentButton
              value="third"
              className={`modaltest ${
                selectedTab === "third"
                  ? "modaltest_checked"
                  : "modaltest_no_checked"
              }`}
            >
              {t("modal_info.third_tab")}
            </IonSegmentButton>
            {/* <IonSegmentButton
              value="fourth"
              className={`modaltest ${
                selectedTab === "fourth"
                  ? "modaltest_checked"
                  : "modaltest_no_checked"
              }`}
            >
              {t("modal_info.fourth_tab")}
            </IonSegmentButton> */}
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent
        className={`ion-padding  info-text-modal
            `}
      >
        <IonGrid>
          {selectedTab === "first" && (
            <IonRow className="poi-tabs">
              <IonCol size="12">
                <p className="ion-padding">
                  {currentTranslations?.description?.length > textLimit &&
                  !showFullText
                    ? currentTranslations?.description.slice(0, textLimit) +
                      "..."
                    : currentTranslations?.description}
                  {currentTranslations?.description?.length > textLimit &&
                    !showFullText && (
                      <a onClick={() => setShowFullText(true)}>Leer más</a>
                    )}
                </p>
              </IonCol>
              {/* <IonCol>
            
                <div className="ion-padding">
                  <MNXOIconCardInterest
                    icon={trailSignOutline}
                    title="Título de ejemplo"
                    text="Este es un ejemplo de un texto."
                  />
                </div>
              </IonCol> */}
            </IonRow>
          )}
          {/* <div className="ion-padding">
          <MNXOIconCardInterest
            icon={trailSignOutline}
            title="Título de ejemplo"
            text="Este es un ejemplo de un texto."
          />
        </div> */}

          {selectedTab === "second" && (
            <IonRow>
              <IonCol className="home-page-masonry-container">
                <MasonryGallery
                  isModal={true}
                  images={gallery}
                  enableZoom={false}
                />
              </IonCol>
            </IonRow>
          )}
          {selectedTab === "third" && (
            <div className="poi-tabs">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20072.04267412303!2d-13.826046991736144!3d28.738926673596442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xc46337eb5976567%3A0x4b8f6a40d8d154b0!2sLas%20Lagunitas!5e0!3m2!1ses!2ses!4v1697632359885!5m2!1ses!2ses"
                width="100%"
                height="420"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                style={{ border: 0 }}
              ></iframe>
            </div>
          )}
          {selectedTab === "fourth" && (
            <div>
              <p>tab4 content</p>
            </div>
          )}
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default MNXOModalInterest;

const path = "errorMessages."

export const getErrorMessages = (t: any) => {
    const errorMessages =
        [
            { statusCode: 401, message: t(path + "401") },
            { statusCode: 404, message: t(path + "404") },
            { statusCode: 500, message: t(path + "500") },
        ]
    return errorMessages;
}
import { IonButton, IonCol, IonGrid, IonRow, useIonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useValidate } from "../hooks/useValidate";
import MNXOApplicationCard from "../../application/components/MNXOApplicationCard";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useLocation } from "react-router";
import { useCamera } from "../hooks/useCamera";
import { useTranslation } from "react-i18next";

interface Props {
  handleCardClick: any;
}

const MNXOQrReader: React.FC<Props> = ({ handleCardClick }) => {
  const { getAuthorizationByUUID } = useValidate();
  const [result, setResult] = useState<any>(null);
  const [readQr, setReadQr] = useState(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { setIsFrontCamera, isFrontCamera } = useCamera();
  const [t, i18n] = useTranslation("global");

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleCameraToggle = () => {
    setIsFrontCamera((prevIsFrontCamera) => !prevIsFrontCamera);
    setReadQr(false);

    setTimeout(() => {
      setReadQr(true);
    }, 3000);
  };

  const [present] = useIonToast();
  const scanQR = async (result: any) => {
    if (readQr) {
      try {
        setResult(null);
        const response = await getAuthorizationByUUID(result.toString());

        setResult(response.data);
        if (response.status == 200) {
          present({
            message: t("validation_page.scan.checked"),
            duration: 3000,
            position: "bottom",
            color: "success",
          });
        }
      } catch (error: any) {
        if (error.response.status == 400) {
          present({
            message: t("validation_page.scan.not_found"),
            duration: 3000,
            position: "bottom",
            color: "danger",
          });
        } else if (error.response.status == 500) {
          present({
            message: t("validation_page.scan.server_error"),
            duration: 3000,
            position: "bottom",
            color: "danger",
          });
        } else if (error.response.status == 402) {
          present({
            message: t("validation_page.scan.not_in_valid_state"),
            duration: 3000,
            position: "bottom",
            color: "danger",
          });
        }
        // Puedes agregar más detalles al mensaje de error según tus necesidades
      }
    }
  };

  const onClick = (application: any) => {
    handleCardClick(application);
  };

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol className="flex-center">
            <div className="qr-container">
              <div className="qr-video">
                <QrScanner
                  constraints={
                    isFrontCamera
                      ? { facingMode: "user" }
                      : { facingMode: "environment" }
                  }
                  onDecode={(result) => scanQR(result)}
                  onError={(error) => console.log(error?.message)}
                  scanDelay={3000}
                />
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="flex-center">
          <IonCol>
            <IonButton onClick={handleCameraToggle}>
              {t("validation_page.toggle_camara")}
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {result !== null && (
              <MNXOApplicationCard
                visitor={result.visitors[0]}
                application={result}
                agentValidation={true}
                onCardClick={onClick}
                handleOpen={handleOpen}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default MNXOQrReader;

import {
    useIonToast
} from "@ionic/react";

export const useToast = () => {
    const [present] = useIonToast();

    const presentToast = (message?: string, position?: "top" | "middle" | "bottom", duration?: number) => {
        present({
            message: message || "This is a toast message",
            duration: duration || 1500,
            position: position || "bottom",
        });
    };

    return { presentToast };
};


interface legalID {
  legalIdType: string;
  legalIdNum: string;
}

export class Visitor {
  // TODO: declare if these variables are public, protected or private
  isPrincipal: boolean = false;
  name: string;
  age: number;
  country: string;
  legalID: legalID;
  authorization?: string;
  visitorType?: string;

  constructor(visitorType?: string, newCountry?: string) {
    this.name = "";
    this.age = 0;
    this.country = newCountry || ""; // Establecer el país si se proporciona newCountry, de lo contrario, dejarlo vacío
    this.legalID = {
      legalIdType: "",
      legalIdNum: "",
    };
    this.authorization = "";
    this.visitorType = visitorType;
  }
}

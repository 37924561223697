import { IonContent, IonPage } from "@ionic/react";
import Header from "../../../components/Header";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import DOMPurify from "dompurify";
import MNXOFooter from "../../../components/MNXOFooter";
import { useLoading } from "../../../hooks/useLoading";
import MNXOContent from "../../../components/MNXOContent";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const Rules: React.FC = () => {
  const { infoTranslation: rulesOfUse } = useAppTranslations("terms_of_use");

  // Loading
  const { presentLoading, dismissLoading } = useLoading();
  const isLoading = !rulesOfUse;

  useEffect(() => {
    isLoading ? presentLoading() : dismissLoading();
  }, [isLoading]);

  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });
  rulesOfUse && rulesOfUse.content.replace(/<a /g, '<a target="_blank" ');
  const [t, i18n] = useTranslation("global");
  return (
    <IonPage>
      <Header />
      <MNXOContent containerStyle="information-container">
        <div className="information-content ion-padding">
          <h2>
            {rulesOfUse && rulesOfUse.title
              ? rulesOfUse.title
              : t("rules-page.header.title")}
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(rulesOfUse && rulesOfUse.content),
            }}
          ></div>
        </div>
      </MNXOContent>
      <MNXOFooter className="MNXOModalFooter" />
    </IonPage>
  );
};

export default Rules;

export class AuthorizationList {
  authorizations: any;
  constructor(authorizations: any) {
    this.authorizations = authorizations;
  }
}

export class UpdateAuthorizationByAgent {
  uuid: any;
  authorizationStatus: any;
  constructor(uuid: any, authorizationStatus: any) {
    this.uuid = uuid;
    this.authorizationStatus = authorizationStatus;
  }
}

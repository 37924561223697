import {
  IonCol,
  IonContent,
  IonGrid,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
} from "@ionic/react";
import React, { useEffect } from "react";
import Header from "../../../components/Header";
import IncidentsList from "../components/IncidentList";
import { useIncident } from "../hooks/useIncident";
import { useAuth } from "../../../providers/AuthProviders";
import { decodedToken } from "../../../utils/userToken";
import { useTranslation } from "react-i18next";
import MNXOContent from "../../../components/MNXOContent";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";

const Incidents: React.FC = () => {
  const { token } = useAuth();
  const { agentIncidents } = useIncident();
  let email;

  // agentIncidents && // console.log("agentIncidents", agentIncidents);
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    if (token) {
      email = decodedToken(token.access_token).email;
      // Verifica si se ha obtenido un valor de email
      if (email) {
        // Puedes continuar aquí con el código que requiere el valor de email
        // console.log(email);
        // ... más código
      }
    }
  }, [token]); // Asegúrate de incluir token como dependencia para que el efecto se ejecute cuando cambie

  //TODO: usar tables
  //TODO: sorted table
  return (
    <IonPage>
      <Header isWhiteBackground={false} backButtonLink="/dashboard/incident" />
      <MNXOContent
        containerStyle="background-body-color"
        page={""}
        type={""}
        title={t("incidents_page.header.title")}
        text={t("incidents_page.header.text")}
        chipText={t("incidents_page.header.chip")}
      >
        <MNXODescriptiveText text="" subtext=""></MNXODescriptiveText>
        <IonLoading isOpen={false} message={"Loading..."} />
        <IonGrid className="ion-padding" style={{ marginTop: "20px" }}>
          <IonRow>
            <IonCol>
              <IncidentsList
                agentIncidents={agentIncidents && agentIncidents}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </MNXOContent>
    </IonPage>
  );
};

export default Incidents;

import { Translation } from "./Translation";
import { defaultLanguage } from "./VisitingShift";

export class VisitorType {
    alias: string;
    minAge: number;
    maxAge: number;
    isActive: boolean;
    altersCapacity: boolean;
    translations: VisitorTypeTranslation[];

    constructor() {
        this.alias = "";
        this.minAge = 0;
        this.maxAge = 0;
        this.isActive = false;
        this.altersCapacity = false;
        this.translations = [
            new VisitorTypeTranslation(defaultLanguage.ES),
            new VisitorTypeTranslation(defaultLanguage.EN),
        ];
    }
}

export class VisitorTypeTranslation extends Translation {
    name: string;
    description: string;
    subtitle: string;

    constructor(language: string) {
        super(language);
        this.name = ""
        this.description = ""
        this.subtitle = ""
    }
}
import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonIcon,
} from "@ionic/react";
import { close } from "ionicons/icons";
import { useTranslation } from "react-i18next";

interface TypeaheadProps {
  countries: any[];
  selectedCountry: string;
  title?: string;
  onSelectionCancel?: () => void;
  handleCountry: (country: string) => void;
  closeModal: () => void;
}

const MNXOCountrySelector: React.FC<TypeaheadProps> = ({
  countries,
  selectedCountry,
  title,
  onSelectionCancel,
  handleCountry,
  closeModal,
}) => {
  const [t, i18n] = useTranslation("global");
  const [filteredCountries, setFilteredCountries] = useState<any[]>();

  const cancelChanges = () => {
    if (onSelectionCancel !== undefined) {
      onSelectionCancel();
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      // TODO: usar [value, text] como selected country
      const currentCountry = countries.find(
        (country) => country[0] === selectedCountry
      );
      const countryIndex = countries.indexOf(currentCountry);

      const sortedArray: any[] = [...countries];
      sortedArray.splice(countryIndex, 1);
      sortedArray.unshift(currentCountry);
      setFilteredCountries(sortedArray);
    } else {
      setFilteredCountries([...countries]);
    }
  }, []);

  const searchbarInput = (ev: any) => {
    filterList(ev.target.value);
  };

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  const filterList = (searchQuery: string | null | undefined) => {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined || searchQuery === null) {
      setFilteredCountries([...countries]);
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase();
      setFilteredCountries(
        countries.filter((country) => {
          // country[1] = country text
          return country[1].toLowerCase().includes(normalizedQuery);
        })
      );
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar className="custom">
          <IonButtons slot="end">
            <IonIcon
              onClick={() => closeModal()}
              className="close-modal-icon"
              color="lobos-primary"
              icon={close}
            />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
        <IonToolbar className="custom">
          <IonSearchbar
            placeholder={t("button.search")}
            onIonInput={searchbarInput}
          />
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" class="ion-padding">
        <IonList id="modal-list">
          {filteredCountries?.map((item, index) => (
            <IonItem
              key={index}
              className={`${selectedCountry === item[0] && "selected"} `}
              onClick={() => handleCountry(item[0])}
            >
              {item[1]}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </>
  );
};

export default MNXOCountrySelector;

import { useIonLoading } from "@ionic/react";
import { useAppTranslations } from "./useAppTranslations";

// TODO: mejorar para que se active una unica vez y no en cada render

export const useLoading = () => {
  const [present, dismiss] = useIonLoading();
  const { translation } = useAppTranslations();

  const presentLoading = (
    message: string = translation("app_data.loading"),
    duration: any = 1000,
    spinner: any = "bubbles"
  ) => {
    const customDuration = duration === "undefined" ? undefined : 1000;
    // console.log("loading activado");
    present({
      cssClass: "custom-class",
      message: translation("app_data.loading"),
      duration: customDuration,
      spinner: spinner,
    });
  };

  return { presentLoading, dismissLoading: dismiss };
};

export const formatDate = (date: Date) => {
  const formatedDate = date.toISOString().slice(0, 16);
  return formatedDate;
};

export const obtenerFechaActualComoYYYYMMDD = () => {
  const fechaActual = new Date();
  const year = fechaActual.getFullYear();
  const month = String(fechaActual.getMonth() + 1).padStart(2, "0");
  const day = String(fechaActual.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export function stringToLocaleDate(date: string) {
  var splits = date.split("-");

  var dateFormated = splits[2] + "-" + splits[1] + "-" + splits[0];

  return dateFormated;
}

import {
  IonAccordion,
  IonAccordionGroup,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
} from "@ionic/react";
import { appsOutline } from "ionicons/icons";
import React from "react";
import { getDashboardRoutes } from "../routes/menu/dashboard.routes";
import { getAgentRoutes } from "../routes/menu/agent.routes";

import { useLocation } from "react-router";
import {
  PrivateAdminComponent,
  PrivateAgentComponent,
} from "../providers/AuthProviders";
import { useTranslation } from "react-i18next";
import { getAdminRoutes } from "../routes/menu/admin.routes";

const DashboardMenu: React.FC = () => {
  // Obtain the current location of the page
  const location = useLocation();

  // Get the current language and the function to change it
  const [t, i18n] = useTranslation("global");

  // Get the dashboard routes
  const dashboardRoutes = getDashboardRoutes(t);
  const agentRoutes = getAgentRoutes(t);
  const adminRoutes = getAdminRoutes(t);

  // Check if the current location matches the provided route
  const isActivePage = (path: string): boolean => {
    return location.pathname === path;
  };

  return (
    <>
      <IonAccordionGroup>
        <IonAccordion value="second">
          <IonItem
            slot="header"
            className="menu-item-background top-left-radius"
          >
            <IonIcon
              className="ion-icon-inside-menu-item"
              id="icons"
              icon={appsOutline}
            />
            <IonLabel className="">
              {t("app_data.routes.dashboard.title")}
            </IonLabel>
          </IonItem>
          <div slot="content">
            <IonList
              style={{
                paddingLeft: "24px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
              className="menu-lists "
            >
              {/* Botón incidents side menú privado para agentes */}
              {/* <PrivateAdminComponent>
                {adminRoutes.map((item, index) => (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      lines="none"
                      id="item-menu"
                      routerLink={item.url}
                      key={item.title}
                      className={
                        isActivePage(item.url)
                          ? "menu-background2 active item-menu-toggle "
                          : "menu-background2  item-menu-toggle"
                      }
                    >
                     
                      <IonLabel className="label-accordion-menu">
                        {item.title}
                      </IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                ))}
              </PrivateAdminComponent> */}
              {/* <PrivateAgentComponent>
                {agentRoutes.map((item, index) => (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      lines="none"
                      id="item-menu"
                      routerLink={item.url}
                      key={item.title}
                      className={
                        isActivePage(item.url)
                          ? "menu-background2 active item-menu-toggle "
                          : "menu-background2  item-menu-toggle"
                      }
                    >
                      <IonLabel className="label-accordion-menu">
                        {item.title}
                      </IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                ))}
              </PrivateAgentComponent> */}

              {dashboardRoutes.map((item, index) => (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    lines="none"
                    id="item-menu"
                    routerLink={item.url}
                    key={item.title}
                    className={
                      isActivePage(item.url)
                        ? "menu-background2 active  item-menu-toggle"
                        : "menu-background2  item-menu-toggle"
                    }
                  >
                    <IonLabel className="label-accordion-menu">
                      {item.title}
                    </IonLabel>
                  </IonItem>
                </IonMenuToggle>
              ))}
            </IonList>
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </>
  );
};

export default DashboardMenu;

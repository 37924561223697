import React, { useEffect, useState } from "react";
import { IonLoading } from "@ionic/react";

interface LoadingComponentProps {
  isOpen: boolean;
  message: string;
  duration: number;
}

const MNXOLoading: React.FC<LoadingComponentProps> = ({
  isOpen,
  message,
  duration,
}) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (isVisible) {
      setIsVisible(true);
      timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, duration);
    } else {
      setIsVisible(false);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isOpen, duration]);

  return (
    <IonLoading
      cssClass="custom-class"
      isOpen={isVisible}
      message={message}
      duration={duration}
    />
  );
};

export default MNXOLoading;

import { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonMenu,
  IonToolbar,
  IonList,
  IonLabel,
  IonItem,
  IonMenuToggle,
  useIonRouter,
  IonAccordion,
  IonAccordionGroup,
  IonIcon,
  IonCol,
  IonRow,
  IonFooter,
} from "@ionic/react";

import {
  useAuth,
  PrivateDashboardMenu,
  PrivateProfileElement,
  PrivateComponentLogout,
} from "../providers/AuthProviders";

import {
  informationCircleOutline,
  readerOutline,
  receiptOutline,
} from "ionicons/icons";
import { useLocation } from "react-router";
import { getNavigationRoutes } from "../routes/menu/navegation.routes";
import { getInfoAlias, getInfoRoutes } from "../routes/menu/info.routes";
import { useTranslation } from "react-i18next";
import { getAuthRoutes } from "../routes/menu/authorisations.routes";
import { useStorage } from "../hooks/useStorage";
import MNXOContactButton from "./buttons/ContactButton";

// TODO menu desplegable a la izquierda en version desktop

function Menu() {
  const { onLogout } = useAuth();
  const [t, i18n] = useTranslation("global");
  const navigationRoutes = getNavigationRoutes(t);
  const [logged, setLogged] = useState(false);
  const [isItemPressed, setIsItemPressed] = useState(false);
  const STORAGE_KEY = "logged-user";
  const [isLogged, setIsLogged] = useState(false);
  const { data: validUser } = useStorage(STORAGE_KEY);
  const infoRoutes = getInfoRoutes(t);
  const infoAlias = getInfoAlias(t);
  const authRoutes = getAuthRoutes(t);

  const handleItemPress = () => {
    setIsItemPressed(true);
  };

  const navigation = useIonRouter();

  useEffect(() => {
    if (validUser && !Array.isArray(validUser)) {
      setIsLogged(true);
    }
  }, [validUser]);

  const isDesktop = window.innerWidth > 768; // Definir el punto de corte según tus necesidades
  // useEffect(() => {
  //   // window.location.reload();
  // }, [window.innerWidth]);

  const location = useLocation(); // Obtener la ubicación actual de la página

  const isActivePage = (path: string): boolean => {
    // Verificar si la ubicación actual coincide con la ruta proporcionada
    return location.pathname === path;
  };
  return (
    <>
      <IonMenu
        className={isDesktop ? "menu-border" : ""}
        side={isDesktop ? "start" : "end"}
        contentId="main"
        type="overlay"
        disabled={location.pathname === "/" ? true : false}
      >
        <IonContent className="menu-background blue">
          <div className="bottom-right-radius">
            <IonHeader className="header-menu">
              <IonToolbar className="menu-background">
                <IonRow className="background-base-white">
                  <IonCol className="background-base-blue" size="auto">
                    <div className="menu-logo logo">
                      <img
                        src="assets/Logo_Lobos.png"
                        width={"80px"}
                        alt="Islote de Lobos logo"
                      />
                    </div>
                  </IonCol>

                  <IonCol className="menu-logo right-radius" />
                </IonRow>
              </IonToolbar>
            </IonHeader>
            {PrivateProfileElement()}
            {PrivateDashboardMenu()}
            <div className="background-base-white">
              <IonList
                className={`menu-background ${
                  !isLogged && "top-left-radius"
                }  `}
              >
                {navigationRoutes.map((item, index) => (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      lines="none"
                      id="item-menu"
                      routerLink={item.url}
                      key={item.title}
                      className={
                        isActivePage(item.url)
                          ? "active  menu-background2"
                          : "menu-background2"
                      }
                    >
                      <IonIcon
                        className={
                          isActivePage(item.url)
                            ? "active menu-icons"
                            : "menu-icons"
                        }
                        id="icons"
                        icon={item.icon}
                      />
                      <IonLabel>{item.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                ))}
              </IonList>
            </div>
            <IonAccordionGroup>
              <IonAccordion value="first">
                <IonItem slot="header" className="menu-item-background">
                  <IonIcon
                    className="ion-icon-inside-menu-item "
                    id="icons"
                    icon={receiptOutline}
                  />
                  <IonLabel>{t("app_data.routes.auth.title")}</IonLabel>
                </IonItem>
                <div slot="content">
                  <IonList
                    style={{
                      paddingLeft: "24px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                    className="menu-lists"
                  >
                    {authRoutes.map((item, index) => (
                      <IonMenuToggle key={index} autoHide={false}>
                        <IonItem
                          className={
                            isActivePage(item.url)
                              ? " active menu-background2  item-menu-toggle"
                              : "menu-background2  item-menu-toggle"
                          }
                          lines="none"
                          id="item-menu"
                          routerLink={item.url}
                          key={item.title}
                        >
                          {/* <IonIcon
                          className="menu-icons-secondary"
                          id="icons"
                          icon={item.icon}
                        /> */}

                          <IonLabel className="label-accordion-menu">
                            {item.title}
                          </IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    ))}
                  </IonList>
                </div>
              </IonAccordion>
            </IonAccordionGroup>
            <IonAccordionGroup>
              <IonAccordion value="first">
                <IonItem slot="header" className="menu-item-background">
                  <IonIcon
                    className="ion-icon-inside-menu-item "
                    id="icons"
                    icon={informationCircleOutline}
                  />
                  <IonLabel>{infoAlias}</IonLabel>
                </IonItem>
                <div slot="content">
                  <IonList
                    style={{
                      paddingLeft: "24px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                    className="menu-lists"
                  >
                    {infoRoutes?.map((item, index) => (
                      <IonMenuToggle key={index} autoHide={false}>
                        <IonItem
                          className={
                            isActivePage(item.url)
                              ? "active menu-background2 item-menu-toggle"
                              : "menu-background2 item-menu-toggle"
                          }
                          lines="none"
                          id="item-menu"
                          routerLink={item.url}
                          key={item.title}
                        >
                          {/* <IonIcon
                          className="menu-icons-secondary"
                          id="icons"
                          icon={item.icon}
                        /> */}

                          <IonLabel className="label-accordion-menu">
                            {item.title}
                          </IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    ))}
                  </IonList>
                </div>
              </IonAccordion>
            </IonAccordionGroup>
          </div>
        </IonContent>

        <IonFooter className="ion-no-border">
          <IonToolbar className="cabildo-background-base">
            <IonRow className="ion-justify-content-center">
              <IonCol size="auto">
                <MNXOContactButton />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="12" className="cabildo-logo-container base">
                <div className="cabildo-logo-container radius"></div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="2" className="logout-button-col">
                {PrivateComponentLogout()}
              </IonCol>
              <IonCol size="10" className="cabildo-logo-container">
                <IonRow className="ion-justify-content-end">
                  <a href="/login" style={{ width: "90%" }}>
                    <img src="/assets/Logo_Cabildo.png" alt="" />
                  </a>
                </IonRow>
              </IonCol>
            </IonRow>
            {/* <IonMenuToggle className="cabildo-background-base" autoHide={false}>
              {LoggedButton()}
            </IonMenuToggle> */}
          </IonToolbar>
        </IonFooter>
      </IonMenu>
    </>
  );
  // return isDesktop ? <MenuDesktop /> : <Menu />;
}
export default Menu;

import { IonModal } from "@ionic/react";
import React, { useRef } from "react";
import InformationTypeModal from "../useCases/faq/components/InformationTypeModal";
import { useTranslation } from "react-i18next";
import MNXOHeader from "./MNXOHeader";
import MNXODescriptiveText from "./MNXODescriptiveText";

interface Props {
  informationObject: any;
  isOpen: boolean;
  setIsOpen?: any;
  typeofModal: string;
}

const Modal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  typeofModal,
  informationObject,
}) => {
  const [t, i18n] = useTranslation("global");
  const modal = useRef<HTMLIonModalElement>(null);
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
      <MNXOHeader
        text={t("button.edit")}
        title={""}
        type="modal"
        handleClose={handleClose}
      />   
      {typeofModal == "information" && (
        <InformationTypeModal
          infoObject={informationObject}
          setIsOpen={setIsOpen}
        />
      )}
    </IonModal>
  );
};

export default Modal;

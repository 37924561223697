import React, { useEffect, useState } from "react";
import Masonry from "react-responsive-masonry";
import { ResponsiveMasonry } from "react-responsive-masonry";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonRow,
  IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import MNXOZoomModal from "./MNXOZoomImageModal";

interface Props {
  locationPath?: any;
  images: any;
  isModal?: boolean;
  enableZoom?: boolean;
}

const MNXOMasonryGallery: React.FC<Props> = ({
  images,
  isModal,
  enableZoom = true,
}) => {
  const [t, i18n] = useTranslation("global");
  const columnsCountBreakPoints = { 350: 1, 750: 2, 900: 3 };
  const initialImageCount = 4;

  const [showMore, setShowMore] = useState(false);
  const [showImages, setShowImages] = useState<any>([]);
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const location = useLocation();

  useEffect(() => {
    const shuffledImages = images?.sort(() => Math.random() - 0.5);
    setShowImages(shuffledImages);
  }, [images]);

  const visibleImages = showMore
    ? showImages
    : showImages?.slice(0, initialImageCount);

  const handleImageClick = (
    image: any,
    event?: React.MouseEvent<HTMLDivElement>
  ) => {
    // console.log("Clicked image:", image);
    if (event) {
      event.stopPropagation(); // Detiene la propagación del evento
    }
    setSelectedImage(image);
  };

  const handleClose = () => {
    // console.log("Closing modal");
    setSelectedImage(null);
  };

  return (
    <IonGrid className="masonry-container" id="masonry">
      <IonRow style={{ marginBottom: "20px" }}>
        <IonCol>
          {visibleImages?.length > 0 ? (
            <ResponsiveMasonry
              columnsCountBreakPoints={columnsCountBreakPoints}
            >
              <Masonry gutter="10px">
                {visibleImages?.map((image: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="masonry-item"
                      onClick={(event) => handleImageClick(image, event)}
                    >
                      {location.pathname === "/info-interest" ? (
                        <IonImg
                          id="masonry"
                          className="masonry-img"
                          src={
                            location.pathname === "/info-interest"
                              ? image
                              : image.src
                          }
                          alt={
                            location.pathname === "/info-interest"
                              ? "image"
                              : image.alt
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                      ) : (
                        <img
                          id="masonry"
                          className="masonry-img"
                          src={image.src}
                          alt={image.alt}
                        />
                      )}
                    </div>
                  );
                })}
                <IonRow>
                  <IonCol size="12" className="flex-center">
                    {!showMore &&
                    images?.length > initialImageCount &&
                    !isModal ? (
                      <IonButton
                        className="masonry-show-button"
                        size="large"
                        onClick={() => setShowMore(true)}
                      >
                        {t("button.show_more")}
                      </IonButton>
                    ) : !isModal ? (
                      <IonButton
                        className="masonry-show-button"
                        size="large"
                        onClick={() => setShowMore(false)}
                      >
                        {t("button.show_less")}
                      </IonButton>
                    ) : (
                      ""
                    )}
                  </IonCol>
                </IonRow>
              </Masonry>
            </ResponsiveMasonry>
          ) : (
            <IonText>{t("home_page.non_images")}</IonText>
          )}
        </IonCol>
      </IonRow>
      {enableZoom && selectedImage && (
        <MNXOZoomModal
          selectedImage={selectedImage}
          handleClose={handleClose}
        />
      )}
    </IonGrid>
  );
};

export default MNXOMasonryGallery;

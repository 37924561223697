import {
  IonButton,
  IonCol,
  IonFooter,
  IonGrid,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface MNXOFooterProps {
  className?: string;
  background?: string;
}

const MNXOFooter: React.FC<MNXOFooterProps> = ({ className, background }) => {
  const [t, i18n] = useTranslation("global");
  return (
    <IonFooter className={className}>
      <IonToolbar>
        <IonGrid>
          <IonRow className="MNXOFooter-button">
            <IonCol className="flex-center">
              <IonButton
                className="footer"
                onClick={() => window.location.replace("/application")}
              >
                {t("button.new_auth")}
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow className="MNXOFooter-button ion-align-items-center">
            <IonCol className="flex-center">
              <IonButton
                className={`${
                  background === "white"
                    ? "secondary-button"
                    : "secondary-button-blue-background"
                } border-radius  footer`}
                fill="clear"
                onClick={() => window.location.replace("/authorisations")}
              >
                {t("button.already_have")}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonFooter>
  );
};

export default MNXOFooter;

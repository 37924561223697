import { Storage } from "@ionic/storage";
import axios from "axios";
import { useEffect, useState } from "react";
import { checkIfNaturalSpaceTokenExists } from "../utils/userToken";

const NATURAL_SPACE_STORAGE_KEY = "naturalspace";
const API_URL = process.env.REACT_APP_BACKEND_URL_BASE;
const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
const clientSecret = process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET;
axios.defaults.headers.common["client_id"] = clientId;
axios.defaults.headers.common["client_secret"] = clientSecret;

export const useStorage = (storageKey: string) => {
  const [store, setStore] = useState<Storage>();
  const [data, setData] = useState<any | null>(null);
  const [naturalSpaceUUID, setNaturalSpaceUUID] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const initStorage = async () => {
        setLoading(true);
        // Store configuration
        const newStore = new Storage({
          name: "naturalspace",
          storeName: "lobospass",
        });

        const store = await newStore.create();
        setStore(store);

        if (!data) {
          const storedData = (await store!.get(storageKey!)) || [];
          setData(storedData);
        }
      };

      initStorage();
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (naturalSpaceUUID) {
      const manageNaturalSpaceUUID = async () => {
        const isNaturalSpaceToken =
          checkIfNaturalSpaceTokenExists(naturalSpaceUUID);
        isNaturalSpaceToken && setAxiosHeader();
      };
      manageNaturalSpaceUUID();
    }
  }, [naturalSpaceUUID]);

  const getNaturalSpaceUUID = async () => {
    const response = await axios.get(API_URL + "naturalspace");

    // console.log("LLAMADA");
    axios.defaults.headers.common["naturalspaceuuid"] =
      response.data.naturalspaceuuid;
    // axios.defaults.headers.common["naturalspaceuuid"] = response.data;
    // console.log(
    //   "HEADER LLAMADO",
    //   axios.defaults.headers.common["naturalspaceuuid"]
    // );
    setNaturalSpaceUUID(response.data.naturalspaceuuid);
    // setNaturalSpaceUUID(response.data);

    await store?.set(NATURAL_SPACE_STORAGE_KEY, response.data.naturalspaceuuid);
    // await store?.set(NATURAL_SPACE_STORAGE_KEY, response.data);
  };

  const setAxiosHeader = () => {
    axios.defaults.headers.common["naturalspaceuuid"] = naturalSpaceUUID;

    // console.log(
    //   "HEADER SETEADO",
    //   axios.defaults.headers.common["naturalspaceuuid"]
    // );
  };

  const saveStorage = async (dataToStore: any) => {
    setData(dataToStore);
    await store?.set(storageKey, dataToStore);
  };

  const getStoredData = async () => {
    return await store?.get(storageKey);
  };

  const removeStorage = async () => {
    await store?.remove(storageKey);
  };

  const removeAllStorage = async () => {
    await store?.clear();
  };

  return {
    store,
    data,
    loadingStorage: loading,
    getStoredData,
    saveStorage,
    removeStorage,
    removeAllStorage,
    naturalSpaceUUID,
  };
};

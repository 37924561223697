import React from "react";
import CustomDate from "./CustomDate";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
interface CalendarProps {
  availableDays: any;
  selectedDate: any;
  setDate: any;
}

const CustomCalendar: React.FC<CalendarProps> = ({
  availableDays,
  selectedDate,
  setDate,
}) => {
  return (
    <>
      <Swiper
        spaceBetween={10}
        slidesPerView={5}
        slidesOffsetBefore={0}
        pagination={{ clickable: true }}
        className="swiper-container"
      >
        {availableDays.map((availableDay: any, index: number) => (
          <SwiperSlide key={index}>
            <CustomDate
              key={index}
              selectedDate={selectedDate}
              visitingDate={availableDay}
              setDate={setDate}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default CustomCalendar;

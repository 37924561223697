import { IonChip } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useAppTranslations } from "../hooks/useAppTranslations";

interface ContainerProps {
  text: string;
  color?: string;
  translations?: any;
}

type chipProps = {
  text: string | undefined;
  color: string | undefined;
};

const MNXOChip: React.FC<ContainerProps> = ({
  text,
  color = null,
  translations,
}) => {
  const [chipProps, setChipProps] = useState<chipProps>({
    text: undefined,
    color: undefined,
  });

  const { currentLanguage, chipStatuses } = useAppTranslations();

  const {
    created,
    confirmed,
    verificationPending,
    active,
    partiallyChecked,
    checked,
    expired,
    cancelled,
    revoked,
  } = chipStatuses;

  useEffect(() => {
    if (chipStatuses) {
      setStatusColor();
    }
  }, [currentLanguage, text]);

  const setStatusColor = () => {
    const statuses = [
      { name: "CREATED", translation: created, color: "pending" },
      { name: "CONFIRMED", translation: confirmed, color: "okay" },
      {
        name: "VERIFICATION_PENDING",
        translation: verificationPending,
        color: "pending",
      },
      { name: "ACTIVE", translation: active, color: "okay" },
      {
        name: "PARTIALLY_CHECKED",
        translation: partiallyChecked,
        color: "pending",
      },
      { name: "CHECKED", translation: checked, color: "checked" },
      { name: "EXPIRED", translation: expired, color: "expired" },
      { name: "CANCELLED", translation: cancelled, color: "no" },
      { name: "REVOKED", translation: revoked, color: "no" },
    ];

    const currentStatus = statuses.find((item) => item.name === text);

    setChipProps((prev: any) => ({
      ...prev,
      text: currentStatus?.translation,
      color: currentStatus ? currentStatus.color : "created",
    }));
  };

  return (
    <IonChip className={chipProps.color ? chipProps?.color : ""}>
      {chipProps?.text}
    </IonChip>
  );
};

export default MNXOChip;

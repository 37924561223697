import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
  IonToggle,
  useIonLoading,
  useIonRouter,
} from "@ionic/react";
import React, { useState } from "react";
import AlertWithInput from "../../../components/AlertWithInput";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../providers/AuthProviders";
import Header from "../../../components/Header";
import { useStorage } from "../../../hooks/useStorage";
import { Preferences } from "@capacitor/preferences";
import { useToast } from "../../../hooks/useToast";
import axios from "axios";
interface FormData {
  username: string;
  password: string;
}
const LoginForm: React.FC = () => {
  const INTRO_KEY = "intro-seen";

  const STORAGE_KEY = "logged-user";
  const { saveStorage } = useStorage(STORAGE_KEY);
  const { presentToast } = useToast();
  const USER_ADMIN_PW = process.env.REACT_APP_USER_ADMIN_PW;
  const USER_AGENT_PW = process.env.REACT_APP_USER_AGENT_PW;

  const [formValues, setFormValues] = useState<FormData>({
    username: "",
    password: "",
  });

  const [introDone, setIntroDone] = useState(false);
  const [store, setStore] = useState<Storage>();
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    formState: isValid,
    getValues,
    watch,
  } = useForm();

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFormValues((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [present, dismiss] = useIonLoading();
  const { onLogin, onRegister } = useAuth();

  const navigation = useIonRouter();

  const finishIntro = async () => {
    await store?.set(INTRO_KEY, true);
    setIntroDone(true);
  };

  type formCredentials = {
    username: string;
    password: string;
    rol: string;
  };

  const users: formCredentials[] = [
    // agents
    {
      username: "agente1@agente.com",
      password: USER_AGENT_PW!.toString(),
      rol: "agent",
    },

    // admins
    {
      username: "mannix@mnxonline.com",
      password: USER_ADMIN_PW!.toString(),
      rol: "admin",
    },
  ];

  // TODO: pasar a un helper
  const sanitizeString = (string: string, type?: string) => {
    if (type === "password") return string.trim();
    return string.toLowerCase().trim();
  };

  const checkCredentials = (formdata: FormData) => {
    const { username, password } = formdata;

    const isUserValid = users.find(
      (user: any) =>
        user.username === sanitizeString(username) &&
        user.password === sanitizeString(password, "password")
    );

    return isUserValid;
  };

  const storeUserAndNext = async (validUser: any) => {
    const { username, password, rol } = validUser;

    const userCredentials = {
      username: username,
      rol: rol,
    };
    Preferences.set({
      key: STORAGE_KEY,
      value: JSON.stringify(userCredentials),
    });
    await saveStorage(userCredentials);
    axios.defaults.headers.common["agent"] = username;
    navigation.push("/dashboard/main", "forward", "replace");
    window.location.reload();
  };

  const doLogin = async () => {
    const validUser = checkCredentials(formValues);
    validUser
      ? storeUserAndNext(validUser)
      : presentToast("invalid credentials");
  };

  return (
    <IonPage>
      <IonContent className=" lobos-background ion-align-items-center ion-justify-content-center">
        <Header name="" img type="transparent" isWhiteBackground={false} />
        <div>
          <IonGrid>
            <IonRow>
              <IonCol sizeXs="10" offsetXs="1" sizeXl="6" offsetXl="3">
                <form
                  style={{ marginTop: "250px" }}
                  className=""
                  onSubmit={handleSubmit(doLogin)}
                >
                  <IonLabel>
                    <h1 color="light">Login </h1>
                  </IonLabel>
                  <IonInput
                    name="username"
                    style={{ marginTop: "16px" }}
                    type="email"
                    className="custom"
                    label="User"
                    labelPlacement="stacked"
                    fill="outline"
                    value={formValues.username}
                    onIonInput={handleChange}
                  />
                  <IonInput
                    name="password"
                    style={{ marginTop: "16px" }}
                    className="custom"
                    label="Password"
                    labelPlacement="stacked"
                    fill="outline"
                    type="password"
                    value={formValues.password}
                    onIonInput={(e) => handleChange(e)}
                  ></IonInput>

                  <IonToggle labelPlacement="start">
                    <p color="text-black">Remember password</p>
                  </IonToggle>
                  <IonGrid className="">
                    <IonRow>
                      <IonCol className="ion-text-center">
                        <IonButton
                          type="submit"
                          disabled={
                            formValues.password === "" &&
                            formValues.username === ""
                          }
                        >
                          Login
                        </IonButton>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-align-items-center ">
                      <IonCol className="ion-text-center">
                        <IonButton
                          className="ion-padding-top border-radius white"
                          fill="clear"
                          id="recover"
                        >
                          ¿Forgot your password?
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </form>

                <AlertWithInput
                  message="Recover password"
                  subMesage="Enter your Email and we will send you a recovery code."
                  email={watch("username")}
                  trig="recover"
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginForm;

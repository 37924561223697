import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { getHomePageIcons } from "../mockups/icons";
import { useEffect, useState } from "react";
import { Navigation } from "swiper/modules";

const MNXOIconSwiper: React.FC<{
  handleCategorySelect: (category: string) => void;
}> = ({ handleCategorySelect }) => {
  const [t, i18n] = useTranslation("global");

  const iconList = getHomePageIcons(t);

  const [clickedIcon, setClickedIcon] = useState<string | null>(null);

  const handleIconClick = (category: string) => {
    handleCategorySelect(category);
    setClickedIcon(category);
  };
  const [slidesPerView, setSlidesPerView] = useState(3);
  useEffect(() => {
    const updateSlidesPerView = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 768) {
        setSlidesPerView(3); // Muestra 3 en móvil
      } else {
        setSlidesPerView(5); // Muestra 5 en tablet y escritorio
      }
    };

    // Actualiza el número de slides cuando el tamaño de la pantalla cambia
    window.addEventListener('resize', updateSlidesPerView);

    // Limpia el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', updateSlidesPerView);
    };
  }, []); // Se ejecuta solo al montar el componente
  return (
    <div>
      <Swiper
        spaceBetween={4}
        slidesPerView={slidesPerView}
        modules={[Navigation]}
        navigation
        className="icon-swiper"
      >
        {iconList.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={`icon-wrapper `}>
              <div
                onClick={() => handleIconClick(item.label)}
                className={`icon-circle 
              ${clickedIcon === item.label ? "clicked" : ""}`}
              >
                <IonIcon
                  className={`icon-category 
              ${clickedIcon === item.label ? "clicked" : ""}`}
                  icon={item.icon}
                ></IonIcon>
              </div>
              <div className="icon-label-wrapper">
                <p className="icon-label">{item.label}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MNXOIconSwiper;

import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import React from "react";
import Header from "../../../components/Header";
import MNXOContent from "../../../components/MNXOContent";
import MNXODashboardCard from "../components/MNXODashboardCard";
import { useTranslation } from "react-i18next";
import MNXODescriptiveText from "../../../components/MNXODescriptiveText";

const MNXOConfigurationPage: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <IonPage>
      <Header hide={true} backButtonLink={"/dashboard/main"} />
      <MNXOContent
        containerStyle="background-body-color"
        page={""}
        type={""}
        title={t("configuration_page.header.title")}
        text={t("configuration_page.header.text")}
        chipText={t("configuration_page.header.chip")}
      >
        <MNXODescriptiveText text="" subtext=""></MNXODescriptiveText>
        <IonGrid>
          <IonRow className="dashboard-first-row dashboard-row ion-padding subsection">
            <IonCol>
              <MNXODashboardCard
                title={t("configuration_page.information_card.title")}
                // icon={serverOutline}
                link={"/dashboard/configuration/information"}
              />
            </IonCol>
            <IonCol>
              <MNXODashboardCard
                title={t(
                  "configuration_page.visiting_configuration_card.title"
                )}
                // icon={statsChartOutline}
                link={"/dashboard/configuration/visitingconfiguration"}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </MNXOContent>
    </IonPage>
  );
};

export default MNXOConfigurationPage;

const path = "pages.edit_visiting_shift.";

export const getVisitingShiftPageTranslations = (translate: any) => {
    const visitingShiftPageTranslations = {
        title: translate(path + "title"),
        formInputs: {
            maxCapacity: translate(path + "form_inputs.max_capacity"),
            maxVisitorsPerApplication: translate(path + "form_inputs.max_visitors_per_application"),
            isActive: translate(path + "form_inputs.is_active")
        },
        statusCodeMessages: {
            200: translate(path + "status_codes.200"),
            404: translate("errorMessages.404"),
            500: translate("errorMessages.500")
        },
        buttons: {
            save: translate("button.save"),
            remove: translate("button.remove"),
            addOpeningHour: translate("button.add_opening_hour"),
            addTranslation: translate("button.add_translation"),
        }
    }

    return visitingShiftPageTranslations;
}
import { IonCol, IonLabel, IonRow, IonSearchbar } from "@ionic/react";
import { trashBin } from "ionicons/icons";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  text?: string;
  type?: string;
  searchKeyword?: any;
  setSearchKeyword?: any;
}
const MNXOSticker: React.FC<Props> = ({
  text,
  type,
  searchKeyword,
  setSearchKeyword,
}) => {
  const stickerClassName =
    type === "search_bar" ? "search-bar-sticker" : "label-chip ";
  const [t, i18n] = useTranslation("global");

  const inputSearch = (ev: any) => {
    let query = "";
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!;

    setSearchKeyword(query);
  };
  return (
    <div className={`label-chip `}>
      {type === "search_bar" ? (
        <IonRow>
          <IonCol>
            <IonSearchbar
              className="home"
              animated={true}
              placeholder={t("header.chip.search")}
              value={searchKeyword ? searchKeyword : ""}
              onInput={(e) => {
                setSearchKeyword && inputSearch(e);
              }}
              showClearButton="never"
              // ionClear={setSearchKeyword("")}
            />
          </IonCol>
        </IonRow>
      ) : (
        <IonLabel>{text}</IonLabel>
      )}
    </div>
  );
};

export default MNXOSticker;

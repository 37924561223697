import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToggle,
} from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { lobosCoords, useIncident } from "../hooks/useIncident";
import axios from "axios";
import FormData from "form-data";
import MNXOGeolocationMap from "../../application/components/MNXOGeolocationMap";
import { useTranslation } from "react-i18next";
import MNXOModalFooter from "../../../components/MNXOModalFooter";

interface ContainerProps {
  updateIncidentData: any;
  incidentData: any;
  openModal: any;
  takePhoto?: any;
  sendPhoto?: any;
  photos?: any;
  setIsOpen: any;
}

interface coordinates {
  latitude: number;
  longitude: number;
}

const IncidentForm: React.FC<ContainerProps> = ({
  updateIncidentData,
  incidentData,
  takePhoto,
  openModal,
  sendPhoto,
  photos,
  setIsOpen,
}) => {
  const { incidentsAgentList, incidentTypes } = useIncident();
  // console.log(incidentTypes);
  // modal para mostrar según la respuesta del back
  const [isResolved, setIsResolved] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { agents, type, date, description, observations, location } =
    incidentData;

  const [currentCoords, setCurrentCoords] = useState<coordinates>(lobosCoords);
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    updateIncidentData({
      ...incidentData,
      location: {
        latitude: currentCoords.latitude,
        longitude: currentCoords.longitude,
      },
    });
  }, [currentCoords]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    // if (name === "agents") // console.log("agents", value);

    if (name === "address") {
      const updatedLocation = {
        ...incidentData.location,
        [name]: value,
      };

      const updatedIncidentData = {
        ...incidentData,
        location: updatedLocation,
      };
      updateIncidentData(updatedIncidentData);
      checkFormValues(updatedIncidentData);
    } else {
      const updatedIncidentData = {
        ...incidentData,
        [name]: value,
      };
      checkFormValues(updatedIncidentData);
      updateIncidentData(updatedIncidentData);
    }
  };

  // const currentDate = date.toISOString().slice(0, 16);
  // Se envía el formulario de incidencia al backend
  const onSubmit = async (data: any) => {
    data.preventDefault();
    // console.log("DATA", incidentData);
    // await updateIncidentData(data);
    openModal(true);
  };

  const checkFormValues = (updatedIncidentData: any) => {
    const { agents, description, location, incidentType } = updatedIncidentData;
    // console.log("valores a chekear", incidentData);
    if (
      description === "" ||
      location.address === "" ||
      !location.address ||
      incidentType === ""
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  const toggleIsActive = () => {
    setIsResolved(!isResolved);

    const currentStatus = isResolved ? "open" : "closed";

    updateIncidentData({
      ...incidentData,
      incidentStatus: currentStatus,
    });
  };
  const modalDismiss = async () => {
    setIsOpen(false);
  };
  return (
    <>
      {/*Formulario */}
      <IonLoading
        cssClass="custom-class"
        isOpen={false}
        duration={3000}
        message={"Loading..."}
      />
      <form onSubmit={onSubmit}>
        <IonList>
          <IonGrid>
            <IonRow style={{ gap: "16px" }}>
              <IonCol>
                <IonSelect
                  label={t("pages.create_incident.form_inputs.agents")}
                  placeholder={t(
                    "pages.create_incident.form_inputs.placeholder_agent"
                  )}
                  multiple={true}
                  name="agents"
                  onIonChange={(e) => {
                    handleChange(e);
                  }}
                  className="custom "
                  labelPlacement="stacked"
                  fill="outline"
                >
                  {incidentsAgentList?.map((agent: any, index: any) => (
                    <IonSelectOption key={index} value={agent}>
                      {agent.firstName}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
              <IonCol>
                <IonInput
                  label={t("pages.create_incident.form_inputs.date")}
                  type="datetime-local"
                  value={date}
                  placeholder="Insert a date"
                  name="date"
                  onIonChange={(e) => {
                    handleChange(e);
                  }}
                  className="custom input-date"
                  labelPlacement="stacked"
                  fill="outline"
                  style={{ "paddingStart": "16px" }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonSelect
                  label={t("pages.create_incident.form_inputs.type")}
                  placeholder={t(
                    "pages.create_incident.form_inputs.placeholder_type"
                  )}
                  name="incidentType"
                  onIonChange={(e) => {
                    handleChange(e);
                  }}
                  className="custom"
                  labelPlacement="stacked"
                  fill="outline"
                >
                  {incidentTypes?.map((type: any, index: any) => (
                    <IonSelectOption key={index} value={type.uuid}>
                      {type.description}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonTextarea
                  label={t("pages.create_incident.form_inputs.description")}
                  placeholder={t(
                    "pages.create_incident.form_inputs.placeholder_desc"
                  )}
                  autoGrow={true}
                  value={description}
                  name="description"
                  onIonInput={(e) => {
                    handleChange(e);
                  }}
                  className="custom"
                  labelPlacement="stacked"
                  fill="outline"
                  style={{ "--padding-start": "16px", "--padding-end": "16px" }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonTextarea
                  label={t("pages.create_incident.form_inputs.observations")}
                  autoGrow={true}
                  placeholder={t(
                    "pages.create_incident.form_inputs.placeholder_obs"
                  )}
                  name="observations"
                  value={observations}
                  onIonInput={(e) => {
                    handleChange(e);
                  }}
                  className="custom"
                  labelPlacement="stacked"
                  fill="outline"
                  style={{ "--padding-start": "16px", "--padding-end": "16px" }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonInput
                  type="text"
                  label={t("pages.create_incident.form_inputs.address")}
                  placeholder={t(
                    "pages.create_incident.form_inputs.placeholder_address"
                  )}
                  name="address"
                  required
                  value={incidentData.location.address}
                  onIonInput={(e) => {
                    handleChange(e);
                  }}
                  className="custom"
                  labelPlacement="stacked"
                  fill="outline"
                  style={{ "--padding-start": "16px", "--padding-end": "16px" }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ marginTop: "32px" }}>
                <IonLabel className="text-label-incident-form">
                  {t("pages.create_incident.form_inputs.image")}
                </IonLabel>
                <IonItem lines="none">
                  <input
                    type="file"
                    placeholder={t(
                      "pages.create_incident.form_inputs.placeholder_img"
                    )}
                    onChange={(e) => sendPhoto(e)}
                    /* {...register("image1")} */
                    className="custom"
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ marginTop: "32px" }}>
                <IonLabel className="text-label-incident-form">
                  {t("pages.create_incident.form_inputs.location")}
                </IonLabel>
                <div className="ion-padding">
                  <MNXOGeolocationMap
                    currentCoords={currentCoords}
                    setCurrentCoords={setCurrentCoords}
                  />
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ marginLeft: "16px" }}>
                <IonToggle
                  className={isResolved ? "active-toggle" : ""}
                  checked={isResolved}
                  onClick={toggleIsActive}
                >
                  <p>{t("pages.create_incident.form_inputs.is_resolved")}</p>
                </IonToggle>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonList>
        <IonButton
          disabled={!isValid}
          expand="full"
          type="submit"
          shape="round"
        >
          <IonIcon slot="end" />
          {t("button.save")}
        </IonButton>
      </form>
    </>
  );
};

export default IncidentForm;

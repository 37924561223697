import React, { useState } from "react";
import {
  IonCard,
  IonRow,
  IonCol,
  IonCardContent,
  IonText,
  IonGrid,
  IonImg,
} from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { locationOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
interface InterestCardProps {
  imageSrc: string;
  subtitle: string;
  title: string;
  text: string;
  interestPoint?: any;
  currentLanguage?: any;
  onClick: () => void;
}

const MNXOInterestCard: React.FC<InterestCardProps> = ({
  // imageSrc,
  // subtitle,
  // title,
  // text,
  interestPoint,
  currentLanguage,
  onClick,
}) => {
  const [t] = useTranslation("global");

  const [liked, setLiked] = useState(false); // Estado para controlar si le gusta
  const {
    alternateName,
    image,
    imageGallery,
    isActive,
    Location,
    translations,
  } = interestPoint;

  const currentTranslations = translations?.find(
    (translation: any) => translation.language === currentLanguage.toUpperCase()
  );

  const { name, subtitle } = currentTranslations;

  const IMAGE_URL = "../assets/slides/" + image;

  const toggleLike = () => {
    setLiked(!liked); // Cambia el estado al hacer clic en el corazón
  };
  return (
    <>
      {currentTranslations && (
        <IonCard
          className="interest-card ion-align-items-center"
          onClick={onClick}
        >
          <IonGrid>
            <IonRow className="background">
              <IonCol size="10">
                <IonRow>
                  <IonCol size="3" sizeXs="4">
                    <IonImg
                      className="interest-img"
                      src={require(`../assets/slides/${image}`)}
                      alt={name}
                    />
                  </IonCol>
                  <IonCol size="9" sizeXs="8">
                    <IonCardContent className="ion-align-items-center">
                      <IonText className="interest-card-content">
                        <h1>{name}</h1>
                        {/* <div className="rating star-icon-container">
                    <IonIcon className="star-icon" icon={starOutline}></IonIcon>
                    <IonIcon className="star-icon" icon={starOutline}></IonIcon>
                    <IonIcon className="star-icon" icon={starOutline}></IonIcon>
                    <IonIcon className="star-icon" icon={starOutline}></IonIcon>
                    <IonIcon className="star-icon" icon={starOutline}></IonIcon>
                  </div> */}
                      </IonText>
                      <IonText>
                        <p>{subtitle}</p>
                      </IonText>
                      <IonText className="location-text">
                        <IonIcon icon={locationOutline} />
                        <a>{name}</a>
                      </IonText>
                    </IonCardContent>
                    {/* <div className="like-icon" onClick={toggleLike}>
                  {liked ? (
                    <IonIcon icon={heart} style={{ color: "red" }} />
                  ) : (
                    <IonIcon icon={heartOutline} />
                  )}
                </div> */}
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
      )}
    </>
  );
};

export default MNXOInterestCard;

import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import React from "react";
import Header from "../components/Header";

const ErrorPage: React.FC = () => {
  return (
    <IonPage>
      <Header name="Error Page" isWhiteBackground={false} />

      <IonContent className="ion-padding ">
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="10" offsetXs="1" sizeXl="6" offsetXl="3">
              <div
                style={{ marginTop: "70%" }}
                className="container login-form ion-padding  error-page-content"
              >
                <h1>Error!</h1>
                <p>Lo siento, ha ocurrido un error.</p>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ErrorPage;

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonModal,
  IonRow,
} from "@ionic/react";
import React from "react";
import MNXOHeader from "../../../components/MNXOHeader";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";

interface ContainerProps {
  modal: any;
  presentingElement: any;
  data: any;
  dismiss: any;
  isOpen: any;
  onDidDismiss: any;
  isThirdView?: boolean;
}

const MNXOModal: React.FC<ContainerProps> = ({
  modal,
  presentingElement,
  data,
  onDidDismiss,
  isOpen,
  isThirdView,
}) => {
  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });

  const [t, i18n] = useTranslation("global");
  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      ref={modal}
      // canDismiss={canDismiss}
      presentingElement={presentingElement!}
    >
      <MNXOHeader
        text={data && data.title}
        textChip={data && !isThirdView && data.subtitle}
        type="modal"
        title={""}
        isThirdView={isThirdView ? true : false}
      />

      <IonContent className="modal_third_view_content">
        <IonGrid>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol>
              <div
                dangerouslySetInnerHTML={
                  data && { __html: DOMPurify.sanitize(data.content) }
                }
              ></div>
              {/* <IonText>{data && data.content}</IonText> */}
            </IonCol>
          </IonRow>
          <IonRow className="flex-center">
            <IonCol>
              <IonButton onClick={onDidDismiss} style={{ marginTop: "16px" }}>
                {t("button.close")}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default MNXOModal;

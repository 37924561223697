import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonMenuToggle,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import { informationCircle } from "ionicons/icons";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  isOpen: boolean;
  handleClose: () => void;
  handleApplicationStatus?: any;
  applicationData: any;
  sendApplication?: any;
  setShowAlert?: any;
  toConfirm?: boolean;
}

const ApplicationModal: React.FC<ContainerProps> = ({
  isOpen,
  handleClose,
  applicationData,
  handleApplicationStatus,
  sendApplication,
  setShowAlert,
  toConfirm,
}) => {
  const [t, i18n] = useTranslation("global");
  const { visitingDate, applicationStatus, visitingShift, email, visitors } =
    applicationData;

  const [verificationEmail, setVerificationEmail] = useState<any>();
  const [isValid, setIsValid] = useState<boolean>(false);

  const emailVerify = (verificationEmail: any) => {
    if (verificationEmail === email.trim().toLowerCase()) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const confirm = async () => {
    const response = await sendApplication(applicationData);
    const { data, status } = response;

    if (status === 200) {
      handleClose();
      await handleApplicationStatus(data.applicationStatus);
      setShowAlert(true);
    } else {
      // console.log("ERROR", data);
    }
  };

  return (
    <IonModal
      className="modal-container"
      isOpen={isOpen}
      onDidDismiss={handleClose}
    >
      <IonHeader>
        <IonToolbar style={{ paddingInline: "16px" }}>
          <IonTitle>{t("app_form.confirm_data")}</IonTitle>
          <IonButton slot="end" onClick={handleClose}>
            {t("button.back")}
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent className="container">
        <IonItemGroup>
          <IonItemDivider className="ion-padding" color="secondary">
            <IonTitle>{t("app_form.app_info")}</IonTitle>
          </IonItemDivider>
          <IonItem>
            <IonLabel>{t("app_form.date")}</IonLabel>
            <IonText>{visitingDate.substring(0, 10)}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>{t("app_form.shift_label")}</IonLabel>
            <IonText>{t("app_form.name")}</IonText>
          </IonItem>
          {applicationStatus && (
            <IonItem>
              <IonLabel>{t("app_form.shedule")}</IonLabel>
              <IonText>{`From ${visitingShift.openingHour.opens} to ${visitingShift.openingHour.closes}`}</IonText>
            </IonItem>
          )}

          {/* <IonItem>
            <IonLabel>Email</IonLabel>
            <IonText>{email}</IonText>
          </IonItem> */}
          {applicationStatus && (
            <IonItem>
              <IonLabel>{t("app_form.status")}</IonLabel>
              <IonText>{applicationStatus}</IonText>
            </IonItem>
          )}
        </IonItemGroup>
        <IonItemGroup style={{ marginBottom: "16px" }}>
          <IonAccordionGroup>
            <IonAccordion value="first">
              <IonItem slot="header" color="secondary">
                <IonIcon id="icons" icon={informationCircle} />
                <IonLabel>{t("app_form.visitor_label")}</IonLabel>
              </IonItem>
              <div slot="content">
                <IonList>
                  {visitors.map((visitor: any, index: number) => {
                    const {
                      name,
                      age,
                      authorization,
                      country,
                      legalID,
                      visitorType,
                      isPrincipal,
                    } = visitor;
                    const { legalIdType, legalIdNum } = legalID;
                    // visitorType && // console.log("ALIAS", visitorType.alias);
                    return (
                      <IonMenuToggle key={index} autoHide={false}>
                        <IonItem lines="none" color="secondary">
                          <IonLabel>
                            {isPrincipal
                              ? visitorType.alias + " - main visitor"
                              : visitorType.alias}
                          </IonLabel>
                          <IonText>{name}</IonText>
                        </IonItem>
                        <IonItem lines="none">
                          <IonLabel>{t("app_form.age")}</IonLabel>
                          <IonText>{+age}</IonText>
                        </IonItem>
                        <IonItem lines="none">
                          <IonLabel>{t("app_form.country")}:</IonLabel>
                          <IonText>{country}</IonText>
                        </IonItem>
                        <IonItem lines="none">
                          <IonLabel>{t("app_form.type.id")}</IonLabel>
                          <IonText>{legalIdType}</IonText>
                        </IonItem>
                        <IonItem lines="none">
                          <IonLabel>{t("app_form.num_id")}</IonLabel>
                          <IonText>{legalIdNum}</IonText>
                        </IonItem>
                        {authorization && (
                          <>
                            <IonItem>
                              <IonLabel>{t("app_form.status")}</IonLabel>
                              <IonText>
                                {authorization.authorizationStatus}
                              </IonText>
                            </IonItem>

                            <div
                              style={{
                                height: "auto",
                                margin: "0 auto",
                                maxWidth: 128,
                                width: "100%",
                              }}
                            >
                              <QRCode
                                size={256}
                                style={{
                                  height: "auto",
                                  maxWidth: "100%",
                                  width: "100%",
                                }}
                                value={authorization.uuid}
                                viewBox={`0 0 256 256`}
                              />
                            </div>
                          </>
                        )}
                      </IonMenuToggle>
                    );
                  })}
                </IonList>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        </IonItemGroup>

        <IonGrid>
          <IonRow className="ion-align-items-center ion-justify-content-between">
            {toConfirm && (
              <>
                {/* <IonButton size="default" slot="end" onClick={handleClose}>
                Cancel
              </IonButton> */}
                <IonCol size="8">
                  <form>
                    <IonInput
                      type="email"
                      name="email"
                      label="Email"
                      labelPlacement="stacked"
                      fill="outline"
                      helperText="Please, confirm your email"
                      value={verificationEmail}
                      onIonInput={(e) => {
                        setVerificationEmail(e.target.value);
                        emailVerify(e.target.value);
                      }}
                      required
                    />
                  </form>
                </IonCol>
                <IonCol size="auto">
                  <IonButton
                    disabled={isValid ? false : true}
                    size="default"
                    strong={true}
                    onClick={confirm}
                  >
                    {t("button.confirm")}
                  </IonButton>
                </IonCol>
              </>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default ApplicationModal;

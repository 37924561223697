import { useEffect, useState } from "react";
import { useFetchData } from "../../../hooks/useFetchData";
import { useStorage } from "../../../hooks/useStorage";
import axios from "axios";
import { useToast } from "../../../hooks/useToast";
import { useAppTranslations } from "../../../hooks/useAppTranslations";
import { VisitingShift, OpeningHours } from "../../../models/VisitingShift";

const API_URL_BASE = process.env.REACT_APP_BACKEND_URL_BASE;

// ATHOR: JOEL
export const useOpeningHours = () => {
  const { naturalSpaceUUID } = useStorage("naturalspace");
  const { presentToast } = useToast();
  const { translation } = useAppTranslations();
  const {
    data: openingHours,
    loading,
    error,
    fetchData: fetchOpeningHours,
  } = useFetchData();
  const [openingHoursFormData, setOpeningHoursFormData] =
    useState<OpeningHours>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (naturalSpaceUUID) {
          await getOpeningHours();
        }
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, [naturalSpaceUUID]);

  const getOpeningHours = async () => {
    // console.log("llamada");
    const apiURL = API_URL_BASE + "openinghours";
    const data = await fetchOpeningHours(apiURL);
  };

  const updateOpeningHours = async (inputData: any) => {
    const { name, value } = inputData;
    setOpeningHoursFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const presentResponseToast = (statusCode: number) => {
    let message: string;
    const position = "middle";
    const duration = 3000;

    // TODO: improve and use static translations
    switch (statusCode) {
      case 200:
        message = "Todo ha ido bien";
        break;

      default:
        message = "Algo ha fallado";
        break;
    }
    presentToast(message, position, duration);
  };

  const sendOpeningHours = async (method: string = "post") => {
    try {
      const data = openingHoursFormData;

      const config = {
        method: method,
        maxBodyLength: Infinity,
        url: API_URL_BASE + "openinghours",
        data: data,
      };

      const response = await axios.request(config);

      if (response) {
        presentResponseToast(response.status);
        await getOpeningHours();
      }
    } catch (error: any) {
      presentResponseToast(error.response.status);
    }
  };

  return {
    openingHours,
    loading,
    openingHoursFormData,
    setOpeningHoursFormData,
    updateOpeningHours,
    sendOpeningHours,
  };
};

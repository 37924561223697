import { Preferences } from "@capacitor/preferences";
import { History } from "../../../models/History";
import axios from "axios";


const API_URL = process.env.REACT_APP_BACKEND_URL_BASE;
// const API_URL = "http://localhost:3002/naturalspace/";

export const useInformation = () => {



  const updateInformation = async (newInformation: any) => {

    const { alias, translation, aliasToEdit } = newInformation;
    const { title, subtitle, content, language, slug } = translation;

    // TODO: implementar hook useHistory
    const { value } = await Preferences.get({ key: "logged-user" })
    const loggedUser: any = JSON.parse(value!)

    const history = new History()
    history.comment = "Información actualizada"
    history.description = "Descripción por defecto"
    history.modifiedBy = loggedUser.username

    //information to edit object
    const updatedTranslation = {
      informationHistory: history,
      information: {
        alias: aliasToEdit,
        title: title,
        subtitle: subtitle,
        slug: slug,
        content: content,
      },
    };

    const url = API_URL + `information/${alias}/${language}`;
    const response = await axios.patch(url, updatedTranslation);
    const { data, status } = response;
    return { data, status };
  };

  return { updateInformation };
};
